import React from "react";

import styles from "./styles.module.css";


const Checkbox = (props) => {
    return (
        <div
            className={styles.field}
            data-comment={props.dataComment}
        >
            <input
                id={props.name}
                name={props.name}
                type="checkbox"
                className={styles.input}
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            <label
                htmlFor={props.name}
                className={styles.label}
            >
                {props.label}
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    name: "",
    label: "",
    checked: false,
    disabled: false,
    dataComment: "",
    onChange: () => {},
};

export default Checkbox;
