// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._dv6ImjECUPf6_419mUl {\n    background-color: #fff;\n    color: #000;\n\n    height: 60vh;\n\n    min-width: 30rem;\n    max-width: 40rem;\n}\n\n@media only screen and (max-width: 600px) {\n    ._dv6ImjECUPf6_419mUl {\n        height: 100%;\n        width: 100%;\n\n        max-width: 100%;\n    }\n}", "",{"version":3,"sources":["webpack://./base/components/popup-ads-preview/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;;IAEX,YAAY;;IAEZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;;QAEX,eAAe;IACnB;AACJ","sourcesContent":[".popupJuiceStoryAds {\n    background-color: #fff;\n    color: #000;\n\n    height: 60vh;\n\n    min-width: 30rem;\n    max-width: 40rem;\n}\n\n@media only screen and (max-width: 600px) {\n    .popupJuiceStoryAds {\n        height: 100%;\n        width: 100%;\n\n        max-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupJuiceStoryAds": "_dv6ImjECUPf6_419mUl"
};
export default ___CSS_LOADER_EXPORT___;
