import GTag from "juice-base/lib/gtag.js";
import device from "juice-base/lib/device.js";

import api from "juice-app/api.js";
import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import settings from "juice-app/settings.js";


const initGlobalErrorHandler = () => {
    if (!window) {
        return;
    }

    window.onerror = (message, url, line, column, error) => {
        const userSession = storage.session.loadSession()
              || storage.local.loadSession();

        if (!userSession) {
            return false;
        }

        const err = {
            message,
            url,
            line,
            column,
            error: error.toString(),
        };

        api.error.sendError({
            session: userSession,
            error: err,
        });

        return false;
    };
};

const initSiteInfo = async (store) => {
    const res = await api.site.getInfo();

    if (res.ok) {
        if (res?.info?.gtag) {
            GTag.init(res.info.gtag);
        }

        if (res.info.sponsors) {
            store.dispatch(actions.info.setSponsors(res.info.sponsors));
        }
    }
};

const setTitle = () => {
    const els = document.getElementsByTagName("title");

    if (els.length > 0) {
        els[0].textContent = settings.appName;
    }
};

const hideAppLoader = () => {
    const body = document.querySelector("body");

    if (body) {
        body.classList.remove("bodyLoading");
    }

    const els = document.getElementsByClassName("appLoader");

    if (els.length > 0) {
        els[0].remove();
    }
};

export const preInit = (store) => {
    initGlobalErrorHandler();
    initSiteInfo(store);
    setTitle();

    // NOTE: check webpack for global VERSION variable
    store.dispatch(actions.app.setVersion(VERSION)); // eslint-disable-line no-undef
    store.dispatch(actions.device.setIsPWA(device.isPWA));

    store.dispatch(actions.user.setUserLoading(true));
};

export const postInit = () => {
    hideAppLoader();
};
