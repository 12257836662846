import React from "react";

import staticFiles from "juice-base/static-files.js";
import Icon from "juice-base/components/icon/index.js";
import MenuContentRow from "juice-base/components/menu-content-row/index.js";


const StudentOverview = (props) => {
    const renderReadingLevel = () => {
        const icon = (
            <Icon
                url={staticFiles.iconPencilFilled}
                name="Edit grade"
                onClick={props.onEditGrade}
            />
        );

        return (
            <MenuContentRow
                label="Reading level"
                value={props.grade}
                icon={icon}
            />
        );
    };

    const renderQuizScore = () => {
        const val = props.quizAverage ? `${props.quizAverage}%` : "0%";

        return (
            <MenuContentRow
                label="Cumulative quiz average"
                value={val}
            />
        );
    };

    return (
        <div>
            {renderReadingLevel()}
            {renderQuizScore()}
        </div>
    );
};

StudentOverview.defaultProps = {
    grade: "",
    quizAverage: "",
    onEditGrade: () => {},
};

export default StudentOverview;
