import React from "react";

import classNames from "juice-base/lib/class-names.js";
import styles from "./styles.module.css";


const Icon = (props) => {
    if (!props.url || !props.name) {
        return null;
    }

    let iconName = null;

    if (props.withName) {
        iconName = (
            <div className={styles.iconName}>
                {props.name}
            </div>
        );
    }

    const iconClassName = classNames({
        [styles.container]: true,
        [styles.containerWithName]: props.withName,
        [props.className]: props.className,
    });

    return (
        <div
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
            className={iconClassName}
        >
            <img
                src={props.url}
                title={props.name}
                alt={props.name}
                className={styles.img}
            />
            {iconName}
        </div>
    );
};

Icon.defaultProps = {
    url: "",
    name: "",
    className: "",
    withName: false,
    onClick: () => { },
};

export default Icon;
