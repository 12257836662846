import React, { useEffect } from "react";

import staticFiles from "../../static-files.js";

import { groupWordDefinitionsByPos } from "../../project/vocabulary.js";
import Icon from "../icon/index.js";
import IconSoundLoading from "../icon-sound-loading/index.js";
import EventsWrapper from "../events-wrapper/index.js";

import styles from "./styles.module.css";


const WordCard = (props) => {
    const {
        word,
        definitions,
        className,
    } = props;

    if (!word) {
        return null;
    }

    const groupedDefinitions = groupWordDefinitionsByPos(definitions);

    useEffect(() => {
        return () => {
            const ds = groupedDefinitions.map(([pos, gDs]) => {
                const text = [pos];
                gDs.forEach((d, i) => {
                    text.push(`${i + 1}. ${d.definition}.`);
                });

                return text.join(". ");
            });

            props.onStopAll([
                word.word,
                ...ds,
            ]);
        };
    }, []);

    const onSpeech = (text, isDefinition) => {
        if (!text) {
            return;
        }

        if (!props.audio[text]) {
            props.onLoad(text, word.id, isDefinition);
        }
    };

    const renderWordIcon = (text, isDefinition = false) => {
        const { audio } = props;

        if (audio[text] && audio[text].isLoading) {
            return (
                <IconSoundLoading />
            );
        }

        if (audio[text] && audio[text].isPlaying) {
            return (
                <Icon
                    url={staticFiles.iconAudioStop}
                    name="Stop"
                    onClick={() => {
                        props.onStop(text, word.id);
                    }}
                />
            );
        }

        if (audio[text]
            && !audio[text].isLoading
            && !audio[text].isPlaying) {
            return (
                <Icon
                    url={staticFiles.iconAudioPlay}
                    name="Play"
                    onClick={() => {
                        props.onPlay(text, word.id, isDefinition);
                    }}
                />
            );
        }

        return (
            <Icon
                url={staticFiles.iconSound}
                name="Sound"
                onClick={() => {
                    onSpeech(text, isDefinition);
                }}
            />
        );
    };

    const renderDefinitions = () => {
        const ds = groupedDefinitions.map(([pos, groupedDefenitions]) => {
            const lis = [];
            const text = [pos];

            groupedDefenitions.forEach((d, i) => {
                text.push(`${i + 1}. ${d.definition}.`);

                const li = (
                    <li className={styles.definitionPosValue}>
                        {d.definition}
                    </li>
                );

                lis.push(li);
            });

            return (
                <div
                    key={`word-definitions-${word.id}-${pos}`}
                    className={styles.definitionGroup}
                >
                    <div className={styles.defitionPos}>
                        {renderWordIcon(text.join(". "), true)}
                        <div className={styles.defitionPosValue}>
                            {pos}
                        </div>
                    </div>
                    <ul className={styles.definitionPosValues}>
                        {lis}
                    </ul>
                </div>
            );
        });

        return (
            <div className={styles.definitions}>
                {ds}
            </div>
        );
    };

    const classes = [styles.wordCard];

    if (className) {
        classes.push(className);
    }

    return (
        <EventsWrapper className={classes.join(" ")}>
            <div className={styles.wordTitle}>
                Vocabulary
            </div>

            <div className={styles.word}>
                <div className={styles.wordSoundIcon}>
                    {renderWordIcon(word.word)}
                </div>

                <div className={styles.wordValue}>
                    {word.word}
                </div>

                <div className={styles.wordPronunciation}>
                    {word.pronunciation}
                </div>
            </div>

            {renderDefinitions()}
        </EventsWrapper>
    );
};

WordCard.defaultProps = {
    word: null,
    definitions: [],
    audio: {},
    className: "",
    onLoad: () => {},
    onPlay: () => {},
    onStop: () => {},
    onStopAll: () => {},
};

export default WordCard;
