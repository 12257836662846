import Player from "./player.js";


class AudioManager {
    constructor(callback) {
        this.callback = callback;

        this.playNext = {
            groupName: "",
            trackId: 0,
        };

        this.currentAudio = {
            groupName: "",
            trackId: 0,
            isPlaying: false,
        };

        this.filesByGroups = {};

        this.players = {};
    }

    /* --- */

    isCurrentAudio(groupName, trackId) {
        if (this.currentAudio.groupName === groupName) {
            return this.currentAudio.trackId === trackId
                || this.currentAudio.trackId === parseInt(trackId, 10);
        }

        return false;
    }

    clearCurrentAudio() {
        this.currentAudio.groupName = "";
        this.currentAudio.trackId = 0;
        this.currentAudio.isPlaying = false;
    }

    getPlayer(groupName, trackId) {
        if (this.players[groupName]
            && this.players[groupName][trackId]) {
            return this.players[groupName][trackId];
        }

        return null;
    }

    /* --- */

    playerStart(groupName, trackId, file) {
        if (!this.players[groupName]) {
            this.players[groupName] = {};
        }

        this.pauseAll();

        if (!this.players[groupName][trackId]) {
            const player = new Player(file.audioFiles, {
                onEnded: (isNext) => {
                    if (!isNext) {
                        this.currentAudio.isPlaying = false;
                        this.callback(this.getState());
                    }
                },
            });

            player.play();
            this.players[groupName][trackId] = player;

            this.currentAudio.groupName = groupName;
            this.currentAudio.trackId = trackId;
            this.currentAudio.isPlaying = true;

            this.callback(this.getState());
        } else {
            this.currentAudio.groupName = groupName;
            this.currentAudio.trackId = trackId;
            this.currentAudio.isPlaying = true;

            this.players[groupName][trackId].play();

            this.callback(this.getState());
        }
    }

    play(groupName, trackId) {
        this.pauseAll();

        if (this.players[groupName] && this.players[groupName][trackId]) {
            const player = this.players[groupName][trackId];
            player.play();

            this.currentAudio.groupName = groupName;
            this.currentAudio.trackId = trackId;
            this.currentAudio.isPlaying = true;

            this.callback(this.getState());
        } else {
            const group = this.filesByGroups[groupName] || {};
            const file = group[trackId];

            if (file) {
                this.playerStart(groupName, trackId, file);
            }
        }
    }

    pause(groupName, trackId) {
        if (this.players[groupName] && this.players[groupName][trackId]) {
            const player = this.players[groupName][trackId];
            player.pause();

            this.currentAudio.groupName = groupName;
            this.currentAudio.trackId = trackId;
            this.currentAudio.isPlaying = false;

            this.callback(this.getState());
        }
    }

    stop(groupName, trackId) {
        if (this.players[groupName] && this.players[groupName][trackId]) {
            const player = this.players[groupName][trackId];
            player.stop();

            if (this.isCurrentAudio(groupName, trackId)) {
                this.currentAudio.isPlaying = false;
            }

            this.callback(this.getState());
        }
    }

    rewind(groupName, trackId) {
        if (this.players[groupName] && this.players[groupName][trackId]) {
            const player = this.players[groupName][trackId];
            player.rewind();
        }
    }

    forward(groupName, trackId) {
        if (this.players[groupName] && this.players[groupName][trackId]) {
            const player = this.players[groupName][trackId];
            player.forward();
        }
    }

    setPlaybackRate(groupName, trackId, rate) {
        if (this.players[groupName] && this.players[groupName][trackId]) {
            const player = this.players[groupName][trackId];
            player.setPlaybackRate(rate);
        }
    }

    /* --- */

    setPlayNext(groupName, trackId) {
        this.playNext.groupName = groupName;
        this.playNext.trackId = trackId;
    }

    clearPlayNext() {
        this.playNext.groupName = "";
        this.playNext.trackId = "";
    }

    tryPlayNext() {
        const { groupName, trackId } = this.playNext;

        if (groupName && trackId) {
            const group = this.filesByGroups[groupName] || {};
            const file = group[trackId];

            if (file) {
                this.playerStart(groupName, trackId, file);
                this.clearPlayNext();
            }
        }
    }

    setFiles(files) {
        this.filesByGroups = files || {};
        this.callback(this.getState());

        this.tryPlayNext();
    }

    /* --- */

    pauseAll() {
        Object.keys(this.players).forEach((groupName) => {
            const group = this.players[groupName] || {};

            Object.keys(group).forEach((trackId) => {
                const player = group[trackId];

                if (player && player.pause) {
                    player.pause();
                }
            });
        });

        this.clearCurrentAudio();
        this.callback(this.getState());
    }

    stopAll() {
        Object.keys(this.players).forEach((groupName) => {
            const group = this.players[groupName] || {};

            Object.keys(group).forEach((trackId) => {
                const player = group[trackId];

                if (player && player.stop) {
                    player.stop();
                }
            });
        });

        this.clearCurrentAudio();
        this.callback(this.getState());
    }

    stopAllTracks(groupName, trackIds) {
        const groupPlayers = this.players[groupName] || {};

        Object.keys(groupPlayers).forEach((trackId) => {
            if (trackIds.indexOf(trackId) === -1) {
                return;
            }

            const player = groupPlayers[trackId];

            if (player && player.stop) {
                player.stop();
            }

            if (this.isCurrentAudio(groupName, trackId)) {
                this.clearCurrentAudio();
            }
        });

        this.callback(this.getState());
    }

    /* --- */

    getState() {
        const state = {};

        Object.keys(this.filesByGroups).forEach((groupName) => {
            state[groupName] = {};

            const group = this.filesByGroups[groupName] || {};

            Object.keys(group).forEach((trackId) => {
                const file = group[trackId];

                if (file) {
                    const player = this.getPlayer(groupName, trackId);
                    const playbackRate = player ? player.playbackRate : 1;

                    state[groupName][trackId] = {
                        isLoading: file.isLoading,
                        isPlaying: false,
                        playbackRate,
                    };

                    if (this.isCurrentAudio(groupName, trackId)) {
                        state[groupName][trackId].isPlaying = this.currentAudio.isPlaying;
                    }
                }
            });
        });

        return state;
    }
}

export default AudioManager;
