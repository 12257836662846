import React from "react";

import styles from "./styles.module.css";


const Text = (props) => {
    const classes = [
        styles.text,
    ];

    if (props.className) {
        classes.push(props.className);
    }

    if (props.onClick) {
        /* eslint-disable */
        return (
            <div
                className={classes.join(" ")}
                dangerouslySetInnerHTML={{
                    __html: props.children,
                }}
                onClick={props.onClick}
            />
        );
        /* eslint-enable */
    }

    /* eslint-disable */
    return (
        <div
            className={classes.join(" ")}
            dangerouslySetInnerHTML={{
                __html: props.children,
            }}
        />
    );
    /* eslint-enable */
};

Text.defaultProps = {
    className: "",
    children: null,
    onClick: null,
};

export default Text;
