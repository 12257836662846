import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Skeleton = (props) => {
    const skeletonClassName = classNames({
        [styles.skeleton]: true,
        [styles.skeletonAnimation]: props.withAnimation,
        [styles.text]: props.variant === "text",
        [styles.circle]: props.variant === "circle",
        [styles.rect]: props.variant === "rect",
    });

    const skeletStyle = {};

    if (props.height) {
        skeletStyle.height = `${props.height}rem`;
    }

    if (props.width) {
        skeletStyle.width = `${props.width}rem`;
    }

    if (props.fullHeight) {
        skeletStyle.height = "100%";
    }

    if (props.fullWidth) {
        skeletStyle.width = "100%";
    }

    return (
        <div
            className={skeletonClassName}
            style={skeletStyle}
        />
    );
};

Skeleton.defaultProps = {
    variant: "",
    width: 0,
    height: 0,
    fullWidth: false,
    fullHeight: false,
    withAnimation: false,
};

export default Skeleton;
