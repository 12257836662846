import * as types from "./types.js";


const initialState = {
    isUserLoading: false,
    isUserLoaded: false,
    session: "",
    user: {},

    isOptionsLoaded: false,
    options: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_USER_LOADING: {
            return {
                ...state,
                isUserLoading: action.payload.isUserLoading,
            };
        }

        case types.SET_USER_SESSION: {
            return {
                ...state,
                session: action.payload.session,
            };
        }

        case types.SET_USER: {
            return {
                ...state,
                isUserLoading: false,
                isUserLoaded: true,
                user: action.payload.user ?? {},
            };
        }

        case types.CLEAR_USER: {
            return {
                ...state,
                isUserLoading: false,
                isUserLoaded: false,
                user: {},
            };
        }

        case types.SET_USER_OPTIONS: {
            return {
                ...state,
                isOptionsLoaded: true,
                options: action.payload.options,
            };
        }

        case types.SET_USER_OPTION: {
            const { options } = state;
            const { key, value } = action.payload;

            return {
                ...state,
                options: {
                    ...options,
                    [key]: value,
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
