import React from "react";
import ReactPaginate from "react-paginate";

import styles from "./styles.module.css";


const Pagination = (props) => {
    const onChange = (val) => {
        props.onChange(val.selected);
    };

    let prevButton = "<";
    let nextButton = ">";

    if (props.defaultPage === 0) {
        prevButton = null;
    }

    if (props.defaultPage === props.count - 1) {
        nextButton = null;
    }

    return (
        <ReactPaginate
            previousLabel={prevButton}
            nextLabel={nextButton}
            breakLabel="..."
            initialPage={props.defaultPage}
            disableInitialCallback
            pageCount={props.count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={props.pageRangeDisplayed}
            onPageChange={onChange}
            breakClassName={styles.threeDot}
            containerClassName={styles.pagination}
            previousClassName={styles.nextOrPreviousButtons}
            nextClassName={styles.nextOrPreviousButtons}
            pageClassName={styles.page}
            activeClassName={styles.selectedPage}
        />
    );
};

Pagination.defaultProps = {
    onChange: () => {},
    count: 0,
    defaultPage: 0,
    pageRangeDisplayed: 2,
};

export default Pagination;
