import React from "react";

import classNames from "juice-base/lib/class-names.js";
import Error from "juice-base/components/forms/error/index.js";

import styles from "./styles.module.css";


const Input = (props) => {
    const {
        type,
        autoComplete,
        name,
        label,
        value,
        placeholder,
        error,
        maxLength,
        onKeyPress,
        onChange,
        onBlur,
    } = props;

    let inputError = null;

    if (error) {
        inputError = (
            <Error>
                {error}
            </Error>
        );
    }

    let icon = null;

    if (props.iconSrc) {
        icon = (
            <img
                className={styles.inputIcon}
                src={props.iconSrc}
                alt="icon"
            />
        );
    }

    const fieldInputClassName = classNames({
        [styles.fieldInput]: true,
        [styles.fieldInputWithIcon]: icon,
        [styles.fieldInputError]: error,
        [styles.fieldInputTransparent]: props.transparent,
    });

    const inputClassName = classNames({
        [styles.input]: true,
        [styles.fieldInputTransparent]: props.transparent,
    });

    return (
        <div className={styles.field}>
            <label htmlFor={name} className={styles.fieldLabel}>
                {label}
            </label>
            <div className={fieldInputClassName}>
                {icon}
                <input
                    id={name}
                    name={name}
                    type={type}
                    autoComplete={autoComplete}
                    value={value}
                    placeholder={placeholder}
                    onKeyPress={onKeyPress}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    className={inputClassName}
                />
            </div>
            {inputError}
        </div>
    );
};

Input.defaultProps = {
    type: "text",
    autoComplete: "",
    name: "",
    label: "",
    value: "",
    placeholder: "",
    error: "",
    maxLength: 255,
    iconSrc: null,
    transparent: false,
    onKeyPress: () => { },
    onChange: () => { },
    onBlur: () => { },
};

export default Input;
