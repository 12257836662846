import React from "react";

import styles from "./styles.module.css";


const Avatar = (props) => {
    return (
        <div
            className={styles.avatar}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        >
            {props.name || "A"}
        </div>
    );
};

Avatar.defaultProps = {
    name: "A",
    onClick: () => {},
};

export default Avatar;
