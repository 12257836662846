import React from "react";

import { capitalize } from "../../../lib/string.js";

import styles from "./styles.module.css";


const Error = (props) => {
    const { children } = props;

    return (
        <div className={styles.error}>
            {capitalize(children)}
        </div>
    );
};

Error.defaultProps = {
    children: null,
};

export default Error;
