import React from "react";

import Close from "../close/index.js";
import PopupCommon from "../popup-common/index.js";
import AccountSettings from "../account-settings/index.js";

import styles from "./styles.module.css";


const PopupAccountSettings = (props) => {
    return (
        <PopupCommon
            showClose={false}
            closeOnLayoutClick
        >
            <div className={styles.settings}>
                <Close
                    onClose={props.onClose}
                    className={styles.close}
                />
                <AccountSettings
                    settings={props.accountSettings}
                />
            </div>
        </PopupCommon>
    );
};

PopupAccountSettings.defaultProps = {
    accountSettings: [],
    onClose: () => {},
};

export default PopupAccountSettings;
