import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const StandardType = (props) => {
    if (!props.type) {
        return null;
    }

    const [isExpanded, setIsExpanded] = useState(false);

    const onToggle = () => {
        setIsExpanded((val) => !val);
    };

    const renderToggler = () => {
        if (isExpanded) {
            return (
                <img
                    className={styles.togglerImg}
                    src={staticFiles.iconMinusInCircleFlamingo}
                    alt="Shrink"
                />
            );
        }

        return (
            <img
                className={styles.togglerImg}
                src={staticFiles.iconPlusInCircleFlamingo}
                alt="Expand"
            />
        );
    };

    const descClassName = classNames({
        [styles.description]: true,
        [styles.descriptionExpanded]: isExpanded,
    });

    return (
        <div className={styles.standardType}>
            <div className={styles.header}>
                <img
                    className={styles.headerImg}
                    src={staticFiles.standardsFlamingo}
                    alt="Standards"
                />
                <div className={styles.headerTitle}>
                    {props.type.name}
                </div>
                <div
                    className={styles.toggler}
                    onClick={onToggle}
                    onKeyPress={onToggle}
                    role="button"
                    tabIndex="-1"
                >
                    {renderToggler()}
                </div>
            </div>
            <div className={descClassName}>
                <div className={styles.descriptionContent}>
                    <div className={styles.descriptionName}>
                        {props.type.standard}
                    </div>
                    <div>{props.type.details}</div>
                </div>
            </div>
        </div>
    );
};

StandardType.defaultProps = {
    type: null,
};

export default StandardType;
