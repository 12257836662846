const keys = {
    session: "session",

    dailyJuicesDefaultGrades: "dailyJuicesDefaultGrades",
    dailyJuices: "dailyJuices",
    dailyJuiceQuizzes: "dailyJuiceQuizzes",
    dailyJuiceConfetti: "dailyJuiceConfetti",
    teacherSelectedClass: "teacherSelectedClass",
};

/* --- */

export const saveSession = (session) => {
    if (window.localStorage) {
        localStorage.setItem(keys.session, session);
    }
};

export const loadSession = () => {
    if (window.localStorage) {
        return localStorage.getItem(keys.session) || "";
    }

    return "";
};

export const clearSession = () => {
    if (window.localStorage) {
        localStorage.removeItem(keys.session);
    }
};

/* --- */

export const loadDailyJuicesDefaultGrades = () => {
    if (window.localStorage) {
        return localStorage.getItem(keys.dailyJuicesDefaultGrades) || "";
    }

    return "";
};

export const saveDailyJuicesDefaultGrades = (grades) => {
    if (window.localStorage) {
        localStorage.setItem(keys.dailyJuicesDefaultGrades, grades || "");
    }
};

/* --- */

export const loadDailyJuices = () => {
    if (window.localStorage) {
        const juices = localStorage.getItem(keys.dailyJuices) || "[]";

        try {
            return JSON.parse(juices);
        } catch {
            return [];
        }
    }

    return [];
};

export const saveDailyJuices = (juices) => {
    if (window.localStorage) {
        const item = JSON.stringify(juices || "[]");
        localStorage.setItem(keys.dailyJuices, item);
    }
};

/* --- */

export const loadDailyJuiceConfetti = () => {
    if (window.localStorage) {
        const confetti = localStorage.getItem(keys.dailyJuiceConfetti) || "[]";

        try {
            return JSON.parse(confetti);
        } catch {
            return [];
        }
    }

    return [];
};

export const saveDailyJuiceConfetti = (juiceId) => {
    const confetti = loadDailyJuiceConfetti();

    for (let i = 0; i < confetti.length; i += 1) {
        const c = confetti[i];
        if (c.juiceId === juiceId) {
            return;
        }
    }

    confetti.push({ juiceId });

    if (window.localStorage) {
        const item = JSON.stringify(confetti || []);
        localStorage.setItem(keys.dailyJuiceConfetti, item);
    }
};

export const hasDailyJuiceConfetti = (juiceId) => {
    const confetti = loadDailyJuiceConfetti();

    for (let i = 0; i < confetti.length; i += 1) {
        const c = confetti[i];
        if (c.juiceId === juiceId) {
            return true;
        }
    }

    return false;
};

/* --- */

export const saveTeacherSelectedClass = (id) => {
    if (window.localStorage) {
        localStorage.setItem(keys.teacherSelectedClass, id);
    }
};


export const loadTeacherSelectedClass = () => {
    if (window.localStorage) {
        return localStorage.getItem(keys.teacherSelectedClass) || "";
    }

    return "";
};

/* --- */

// TODO: delete with auth
export const loadDailyJuiceQuizzes = () => {
    if (window.localStorage) {
        const quizzes = localStorage.getItem(keys.dailyJuiceQuizzes) || "[]";

        try {
            return JSON.parse(quizzes);
        } catch {
            return [];
        }
    }

    return [];
};

export const loadDailyJuiceQuizzesById = (juiceId) => {
    const ret = [];

    const quizzes = loadDailyJuiceQuizzes();

    for (let i = 0; i < quizzes.length; i += 1) {
        const q = quizzes[i];

        if (q.juiceId === juiceId) {
            ret.push(q);
        }
    }

    return ret;
};

export const saveDailyJuiceQuizzes = (dailyJuiceQuizzes) => {
    if (window.localStorage) {
        const item = JSON.stringify(dailyJuiceQuizzes || "[]");
        localStorage.setItem(keys.dailyJuiceQuizzes, item);
    }
};

export const addDailyJuiceQuiz = (newQuiz) => {
    const quizzes = loadDailyJuiceQuizzes();

    for (let i = 0; i < quizzes.length; i += 1) {
        const q = quizzes[i];

        if (q.juiceId === newQuiz.juiceId
            && q.quizId === newQuiz.quizId
            && q.questionId === newQuiz.questionId) {
            return;
        }
    }

    const quiz = {
        ...newQuiz,
        datetime: new Date().getTime(),
    };

    saveDailyJuiceQuizzes([
        ...quizzes,
        quiz,
    ]);
};

export const delDailyJuiceAllQuizzes = (juiceId) => {
    const quizzes = loadDailyJuiceQuizzes();

    const newQuizzes = [];

    quizzes.forEach((q) => {
        if (q.juiceId !== juiceId) {
            newQuizzes.push(q);
        }
    });

    saveDailyJuiceQuizzes(newQuizzes);
};

export const delDailyJuiceQuiz = (juiceId, quizId) => {
    const quizzes = loadDailyJuiceQuizzes();

    const newQuizzes = [];

    quizzes.forEach((q) => {
        if (q.juiceId !== juiceId || q.quizId !== quizId) {
            newQuizzes.push(q);
        }
    });

    saveDailyJuiceQuizzes(newQuizzes);
};
