import { useState } from "react";


const useWordPopup = () => {
    const [state, setState] = useState({
        isLoading: false,
        word: "",
        errors: {},
    });

    const setLoading = (isLoading, word) => {
        setState((prev) => ({
            ...prev,
            isLoading,
            word,
        }));
    };

    const setError = (word, error) => {
        setState((prev) => ({
            ...prev,
            isLoading: false,
            errors: {
                ...prev.errors,
                [word]: error,
            },
        }));
    };

    const setWord = (word) => {
        setState((prev) => ({
            ...prev,
            isLoading: false,
            word,
        }));
    };

    return {
        state,
        setLoading,
        setError,
        setWord,
    };
};

export default useWordPopup;
