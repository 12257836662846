import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { tryFormatDate, formatDayDate } from "../../lib/date.js";

import "./styles.css";
import styles from "./styles.module.css";


const DailyJuiceCarousel = (props) => {
    const [selectedIndicator, setSelectedIndicator] = useState(0);

    const carouselRef = useRef(null);

    const playInterval = 4000;

    const items = [];

    props.items.forEach((item) => {
        let backgroundImage = null;

        if (item.featuredImage && item.featuredImage.sizes && item.featuredImage.sizes.medium) {
            backgroundImage = item.featuredImage.sizes.medium.src;
        } else if (item.backgroundImage
            && item.backgroundImage.sizes
            && item.backgroundImage.sizes.medium
        ) {
            backgroundImage = item.backgroundImage.sizes.medium.src;
        }

        items.push(
            <div
                className={styles.slide}
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <div className={styles.gradientLayout}>
                    <div className={styles.date}>
                        {tryFormatDate(item.date, formatDayDate)}
                    </div>
                    <div className={styles.title}>
                        {item.title}
                    </div>
                    <Link
                        to={`/daily-juice/${item.juiceId}`}
                        className={styles.juiceButton}
                    >
                        Open daily juice
                    </Link>
                </div>
            </div>,
        );
    });

    const renderIndicator = (clickFn, isSelected, index) => {
        const indicatorClasses = [styles.carouselIndicator];

        if (index < selectedIndicator) {
            indicatorClasses.push(styles.pastIndicator);
        }

        let progress = null;

        if (isSelected) {
            progress = (
                <div
                    style={{
                        animationDuration: `${playInterval}ms`,
                    }}
                    className={styles.indicatorProgress}
                />
            );

            indicatorClasses.push(styles.selectedIndicator);

            if (selectedIndicator !== index) {
                setSelectedIndicator(index);
            }
        }

        return (
            <div
                onClick={clickFn}
                onKeyPress={clickFn}
                role="button"
                tabIndex="-1"
                className={indicatorClasses.join(" ")}
            >
                {progress}
            </div>
        );
    };

    return (
        <div
            className={styles.carouselWrapper}
            data-comment="section-carousel"
        >
            <Carousel
                ref={carouselRef}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                stopOnHover={false}
                useKeyboardArrows={false}
                onChange={() => {
                    if (carouselRef.current) {
                        carouselRef.current.resetAutoPlay();
                    }
                }}
                swipeable
                autoPlay
                interval={playInterval}
                infiniteLoop
                emulateTouch
                renderIndicator={renderIndicator}
            >
                {items}
            </Carousel>
        </div>
    );
};

DailyJuiceCarousel.defaultProps = {
    items: [],
};

export default DailyJuiceCarousel;
