import user from "./user.js";
import dailyJuice from "./daily-juice.js";


const locationIds = {
    dashboard: 0,
    dailyJuice: 1,
    vocabulary: 2,
    myAccount: 3,
    login: 4,
    logout: 5,
    signup: 6,
    recoverPassword: 7,
    class: 9,
    search: 10,
    home: 11,
    changePassword: 12,
    student: 13,
};

const eventIds = {
    juiceOpen: 0,
    juiceClose: 1,
    juiceStoryOpen: 2,
    juiceStoryClose: 3,
    juiceStoryReadStory: 4,
    juiceStoryExtraJuiceOpen: 5,
    juiceStoryExtraJuiceClose: 6,
    juiceStoryExtraJuiceVocabOpen: 7,
    juiceStoryVideoPlay: 8,
    juiceStoryVideoPause: 9,
    juiceStoryVocabOpen: 10,
    juiceStoryVocabClose: 11,
    studentHomeVideoOpen: 12,
    studentHomeVideoPlay: 13,
    studentHomeVideoPause: 14,
    juiceStoryImageOpen: 15,
    juiceStoryImageClose: 16,
    juiceStoryQuizQuestionOpen: 17,
    juiceStoryQuizQuestionClose: 18,
    juiceStoryQuizQuiestionSubmit: 19,
    juiceSponsorHome: 20,
    juiceSponsorFirst: 21,
    juiceSponsorLast: 22,
    juiceStoryWordSpeechPause: 23,
    juiceStoryWordSpeechPlay: 24,
    juiceStoryVideoEnd: 25,
    userLogin: 26,
    userLogout: 27,
    extraJuiceOpenFromWordInStory: 28,
    extraJuiceOpenFromButtonInStory: 29,
    extraJuiceOpenFromExplore: 30,
    extraJuiceReadMore: 31,
    juiceStoryDefinitionPlay: 32,
    dailyJuiceStoryListen: 33,
    vocabOpenFromExtraJuice: 34,
    juiceStoryQuizQuiestionAnswerClick: 35,
    classAddStudent: 36,
    classDeleteStudent: 37,
    classEditStudent: 38,
    classResetPassword: 39,
    classChangeClass: 40,
    classOpenStudentInfo: 41,
    teacherDailyJuiceClose: 41,
    teacherDailyJuiceOpen: 42,
    teacherDailyJuiceExtraJuiceOpen: 43,
    teacherDailyJuiceExtrajuiceVocabOpen: 44,
    onSearch: 45,
    searchStoryClick: 46,
    searchFilterClick: 47,
    teacherHomeChangeClass: 48,
    teacherHomeEditClassAnnouncement: 49,
    teacherHomeUploadAnImage: 50,
    teacherDailyJuiceVideoStart: 51,
    teacherDailyJuiceVideoPause: 52,
    teacherDailyJuiceDefinitionPlay: 53,
    teacherDailyJuicePronouncePlay: 54,
    teacherHomeViewAllScores: 55,
    userChangePassword: 56,
    guardianHomeSelectStudent: 57,
    guardianStudentEditStudent: 58,
    guardianStudentSelectStudent: 59,
    dailyJuiceOnQuizAnswerClick: 60,
};


const parsePathname = (pathname) => {
    let newPath = "";

    if (pathname) {
        newPath = pathname.split("/").filter((path) => path !== "");
    }

    const loc = newPath[0] || "";

    if (loc === "") {
        return locationIds.home;
    }

    if (loc === "daily-juice") {
        return locationIds.dailyJuice;
    }

    if (loc === "class") {
        return locationIds.class;
    }

    if (loc === "search") {
        return locationIds.dailyJuice;
    }

    if (loc === "user" && newPath[1] === "profile") {
        return locationIds.myAccount;
    }

    return undefined;
};

export default (methods) => {
    const ids = {
        location: locationIds,
        event: eventIds,
    };

    return {
        user: user({ ...methods, parsePathname }, ids),

        dailyJuice: dailyJuice(methods, ids),

        // -------- old events --------
        dailyJuicesHomePageSponsor(params) {
            methods.gtag.dailyJuicesOnClickHomePageSponsor(params.grades, params.classSlug);

            methods.methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceSponsorHome,
            });
        },

        dailyJuiceFirstPageSponsor(params) {
            methods.gtag.dailyJuiceOnClickFirstPageSponsor(
                params.juiceId,
                params.grades,
                params.classSlug,
            );

            methods.methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceSponsorFirst,
                objectId: params.juiceId,
            });
        },

        dailyJuiceOnClickReadStory(params) {
            methods.gtag.dailyJuiceOnClickReadStory(
                params.juiceId,
                params.postId,
                params.grades,
                params.classSlug,
            );

            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceStoryReadStory,
                objectId: params.juiceId,
                subObjectId: params.postId,
            });
        },

        extraJuiceReadMore(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.extraJuiceReadMore,
            });
        },

        dailyJuiceOnOpenQuiz(params) {
            // TODO
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceStoryQuizQuestionOpen,
            });
        },
        // -------- old events --------

        studentHomeVideoOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.studentHomeVideoOpen,
                objectId: params.videoId,
            });
        },

        studentHomeVideoPlay(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.studentHomeVideoPlay,
                objectId: params.videoId,
            });
        },

        studentHomeVideoPause(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.studentHomeVideoPause,
                objectId: params.videoId,
            });
        },

        /* --- */

        classAddStudent(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.class,
                eventId: eventIds.classAddStudent,
                objectId: params.classId,
            });
        },

        classDeleteStudent(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.class,
                eventId: eventIds.classDeleteStudent,
                objectId: params.classId,
            });
        },

        classEditStudent(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.class,
                eventId: eventIds.classEditStudent,
                objectId: params.classId,
            });
        },

        classResetPassword(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.class,
                eventId: eventIds.classResetPassword,
                objectId: params.classId,
            });
        },

        classChangeClass(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.class,
                eventId: eventIds.classChangeClass,
                objectId: params.classId,
            });
        },

        classOpenStudentInfo(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.class,
                eventId: eventIds.classOpenStudentInfo,
                objectId: params.classId,
            });
        },

        /* --- */

        teacherDailyJuiceOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceOpen,
                objectId: params.juiceId,
            });
        },

        teacherDailyJuiceClose(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceClose,
            });
        },

        teacherDailyJuiceExtraJuiceOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceExtraJuiceOpen,
                objectId: params.juiceId,
                subObjectId: params.extraJuiceId,
            });
        },

        teacherDailyJuiceExtrajuiceVocabOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceExtrajuiceVocabOpen,
                objectId: params.extraJuiceId,
                subObjectId: params.wordId,
            });
        },

        teacherDailyJuiceVideoStart(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceVideoStart,
                objectId: params.storyId,
                subObjectId: params.videoId,
            });
        },

        teacherDailyJuiceVideoPause(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceVideoPause,
                objectId: params.storyId,
                subObjectId: params.videoId,
            });
        },

        // TODO:
        teacherDailyJuiceDefinitionPlay(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceDefinitionPlay,
                objectId: params.wordId,
            });
        },

        teacherDailyJuicePronouncePlay(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuicePronouncePlay,
                objectId: params.wordId,
            });
        },

        /* --- */

        onSearch(params) {
            methods.gtag.onSearch({
                userId: params.userId,
                grades: params.grades,
                word: params.word,
            });

            methods.sendEvent({
                session: params.session,
                locationId: locationIds.search,
                eventId: eventIds.onSearch,
            });
        },

        searchStoryClick(params) {
            methods.gtag.searchStoryClick({
                userId: params.userId,
                grades: params.grades,
                storyId: params.storyId,
            });

            methods.sendEvent({
                session: params.session,
                locationId: locationIds.search,
                eventId: eventIds.searchStoryClick,
                objectId: params.storyId,
            });
        },

        searchFilterClick(params) {
            methods.gtag.searchFilterClick({
                userId: params.userId,
                grades: params.grades,
            });

            methods.sendEvent({
                session: params.session,
                locationId: locationIds.search,
                eventId: eventIds.searchFilterClick,
            });
        },

        /* --- */

        teacherHomeChangeClass(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.teacherHomeChangeClass,
                objectId: params.classId,
            });
        },

        teacherHomeEditClassAnnouncement(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.teacherHomeEditClassAnnouncement,
                objectId: params.classId,
            });
        },

        teacherHomeUploadAnImage(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.teacherHomeUploadAnImage,
                objectId: params.classId,
            });
        },

        teacherHomeViewAllScores(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.teacherHomeViewAllScores,
            });
        },

        /* --- */

        guardianHomeSelectStudent(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.guardianHomeSelectStudent,
                objectId: params.studentId,
            });
        },

        guardianStudentEditStudent(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.student,
                eventId: eventIds.guardianStudentEditStudent,
                objectId: params.studentId,
            });
        },

        guardianStudentSelectStudent(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.student,
                eventId: eventIds.guardianStudentSelectStudent,
                objectId: params.studentId,
            });
        },
    };
};
