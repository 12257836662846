import React from "react";

import styles from "./styles.module.css";


const ButtonWithIcon = (props) => {
    const classes = [styles.button];

    return (
        <button
            type={props.type} // eslint-disable-line react/button-has-type
            className={classes.join(" ")}
            onClick={props.onClick}
            onKeyPress={props.onClick}
        >
            <img
                src={props.iconUrl}
                title={props.iconName}
                alt={props.iconName}
                className={styles.img}
            />

            {props.children}
        </button>
    );
};

ButtonWithIcon.defaultProps = {
    iconUrl: "",
    iconName: "",
    type: "submit",
    children: null,
    onClick: () => {},
};

export default ButtonWithIcon;
