import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import staticFiles from "juice-base/static-files.js";
import { tryFormatDate, formatDayMonthYearDate } from "juice-base/lib/date.js";

import { withAuth } from "juice-base/components/auth/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Dropdown from "juice-base/components/dropdown/index.js";

import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getDefaultFilterGrades = (grades) => {
    let defaultValue = "";
    const defaultGrades = storage.local.loadDailyJuicesDefaultGrades();

    if (grades && grades.length > 0) {
        if (defaultGrades) {
            for (let i = 0; i < grades.length; i += 1) {
                const grade = grades[i];
                if (grade.value === defaultGrades) {
                    defaultValue = { ...grade };
                    break;
                }
            }
        } else {
            defaultValue = grades[settings.grades.length - 1];
        }
    }

    return defaultValue;
};

const StudentIndexTrial = () => {
    const [filterByGrade, setFilterByGrade] = useState(() => {
        const gs = getDefaultFilterGrades(settings.grades);
        return gs ? gs.value : "";
    });

    const store = useSelector((state) => ({
        session: state.user.session,
        juices: state.juices.juices,
        isJuicesLoaded: state.juices.isJuicesLoaded,
        isJuicesLoading: state.juices.isJuicesLoading,
        juicesPage: state.juices.juicesPage,
        juicesHasMorePages: state.juices.juicesHasMorePages,
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    const loadJuices = (
        page = store.juicesPage,
        grades = filterByGrade,
        clearJuices = false,
    ) => {
        if (clearJuices) {
            dispatch(actions.juices.clearJuices());
        }

        dispatch(actions.juices.setJuicesPage({
            juicesPage: page,
        }));

        dispatch(actions.juices.setJuicesLoading({
            isJuicesLoading: true,
        }));

        api.dailyJuices.getJuicesByPage({
            session: store.session,
            grades: grades.split("-").join(",").toLowerCase(),
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setJuices({
                    juices: clearJuices ? res.juices : store.juices.concat(res.juices),
                    hasMorePages: res.hasMore,
                }));
            }
        });
    };

    useEffect(() => {
        if (store.juicesPage === 0 && !store.isJuicesLoaded) {
            loadJuices();
        }
    }, [store.session]);

    const onOpenJuice = (juiceId) => {
        history.push(`/daily-juice/${juiceId}`);
    };

    const onChangeGrade = (value) => {
        const newGrade = value.value;
        setFilterByGrade(newGrade);
        storage.local.saveDailyJuicesDefaultGrades(newGrade);

        dispatch(actions.juices.setLastVisitedJuice({
            lastVisitedJuice: null,
        }));

        loadJuices(0, newGrade, true);
    };

    const onLoadMore = () => {
        loadJuices(store.juicesPage + 1);
    };

    const renderGradeFilter = () => {
        return (
            <Dropdown
                defaultValueLabel="Reading level"
                values={settings.grades}
                selectedValue={filterByGrade}
                orangeTheme
                iconContainerClassName={styles.dropdownIcon}
                icon={{
                    src: staticFiles.readingLevel,
                    alt: "Reading level",
                    title: "Reading level",
                }}
                onChange={onChangeGrade}
            />
        );
    };

    const renderJuices = () => {
        if (store.isJuicesLoading && store.juices.length === 0) {
            return (
                <RequestLoader />
            );
        }

        const js = store.juices.map((j) => {
            const title = [];

            if (j.juiceDate) {
                const juiceDate = tryFormatDate(j.juiceDate, formatDayMonthYearDate);
                title.push(juiceDate);
            } else if (j.title) {
                title.push(j.title);
            }

            let featuredImage = null;

            if (j
                && j.featuredImage
                && j.featuredImage.sizes
                && j.featuredImage.sizes.thumbnail) {
                featuredImage = (
                    <img
                        src={j.featuredImage.sizes.thumbnail.src}
                        alt="Featured"
                    />
                );
            }

            return (
                <div
                    className={styles.juice}
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                        onOpenJuice(j.id);
                    }}
                    onKeyPress={() => {
                        onOpenJuice(j.id);
                    }}
                >
                    <div className={styles.featuredImage}>
                        {featuredImage}
                    </div>

                    <div>{title.join(" - ")}</div>

                    <div className={styles.openStoryIcon}>
                        <img
                            className={styles.readStoryIcon}
                            src={staticFiles.notebookOrange}
                            alt="Open story"
                        />
                        <img
                            className={styles.goToStoryArrow}
                            src={staticFiles.arrowRight}
                            alt="Go to story"
                        />
                    </div>
                </div>
            );
        });

        if (js.length === 0) {
            const noJuices = (
                <div className={styles.noJuices}>
                    No juices
                </div>
            );

            js.push(noJuices);
        }

        return (
            <div className={styles.juices}>
                {js}
            </div>
        );
    };

    const renderLoadMoreButton = () => {
        if (!store.isJuicesLoaded
            || store.juices.length === 0
            || !store.juicesHasMorePages) {
            return null;
        }

        if (store.isJuicesLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div
                className={styles.loadMoreButton}
                tabIndex="-1"
                role="button"
                onClick={onLoadMore}
                onKeyPress={onLoadMore}
            >
                Load More
            </div>
        );
    };

    if (!store.isJuicesLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {/* TODO: move backgroundLayout to component */}
            <div className={styles.backgroundLayout} />

            <div className={styles.index}>
                {renderGradeFilter()}
                {renderJuices()}
                {renderLoadMoreButton()}
            </div>
        </>
    );
};

export default withAuth([
    "student",
    "guardian",
])(StudentIndexTrial);
