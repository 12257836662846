// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xj3akmSZlG2e7_qR_yp2 {\n    padding: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-student-info/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".loader {\n    padding: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "xj3akmSZlG2e7_qR_yp2"
};
export default ___CSS_LOADER_EXPORT___;
