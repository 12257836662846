import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import Icon from "juice-base/components/icon/index.js";
import JuiceContent from "juice-base/components/juice-content/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";

import styles from "./styles.module.css";


const PopupExtraJuice = (props) => {
    const { storyId, extraJuice } = props;

    if (storyId === -1 || !extraJuice) {
        return null;
    }

    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    const renderTitle = () => {
        return (
            <div className={styles.headerTitle}>
                <img
                    src={staticFiles.iconOrange}
                    alt="Orange"
                    title="Orange"
                    className={styles.headerTitleImg}
                />
                <div className={styles.headerTitleText}>
                    {extraJuice.title}
                </div>
            </div>
        );
    };

    const renderPlayer = () => {
        if (!props.audio) {
            return null;
        }

        return (
            <PlayerAudio
                image={extraJuice.image || ""}
                title={extraJuice.title}
                category="Extra Juice"
                audio={props.audio}
                isExpanded={isPlayerExpanded}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                onExpand={() => {
                    setIsPlayerExpanded((prev) => !prev);
                }}
                onClose={props.onPlayerClose}
            />
        );
    };

    const audioPlayer = (
        <Icon
            url={staticFiles.iconPlayerHeadphone}
            name="Headphone"
            className={styles.topTitleHeadphone}
            onClick={() => {
                if (!props.audio) {
                    props.onAudioPlay();
                }
            }}
        />
    );

    const titleBoxStyles = {};

    if (extraJuice.image) {
        titleBoxStyles.backgroundImage = `url(${extraJuice.image})`;
    }

    return (
        <PopupWindow
            title={renderTitle()}
            rightControl={[audioPlayer]}
            player={renderPlayer()}
            playerExpanded={isPlayerExpanded}
            onClose={props.onClose}
        >
            <div className={styles.popupContent}>
                <div
                    className={styles.titleBox}
                    style={titleBoxStyles}
                >
                    {extraJuice.title}
                </div>

                <JuiceContent
                    storyId={props.storyId}
                    content={extraJuice.content}
                    onWordClick={(word) => {
                        props.onWordClick(word);
                    }}
                    className={styles.content}
                />
            </div>
        </PopupWindow>
    );
};

PopupExtraJuice.defaultProps = {
    storyId: -1,
    extraJuice: null,
    onWordClick: () => {},

    onFirstPlay: () => {},
    onPlay: () => {},
    onPause: () => {},
    onRewind: () => {},
    onForward: () => {},
    onChangeRate: () => {},
    onPlayerClose: () => {},

    onClose: () => {},
};

export default PopupExtraJuice;
