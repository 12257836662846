
export const scrollToTop = () => {
    if (window.scroll) {
        window.scroll({
            top: 0,
            left: 0,
        });
    } else if (window.scrollTo) {
        window.scrollTo(0, 0);
    } else if (window.scrollTop) {
        window.scrollTop = 0;
    }
};

export const hideBodyScroll = () => {
    const body = window.document.getElementsByTagName("body");

    if (body && body[0] && body[0].style) {
        body[0].style.overflowY = "hidden";
    }
};

export const unhideBodyScroll = () => {
    const body = window.document.getElementsByTagName("body");

    if (body && body[0] && body[0].style) {
        body[0].style.overflowY = "auto";
    }
};

export default {
    scrollToTop,
    hideBodyScroll,
    unhideBodyScroll,
};
