// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zJW_GoXPB8CZTE7kz1cm,\n.a5nJHINNCN5b0CtftBck,\n.MWXJ7YusSpLbaUf7NoLn,\n._qJVxhN4OkovZiK1JjBy {\n   display: grid;\n   align-content: center;\n\n   border-radius: 2rem;\n\n   width: fit-content;\n   height: fit-content;\n\n   padding: 0.7rem 1.2rem;\n\n   font-weight: 600;\n   font-size: 1.4rem;\n}\n\n.zJW_GoXPB8CZTE7kz1cm {\n   color: #093C51;\n   background: #A7DCEE;\n}\n\n.a5nJHINNCN5b0CtftBck {\n   color: #EF3F5E;\n   background: #FCE8EB;\n}\n\n.MWXJ7YusSpLbaUf7NoLn {\n   color: #14B981;\n   background: #E2F6EF;\n}\n\n._qJVxhN4OkovZiK1JjBy {\n   color: #F29D0A;\n   background: #FDF3E1;\n}\n\n.ZL_u2MUDEF2t4cWJJCH8 img,\n.OIiqr2ZXXf2vBzsfDGdp img {\n   width: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/icon-status/styles.module.css"],"names":[],"mappings":"AAAA;;;;GAIG,aAAa;GACb,qBAAqB;;GAErB,mBAAmB;;GAEnB,kBAAkB;GAClB,mBAAmB;;GAEnB,sBAAsB;;GAEtB,gBAAgB;GAChB,iBAAiB;AACpB;;AAEA;GACG,cAAc;GACd,mBAAmB;AACtB;;AAEA;GACG,cAAc;GACd,mBAAmB;AACtB;;AAEA;GACG,cAAc;GACd,mBAAmB;AACtB;;AAEA;GACG,cAAc;GACd,mBAAmB;AACtB;;AAEA;;GAEG,WAAW;AACd","sourcesContent":[".iconOpened,\n.iconUnopened,\n.iconComplete,\n.iconInProgress {\n   display: grid;\n   align-content: center;\n\n   border-radius: 2rem;\n\n   width: fit-content;\n   height: fit-content;\n\n   padding: 0.7rem 1.2rem;\n\n   font-weight: 600;\n   font-size: 1.4rem;\n}\n\n.iconOpened {\n   color: #093C51;\n   background: #A7DCEE;\n}\n\n.iconUnopened {\n   color: #EF3F5E;\n   background: #FCE8EB;\n}\n\n.iconComplete {\n   color: #14B981;\n   background: #E2F6EF;\n}\n\n.iconInProgress {\n   color: #F29D0A;\n   background: #FDF3E1;\n}\n\n.iconCheck img,\n.iconUncheck img {\n   width: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconOpened": "zJW_GoXPB8CZTE7kz1cm",
	"iconUnopened": "a5nJHINNCN5b0CtftBck",
	"iconComplete": "MWXJ7YusSpLbaUf7NoLn",
	"iconInProgress": "_qJVxhN4OkovZiK1JjBy",
	"iconCheck": "ZL_u2MUDEF2t4cWJJCH8",
	"iconUncheck": "OIiqr2ZXXf2vBzsfDGdp"
};
export default ___CSS_LOADER_EXPORT___;
