import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import RequestLoader from "juice-base/components/request-loader/index.js";


const Auth = (props) => {
    const history = useHistory();

    const store = useSelector((state) => state.user);

    if (store.isUserLoading) {
        return (
            <div>
                <RequestLoader />
            </div>
        );
    }

    if (!store.user.userId) {
        history.push("/user/sign-in");
        return null;
    }

    let isValidRole = false;

    for (let i = 0; i < store.user.roles.length; i += 1) {
        const userRole = store.user.roles[i];

        if (props.roles.indexOf(userRole) !== -1) {
            isValidRole = true;
            break;
        }
    }

    if (!isValidRole) {
        history.push("/user/sign-in");
        return null;
    }

    return props.children;
};

Auth.defaultProps = {
    roles: [],
};


export const withAuth = (roles) => (Component) => {
    return (props) => {
        /* eslint-disable */
        return (
            <Auth roles={roles}>
                <Component {...props} />
            </Auth>
        );
        /* eslint-enable */
    };
};

export default Auth;
