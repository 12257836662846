import React from "react";

import EventsWrapper from "../events-wrapper/index.js";
import Close from "../close/index.js";
import PopupScroller from "../popup-scroller/index.js";

import styles from "./styles.module.css";


const PopupText = (props) => {
    const { lines } = props;

    return (
        <PopupScroller
            onClose={props.onClose}
            showClose={false}
            popupClasses={[
                styles.overlappedPopup,
                props.popupClasses,
            ].join(" ")}
        >
            <EventsWrapper className={styles.textPopup}>
                <div className={styles.textContainer}>
                    <Close
                        onClose={props.onClose}
                        className={styles.close}
                    />
                    <div>
                        {lines.map((line) => <div>{line}</div>)}
                    </div>
                </div>
            </EventsWrapper>
        </PopupScroller>
    );
};

PopupText.defaultProps = {
    lines: [],
    popupClasses: "",
    onClose: () => {},
};

export default PopupText;
