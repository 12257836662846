import React from "react";

import { Formik } from "formik";

import { hasValue } from "juice-base/lib/object.js";
import * as v from "juice-base/lib/form-validators.js";

import MessageDanger from "juice-base/components/message-danger/index.js";
import MessagePassword from "juice-base/components/message-password/index.js";
import Password from "juice-base/components/forms/password-custom/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const UserSignUpPasswordForm = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return !hasValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsPassword = v.validate(values.newPassword, [
            v.required("Please enter password"),
            v.min(`Minimum password length is ${props.passwordMinLength}`, props.passwordMinLength),
            v.hasUpperCase("Please use at least one upper case letter"),
            v.hasLowerCase("Please use at least one lower case letter"),
            v.hasSymbol("Please use at least one symbol letter"),
        ]);

        const errors = {};

        if (errorsPassword) {
            errors.newPassword = errorsPassword;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Password
                    name="newPassword"
                    label="New password *"
                    type="password"
                    placeholder="Password *"
                    autoComplete="new-password"
                    value={values.newPassword}
                    error={errors.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <MessagePassword passwordMinLength={props.passwordMinLength} />

                <div className={styles.message}>
                    <MessageDanger>
                        Please make note of your username.
                        You&apos;ll need it to sign in to The Juice.
                    </MessageDanger>
                </div>

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpPasswordForm.defaultProps = {
    initialValues: {},
    passwordMinLength: 8,
    onSubmit: () => {},
};

export default UserSignUpPasswordForm;
