import React, { useState } from "react";

import { DATES } from "juice-base/lib/date.js";

import IconStatus from "juice-base/components/icon-status/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";

import Skeleton from "juice-base/components/skeleton/index.js";
import SkeletonHeader from "juice-base/components/skeleton-header/index.js";
import SkeletonTabsSelector from "juice-base/components/skeleton-tabs-selector";

import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
    SectionTeacherMessage,
} from "juice-base/components/section-teacher/index.js";

import styles from "./styles.module.css";


const TeacherStudentsActivity = (props) => {
    const [selectedTab, setSelectedTab] = useState("today");

    const [selectedStatus, setSelectedStatus] = useState("all");

    let isStudentJuicesExists = false;

    const studentsWithSelectedStatus = props.students.filter((s) => {
        if (selectedStatus === "all") {
            return true;
        }

        if (selectedStatus === "unopened" && s.juiceStatusId === 0) {
            return true;
        }

        if (selectedStatus === "in-progress" && s.juiceStatusId === 1) {
            return true;
        }

        if (selectedStatus === "completed" && s.juiceStatusId === 2) {
            return true;
        }

        return false;
    });

    for (let i = 0; i < props.students.length; i += 1) {
        if (props.students[i].juiceStatusId !== -1) {
            isStudentJuicesExists = true;
            break;
        }
    }

    const onTabChange = (values) => {
        setSelectedTab(values.value);

        props.onChangeDataRange(values.value);
    };

    const onStatusChange = (status) => {
        setSelectedStatus(status);
    };

    const renderAssignments = () => {
        let assignmentsSections = (
            <div className={styles.assignmentsLoader}>
                <RequestLoader />
            </div>
        );

        if (props.isSkeleton) {
            const rows = [];

            for (let i = 0; i < 6; i += 1) {
                rows.push(
                    <div className={styles.skeletonRow}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" height={2} />
                    </div>,
                );
            }

            assignmentsSections = (
                <div className={styles.skeleton}>
                    {rows}
                </div>
            );
        } else if (props.isLoaded) {
            const users = studentsWithSelectedStatus.map((student) => {
                return (
                    <div className={styles.assignmentSectionItem}>
                        <div
                            className={styles.assignmentSectionItemName}
                            onClick={() => {
                                props.onStudentClick(student.id);
                            }}
                            onKeyPress={() => {
                                props.onStudentClick(student.id);
                            }}
                            role="button"
                            tabIndex="-1"
                        >
                            {student.fullName}
                        </div>
                        <IconStatus
                            statusId={student.juiceStatusId}
                        />
                    </div>
                );
            });

            if (props.students.length === 0) {
                assignmentsSections = (
                    <SectionTeacherMessage>
                        No students found in this class
                    </SectionTeacherMessage>
                );
            } else if (studentsWithSelectedStatus.length > 0 && isStudentJuicesExists) {
                assignmentsSections = (
                    <div className={styles.assignmentSection}>
                        {users}
                    </div>
                );
            } else if (studentsWithSelectedStatus.length === 0 && props.students.length > 0) {
                assignmentsSections = (
                    <SectionTeacherMessage>
                        There are no students with the selected status.
                    </SectionTeacherMessage>
                );
            } else {
                assignmentsSections = (
                    <SectionTeacherMessage>
                        Nothing to see here either.
                        Change the timeframe to see individual student activity data.
                    </SectionTeacherMessage>
                );
            }
        }

        return (
            <div className={styles.assignmentsSections}>
                {assignmentsSections}
            </div>
        );
    };

    const renderTimeRange = () => {
        if (props.isSkeleton) {
            return <SkeletonTabsSelector />;
        }

        if (!isStudentJuicesExists) {
            return null;
        }

        const menu = [
            { value: "all", label: "All" },
            { value: "completed", label: "Completed" },
            { value: "in-progress", label: "In Progress" },
            { value: "unopened", label: "Unopened" },
        ];

        return (
            <div className={styles.dateRange}>
                <MenuHorizontalScrolling
                    items={menu}
                    selected={selectedStatus}
                    onSelect={onStatusChange}
                />
            </div>
        );
    };

    let header = <div>Student Activity</div>;

    let rightControls = (
        <SectionTeacherExplanation
            pageWidth={props.pageWidth}
            alignLeft={props.pageWidth < 920}
        >
            Review individual student&apos;s Daily Juice completion status.
        </SectionTeacherExplanation>
    );

    if (props.isSkeleton) {
        header = <SkeletonHeader />;

        rightControls = <Skeleton variant="circle" width={2} height={2} />;
    }

    let bottomControls = [];

    if (!props.isSkeleton) {
        bottomControls = [
            <Tabs
                tabs={[
                    DATES.today,
                    DATES.yesterday,
                    DATES.thisWeek,
                ]}
                selectedTab={selectedTab}
                blueTheme
                onlyTabs
                onChange={onTabChange}
            />,
        ];
    }

    return (
        <SectionTeacher dataComment="daily-juice-activity">
            <SectionTeacherHeader
                label={header}
                rightControls={[rightControls]}
                bottomControls={bottomControls}
            />
            {renderTimeRange()}
            {renderAssignments()}
        </SectionTeacher>
    );
};

TeacherStudentsActivity.defaultProps = {
    pageWidth: 0,
    students: [],

    isLoaded: false,
    isSkeleton: true,

    onChangeDataRange: () => {},
    onStudentClick: () => {},
    onCloseStudentPopup: () => {},
};

export default TeacherStudentsActivity;
