import React, { useState } from "react";

import Grades from "juice-base/project/grades.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import TabsScrolling from "juice-base/components/tabs-scrolling/index.js";
import MenuWithContent, { MenuContent } from "juice-base/components/menu-with-content/index.js";

// TODO: rename v2 to v1
import PopupFullScreen from "juice-base/components/popup-full-screen-v2/index.js";

import TeacherStudentJuices from "juice-base/business/teacher-student-juices/index.js";
import StudentAccountInfo from "juice-base/business/student-account-info/index.js";
import StudentOverview from "juice-base/business/student-overview/index.js";
import StudentQuizPerformance from "juice-base/business/student-quiz-performance/index.js";

import styles from "./styles.module.css";


const PopupStudentInfo = (props) => {
    const [selectedStudentId, setSelectedStudentId] = useState(() => {
        return props.student?.id || null;
    });

    const [isMenuVisible, setIsMenuVisible] = useState(true);

    /* --- */

    const onSelectStudent = (id) => {
        props.onSelectStudent(id);
        setSelectedStudentId(id);
    };

    const onMenuClick = () => {
        setIsMenuVisible(false);
    };

    /* --- */

    const renderStudentSelector = () => {
        if (props.studentsList.length === 0) {
            return null;
        }

        const tabs = props.studentsList.map((student) => {
            return {
                value: student.id,
                label: student.fullName,
            };
        });

        return (
            <TabsScrolling
                tabs={tabs}
                defaultTab={selectedStudentId}
                hideArrows={props.hideArrows}
                withShadow={false}
                onChange={onSelectStudent}
            />
        );
    };

    const renderStudentOverview = () => {
        if (!props.student.isLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        const overview = props.student?.data?.overview || {};
        const gradeField = Grades.getGradeGroup(overview.studentGrade);

        return (
            <StudentOverview
                grade={gradeField}
                quizAverage={overview.cumulativeQuizAverage}
                onEditGrade={props.student.onEditGrade}
            />
        );
    };

    const renderStudentAccountInfo = () => {
        if (!props.student.isLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <StudentAccountInfo
                account={props.student.data.account}
                onDeleteAccount={props.student.onDeleteAccount}
                onEditName={props.student.onEditName}
                onEditEmail={props.student.onEditEmail}
                onEditPassword={props.student.onEditPassword}
            />
        );
    };

    const renderDailyJuices = () => {
        const tableRows = [];

        if (props?.dailyJuices?.juices) {
            props.dailyJuices.juices.forEach((dj) => {
                let extraJuices = [];
                let videos = [];

                if (dj?.extraJuices) {
                    extraJuices = dj.extraJuices.map((ej) => ej.statusId);
                }

                if (dj?.lifeHacks) {
                    videos = dj.lifeHacks.map((video) => video.statusId);
                }

                tableRows.push({
                    date: dj.date,
                    quizStatus: dj.statusId,
                    quizScore: `${dj.quizScore}%`,
                    extraJuices,
                    videos,
                });
            });
        }

        let hasMorePages = props?.dailyJuices?.hasMore;

        if (!hasMorePages) {
            hasMorePages = false;
        }

        return (
            <TeacherStudentJuices
                data={tableRows}
                hasMore={hasMorePages}
                isLoading={props.isDailyJuicesLoading}
                isMobile={props.isCards}
                onLoadMoreJuices={props.onLoadMoreJuices}
            />
        );
    };

    const renderQuizPerformance = () => {
        if (!props.dailyJuiceDates.isLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <StudentQuizPerformance
                dailyJuiceDates={props.dailyJuiceDates}
                performance={props.quizPerformance.data}
                onDateChange={props.quizPerformance.onDateChange}
                onDateRangeChange={props.quizPerformance.onDateRangeChange}
                onStandardTypeClick={props.quizPerformance.onStandardTypeClick}
                onClear={props.quizPerformance.onClear}
                isMobile={props.isMobile}
            />
        );
    };

    const renderContent = () => {
        return (
            <MenuWithContent
                isMobile={props.isMobile}
                isMobileMenuVisible={isMenuVisible}
                defaultSelected={props.defaultSelectedMenu}
                onMenuClick={onMenuClick}
            >
                <MenuContent name="Student Overview">
                    {renderStudentOverview()}
                </MenuContent>

                <MenuContent name="Daily Juice">
                    {renderDailyJuices()}
                </MenuContent>

                <MenuContent name="Quiz Performance">
                    {renderQuizPerformance()}
                </MenuContent>

                <MenuContent name="Account Information">
                    {renderStudentAccountInfo()}
                </MenuContent>
            </MenuWithContent>
        );
    };

    return (
        <PopupFullScreen
            title="Student View"
            subheader={renderStudentSelector()}
            isMobile={props.isMobile}
            isArrowBackVisible={!isMenuVisible}
            onArrowBack={(value) => {
                setIsMenuVisible(value);
            }}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupFullScreen>
    );
};

PopupStudentInfo.defaultProps = {
    studentsList: [],

    dailyJuices: {},
    dailyJuiceDates: {},

    isCards: false,
    isMobile: false,

    hideArrows: false,
    isDailyJuicesLoading: false,

    defaultSelectedMenu: 0,

    student: {
        isLoaded: false,
        id: null,
        data: {},

        onEditName: () => {},
        onEditEmail: () => {},
        onEditGrade: () => {},
        onEditPassword: () => {},

        onDeleteAccount: () => {},
    },

    quizPerformance: {
        data: {},
        onDateChange: () => {},
        onDateRangeChange: () => {},
        onClear: () => {},
    },

    onLoadMoreJuices: () => {},
    onSelectStudent: () => {},
    onClose: () => {},
};

export default PopupStudentInfo;
