import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const LinkNext = (props) => {
    return (
        <div
            className={styles.linkNext}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <div className={styles.linkNextText}>
                {props.children}
            </div>
            <img
                className={styles.linkNextImg}
                src={staticFiles.arrowRightRose}
                alt="Next"
            />
        </div>
    );
};

LinkNext.defaultProps = {
    onClick: () => {},
};

export default LinkNext;
