import React, { useEffect, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";
import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";

import useScroller from "./use-scroller.js";

import styles from "./styles.module.css";


const SwiperDailyJuiceStories = (props) => {
    const scrollerRef = useRef(null);

    const scroller = useScroller(scrollerRef, {
        defaultPage: props.page,
        onLeft: props.onLeft,
        onRight: props.onRight,
    });

    useEffect(() => {
        if (scroller.current) {
            scroller.current.tryScrollByIndex(props.page);
        }
    }, [props.page]);

    const renderPages = () => {
        const pageClassName = classNames({
            [juiceScrollbarStyles.scrollbar]: true,
            [styles.page]: true,
            [styles.pageForSafari]: props.isSafari && !props.isPWA,
        });

        return props.children.map((page, pageIndex) => {
            const pageElem = React.cloneElement(page, {
                isVisible: pageIndex === props.page,
            });

            return (
                <div className={pageClassName}>
                    {pageElem}
                </div>
            );
        });
    };

    return (
        <div
            className={styles.swiper}
            data-comment="swiper"
        >
            <div
                ref={scrollerRef}
                className={styles.pages}
            >
                {renderPages()}
            </div>
        </div>
    );
};

SwiperDailyJuiceStories.defaultProps = {
    page: 0,
    onLeft: () => {},
    onRight: () => {},
    isSafari: false,
    isPWA: false,
    children: null,
};

export default SwiperDailyJuiceStories;
