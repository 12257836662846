import React, { useState } from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";

import User from "juice-base/project/user.js";
import Icon from "juice-base/components/icon/index.js";
import PopupUserLogout from "juice-base/components/popup-user-logout/index.js";

import styles from "./styles.module.css";


const ShortNav = (props) => {
    const {
        pathname,
        user,
        sectionsByRoles,
    } = props;

    const [isVisibleConfirmLogoutPopup, setVisibleConfirmLogoutPopup] = useState(false);

    if (!user.userId) {
        return null;
    }

    const sections = [];

    User.getNavSections(user, sectionsByRoles).forEach((sec) => {
        const sClasses = [styles.section];

        if (pathname.indexOf(sec.to) === 0) {
            sClasses.push(styles.sectionSelected);
        }

        let iconUrl = staticFiles.iconJuice;

        if (sec.name === "Daily Juice") {
            iconUrl = staticFiles.iconJuiceCup;
        } else if (sec.name === "Class") {
            iconUrl = staticFiles.iconUsers;
        } else if (sec.name === "Vocab") {
            iconUrl = staticFiles.iconVocabA;
        } else if (sec.name === "Search") {
            iconUrl = staticFiles.iconSearch;
        } else if (sec.name === "Account") {
            iconUrl = staticFiles.iconAccount;
        } else if (sec.name === "Student") {
            iconUrl = staticFiles.iconBarGraph;
        }

        const el = (
            <Link
                to={sec.to}
                className={sClasses.join(" ")}
                data-comment={`mobile-header-${sec.dataComment}`}
            >
                <Icon
                    url={iconUrl}
                    code={sec.name}
                    name={sec.name}
                    withName
                />
            </Link>
        );

        sections.push(el);
    });

    const homeSectionClasses = [styles.section];

    if (pathname === "/") {
        homeSectionClasses.push(styles.sectionSelected);
    }

    const homeLink = (
        <Link
            to="/"
            className={homeSectionClasses.join(" ")}
            data-comment="mobile-header-link-home"
        >
            <Icon
                url={staticFiles.iconHome}
                name="Home"
                withName
            />
        </Link>
    );

    let logoutLink = null;

    if (User.isTypeTrial(user)) {
        const logoutSectionClasses = [styles.section];

        if (pathname === "/") {
            logoutSectionClasses.push(styles.sectionSelected);
        }

        logoutLink = (
            <div className={logoutSectionClasses.join(" ")}>
                <Icon
                    onClick={() => {
                        setVisibleConfirmLogoutPopup(true);
                    }}
                    url={staticFiles.iconLogOut}
                    name="Sign Out"
                    withName
                />
            </div>
        );
    }

    const renderConfirmLogoutPopup = () => {
        if (!isVisibleConfirmLogoutPopup) {
            return null;
        }

        return (
            <PopupUserLogout
                onLogout={() => {
                    props.onSignOut();
                    setVisibleConfirmLogoutPopup(false);
                }}
                onClose={() => {
                    setVisibleConfirmLogoutPopup(false);
                }}
            />
        );
    };

    return (
        <>
            {renderConfirmLogoutPopup()}
            <div className={styles.shortNav}>
                {homeLink}
                {sections}
                {logoutLink}
            </div>
        </>
    );
};

ShortNav.defaultProps = {
    pathname: "/",
    user: {},
    sectionsByRoles: {},
    onSignOut: () => {},
};

export default ShortNav;
