import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import User from "juice-base/project/user.js";
import DailyJuice from "juice-base/project/daily-juice.js";
import DailyJuices from "juice-base/project/daily-juices.js";

import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";
import useWordPopup from "juice-base/hooks/use-word-popup/index.js";

import device from "juice-base/lib/device.js";
import scroll from "juice-base/lib/scroll.js";
import actions from "juice-base/store/actions.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import LinkNext from "juice-base/components/link-next/index.js";

import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupImage from "juice-base/components/popup-image/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import PopupEmoji from "juice-base/components/popup-emoji/index.js";

import PopupDailyJuiceStatesLegend from "juice-base/business/popup-daily-juice-states-legend/index.js";
import PopupDailyJuiceStats from "juice-base/business/popup-daily-juice-stats/index.js";

import SwiperDailyJuiceStories from "juice-base/business/swiper-daily-juice-stories/index.js";
import SwiperDailyJuiceStoryPage from "juice-base/business/swiper-daily-juice-story-page/index.js";
import DailyJuiceNav from "juice-base/business/daily-juice-nav/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";
import DailyJuiceActivity from "juice-base/business/daily-juice-activity/index.js";
import QuizQuestion from "juice-base/business/quiz-question/index.js";

import settings from "juice-app/settings.js";
import events from "juice-app/events.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    juicesById: state.juices.juicesById,
    wordsByName: state.vocabulary.wordsByName,
    t2sJuiceStories: state.text2speech.juiceStories,
    t2sExtraJuices: state.text2speech.extraJuices,
    t2sWords: state.text2speech.words,
    guardian: state.guardian,
    dimensions: state.device.dimensions,
    studentTodo: state.studentTodo,
});

const StudentDailyJuiceStory = () => {
    const activityStoryId = "activity";
    const activityStoryTitle = "Activity Overview";

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const wordPopup = useWordPopup();
    const [imagePopup, setImagePopup] = useState(null);
    const [isVisibleQuizEmojiPopup, setIsVisibleQuizEmojiPopup] = useState(false);
    const [isVisibleLegendPopup, setIsVisibleLegendPopup] = useState(false);
    const [isVisibleStatsPopup, setIsVisibleStatsPopup] = useState(false);

    const [audioPlayerPopup, setAudioPlayerPopup] = useState({
        storyId: -1,
    });

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        storyId: -1,
        ejId: -1,
        isVisiblePlayer: false,
    });

    const store = useSelector(storeSelector);

    const { isJuiceById, juiceId } = DailyJuices.getJuiceId(params.juiceIdOrDate, store.juicesById);
    const juiceGrades = (store.juicesById[juiceId] || {}).grades || [];

    const isPreview = history.location.pathname.indexOf("preview") !== -1;
    const hasRoleGuardian = User.hasRoleGuardian(store.user);

    const am = useAudioManager({
        isMac: device.isMac,
    });

    /* --- */

    const addEventReadStoryClick = (values) => {
        events.dailyJuice.storyOpen({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventFeaturedImageClick = (values) => {
        events.dailyJuice.featuredImageClick({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoPlay = (values) => {
        events.dailyJuice.videoPlay({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoPause = (values) => {
        events.dailyJuice.videoPause({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoEnded = (values) => {
        events.dailyJuice.videoEnded({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVocabularyWordClick = (values) => {
        events.dailyJuice.onVocabularyWordClick({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVocabularyWordToSpeech = (values) => {
        if (values.isDefinition) {
            events.dailyJuice.onDefinitionPlay({
                disabled: isPreview,
                session: store.session,
                juiceId: values.juiceId,
                wordId: values.wordId,
                ...values,
            });
            return;
        }

        events.dailyJuice.onPlayWordToSpeech({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventPauseVocabularyWordToSpeech = (values) => {
        events.dailyJuice.onPauseWordToSpeech({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventExtraJuice = (values) => {
        events.dailyJuice.extraJuiceOpen({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventSubmitQuiz = (values) => {
        events.dailyJuice.submitQuiz({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventClickOnQuizAnswer = (values) => {
        events.dailyJuice.quizAnswerClick({
            disabled: isPreview,
            session: store.session,
            juiceId: values?.juiceId || -1,
            quizId: values?.values?.quizId || -1,
            answerId: values?.values?.answerId || -1,
        });
    };

    const addEventStoryOpen = (values) => {
        events.dailyJuice.storyOpen({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    /* --- */

    const onGoBack = () => {
        let backUrl = "";

        const { juiceIdOrDate } = params;

        if (isPreview) {
            backUrl = `/daily-juice-preview-${settings.previewCode}/${juiceIdOrDate}`;
        } else {
            backUrl = `/daily-juice/${juiceIdOrDate}`;
        }

        history.push(backUrl);
    };

    const onGoToStory = (sId) => {
        let storyUrl = "";

        const { juiceIdOrDate } = params;

        if (isPreview) {
            storyUrl = `/daily-juice-preview-${settings.previewCode}/${juiceIdOrDate}/${sId}`;
        } else {
            storyUrl = `/daily-juice/${juiceIdOrDate}/${sId}`;
        }

        history.push(storyUrl);
    };

    const loadJuiceCallback = (res) => {
        if (!res.ok) {
            onGoBack();
            return;
        }

        const stories = res?.data?.stories?.juice || [];
        const storiesIds = stories.map((s) => s.ID);
        const sId = parseInt(params.storyId, 10);

        if (storiesIds.indexOf(sId) === -1) {
            onGoBack();
            return;
        }

        events.dailyJuice.open({
            disabled: isPreview,
            session: store.session,
            juiceId: res.data.id,
            grades: res.data.grades,
        });


        dispatch(actions.juices.setJuice({
            juice: res.data,
        }));
    };

    const loadJuiceById = (studentId) => {
        const { juiceIdOrDate } = params;

        const myGetJuice = isPreview
            ? api.dailyJuices.getUnpublishedJuice
            : api.dailyJuices.getJuice;

        const req = {
            session: store.session,
            juiceId: juiceIdOrDate,
            studentId,
        };

        myGetJuice(req).then(loadJuiceCallback);
    };

    const loadJuiceByDate = () => {
        const { juiceIdOrDate } = params;

        api.dailyJuices.getJuiceByDate({
            session: store.session,
            date: juiceIdOrDate,
        }).then(loadJuiceCallback);
    };

    const loadJuice = (studentId) => {
        if (isJuiceById) {
            loadJuiceById(studentId);
        } else {
            loadJuiceByDate();
        }
    };

    const loadGuardianStudents = () => {
        // TODO: support for dates
        const { juiceIdOrDate } = params;

        const req = {
            session: store.session,
            juiceId: juiceIdOrDate,
            studentId: store.guardian.selectedStudent,
        };

        api.students.getStudentsByGuardian(req).then((res) => {
            const students = res.students || [];

            let selectedStudentId = store.guardian.selectedStudent;
            if (!selectedStudentId) {
                selectedStudentId = students[0] ? students[0].id || null : null;
            }

            dispatch(actions.guardian.setStudents({
                students,
            }));

            dispatch(actions.guardian.setSelectedStudent({
                selectedStudent: selectedStudentId,
            }));

            loadJuice(selectedStudentId);
        });
    };

    const getJuiceStories = () => {
        const juice = store.juicesById[juiceId] || null;

        if (juice?.stories?.juice) {
            return juice?.stories?.juice;
        }

        return [];
    };

    const getJuiceStoryIndex = () => {
        const stories = getJuiceStories();

        if (params.storyId === activityStoryId) {
            return stories.length;
        }

        const sId = parseInt(params.storyId, 10);

        for (let i = 0; i < stories.length; i += 1) {
            if (stories[i].ID === sId) {
                return i;
            }
        }

        return 0;
    };

    const getJuiceAndStory = () => {
        const juice = store.juicesById[juiceId] || null;

        if (!juice) {
            return [null, null];
        }

        const stories = juice?.stories?.juice || [];
        const sId = parseInt(params.storyId, 10);

        const story = DailyJuice.getStoryById(stories, sId);

        return [juice, story];
    };

    const getExtraJuice = (sId, ejId) => {
        const dailyJuice = store.juicesById[juiceId] || {};
        const stories = (dailyJuice.stories || {}).juice || [];

        return DailyJuice.getExtraJuiceById(stories, ejId);
    };

    const onGoNextStory = () => {
        const stories = getJuiceStories();
        const nextStory = DailyJuice.getNextStory(stories, parseInt(params.storyId, 10));

        scroll.scrollToTop();

        if (nextStory) {
            addEventStoryOpen({
                juiceId,
                storyId: nextStory.ID,
            });

            onGoToStory(nextStory.ID);
            return;
        }

        onGoToStory(activityStoryId);
    };

    const onGoPrevStory = () => {
        const stories = getJuiceStories();

        scroll.scrollToTop();

        if (params.storyId === activityStoryId) {
            if (stories.length > 0) {
                const prevStory = stories[stories.length - 1];
                onGoToStory(prevStory.ID);
                return;
            }

            onGoBack();
            return;
        }

        const prevStory = DailyJuice.getPrevStory(stories, parseInt(params.storyId, 10));

        if (prevStory) {
            addEventStoryOpen({
                juiceId,
                storyId: prevStory.ID,
            });

            onGoToStory(prevStory.ID);
            return;
        }

        onGoBack();
    };

    const isFirstStoryOpened = () => {
        const stories = getJuiceStories();

        if (stories.length > 0 && stories[0].ID === parseInt(params.storyId, 10)) {
            return true;
        }

        return false;
    };

    const isLastStoryOpened = () => {
        return params.storyId === activityStoryId;
    };

    const onOpenStatsPopup = () => {
        setIsVisibleStatsPopup(true);
    };

    const onCloseStatsPopup = () => {
        setIsVisibleStatsPopup(false);
    };

    /* --- */

    const onOpenImagePopup = (storyId, image) => {
        addEventFeaturedImageClick({
            juiceId,
            grades: juiceGrades,
            storyId,
            imageId: image.id,
        });

        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    const onOpenWordPopup = (word, storyId, openedFrom = "story") => {
        wordPopup.setLoading(true, word);

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));

                wordPopup.setWord(wordValue);

                addEventVocabularyWordClick({
                    juiceId,
                    grades: juiceGrades,
                    storyId,
                    wordId: res.word.id,
                    word,
                    openedFrom,
                });
            } else {
                wordPopup.setError(word, "Cannot load word");
            }
        }).catch(() => {
            wordPopup.setError(word, "Cannot load word");
        });
    };

    const onCloseWordPopup = () => {
        wordPopup.setWord("");
    };

    const onOpenExtraJuicePopup = (sId, ejId, openedBy) => {
        if (sId && ejId) {
            setExtraJuicePopup((prev) => ({
                ...prev,
                storyId: sId,
                ejId,
            }));
        }

        addEventExtraJuice({
            juiceId,
            storyId: sId,
            extraJuiceId: ejId,
            openedBy,
        });
    };

    const onCloseExtraJuicePopup = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            storyId: -1,
            ejId: -1,
        }));
    };

    const onCloseQuizEmojiPopup = () => {
        setIsVisibleQuizEmojiPopup(false);
    };

    /* --- */

    const onPlayJuiceStory = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: params.storyId,
        }));

        dispatch(actions.t2s.setJuiceStoryLoading({
            storyId: params.storyId,
        }));

        api.t2s.juice({
            juiceId,
            storyId: params.storyId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("juiceStories", params.storyId);

                dispatch(actions.t2s.setJuiceStory({
                    juiceId,
                    storyId: params.storyId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: -1,
        }));
    };

    /* --- */

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        dispatch(actions.t2s.setExtraJuiceLoading({
            extraJuiceId,
        }));
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    const onLoadExtraJuiceStory = (extraJuiceId) => {
        // TODO: if already loaded no need to load

        api.t2s.juice({
            juiceId,
            extraJuiceId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("extraJuices", extraJuiceId);

                dispatch(actions.t2s.setExtraJuice({
                    juiceId,
                    extraJuiceId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    /* --- */

    const onQuestionSubmit = (values) => {
        addEventSubmitQuiz({
            juiceId,
            grades: juiceGrades,
            quizId: values.quizId,
            questionId: values.questionId,
            isCorrect: values.isCorrect,
        });

        if (isPreview) {
            dispatch(actions.juices.setJuiceQuestionAnswer({
                juiceId,
                quizId: values.quizId,
                questionId: values.questionId,
                answerId: values.answerId,
            }));

            setIsVisibleQuizEmojiPopup(true);
            return;
        }

        api.quizzes.setQuizAnswer({
            session: store.session,
            ...values,
            juiceId,
        }).then((res) => {
            if (res.ok) {
                const { quiz } = res;
                const quizQuestions = quiz.questions || [];

                for (let i = 0; i < quizQuestions.length; i += 1) {
                    const q = quizQuestions[i];
                    if (q.id === values.questionId) {
                        dispatch(actions.juices.setJuiceQuestion({
                            juiceId,
                            quizId: values.quizId,
                            question: q,
                        }));

                        setIsVisibleQuizEmojiPopup(true);
                        return;
                    }
                }
            } else {
                // TODO:
            }
        });
    };

    /* --- */

    useEffect(() => {
        const [juice, story] = getJuiceAndStory();

        if (story && !story.isUserOpenedStory) {
            dispatch(actions.juices.setStoryViewed({
                juiceId,
                storyId: story.ID,
            }));

            addEventReadStoryClick({
                juiceId,
                storyId: story.ID,
            });
        }

        if (store?.studentTodo?.incompletedJuices
            && store.studentTodo.incompletedJuices.length > 0
            && juice?.stories?.juice) {
            const allQuizzes = [];

            juice.stories.juice.forEach((j) => {
                if (j.quizzes && j.quizzes.length > 0) {
                    allQuizzes.push(j.quizzes);
                }
            });

            let userAnswersCount = 0;

            allQuizzes.forEach((quiz) => {
                if (quiz && quiz[0] && quiz[0].answers) {
                    for (let i = 0; i < quiz[0].answers.length; i += 1) {
                        if (quiz[0].answers[i].is_user_choice) {
                            userAnswersCount += 1;
                            break;
                        }
                    }
                }
            });

            if (allQuizzes.length === userAnswersCount) {
                let isJuiceInIncompletedTodos = false;

                for (let i = 0; i < store.studentTodo.incompletedJuices.length; i += 1) {
                    if (store.studentTodo.incompletedJuices[i].id === juice.id) {
                        isJuiceInIncompletedTodos = true;
                        break;
                    }
                }

                if (isJuiceInIncompletedTodos) {
                    if (store.studentTodo.incompletedJuices.length > 1) {
                        dispatch(actions.studentTodo.deleteIncompleteJuiceById({
                            juiceId: juice.id,
                        }));
                    } else {
                        dispatch(actions.studentTodo.clearIncompletedJuices());
                    }

                    dispatch(actions.juices.clearJuices());
                }
            }
        }
    }, [store.juicesById, params.storyId]);

    useEffect(() => {
        dispatch(actions.juices.setLastVisitedJuice({
            lastVisitedJuice: params.juiceIdOrDate, // TODO:
        }));

        if (hasRoleGuardian) {
            loadGuardianStudents();
        } else {
            loadJuice(store.guardian.selectedStudent);
        }
    }, [params.juiceIdOrDate]);

    useEffect(() => {
        am.setFiles({
            juiceStories: store.t2sJuiceStories,
            extraJuices: store.t2sExtraJuices,
            words: store.t2sWords,
        });
    }, [
        store.t2sJuiceStories,
        store.t2sExtraJuices,
        store.t2sWords,
    ]);

    /* --- */

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (audioPlayerPopup.storyId !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story.featuredImage && story.featuredImage.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.ID;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = (am.state[trackGroupName] || {})[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onFirstPlay={() => {
                    am.playerPauseAll();

                    if (am.state[trackGroupName]
                        && am.state[trackGroupName][trackId]) {
                        am.playerPlay(trackGroupName, trackId);
                    } else {
                        onPlayJuiceStory(story.ID);
                    }
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderWordPopup = () => {
        if (wordPopup.state.isLoading) {
            return (
                <PopupLoading />
            );
        }

        const wordLowerCase = wordPopup.state.word.toLowerCase();

        let word = store.wordsByName[wordPopup.state.word];

        if (!word && store.wordsByName[wordLowerCase]) {
            word = store.wordsByName[wordLowerCase];
        }

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    onClose={onCloseWordPopup}
                />
            );
        }

        const trackGroupName = "words";
        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                onLoad={(text, wordId, isDefinition) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            addEventVocabularyWordToSpeech({
                                juiceId,
                                grades: juiceGrades,
                                wordId,
                                isDefinition,
                            });
                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text, wordId, isDefinition) => {
                    addEventVocabularyWordToSpeech({
                        juiceId,
                        grades: juiceGrades,
                        text,
                        wordId,
                        isDefinition,
                    });
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text, wordId) => {
                    addEventPauseVocabularyWordToSpeech({
                        juiceId,
                        grades: juiceGrades,
                        text,
                        wordId,
                    });
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderExtraJuicePopup = () => {
        if (extraJuicePopup.storyId === -1
            || extraJuicePopup.ejId === -1) {
            return null;
        }

        const eJuice = getExtraJuice(extraJuicePopup.storyId, extraJuicePopup.ejId);

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = (am.state[trackGroupName] || {})[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.storyId}`}
                storyId={extraJuicePopup.storyId}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={(word) => {
                    onOpenWordPopup(word, extraJuicePopup.storyId, "extra-juice");
                }}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onFirstPlay={() => {
                    onLoadExtraJuiceStory(eJuice.id);
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderEmojiPopup = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (q) {
            return (
                <PopupEmoji
                    isCorrect={DailyJuice.isValidQuiz(q)}
                    onClose={onCloseQuizEmojiPopup}
                />
            );
        }

        return null;
    };

    const renderLegendPopup = () => {
        return (
            <PopupDailyJuiceStatesLegend
                onClose={() => {
                    setIsVisibleLegendPopup(false);
                }}
            />
        );
    };

    const renderStatsPopup = () => {
        const stories = getJuiceStories();
        const quizzesStats = DailyJuice.getQuizzesStats(stories);

        return (
            <PopupDailyJuiceStats
                stats={quizzesStats}
                sponsors={store.sponsors}
                isWindows={device.isWindows}
                onSponsorClick={() => {
                    events.dailyJuice.lastPageSponsorClick({
                        disabled: isPreview,
                        session: store.session,
                        juiceId,
                        grades: store.juicesById[juiceId].grades,
                    });
                }}
                onScrollTo={() => {
                    if (quizzesStats.firstSkipped) {
                        onGoToStory(quizzesStats.firstSkipped);
                    }
                    onCloseStatsPopup();
                }}
                onClose={onCloseStatsPopup}
            />
        );
    };

    const renderPopups = (story) => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        const ejPopup = renderExtraJuicePopup();

        if (ejPopup) {
            ps.push(ejPopup);
        }

        if (wordPopup.state.word) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup.storyId !== -1
            || audioPlayerPopup.extraJuiceId !== -1) {
            ps.push(renderPlayerAudioPopup(story));
        }

        if (isVisibleQuizEmojiPopup) {
            ps.push(renderEmojiPopup(story));
        }

        if (isVisibleLegendPopup) {
            ps.push(renderLegendPopup());
        }

        if (isVisibleStatsPopup) {
            ps.push(renderStatsPopup());
        }

        return ps;
    };

    /* --- */

    const renderStoryNav = (juice, story) => {
        let title = story ? story.title : "";

        if (params.storyId === activityStoryId) {
            title = activityStoryTitle;
        }

        const isFirstStory = isFirstStoryOpened();
        const isLastStory = isLastStoryOpened();

        return (
            <div className={styles.nav}>
                <DailyJuiceNav
                    date={juice.juiceDate}
                    title={title}
                    onGoToPrevStory={onGoPrevStory}
                    onGoToNextStory={onGoNextStory}
                    isFirstStoryOpened={isFirstStory}
                    isLastStoryOpened={isLastStory}
                    onBack={() => {
                        scroll.scrollToTop();
                        onGoBack();
                    }}
                />
            </div>
        );
    };

    const renderNextStoryLink = (sId) => {
        const stories = getJuiceStories();
        const nextStory = DailyJuice.getNextStory(stories, sId);

        let nextStoryId = activityStoryId;
        let nextStoryText = `Next UP: ${activityStoryTitle}`;

        if (nextStory) {
            nextStoryId = nextStory.ID;
            nextStoryText = `Next UP: ${nextStory.title || ""}`;
        }

        return (
            <div className={styles.nextStoryBlock}>
                <LinkNext onClick={() => { onGoToStory(nextStoryId); }}>
                    {nextStoryText}
                </LinkNext>
            </div>
        );
    };

    const renderQuiz = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return [null, null];
        }

        let nextStoryLink = null;

        if (DailyJuice.isAnsweredQuiz(q)) {
            nextStoryLink = renderNextStoryLink(story.ID);
        }

        const quiz = (
            <QuizQuestion
                question={q}
                onSubmit={onQuestionSubmit}
                onSelectAnswer={(values) => {
                    addEventClickOnQuizAnswer({
                        juiceId,
                        values,
                    });
                }}
                allowAnswering={!hasRoleGuardian}
            />
        );

        return [quiz, nextStoryLink];
    };

    const renderStory = (juice, story, storyIndex) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        const storyId = story.ID;

        const djStory = (
            <DailyJuiceStory
                story={story}
                storyIndex={storyIndex}
                dimensions={store.dimensions}
                isDefaultVideo={!device.isChrome}
                videoCaptionSrc={videoCaptionSrc}
                onImageClick={(img) => {
                    onOpenImagePopup(storyId, img);
                }}
                onWordClick={(word) => {
                    onOpenWordPopup(word, storyId, "story");
                }}
                onAudioPlay={onPlayJuiceStory}
                onVideoPlayStart={(videoSrc, videoId) => {
                    dispatch(actions.juices.setStoryVideoViewed({
                        juiceId,
                        storyId,
                        videoId,
                    }));

                    addEventVideoPlay({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoId,
                        videoSrc,
                    });
                }}
                onVideoPlayPause={(videoSrc, videoId) => {
                    addEventVideoPause({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoId,
                        videoSrc,
                    });
                }}
                onVideoPlayEnd={(videoSrc, videoId) => {
                    addEventVideoEnded({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoId,
                        videoSrc,
                    });
                }}
                onExtraJuiceWordClick={onOpenExtraJuicePopup}
            />
        );

        const [quiz, link] = renderQuiz(story);

        return (
            <SwiperDailyJuiceStoryPage
                story={djStory}
                quiz={quiz}
                link={link}
            />
        );
    };

    const renderPages = (juice) => {
        const pages = [];

        const stories = getJuiceStories();

        stories.forEach((story, index) => {
            pages.push(renderStory(juice, story, index));
        });

        const djActivity = (
            <div styles={styles.page}>
                <div className={styles.activityOverview}>
                    <DailyJuiceActivity
                        stories={stories}
                        onGoToStory={onGoToStory}
                        onShowReport={onOpenStatsPopup}
                        onInfoClick={() => {
                            setIsVisibleLegendPopup(true);
                        }}
                    />
                </div>
            </div>
        );

        pages.push(djActivity);

        return pages;
    };

    /* --- */

    const [juice, story] = getJuiceAndStory();

    if (!juice || (hasRoleGuardian && !store.guardian.isStudentsLoaded)) {
        return (
            <RequestLoader />
        );
    }

    if (!story && params.storyId !== activityStoryId) {
        return null;
    }

    return (
        <>
            {renderPopups(story)}

            {renderStoryNav(juice, story)}

            <div className={styles.stories}>
                <SwiperDailyJuiceStories
                    page={getJuiceStoryIndex()}
                    onLeft={onGoNextStory}
                    onRight={onGoPrevStory}
                    isSafari={device.isSafari}
                    isPWA={device.isPWA}
                >
                    {renderPages(juice)}
                </SwiperDailyJuiceStories>
            </div>
        </>
    );
};

export default StudentDailyJuiceStory;
