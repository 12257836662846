import React, { useState } from "react";

import Close from "../close/index.js";
import PopupCommon from "../popup-common/index.js";

import styles from "./styles.module.css";


const PopupCategories = (props) => {
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(props.selectedCategories);
    const [clickedOnReset, setClickedOnReset] = useState(false);

    const allCategories = props.categories;

    const isSelectedCategoriesSameAsDefault = () => {
        if (selectedCategoriesIds.length !== props.selectedCategories.length) {
            return false;
        }

        for (let i = 0; i < selectedCategoriesIds.length; i += 1) {
            if (props.selectedCategories.indexOf(selectedCategoriesIds[i]) === -1) {
                return false;
            }
        }

        return true;
    };

    const onClose = () => {
        if (clickedOnReset && !isSelectedCategoriesSameAsDefault()) {
            props.onApply(selectedCategoriesIds);
        } else {
            props.onClose();
        }
    };

    const onCategoryClick = (categoryId, isSelected) => {
        let newSelectedCategories = [...selectedCategoriesIds];

        if (!isSelected) {
            newSelectedCategories.push(categoryId);
        } else if (isSelected) {
            newSelectedCategories = newSelectedCategories.filter((catId) => catId !== categoryId);
        }

        setSelectedCategoriesIds(newSelectedCategories);
    };

    const onApplyCategories = () => {
        if (!isSelectedCategoriesSameAsDefault()) {
            props.onApply(selectedCategoriesIds);
        }
    };

    const onResetCategories = () => {
        if (selectedCategoriesIds.length > 0) {
            setSelectedCategoriesIds([]);
            setClickedOnReset(true);
        }
    };

    const renderCategories = () => {
        const categories = [];

        allCategories.forEach((cat) => {
            const catClasses = [styles.oneCategory];

            let isSelected = false;

            if (selectedCategoriesIds.indexOf(cat.value) !== -1) {
                catClasses.push(styles.oneCategorySelected);
                isSelected = true;
            }

            categories.push(
                <div
                    className={catClasses.join(" ")}
                    role="button"
                    tabIndex="-1"
                    onKeyPress={() => {
                        onCategoryClick(cat.value, isSelected);
                    }}
                    onClick={() => {
                        onCategoryClick(cat.value, isSelected);
                    }}
                >
                    {cat.label}
                </div>,
            );
        });

        return (
            <div className={styles.categories}>
                {categories}
            </div>
        );
    };

    const applyBtnClasses = [styles.applyBtn];
    const resetBtnClasses = [styles.resetBtn];

    if (isSelectedCategoriesSameAsDefault()) {
        applyBtnClasses.push(styles.applyBtnDisabled);
    }

    if (selectedCategoriesIds.length === 0) {
        resetBtnClasses.push(styles.resetBtnDisabled);
    }

    return (
        <PopupCommon
            onClose={onClose}
            showClose={false}
        >
            <div className={styles.popup}>
                <div className={styles.header}>
                    <div className={styles.headerDescription}>
                        <div>Category Filters</div>
                        <div>Select one or more categories to filter search content</div>
                    </div>
                    <Close
                        onClose={onClose}
                        className={styles.close}
                    />
                </div>
                <div className={styles.content}>
                    <div>
                        {renderCategories()}
                    </div>
                    <div
                        className={applyBtnClasses.join(" ")}
                        onClick={onApplyCategories}
                        onKeyPress={onApplyCategories}
                        tabIndex="-1"
                        role="button"
                    >
                        Apply
                    </div>
                    <div
                        className={resetBtnClasses.join(" ")}
                        onClick={onResetCategories}
                        onKeyPress={onResetCategories}
                        tabIndex="-1"
                        role="button"
                    >
                        Reset all filters
                    </div>
                </div>
            </div>
        </PopupCommon>
    );
};

PopupCategories.defaultProps = {
    categories: [],
    selectedCategories: [],
    onApply: () => {},
    onResetCategories: () => {},
    onClose: () => {},
};

export default PopupCategories;
