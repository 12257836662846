import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmClassStudentsLimit = (props) => {
    let title = "No more available seats.";

    let description = `
        Enrollment in this class has reached maximum capacity.
        No more students can be accepted.
        You can remove students to make room or contact support if
        you received this message in error.
    `;

    let supportButton = (
        <ButtonFlat
            onClick={props.onContactSupport}
            uppercase
        >
            Contact support
        </ButtonFlat>
    );

    if (props.isDescription) {
        title = "Too many students.";
        description = `
            This class has ${props.rowsLimit} open seats,
            but the file upload contains ${props.rowsAdded} students.
            You need to remove ${props.rowsAdded - props.rowsLimit} students
            before you can finish enrollment.
        `;
        supportButton = null;
    }

    return (
        <PopupConfirm
            hideScrollbar={props.hideScrollbar}
        >
            <PopupConfirmContent>
                <div>
                    <div>{title}</div>
                    <div>{description}</div>
                </div>
            </PopupConfirmContent>

            <PopupConfirmButtons>
                {supportButton}
                <ButtonFlat
                    onClick={props.onClose}
                    uppercase
                >
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmClassStudentsLimit.defaultProps = {
    isDescription: false,
    hideScrollbar: false,
    rowsLimit: 30,
    rowsAdded: 35,
    onContactSupport: () => {},
    onClose: () => {},
};

export default PopupConfirmClassStudentsLimit;
