// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UMFvOseiQkv_AawseAsV {\n    display: grid;\n    grid-gap: 6rem;\n}\n\n.OTlH0iB_njLYhzKcqvGn {\n    display: grid;\n}\n\n.qNNx5DXofb2TOvI2Wd2Q {\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/teacher-school-details/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 6rem;\n}\n\n.fields {\n    display: grid;\n}\n\n.submitButton {\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "UMFvOseiQkv_AawseAsV",
	"fields": "OTlH0iB_njLYhzKcqvGn",
	"submitButton": "qNNx5DXofb2TOvI2Wd2Q"
};
export default ___CSS_LOADER_EXPORT___;
