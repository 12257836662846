import { useState } from "react";


const getPopupDefaultState = () => ({
    isOpen: false,
    templateLink: null,
});

const useAddStudentPopup = () => {
    const [state, setState] = useState(getPopupDefaultState());

    const open = () => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
        }));
    };

    const close = () => {
        setState(getPopupDefaultState());
    };

    const setTemplate = (templateLink) => {
        setState((prev) => ({
            ...prev,
            templateLink,
        }));
    };

    return {
        state,
        open,
        close,
        setTemplate,
    };
};

export default useAddStudentPopup;
