import React from "react";

import Chip from "juice-base/components/chip/index.js";
import HorizontalScrollingMenu from "juice-base/components/horizontal-scrolling-menu/index.js";

import styles from "./styles.module.css";


const MenuHorizontalScrolling = (props) => {
    const menuItems = props.items.map((menuItem) => {
        return (
            <Chip
                key={menuItem.value}
                label={menuItem.label}
                withCursorPointer={false}
                selected={menuItem.value === props.selected}
            />
        );
    });

    return (
        <div className={styles.menu}>
            <div className={styles.menuScrolling}>
                <HorizontalScrollingMenu
                    dataComment={props.dataComment}
                    selected={`${props.selected}`}
                    menu={menuItems}
                    onSelect={props.onSelect}
                    scrollToSelected
                    hideArrows
                />
            </div>
        </div>
    );
};

MenuHorizontalScrolling.defaultProps = {
    items: [],
    selected: null,
    dataComment: "menu-horizontal-scrolling",
    onSelect: () => {},
};

export default MenuHorizontalScrolling;
