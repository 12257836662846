import React from "react";

import Skeleton from "juice-base/components/skeleton/index.js";

import styles from "./styles.module.css";


const SkeletonHeader = () => {
    return (
        <div className={styles.skeletonHeader}>
            <Skeleton variant="text" />
            <Skeleton variant="circle" />
        </div>
    );
};

SkeletonHeader.defaultProps = {};

export default SkeletonHeader;
