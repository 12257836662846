import * as types from "./types.js";


const initialState = {
    isIncompletedJuicesLoaded: false,
    isIncompletedJuicesLoading: false,
    incompletedJuices: [],
    page: 0,
    hasMorePages: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_INCOMPLETED_JUICES: {
            return {
                ...state,
                isIncompletedJuicesLoaded: true,
                isIncompletedJuicesLoading: false,
                hasMorePages: action.payload.hasMorePages,
                page: action.payload.page,
                incompletedJuices: action.payload.incompletedJuices || [],
            };
        }

        case types.SET_INCOMPLETED_JUICES_LOADING: {
            return {
                ...state,
                isIncompletedJuicesLoading: true,
            };
        }

        case types.CLEAR_INCOMPLETED_JUICES: {
            return {
                ...state,
                isIncompletedJuicesLoaded: false,
                isIncompletedJuicesLoading: false,
                incompletedJuices: [],
                page: 0,
                hasMorePages: false,
            };
        }

        case types.DELETE_INCOMPLETE_JUICE_BY_ID: {
            const newJuices = state.incompletedJuices
                .filter((juice) => juice.id !== action.payload.juiceId);

            return {
                ...state,
                incompletedJuices: newJuices,
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
