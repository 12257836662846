// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dxsv9C_KbtSkQx0gUywG {\n    display: grid;\n\n    grid-gap: 2.5rem;\n\n    padding: 1.5rem;\n\n    border-bottom: solid 0.1rem var(--grey-light);\n}\n\n.RoITiExoEdkZ9G6vtuvr {\n    border-top: solid 0.1rem var(--grey-light);\n}\n\n.xYdn_J5Uc3bId0WpzyCC {\n    border: none;\n}\n\n.OugqkF___gI81vmWB_fm {\n    padding: 0;\n}\n\n/* -------- */\n\n.q7WVTCnf0oGOG6e_tfQH,\n._yEyCr0TVfhylPtmEPsF {\n    display: grid;\n    grid-auto-flow: column;\n\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n}\n\n.q7WVTCnf0oGOG6e_tfQH {\n    font-weight: 600;\n    font-size: 1.8rem;\n}\n\n.q7WVTCnf0oGOG6e_tfQH div:last-child {\n    color: rgb(var(--cerulean));\n}", "",{"version":3,"sources":["webpack://./base/components/section-range/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,gBAAgB;;IAEhB,eAAe;;IAEf,6CAA6C;AACjD;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA,aAAa;;AAEb;;IAEI,aAAa;IACb,sBAAsB;;IAEtB,cAAc;;IAEd,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".section {\n    display: grid;\n\n    grid-gap: 2.5rem;\n\n    padding: 1.5rem;\n\n    border-bottom: solid 0.1rem var(--grey-light);\n}\n\n.sectionBorderTop {\n    border-top: solid 0.1rem var(--grey-light);\n}\n\n.sectionNoBorders {\n    border: none;\n}\n\n.sectionNoPadding {\n    padding: 0;\n}\n\n/* -------- */\n\n.labelSection,\n.minMax {\n    display: grid;\n    grid-auto-flow: column;\n\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n}\n\n.labelSection {\n    font-weight: 600;\n    font-size: 1.8rem;\n}\n\n.labelSection div:last-child {\n    color: rgb(var(--cerulean));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "dxsv9C_KbtSkQx0gUywG",
	"sectionBorderTop": "RoITiExoEdkZ9G6vtuvr",
	"sectionNoBorders": "xYdn_J5Uc3bId0WpzyCC",
	"sectionNoPadding": "OugqkF___gI81vmWB_fm",
	"labelSection": "q7WVTCnf0oGOG6e_tfQH",
	"minMax": "_yEyCr0TVfhylPtmEPsF"
};
export default ___CSS_LOADER_EXPORT___;
