import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getDefaultFilterGrades = (grades) => {
    let defaultValue = "";
    const defaultGrades = storage.local.loadDailyJuicesDefaultGrades();

    if (grades && grades.length > 0) {
        if (defaultGrades) {
            for (let i = 0; i < grades.length; i += 1) {
                const grade = grades[i];
                if (grade.value === defaultGrades) {
                    defaultValue = { ...grade };
                    break;
                }
            }
        } else {
            defaultValue = grades[settings.grades.length - 1];
        }
    }

    return defaultValue;
};

const StudentDailyJuicesTrial = () => {
    const getFilterByGrade = () => {
        const gs = getDefaultFilterGrades(settings.grades);
        return gs ? gs.value : "";
    };

    const [juiceManager, setJuiceManager] = useState({
        error: null,
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        session: state.user.session,
        lastVisitedJuice: state.juices.lastVisitedJuice,
        juices: state.juices.juices,
        isJuicesLoaded: state.juices.isJuicesLoaded,
    }));

    const getLastJuiceId = () => {
        if (store.juices && store.juices[0] && store.juices[0].id) {
            return store.juices[0].id;
        }

        return -1;
    };

    useEffect(() => {
        if (!store.isJuicesLoaded) {
            api.dailyJuices.getJuicesByPage({
                session: store.session,
                page: 0,
                grades: getFilterByGrade().split("-").join(",").toLowerCase(),
            }).then((res) => {
                if (res.ok) {
                    dispatch(actions.juices.setJuices({
                        juices: res.juices,
                        hasMorePages: res.hasMore,
                    }));
                }
            });
        }
    }, [store.isJuicesLoaded]);

    useEffect(() => {
        if (store.lastVisitedJuice) {
            history.push(`/daily-juice/${store.lastVisitedJuice}`);
        } else if (store.isJuicesLoaded) {
            const lastJuiceId = getLastJuiceId();
            if (lastJuiceId === -1) {
                setJuiceManager({
                    error: "Failed to load juice.",
                });
            } else {
                history.push(`/daily-juice/${lastJuiceId}`);
            }
        }
    }, [store.lastVisitedJuice, store.isJuicesLoaded]);

    if (!store.lastVisitedJuice || !store.isJuicesLoaded) {
        return (
            <RequestLoader />
        );
    }

    if (juiceManager.error) {
        return (
            <div className={styles.error}>
                {juiceManager.error}
            </div>
        );
    }

    return null;
};

export default withAuth(["student"])(StudentDailyJuicesTrial);
