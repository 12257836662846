import React from "react";

import Text from "juice-base/components/text/index.js";

import classAnnouncementStyles from "juice-base/components/class-announcement/styles.module.css";
import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";


const DailyJuiceClassAnnouncement = (props) => {
    if (!props.announcement) {
        return null;
    }

    const { announcement } = props;

    if (!announcement.announcementContent) {
        return null;
    }

    const renderContent = () => {
        if (announcement.announcementContent) {
            return (
                <Text className={classAnnouncementStyles.content}>
                    {announcement.announcementContent}
                </Text>
            );
        }

        return null;
    };

    const announcementClassName = [
        dailyJuiceCommonStyles.block,
        dailyJuiceCommonStyles.blockWithPadding,
    ].join(" ");

    const title = announcement.announcementTitle || "Class announcement";

    return (
        <div className={announcementClassName}>
            <div className={dailyJuiceCommonStyles.header}>
                {title}
            </div>
            {renderContent()}
        </div>
    );
};

DailyJuiceClassAnnouncement.defaultProps = {
    announcement: null,
};

export default DailyJuiceClassAnnouncement;
