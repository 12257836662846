import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonFlat = (props) => {
    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.uppercase]: props.uppercase,
        [styles.hugePadding]: props.hugePadding,
    });

    return (
        <button
            type="button"
            className={buttonClassName}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

ButtonFlat.defaultProps = {
    disabled: false,
    uppercase: false,
    hugePadding: false,

    children: null,

    onClick: () => {},
};

export default ButtonFlat;
