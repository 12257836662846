const isPWA = () => {
    if (window.matchMedia) { // eslint-disable-line compat/compat
        return window.matchMedia("(display-mode: standalone)").matches; // eslint-disable-line compat/compat
    }

    return false;
};

export const isOrientationPortrait = () => {
    if (window.matchMedia) { // eslint-disable-line compat/compat
        return window.matchMedia("(orientation: portrait)").matches;
    }

    return true;
};

export const getMobileShortInfo = (client) => {
    if (!client.isMobile || !client.isMobile()) {
        return "";
    }

    if (client.isMobileAndroid()) {
        return "Android";
    }

    if (client.isMobileOpera()) {
        return "Mobile Opera";
    }

    if (client.isMobileWindows()) {
        return "Mobile Windows";
    }

    if (client.isMobileBlackBerry()) {
        return "BlackBerry";
    }

    if (client.isMobileIOS()) {
        if (client.isIphone()) {
            return "IPhone";
        }

        if (client.isIpad()) {
            return "IPad";
        }

        if (client.isIpod()) {
            return "IPod";
        }

        return "Mobile IOS";
    }

    return "";
};

export const getDeviceShortInfo = () => {
    const client = new window.ClientJS();

    const os = client.getOS();
    const br = client.getBrowser();

    const mobile = getMobileShortInfo(client);

    let ret = `${os.name} ${os.version}, ${br.name} ${br.version}`;

    if (mobile) {
        ret += `, ${mobile}`;
    }

    if (isPWA()) {
        ret += ", PWA";
    }

    return ret;
};

export const getDeviceInfo = () => {
    const client = new window.ClientJS();

    const browser = client.getBrowserData();

    const device = [];

    if (browser.device.model) {
        device.push(browser.device.model);
    }

    if (browser.device.type) {
        device.push(browser.device.type);
    }

    if (browser.device.vendor) {
        device.push(browser.device.vendor);
    }

    const engine = [];

    if (browser.engine.name) {
        engine.push(browser.engine.name);
    }

    if (browser.engine.version) {
        engine.push(browser.engine.version);
    }

    return {
        ...browser,

        deviceInfo: device.join(" - "),
        engineInfo: engine.join(" - "),

        isPWA: isPWA(),
        isMobile: client.isMobile(),

        resolutionCurrent: client.getCurrentResolution(),
        resolutionAvailable: client.getAvailableResolution(),

        isChrome: client.isChrome(),
        isFirefox: client.isFirefox(),
        isSafari: client.isSafari(),
        isOpera: client.isOpera(),
        isIE: client.isIE(),

        isWindows: client.isWindows(),
        isMac: client.isMac(),

        isMobileIOS: client.isMobileIOS(),
        isIphone: client.isIphone(),
        isIpad: client.isIpad(),
        isIpod: client.isIpod(),

        isMobileAndroid: client.isMobileAndroid(),
        isMobileOpera: client.isMobileOpera(),
        isMobileWindows: client.isMobileWindows(),
        isMobileBlackBerry: client.isMobileBlackBerry(),

        navigatorUserAgent: navigator.userAgent || "",
        navigatorAppVersion: navigator.appVersion || "",
        navigatorMaxTouchPoints: navigator.maxTouchPoints || "", // eslint-disable-line compat/compat
    };
};

export const deviceShortInfo = getDeviceShortInfo();

export default getDeviceInfo();
