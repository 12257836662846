import React, { useRef, useState, useEffect } from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const SelectCustom = (props) => {
    const selectCustom = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    /* --- */

    const onToggle = () => {
        setIsOpened((val) => !val);
    };

    const onSelect = (evt, opt) => {
        setIsOpened(false);
        props.onSelect(opt.value);
    };

    const onSelectedValueIconClick = (evt) => {
        evt.stopPropagation();
        props.onIconClick();
    };

    /* --- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (selectCustom?.current?.contains
                && selectCustom.current.contains(evt.target)) {
                return;
            }

            setIsOpened(() => false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEentListener("click", globalClose, false);
            }
        };
    }, []);

    /* --- */

    const renderSelectedValueIcon = (iconType) => {
        if (iconType === "info") {
            return (
                <div
                    className={styles.optionSelectedValueIcon}
                    onClick={onSelectedValueIconClick}
                    onKeyPress={onSelectedValueIconClick}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        className={styles.optionSelectedValueIconImg}
                        src={staticFiles.informationBlue}
                        alt="Info"
                    />
                </div>
            );
        }

        return null;
    };

    const renderOptionSelectedIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <div className={styles.optionSelectedIcon}>
                <img
                    src={props.icon.src}
                    alt={props.icon.alt}
                />
            </div>
        );
    };

    const renderOptionSelectedCaret = () => {
        const imgClassName = classNames({
            [styles.optionSelectedCaretImgUp]: isOpened,
        });

        return (
            <div className={styles.optionSelectedCaret}>
                <img
                    className={imgClassName}
                    src={staticFiles.caret}
                    alt="Open selector"
                />
            </div>
        );
    };

    const renderOptionSelectedValue = () => {
        let selectedOpt = null;

        for (let i = 0; i < props.options.length; i += 1) {
            const opt = props.options[i];

            if (opt.value === props.selected) {
                selectedOpt = opt;
                break;
            }
        }

        const label = selectedOpt
            ? selectedOpt.label
            : "Select ...";

        return (
            <div className={styles.optionSelectedValue}>
                {label}
                {renderSelectedValueIcon(selectedOpt ? selectedOpt.icon : "")}
            </div>
        );
    };

    const renderOptions = () => {
        if (!isOpened) {
            return null;
        }

        if (props.options.length === 0) {
            return null;
        }

        const options = props.options.map((opt) => {
            const isSelected = props.selected === opt.value;

            const optClassName = classNames({
                [styles.option]: true,
                [styles.optionWithIcon]: props.icon,
                [styles.optionAlreadySelected]: isSelected,
            });

            let icon = null;

            if (isSelected) {
                icon = (
                    <img
                        src={staticFiles.iconCheckCherry}
                        alt="Checked"
                    />
                );
            }

            return (
                <div
                    className={optClassName}
                    onClick={(evt) => { onSelect(evt, opt); }}
                    onKeyPress={(evt) => { onSelect(evt, opt); }}
                    tabIndex="-1"
                    role="button"
                >
                    {opt.label}
                    {icon}
                </div>
            );
        });

        return (
            <div className={styles.options}>
                {options}
            </div>
        );
    };

    const optSelectedClassName = classNames({
        [styles.optionSelected]: true,
        [styles.optionSelectedWithIcon]: props.icon,
    });

    return (
        <div
            ref={selectCustom}
            className={styles.select}
        >
            <div
                className={optSelectedClassName}
                onClick={onToggle}
                onKeyPress={onToggle}
                tabIndex="-1"
                role="button"
            >
                {renderOptionSelectedIcon()}
                {renderOptionSelectedValue()}
                {renderOptionSelectedCaret()}
            </div>

            {renderOptions()}
        </div>
    );
};

SelectCustom.defaultProps = {
    selected: null,
    options: [],
    icon: null,
    onSelect: () => {},
    onIconClick: () => {},
};

export default SelectCustom;
