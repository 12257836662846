import React from "react";

import staticFiles from "juice-base/static-files.js";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmSignUp = (props) => {
    return (
        <PopupConfirm onClose={props.onClose}>
            <PopupConfirmIcon
                src={staticFiles.iconThumbsUp}
                alt="Thumbs Up"
            />

            <PopupConfirmContent>
                You&apos;re all set!
                We let your teacher know that you&apos;re waiting to join.
                They need to let you in before you can start enjoying The Juice.
                But go ahead and sign in while you wait!
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    uppercase
                    onClick={props.onClose}
                >
                    Ok
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmSignUp.defaultProps = {
    onClose: () => {},
};

export default PopupConfirmSignUp;
