import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Vocabulary from "juice-base/project/vocabulary.js";

import device from "juice-base/lib/device.js";
import { withAuth } from "juice-base/components/auth/index.js";
import useWordPopup from "juice-base/hooks/use-word-popup/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";

import PopupImage from "juice-base/components/popup-image/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";

import actions from "juice-base/store/actions.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    storiesById: state.juiceStories.storiesById,
    wordsByName: state.vocabulary.wordsByName,
    t2sJuiceStories: state.text2speech.juiceStories,
    t2sExtraJuices: state.text2speech.extraJuices,
    t2sWords: state.text2speech.words,
});

const Story = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const store = useSelector(storeSelector);

    const [imagePopup, setImagePopup] = useState(null);
    const [audioPlayerPopup, setAudioPlayerPopup] = useState(-1);
    const wordPopup = useWordPopup();

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        ejId: -1,
        isVisiblePlayer: false,
    });

    const am = useAudioManager({
        isMac: device.isMac,
    });

    const goBack = () => {
        history.push("/");
    };

    /* --- */

    const onOpenImagePopup = (storyId, image) => {
        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    /* --- */

    const onOpenWordPopup = (word) => {
        wordPopup.setLoading(true, word);

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));

                wordPopup.setWord(wordValue);
            } else {
                wordPopup.setError(word, "Cannot load word");
            }
        }).catch(() => {
            wordPopup.setError(word, "Cannot load word");
        });
    };

    const onCloseWordPopup = () => {
        wordPopup.setWord("");
    };

    /* --- */

    const onOpenExtraJuicePopup = (sId, ejId) => {
        if (sId && ejId) {
            setExtraJuicePopup((prev) => ({
                ...prev,
                ejId,
            }));
        }
    };

    const onCloseExtraJuicePopup = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            ejId: -1,
        }));
    };

    /* --- */

    const onPlayJuiceStory = (storyId) => {
        setAudioPlayerPopup(storyId);

        dispatch(actions.t2s.setJuiceStoryLoading({
            storyId,
        }));

        const juiceId = null;

        api.t2s.juice({
            juiceId,
            storyId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("juiceStories", storyId);

                dispatch(actions.t2s.setJuiceStory({
                    juiceId,
                    storyId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup(-1);
    };

    /* --- */

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        if (!am.state.extraJuices[extraJuiceId]) {
            dispatch(actions.t2s.setExtraJuiceLoading({
                extraJuiceId,
            }));
        }
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    const onLoadExtraJuiceStory = (extraJuiceId) => {
        api.t2s.juice({
            juiceId: null,
            extraJuiceId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("extraJuices", extraJuiceId);

                dispatch(actions.t2s.setExtraJuice({
                    juiceId: null,
                    extraJuiceId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    /* --- */

    useEffect(() => {
        api.juiceStories.getStory({
            session: store.session,
            storyId: params.storyId,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juiceStories.setStory({
                    story: res.story,
                }));
            } else {
                goBack();
            }
        });
    }, [params.storyId]);

    useEffect(() => {
        am.setFiles({
            juiceStories: store.t2sJuiceStories,
            extraJuices: store.t2sExtraJuices,
            words: store.t2sWords,
        });
    }, [
        store.t2sJuiceStories,
        store.t2sExtraJuices,
        store.t2sWords,
    ]);

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderExtraJuicePopup = (story) => {
        if (extraJuicePopup.ejId === -1) {
            return null;
        }

        const eJuice = story.extraJuices[0];

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = (am.state[trackGroupName] || {})[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.ejId}`}
                storyId={story.id}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={(word) => {
                    onOpenWordPopup(word);
                }}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onFirstPlay={() => {
                    onLoadExtraJuiceStory(eJuice.id);
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderWordPopup = () => {
        if (wordPopup.state.isLoading) {
            return (
                <PopupLoading />
            );
        }

        const word = Vocabulary.getWord(store.wordsByName, wordPopup.state.word);

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    onClose={onCloseWordPopup}
                />
            );
        }

        const trackGroupName = "words";
        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (audioPlayerPopup !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story?.featuredImage?.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.id;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = (am.state[trackGroupName] || {})[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.id}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onFirstPlay={() => {
                    am.playerPauseAll();

                    if (am.state[trackGroupName]
                        && am.state[trackGroupName][trackId]) {
                        am.playerPlay(trackGroupName, trackId);
                    } else {
                        onPlayJuiceStory(story.id);
                    }
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderPopups = (story) => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        if (extraJuicePopup.ejId !== -1) {
            const ejPopup = renderExtraJuicePopup(story);

            if (ejPopup) {
                ps.push(ejPopup);
            }
        }

        if (wordPopup.state.word) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup !== -1) {
            ps.push(renderPlayerAudioPopup(story));
        }

        return ps;
    };

    const renderStory = (story) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        return (
            <DailyJuiceStory
                story={story}
                dimensions={store.dimensions}
                isDefaultVideo={!device.isChrome}
                videoCaptionSrc={videoCaptionSrc}
                onImageClick={(img) => {
                    onOpenImagePopup(story.id, img);
                }}
                onWordClick={(word) => {
                    onOpenWordPopup(word);
                }}
                onAudioPlay={() => {
                    onPlayJuiceStory(story.id);
                }}
                onExtraJuiceWordClick={onOpenExtraJuicePopup}
            />
        );
    };

    if (!store.storiesById[params.storyId]) {
        return (
            <RequestLoader />
        );
    }

    const story = store.storiesById[params.storyId];

    return (
        <>
            {renderPopups(story)}
            <div className={styles.story}>
                {renderStory(story)}
            </div>
        </>
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(Story);
