export default (apiURL, methods) => {
    return {
        sendEvent(params = {}) {
            const url = `${apiURL}/events`;

            return methods.post(url, {
                session_id: params.session,
                location_id: params.locationId,
                event_id: params.eventId,
                object_id: params.objectId,
                sub_object_id: params.subObjectId,
                sub_sub_object_id: params.subSubObjectId,
            });
        },
    };
};
