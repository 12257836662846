import React from "react";

import classNames from "juice-base/lib/class-names.js";


import styles from "./styles.module.css";


const PlayerButton = (props) => {
    const controlClassName = classNames({
        [styles.controlBtn]: true,
        [styles.controlBtnMedium]: props.medium,
    });

    const imgClassName = classNames({
        [styles.controlBtnImg]: true,
        [styles.controlBtnImgRight]: props.rotateRight,
    });

    return (
        <div
            className={controlClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <img
                data-comment={props.dataComment}
                src={props.iconSrc}
                title={props.iconName}
                alt={props.iconName}
                className={imgClassName}
            />
        </div>
    );
};

PlayerButton.defaultProps = {
    dataComment: "",
    iconSrc: "",
    iconName: "",
    medium: false,
    rotateRight: false,
    onClick: () => {},
};

export default PlayerButton;
