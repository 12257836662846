export const hasEmptyValue = (obj) => {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i += 1) {
        if (!obj[keys[i]]) {
            return true;
        }
    }

    return false;
};

export const hasValue = (obj) => {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i += 1) {
        if (obj[keys[i]]) {
            return true;
        }
    }

    return false;
};

export default {
    hasEmptyValue,
    hasValue,
};
