// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nwcYnMTYZuYYGackf2Q_ {\n    touch-action: pan-y;\n\n    overflow-y: scroll;\n    scrollbar-color: #f97316 transparent;\n    scrollbar-width: thin;\n}\n\n.nwcYnMTYZuYYGackf2Q_::-webkit-scrollbar {\n    width: 1rem;\n}\n\n.nwcYnMTYZuYYGackf2Q_::-webkit-scrollbar-track {\n    background-color: transparent;\n}\n\n.nwcYnMTYZuYYGackf2Q_::-webkit-scrollbar-thumb {\n    background-color: #f97316;\n    border-radius: 2rem;\n    border: 0.3rem solid transparent;\n    background-clip: content-box;\n}\n\n.nwcYnMTYZuYYGackf2Q_::-webkit-scrollbar-thumb:hover {\n    background-color: #f97316;\n}\n", "",{"version":3,"sources":["webpack://./base/components/juice-scrollbar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB,kBAAkB;IAClB,oCAAoC;IACpC,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gCAAgC;IAChC,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".scrollbar {\n    touch-action: pan-y;\n\n    overflow-y: scroll;\n    scrollbar-color: #f97316 transparent;\n    scrollbar-width: thin;\n}\n\n.scrollbar::-webkit-scrollbar {\n    width: 1rem;\n}\n\n.scrollbar::-webkit-scrollbar-track {\n    background-color: transparent;\n}\n\n.scrollbar::-webkit-scrollbar-thumb {\n    background-color: #f97316;\n    border-radius: 2rem;\n    border: 0.3rem solid transparent;\n    background-clip: content-box;\n}\n\n.scrollbar::-webkit-scrollbar-thumb:hover {\n    background-color: #f97316;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollbar": "nwcYnMTYZuYYGackf2Q_"
};
export default ___CSS_LOADER_EXPORT___;
