import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupConfirmIcon = (props) => {
    const imgClassName = classNames({
        [styles.icon]: true,
        [styles.iconBig]: props.big,
    });

    return (
        <img
            src={props.src}
            alt={props.alt}
            className={imgClassName}
        />
    );
};

PopupConfirmIcon.defaultProps = {
    big: false,
    src: "",
    alt: "",
};

export default PopupConfirmIcon;
