import React, { useState } from "react";
import ReactSelect, { components } from "react-select";

import classNames from "juice-base/lib/class-names.js";
import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const selectStyles = {
    indicatorSeparator: () => ({
        display: "none",
    }),

    placeholder: () => ({
        marginLeft: "0.4rem",
        color: "#000",
        fontSize: "1.5rem",
    }),

    control: (provided, state) => {
        const vals = state.getValue();
        let hasValue = vals && vals.length > 0;

        if (vals[0] && vals[0].value === -1) {
            hasValue = false;
        }

        return {
            ...provided,
            ...state.selectProps.customControlStyles,
            border: hasValue
                ? "0.2rem solid #0ea5e9"
                : state.selectProps.customControlStyles.border,
        };
    },

    option: (provided, state) => ({
        ...provided,
        ...state.selectProps.customOptionStyles,
        color: state.isSelected
            ? state.selectProps.customOptionStyles.colorSelected
            : state.selectProps.customOptionStyles.colorDefault,
        backgroundColor: state.isFocused
            ? state.selectProps.customOptionStyles.backgroundColorFocused
            : state.selectProps.customOptionStyles.backgroundColorDefault,
        ":active": {
            backgroundColor: "",
        },
    }),

    menu: () => ({
        zIndex: 999999,
        position: "absolute",
        left: "0",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: "0.1rem 0.1rem 0.3rem 0.1rem #d5d5d5",
        borderRadius: "0.4rem",
    }),

    menuList: (provided) => ({
        ...provided,
        paddingBottom: "0",
    }),

    singleValue: (provided, state) => ({
        fontWeight: state.selectProps.customSingleValueStyles.fontWeight,
    }),
};

const Control = ({ children, ...props }) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.Control {...props}>
            {children}
        </components.Control>
    );
};

const DropdownIndicator = ({ children, ...props }) => {
    let indicator = children;

    if (props.selectProps.customIndicator) {
        indicator = props.selectProps.customIndicator;
    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.DropdownIndicator {...props}>
            {indicator}
        </components.DropdownIndicator>
    );
};

const Select = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.selectedValue || null);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const getDefaultValue = () => {
        let defaultValue = {
            label: props.defaultValueLabel,
            value: -1,
        };

        let selectedItem = null;

        for (let i = 0; i < props.values.length; i += 1) {
            if (props.values[i].value === props.selectedValue) {
                selectedItem = props.values[i];
                break;
            }
        }

        if (selectedItem) {
            defaultValue = {
                label: selectedItem.label,
                value: selectedItem.value,
            };
        }

        return defaultValue;
    };

    const getOptions = (defaultValue) => {
        let opts = [...props.values];

        if (defaultValue) {
            opts = opts.map((value) => {
                let newLabel = value.label;

                let isSelected = false;

                if (!selectedValue && value.value === defaultValue.value) {
                    isSelected = true;
                } else if (value.value === selectedValue) {
                    isSelected = true;
                }

                if (isSelected) {
                    const selectedValueClassName = classNames({
                        [styles.selectedValue]: true,
                    });

                    // TODO: symbols
                    const check = (
                        <div>&#10003;</div>
                    );

                    newLabel = (
                        <div className={selectedValueClassName}>
                            {value.label}
                            {check}
                        </div>
                    );
                }

                return {
                    ...value,
                    label: newLabel,
                };
            });
        }

        return opts;
    };

    const onChange = (val) => {
        props.onChange(val);
        setSelectedValue(val?.value || null);
    };

    const getMaxMenuHeight = () => {
        let maxMenuHeight = "300";

        if (props.isShort) {
            maxMenuHeight = "12rem";
        }

        return maxMenuHeight;
    };

    const getControlStyles = () => {
        return {
            minWidth: "100%",
            minHeight: "5.7rem",

            padding: 0,

            backgroundColor: "#fff",

            opacity: props.isDisabled ? 0.5 : 1,

            border: "0.2rem solid hsl(0deg 0% 84%)",
            borderRadius: "0.4rem",

            controlBoxShadow: "0rem 0rem 0.1rem 0.1rem #00000017",
        };
    };

    const getSingleValueStyles = () => {
        return {
            fontWeight: "normal",
        };
    };

    const getOptionStyles = () => {
        return {
            colorDefault: "#585858",
            colorSelected: "#2D9CDB",

            backgroundColorDefault: "#fff",
            backgroundColorFocused: "#dbe7ff",

            padding: "1rem 1.5rem",
        };
    };

    /* --- */

    const renderIndicator = () => {
        if (isMenuOpen) {
            return (
                <img
                    className={styles.caretUp}
                    src={staticFiles.caret}
                    alt="Opened Menu"
                />
            );
        }

        return (
            <img
                className={styles.caret}
                src={staticFiles.caret}
                alt="Open Menu"
            />
        );
    };

    const defaultValue = getDefaultValue();
    const options = getOptions(defaultValue);

    return (
        <div className={styles.field}>
            <ReactSelect
                id={props.name}
                name={props.name}
                ref={props.selectRef}
                customIndicator={renderIndicator()}
                components={{ Control, DropdownIndicator }}
                options={options}
                defaultValue={defaultValue}
                placeholder={props.placeholder}
                styles={selectStyles}
                isDisabled={props.isDisabled}
                isSearchable={props.isSearchable}
                customOptionStyles={getOptionStyles()}
                customControlStyles={getControlStyles()}
                customSingleValueStyles={getSingleValueStyles()}
                maxMenuHeight={getMaxMenuHeight()}
                onChange={onChange}
                onMenuOpen={() => {
                    setMenuOpen(true);
                }}
                onMenuClose={() => {
                    setMenuOpen(false);
                }}
            />
        </div>
    );
};

Select.defaultProps = {
    selectRef: null,

    name: "",
    values: [],
    selectedValue: -1,

    defaultValueLabel: "Select...",
    placeholder: "Select...",

    isShort: false,
    isDisabled: false,
    isSearchable: false,

    onChange: () => {},
};

export default Select;
