import {
    SET_DIMENSIONS,
    SET_IS_PWA,
} from "./types.js";


export const setDimensions = (dimensions) => ({
    type: SET_DIMENSIONS,
    payload: {
        dimensions,
    },
});

export const setIsPWA = (isPWA) => ({
    type: SET_IS_PWA,
    payload: {
        isPWA,
    },
});
