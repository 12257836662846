// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tz38Z9_szBG_ThKI7BSR {\n    display: grid;\n    grid-template-columns: auto auto;\n}\n\n.wI72ThBnk1kHWOcpjRd3 {\n    grid-auto-flow: row;\n    grid-template-columns: none;\n}", "",{"version":3,"sources":["webpack://./base/components/date-range-custom/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":[".dateRange {\n    display: grid;\n    grid-template-columns: auto auto;\n}\n\n.dateRangeMobile {\n    grid-auto-flow: row;\n    grid-template-columns: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateRange": "tz38Z9_szBG_ThKI7BSR",
	"dateRangeMobile": "wI72ThBnk1kHWOcpjRd3"
};
export default ___CSS_LOADER_EXPORT___;
