const getClassCodeById = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].classCode || "";
        }
    }

    return "";
};

const setClassCodeById = (classes, classId, classCode) => {
    return classes.map((cls) => {
        if (cls.id === classId) {
            return {
                ...cls,
                classCode,
            };
        }

        return cls;
    });
};

const getClassGradeById = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].grade;
        }
    }

    return "";
};

const getClassLimit = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].classLimit;
        }
    }

    return 35;
};

const getStudentsCount = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].studentsCount;
        }
    }

    return 0;
};

export default {
    getClassCodeById,
    setClassCodeById,
    getClassGradeById,
    getClassLimit,
    getStudentsCount,
};
