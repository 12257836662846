import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";

import styles from "./styles.module.css";

const TeacherShareClassCode = (props) => {
    const menu = [
        {
            label: "Class Code",
            onClick: () => {
                props.onGenerateClassCode();
            },
        },
        {
            label: "Direct Link",
            onClick: () => {
                props.onDirectLinkClick();
            },
        },
    ];

    const blocks = menu.map((block) => {
        return (
            <div className={styles.shareClassCodePageBlock}>
                <div className={styles.shareClassCodePageBlockLabel}>
                    {block.label}
                </div>
                <ButtonFlat
                    uppercase
                    hugePadding
                    onClick={block.onClick}
                >
                    Generate
                </ButtonFlat>
            </div>
        );
    });

    return (
        <div className={styles.shareClassCodePage}>
            <div className={styles.shareClassCodePageTitle}>
                Share the code or link with your students.
            </div>
            <div className={styles.shareClassCodePageBlocks}>
                {blocks}
            </div>
        </div>
    );
};

TeacherShareClassCode.defaultProps = {
    onDirectLinkClick: () => {},
    onGenerateClassCode: () => {},
};

export default TeacherShareClassCode;
