import { getTouch } from "juice-base/lib/touches.js";


class Scroller {
    constructor(scrollerElem, ElemBuilder, options) {
        this.callbacks = {
            onLeft: options.onLeft,
            onRight: options.onRight,
        };

        /* --- */

        this.keys = {
            tab: 9,
            right: 37,
            left: 39,
        };

        this.minPageShift = 1;
        this.maxPageShift = 100;

        this.evtCoords = [];

        this.evtStartCoords = {
            x: -1,
            y: -1,
        };

        this.currentIndex = options.defaultPage || 0;

        this.isScrolling = false;

        this.scrollingUnblockTimeoutSeconds = 0.2;

        /* --- */

        this.evtKeyDown = this.evtKeyDown.bind(this);
        this.evtResize = this.evtResize.bind(this);

        this.evtFullscreen = this.evtFullscreen.bind(this);

        this.evtStart = this.evtStart.bind(this);
        this.evtMove = this.evtMove.bind(this);
        this.evtEnd = this.evtEnd.bind(this);

        this.scrollerElem = new ElemBuilder(scrollerElem, {
            evtKeyDown: this.evtKeyDown,
            evtResize: this.evtResize,

            evtFullscreen: this.evtFullscreen,

            evtStart: this.evtStart,
            evtMove: this.evtMove,
            evtEnd: this.evtEnd,
        });

        this.scrollFastToCurrent();

        this.scrollerElem.addEventListeners();
    }

    destroy() {
        this.scrollerElem.removeEventListeners();
    }

    /* --- */

    setCallbacks(options = {}) {
        if (options.onLeft) {
            this.callbacks.onLeft = options.onLeft;
        }

        if (options.onRight) {
            this.callbacks.onRight = options.onRight;
        }
    }

    isMoving() {
        return this.evtStartCoords.x !== -1
            && this.evtStartCoords.y !== -1;
    }

    blockScrolling() {
        this.isScrolling = true;
    }

    unblockScrolling() {
        window.setTimeout(() => {
            this.isScrolling = false;
        }, this.scrollingUnblockTimeoutSeconds * 1000);
    }

    /* --- */

    scrollToCurrent() {
        const { offset } = this.scrollerElem.getOffsetByIndex(this.currentIndex);

        this.scrollerElem.scrollX(offset, {
            withTransition: true,
        });
    }

    scrollFastToCurrent() {
        const { offset } = this.scrollerElem.getOffsetByIndex(this.currentIndex);

        this.scrollerElem.scrollXFast(offset);
    }

    scrollToRestore(options = {}) {
        const { offset } = this.scrollerElem.getOffsetByIndex(this.currentIndex);

        this.scrollerElem.scrollX(offset, {
            withTransition: !!options.withTransition,
        });
    }

    /* --- */

    tryScrollByIndex(index) {
        const { offset } = this.scrollerElem.getOffsetByIndex(index);

        this.currentIndex = index;

        this.scrollerElem.scrollX(offset);
    }

    tryScrollLeft() {
        const nextIndex = this.scrollerElem.getNextIndex(this.currentIndex);

        if (this.currentIndex === nextIndex) {
            this.scrollToCurrent();
            return;
        }

        this.callbacks.onLeft();
    }

    tryScrollRight() {
        const prevIndex = this.scrollerElem.getPrevIndex(this.currentIndex);

        if (this.currentIndex === prevIndex) {
            this.scrollToCurrent();
            return;
        }

        this.callbacks.onRight();
    }

    /* --- */

    evtKeyDown(evt) {
        const { keyCode } = evt;

        const keyCodes = [];

        Object.keys(this.keys).forEach((key) => {
            keyCodes.push(this.keys[key]);
        });

        if (keyCode === this.keys.tab) {
            evt.preventDefault();
            return;
        }

        if (keyCodes.indexOf(keyCode) === -1
            || this.isScrolling) {
            return;
        }

        this.blockScrolling();

        switch (keyCode) {
            case this.keys.right:
                this.tryScrollRight();
                break;
            case this.keys.left:
                this.tryScrollLeft();
                break;
            default:
                break;
        }

        this.unblockScrolling();
    }

    evtResize() {
        this.scrollToRestore({
            withTransition: false,
        });

        // NOTE: after resize fix

        setTimeout(() => {
            this.scrollToRestore({
                withTransition: false,
            });
        }, 200);

        // NOTE: fix for mobile device

        setTimeout(() => {
            this.scrollToRestore({
                withTransition: false,
            });
        }, 300);
    }

    // eslint-disable-next-line class-methods-use-this
    evtFullscreen() {}

    /* --- */

    evtStart({ x, y }) {
        if (this.isScrolling) {
            return;
        }

        this.evtStartCoords.x = x;
        this.evtStartCoords.y = y;

        this.evtCoords = [];
        this.evtCoords.push({ x, y });
    }

    evtMove({ evt, x, y }) {
        if (this.isScrolling || !this.isMoving()) {
            return;
        }

        const xDiff = this.evtStartCoords.x - x;
        const yDiff = this.evtStartCoords.y - y;

        if (Math.abs(xDiff) < this.minPageShift
            && Math.abs(yDiff) < this.minPageShift) {
            return;
        }

        const touch = getTouch(this.evtStartCoords, { x, y });

        if (touch.left || touch.right) {
            const {
                isFirst,
                isLast,
                offset,
            } = this.scrollerElem.getOffsetByIndex(this.currentIndex);

            if (isFirst && (xDiff < -this.maxPageShift)) {
                return;
            }

            if (isLast && (xDiff > this.maxPageShift)) {
                return;
            }

            evt.preventDefault();
            evt.stopPropagation();

            this.evtCoords.push({ x, y });

            this.scrollerElem.scrollXFast(offset + xDiff);
        }
    }

    evtEnd({ x, y }) {
        if (this.isScrolling) {
            return;
        }

        // NOTE: video fix, when on tap page scrolls
        if (this.evtStartCoords.x === -1 && this.evtStartCoords.y === -1) {
            return;
        }

        const touch = getTouch(this.evtStartCoords, { x, y });

        this.blockScrolling();

        if (touch.left) {
            this.tryScrollLeft();
        } else if (touch.right) {
            this.tryScrollRight();
        } else {
            this.scrollToRestore({
                withTransition: false,
            });
        }

        this.evtStartCoords.x = -1;
        this.evtStartCoords.y = -1;

        this.evtCoords = [];

        this.unblockScrolling();
    }
}

export default Scroller;
