import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";

// TODO: rename, it is not a slider
const SliderRange = (props) => {
    const handle = <div className={styles.handle} />;

    let minHandle = handle;
    let maxHandle = handle;

    if (props.noMinHandle) {
        minHandle = null;
    }

    if (props.noHandlers) {
        minHandle = null;
        maxHandle = null;
    }

    const rangeScetionClassName = classNames({
        [styles.rangeSection]: true,
        [styles.rangeSectionNoMinHandle]: props.noMinHandle,
        [styles.rangeSectionNoHandlers]: props.noHandlers,
    });

    const rangeSectionStyle = {
        width: `${props.max - props.min}%`,
        marginLeft: `${props.min}%`,
    };

    const rangeClassNames = classNames({
        [styles.range]: true,
        [styles.rangeNoHandlers]: props.noHandlers,
    });

    const range = (
        <div className={rangeClassNames}>
            <div
                className={rangeScetionClassName}
                style={rangeSectionStyle}
            >
                {minHandle}
                <div className={styles.rangeLine} />
                {maxHandle}
            </div>
        </div>
    );

    return (
        <div className={styles.line}>
            {range}
        </div>
    );
};

SliderRange.defaultProps = {
    min: 0,
    max: 100,

    noMinHandle: false,
    noHandlers: false,
};

export default SliderRange;
