import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import symbols from "juice-base/lib/symbols.js";
import HorizontalScrollingMenu from "juice-base/components/horizontal-scrolling-menu/index.js";

import styles from "./styles.module.css";


const TabsScrolling = (props) => {
    const [selectedTab, setSelectedTab] = useState(props.defaultTab);

    if (props.tabs.length === 0) {
        return null;
    }

    const onChange = (key) => {
        const id = Number(key);
        setSelectedTab(id);

        props.onChange(id);
    };

    const menu = props.tabs.map((tab) => {
        const optionClassName = classNames({
            [styles.option]: true,
            [styles.optionSelected]: selectedTab === tab.value,
        });

        return (
            <div
                key={tab.value}
                className={optionClassName}
            >
                {(tab.label || "").trim() || symbols.nbsp}
            </div>
        );
    });

    const menuStyle = {
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "max-content auto max-content",
    };

    if (props.hideArrows) {
        menuStyle.gridTemplateColumns = "auto";
    }

    const tabsClassName = classNames({
        [styles.tabs]: true,
        [styles.tabsWithShadow]: props.withShadow,
    });

    return (
        <div className={tabsClassName}>
            <HorizontalScrollingMenu
                menuStyle={menuStyle}
                itemClass={styles.menuItem}
                menu={menu}
                selected={`${selectedTab}`}
                onSelect={onChange}
                hideArrows={props.hideArrows}
            />
        </div>
    );
};

TabsScrolling.defaultProps = {
    tabs: [],
    defaultTab: null,
    hideArrows: false,
    withShadow: true,
    onChange: () => {},
};

export default TabsScrolling;
