import {
    SET_TEMPLATE_LOADING_BY_SLUG,
    SET_TEMPLATE_BY_SLUG,
} from "./types.js";


export const setTemplateLoadingBySlug = (payload) => ({
    type: SET_TEMPLATE_LOADING_BY_SLUG,
    payload: {
        slug: payload.slug,
    },
});

export const setTemplateBySlug = (payload) => ({
    type: SET_TEMPLATE_BY_SLUG,
    payload: {
        slug: payload.slug,
        template: payload.template,
    },
});

export default {};
