// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._LyJS8Jc8rArDk7dkoyJ {}\n\n._LyJS8Jc8rArDk7dkoyJ p {\n    margin: 0;\n    padding: 0;\n}\n\n._LyJS8Jc8rArDk7dkoyJ ul,\n._LyJS8Jc8rArDk7dkoyJ ol {\n    margin: 1rem 1.5rem;\n    padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/text/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,SAAS;IACT,UAAU;AACd;;AAEA;;IAEI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".text {}\n\n.text p {\n    margin: 0;\n    padding: 0;\n}\n\n.text ul,\n.text ol {\n    margin: 1rem 1.5rem;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "_LyJS8Jc8rArDk7dkoyJ"
};
export default ___CSS_LOADER_EXPORT___;
