import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Section from "juice-base/components/section/index.js";
import Searcher from "juice-base/components/searcher/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import { withAuth } from "juice-base/components/auth/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import actions from "juice-base/store/actions.js";
import device from "juice-base/lib/device.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const Vocabulary = () => {
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const [wordPopupState, setWordPopup] = useState({
        isLoading: false,
        word: "",
        errors: {},
        isOpen: false,
    });

    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        session: state.user.session,
        isWordsLoaded: state.vocabulary.isWordsLoaded,
        wordsByName: state.vocabulary.wordsByName,
        words: state.vocabulary.words,
        t2sWords: state.text2speech.words,
    }));

    const am = useAudioManager({
        isMac: device.isMac,
    });

    const setPageAndHasMore = (isHasMore) => {
        setHasMore(isHasMore);
        setPage(page + 1);
    };

    const getWords = () => {
        api.vocabulary.getWords({ page }).then((res) => {
            if (res.ok) {
                dispatch(actions.vocabulary.setVocabularyWords({
                    words: store.words.concat(res.words),
                }));

                setPageAndHasMore(res.hasMore);
            }
        });
    };

    useEffect(() => {
        getWords();
    }, []);

    useEffect(() => {
        am.setFiles({
            words: store.t2sWords,
        });
    }, [store.t2sWords]);

    const onSearchWord = (value) => {
        return api.vocabulary.searchWords({
            session: store.session,
            value,
        }).then((res) => {
            if (!res.ok) {
                return [];
            }

            return (res.words || []).map((w) => ({
                value: w.id,
                label: w.word,
            }));
        });
    };

    const onWordClick = (word) => {
        setWordPopup({
            word,
            isLoading: true,
            isOpen: true,
        });

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));
            } else {
                setWordPopup((prev) => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        [word]: "Cannot load word",
                    },
                }));
            }

            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: wordValue,
            }));
        }).catch(() => {
            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: "",
                errors: {
                    ...prev.errors,
                    [word]: "Cannot load word",
                },
            }));
        });
    };

    const onSelectWord = (value) => {
        if (value) {
            onWordClick(value.label);
        }
    };

    const renderSearcher = () => {
        return (
            <Searcher
                onLoad={onSearchWord}
                onChange={onSelectWord}
            />
        );
    };

    const renderWords = () => {
        const ws = store.words.map((w) => {
            return (
                <div key={w.id} className={styles.word}>
                    <div
                        className={styles.wordLink}
                        onClick={() => {
                            onWordClick(w.word);
                        }}
                        onKeyPress={() => {
                            onWordClick(w.word);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        {w.word}
                    </div>
                </div>
            );
        });

        if (ws.length === 0) {
            const noWords = (
                <div className={styles.noWords}>
                    No words
                </div>
            );

            ws.push(noWords);
        }

        return (
            <div className={styles.words}>
                <InfiniteScroll
                    dataLength={store.words.length}
                    next={getWords}
                    hasMore={hasMore}
                    loader={<p>Loading...</p>}
                >
                    {ws}
                </InfiniteScroll>
            </div>
        );
    };

    const renderWordPopup = () => {
        if (!wordPopupState.isOpen) {
            return null;
        }

        if (wordPopupState.isLoading) {
            return (
                <PopupLoading
                    popupClasses={styles.fixedPopup}
                />
            );
        }

        const wordLowerCase = wordPopupState.word.toLowerCase();

        let word = store.wordsByName[wordPopupState.word];

        if (!word && store.wordsByName[wordLowerCase]) {
            word = store.wordsByName[wordLowerCase];
        }

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    popupClasses={styles.fixedPopup}
                    onClose={() => {
                        setWordPopup({
                            isOpen: false,
                            isLoading: false,
                            word: "",
                            errors: {},
                        });
                    }}
                />
            );
        }

        const trackGroupName = "words";
        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                popupClasses={styles.fixedPopup}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={() => {
                    setWordPopup({
                        isOpen: false,
                        isLoading: false,
                        word: "",
                        errors: {},
                    });
                }}
                closeOnLayoutClick
            />
        );
    };

    if (!store.isWordsLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderWordPopup()}
            <div className={styles.vocabulary}>
                <Section name="Vocab">
                    {renderSearcher()}
                    {renderWords()}
                </Section>
            </div>
        </>
    );
};

export default withAuth(["student", "teacher"])(Vocabulary);
