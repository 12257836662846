import React, { useEffect, useState } from "react";

import Students from "juice-base/project/students.js";

import classNames from "juice-base/lib/class-names.js";

import staticFiles from "juice-base/static-files.js";

// TODO: use input-custom
import Input from "juice-base/components/forms/input/index.js";
import Dropdown from "juice-base/components/dropdown/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import PopupConfirmClassStudentsLimit from "juice-base/business/popup-confirm-class-students-limit/index.js";

import styles from "./styles.module.css";


const TeacherAddStudentsRows = (props) => {
    const rowsValues = {
        nameValue: "",
        nameError: null,

        lastNameValue: "",
        lastNameError: null,

        emailValue: "",
        emailError: null,

        gradeValue: props.defaultGrade,
    };

    const [
        isVisibleClassStudentsLimitPopup,
        setIsVisibleClassStudentsLimitPopup,
    ] = useState(false);

    const [isRowsLoading, setRowsLoading] = useState(false);
    const [isRowsEdited, setIsRowsEdited] = useState(false);

    const [rows, setRows] = useState([
        { ...rowsValues },
    ]);

    useEffect(() => {
        if (!isRowsEdited && props.rows.length > 0) {
            setRows(props.rows);
        }
    }, [props.rows]);

    const isRowsLimitReached = () => {
        if (props.noRowsLimit) {
            return false;
        }

        if (rows.length >= props.rowsLimit) {
            return true;
        }

        return false;
    };

    const isRowsMoreThanLimit = () => {
        if (props.noRowsLimit) {
            return false;
        }

        if (rows.length > props.rowsLimit) {
            return true;
        }

        return false;
    };

    const isRowsHasErrors = () => {
        if (isRowsMoreThanLimit()) {
            return true;
        }

        for (let i = 0; i < rows.length; i += 1) {
            if ((rows[i].nameError
                    || !rows[i].nameValue)
                || (rows[i].lastNameError
                    || !rows[i].lastNameValue)
                || rows[i].emailError
                || !rows[i].gradeValue) {
                return true;
            }
        }

        return false;
    };

    const onAddStudentsToClass = () => {
        if (isRowsHasErrors()) {
            return;
        }

        props.onAddStudentsToClass(rows);

        setRows([
            { ...rowsValues },
        ]);
    };

    const onProccedToConfirmation = () => {
        if (isRowsHasErrors()) {
            return;
        }

        props.onProccedToConfirmation(rows);
    };

    const onImportAddStudents = () => {
        if (isRowsHasErrors()) {
            return;
        }

        props.onImportAddStudents(rows);
    };

    const renderInputField = (params) => {
        // TODO: use form or not
        return (
            <Input
                name={params.name}
                placeholder={params.placeholder}
                label={params.label}
                error={params.error}
                value={params.value}
                iconSrc={params.iconSrc ? params.iconSrc : null}
                onChange={(evt) => {
                    params.onChange(evt.target.value);
                }}
                onBlur={() => {
                    if (params.onBlur) {
                        params.onBlur();
                    }
                }}
            />
        );
    };

    const renderGradeLevelDropdown = (params = {}) => {
        let gradeLabel = (params.rowValue);

        for (let i = 0; i < props.grades.length; i += 1) {
            if (props.grades[i].value === params.rowValue) {
                gradeLabel = props.grades[i].label;
                break;
            }
        }

        let selector = (gradeLabel);

        if (params.editable) {
            selector = (
                <Dropdown
                    selectedValue={params.rowValue}
                    values={props.grades}
                    addStudentTheme
                    onChange={(value) => {
                        params.onGradeChange(value.value, params.idx);
                    }}
                />
            );
        }

        return (
            <div className={styles.gradeDropdown}>
                <div className={styles.gradeDropdownLabel}>
                    Reading level *
                </div>
                {selector}
            </div>
        );
    };

    const onSetIsEdited = () => {
        if (!isRowsEdited) {
            setIsRowsEdited(true);
        }
    };

    const onChangeName = (value, id) => {
        const error = Students.validateName(value);

        const newRows = [...rows];

        newRows[id] = {
            ...newRows[id],
            nameValue: value,
            nameError: error,
        };

        setRows(newRows);
        onSetIsEdited();
    };

    const onChangeLastName = (value, id) => {
        const error = Students.validateLastName(value);

        const newRows = [...rows];

        newRows[id] = {
            ...newRows[id],
            lastNameValue: value,
            lastNameError: error,
        };

        setRows(newRows);
        onSetIsEdited();
    };

    const onChangeEmail = (value, id, withValidation = true) => {
        let error = null;

        if (withValidation) {
            error = Students.validateEmail(value);
        }

        const newRows = [...rows];

        newRows[id] = {
            ...newRows[id],
            emailValue: value,
            emailError: error,
        };

        setRows(newRows);
        onSetIsEdited();
    };

    const onChangeGrade = (value, id) => {
        const newRows = [...rows];

        newRows[id] = {
            ...newRows[id],
            gradeValue: value,
        };

        setRows(newRows);
        onSetIsEdited();
    };

    const onAddRow = () => {
        if (isRowsLimitReached()) {
            setIsVisibleClassStudentsLimitPopup(true);
            return;
        }

        setRows([
            ...rows,
            { ...rowsValues },
        ]);

        onSetIsEdited();
    };

    const onSetRows = (newRows) => {
        setRowsLoading(true);
        onSetIsEdited();

        // NOTE: hack to show correct dropdown values
        setTimeout(() => {
            setRows(newRows);
            setRowsLoading(false);
        }, 500);
    };

    const onRemoveRow = (idx) => {
        props.onRemoveStudent({
            onUndo: () => {
                onSetRows(rows);
            },
            row: rows[idx],
        });

        const newRows = rows.filter((row, index) => index !== idx);

        onSetRows(newRows);
    };

    const onUploadDifferentFile = () => {
        setIsRowsEdited(false);

        setRows([
            { ...rowsValues },
        ]);

        props.onUploadDifferentFile();
    };

    const renderRows = (params = {}) => {
        const isMoreThanOneRow = params.rows.length > 1;

        return params.rows.map((row, idx) => {
            let removeRowButton = null;

            if (isMoreThanOneRow && params.editable) {
                removeRowButton = (
                    <div
                        className={styles.removeRowButton}
                        onClick={() => {
                            params.onRemoveRow(idx);
                        }}
                        onKeyPress={() => {
                            params.onRemoveRow(idx);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        <img
                            src={staticFiles.iconTrashV2}
                            alt="Remove row"
                            title="Remove row"
                        />
                    </div>
                );
            }

            const firstNameLabel = "First Name *";
            const lastNameLabel = "Last Name *";
            const emailLabel = "Email";

            let nameField = (
                <div className={styles.reviewField}>
                    <div>{firstNameLabel}</div>
                    <div className={styles.reviewFieldValues}>{row.nameValue}</div>
                </div>
            );

            let lastNameField = (
                <div className={styles.reviewField}>
                    <div>{lastNameLabel}</div>
                    <div className={styles.reviewFieldValues}>{row.lastNameValue}</div>
                </div>
            );

            let emailField = (
                <div className={styles.reviewField}>
                    <div>{emailLabel}</div>
                    <div className={styles.reviewFieldValues}>{row.emailValue}</div>
                </div>
            );

            if (params.editable) {
                nameField = (renderInputField({
                    name: `name-field-${idx}`,
                    label: firstNameLabel,
                    placeholder: "Example: Dylan",
                    value: row.nameValue,
                    error: row.nameError,
                    onChange: (value) => {
                        params.onInputNameChange(value, idx);
                    },
                }));

                lastNameField = (renderInputField({
                    name: `last-name-field-${idx}`,
                    label: lastNameLabel,
                    placeholder: "Example: Ement",
                    value: row.lastNameValue,
                    error: row.lastNameError,
                    onChange: (value) => {
                        params.onInputLastNameChange(value, idx);
                    },
                }));

                emailField = (renderInputField({
                    name: `email-field-${idx}`,
                    label: emailLabel,
                    placeholder: "example@thejuice.news",
                    value: row.emailValue,
                    error: row.emailError,
                    onChange: (value) => {
                        params.onInputEmailChange(value, idx, true);
                    },
                    onBlur: () => {
                        if (!row.emailValue) {
                            params.onInputEmailChange("", idx, false);
                        }
                    },
                }));
            }

            const rowClassName = classNames({
                [styles.contentRowFields]: true,
                [styles.rowWithTrash]: removeRowButton,
                [styles.rowWithRoseBackground]: !props.noRowsLimit && idx > props.rowsLimit - 1,
            });

            return (
                <div className={rowClassName}>
                    <div>{nameField}</div>
                    <div>{lastNameField}</div>
                    <div>{emailField}</div>
                    {renderGradeLevelDropdown({
                        rowValue: row.gradeValue,
                        idx,
                        editable: params.editable,
                        onGradeChange: (value, index) => {
                            params.onGradeChange(value, index);
                        },
                    })}
                    {removeRowButton}
                </div>
            );
        });
    };

    const renderControls = () => {
        if (props.imported) {
            if (props.preview) {
                return (
                    <div className={styles.sectionButtonsInColumn}>
                        <ButtonBig
                            onClick={props.onImportBackToEdit}
                            outlined
                        >
                            Back to edit
                        </ButtonBig>
                        <ButtonBig
                            onClick={onImportAddStudents}
                            disabled={isRowsHasErrors()}
                        >
                            Add students
                        </ButtonBig>
                    </div>
                );
            }

            let controls = null;

            if (rows.length !== 0) {
                controls = (
                    <div className={styles.sectionButtonsInColumn}>
                        <ButtonBig
                            onClick={onUploadDifferentFile}
                            outlined
                        >
                            Upload a different file
                        </ButtonBig>
                        <ButtonBig
                            onClick={onProccedToConfirmation}
                            disabled={isRowsHasErrors()}
                        >
                            Proceed to confirmation
                        </ButtonBig>
                    </div>
                );
            }

            return controls;
        }

        const buttonDisabled = isRowsLimitReached();

        const addAnotherStudentButtonClassName = classNames({
            [styles.addAnotherStudentButton]: true,
            [styles.addAnotherStudentButtonDisabled]: buttonDisabled,
        });

        let addRowImgSrc = staticFiles.iconAddInCircleBlue;

        if (buttonDisabled) {
            addRowImgSrc = staticFiles.iconAddInCircleGrey;
        }

        return (
            <div className={styles.sectionButtonsInRow}>
                {/* TODO: replace with button component */}
                <div
                    className={addAnotherStudentButtonClassName}
                    onClick={onAddRow}
                    onKeyPress={onAddRow}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        src={addRowImgSrc}
                        alt="Add"
                    />
                    Add another student
                </div>
                <ButtonBig
                    disabled={isRowsHasErrors()}
                    onClick={onAddStudentsToClass}
                    uppercase
                >
                    Add students to class
                </ButtonBig>
            </div>
        );
    };

    const onCloseClassStudentsLimitPopup = () => {
        setIsVisibleClassStudentsLimitPopup(false);
    };

    const onContactSupport = () => {
        window.open(props.supportLink, "_blank");
    };

    const renderClassStudentsLimitPopup = () => {
        if (!isVisibleClassStudentsLimitPopup) {
            return null;
        }

        return (
            <PopupConfirmClassStudentsLimit
                onContactSupport={onContactSupport}
                onClose={onCloseClassStudentsLimitPopup}
            />
        );
    };

    if (isRowsLoading) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderClassStudentsLimitPopup()}
            <div className={styles.content}>
                {renderRows({
                    editable: props.editable,
                    rows,
                    onRemoveRow: (idx) => {
                        onRemoveRow(idx);
                    },
                    onInputNameChange: (value, idx) => {
                        onChangeName(value, idx);
                    },
                    onInputLastNameChange: (value, idx) => {
                        onChangeLastName(value, idx);
                    },
                    onInputEmailChange: (value, idx, withValidation) => {
                        onChangeEmail(value, idx, withValidation);
                    },
                    onGradeChange: (value, index) => {
                        onChangeGrade(value, index);
                    },
                })}
                {renderControls()}
            </div>
        </>
    );
};

TeacherAddStudentsRows.defaultProps = {
    editable: false,
    imported: false,
    preview: false,

    rowsLimit: 5,
    noRowsLimit: false,

    defaultGrade: "G7-G8",
    grades: [],

    rows: [],

    supportLink: "",

    onAddStudentsToClass: () => {},

    onRemoveStudent: () => {},

    onUploadDifferentFile: () => {},
    onProccedToConfirmation: () => {},

    onImportBackToEdit: () => {},
    onImportAddStudents: () => {},
};

export default TeacherAddStudentsRows;
