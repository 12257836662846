import React, { useRef, useEffect } from "react";


const GoogleClassromShareButton = (props) => {
    if (!window?.gapi?.sharetoclassroom
        || !props.url) {
        return null;
    }

    const buttonRef = useRef(null);

    useEffect(() => {
        if (buttonRef.current) {
            window.gapi.sharetoclassroom.render(buttonRef.current, {
                title: props.title,
                url: `//${window.location.host}${props.url}`,
            });
        }
    }, [buttonRef]);

    return (
        <div
            ref={buttonRef}
            className="g-sharetoclassroom"
            data-size="32"
            data-url={props.url}
            data-comment={props.dataComment}
        />
    );
};

GoogleClassromShareButton.defaultProps = {
    title: "",
    url: "",
    dataComment: "",
};

export default GoogleClassromShareButton;
