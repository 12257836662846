import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import RequestLoader from "juice-base/components/request-loader/index.js";
import WordCard from "juice-base/components/word-card/index.js";
import { withAuth } from "juice-base/components/auth/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import actions from "juice-base/store/actions.js";
import device from "juice-base/lib/device.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const VocabularyWord = () => {
    const { wordId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const am = useAudioManager({
        isMac: device.isMac,
    });

    const store = useSelector((state) => ({
        session: state.user.session,
        wordsById: state.vocabulary.wordsById,
        t2sWords: state.text2speech.words,
    }));

    useEffect(() => {
        api.vocabulary.getWord({
            session: store.session,
            wordId,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));
            } else {
                history.push("/vocabulary");
            }
        });
    }, [wordId]);

    useEffect(() => {
        am.setFiles({
            words: store.t2sWords,
        });
    }, [store.t2sWords]);

    if (!store.wordsById[wordId]) {
        return (
            <RequestLoader />
        );
    }

    const trackGroupName = "words";
    const audioData = am.state[trackGroupName] || {};

    const { word, definitions } = store.wordsById[wordId];

    return (
        <div className={styles.word}>
            <WordCard
                word={word}
                definitions={definitions}
                audio={audioData}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2sVocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
            />
        </div>
    );
};

export default withAuth(["teacher", "student"])(VocabularyWord);
