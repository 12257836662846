const GRADES = [
    { range: "5-6", value: "G5-G6", label: "Level 5-6" },
    { range: "7-8", value: "G7-G8", label: "Level 7-8" },
    { range: "9-10", value: "G9-G10", label: "Level 9-10" },
    { range: "11-12", value: "G11-G12", label: "Level 11+" },
];

export const getGradesRanges = () => {
    return GRADES.map((val) => val.range);
};

export const getGrades = () => {
    return GRADES;
};

export const getMaxGrades = () => {
    return ["G11", "G12"];
};

export const getGradeRange = (grades = []) => {
    if (!Array.isArray(grades)) {
        return grades;
    }

    if (grades.length === 1) {
        const grade = grades[0];

        if (grade === "G5" || grade === "G6") {
            return "5-6";
        }

        if (grade === "G7" || grade === "G8") {
            return "7-8";
        }

        if (grade === "G9" || grade === "G10") {
            return "9-10";
        }

        if (grade === "G11" || grade === "G12") {
            return "11-12";
        }
    }

    return grades.join(", ");
};

export const getGradeGroup = (grades = []) => {
    if (typeof grades !== "object") {
        return grades;
    }

    if (grades.length === 1) {
        const grade = grades.join("");

        if (grade === "G5" || grade === "G6") {
            return "5-6";
        }

        if (grade === "G7" || grade === "G8") {
            return "7-8";
        }

        if (grade === "G9" || grade === "G10") {
            return "9-10";
        }

        if (grade === "G11" || grade === "G12") {
            return "11+";
        }
    }

    return grades.join(", ");
};

export const getValidGrade = (grade = "", defaultGrade = "G7-G8") => {
    if (grade.indexOf("5") !== -1 || grade.indexOf("6") !== -1) {
        return "G5-G6";
    }

    if (grade.indexOf("7") !== -1 || grade.indexOf("8") !== -1) {
        return "G7-G8";
    }

    if (grade.indexOf("9") !== -1 || grade.indexOf("10") !== -1) {
        return "G9-G10";
    }

    if (grade.indexOf("11") !== -1 || grade.indexOf("12") !== -1) {
        return "G11-G12";
    }

    return defaultGrade;
};

export default {
    getGradesRanges,
    getGrades,
    getMaxGrades,
    getGradeRange,
    getGradeGroup,
    getValidGrade,
};
