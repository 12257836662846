import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import LogoRound from "juice-base/components/logo-round/index.js";
import Footer from "juice-base/components/footer/index.js";
import PopupUserResetPassword from "juice-base/components/popup-user-reset-password/index.js";

import UserSignInForm from "juice-base/forms/user-sign-in/index.js";

import events from "juice-app/events.js";
import { setUserSession, setUser } from "juice-base/store/user/actions.js";
import storage from "juice-base/lib/storage/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const SignIn = () => {
    const { secretKey } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [keyState, setKeyState] = useState({
        isLoaded: false,
        error: "",
    });

    useEffect(() => {
        if (!secretKey) {
            return;
        }

        api.user.checkRegistrationCode({
            secret: secretKey,
        }).then((res) => {
            if (res.ok) {
                setKeyState((prev) => ({
                    ...prev,
                    isLoaded: true,
                }));
            } else {
                setKeyState((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: "Invalid confirmation key",
                }));
            }
        });
    }, []);

    const onSignIn = (values, { setSubmitting, setErrors }) => {
        api.user.signIn(values).then((res) => {
            if (!res.ok) {
                setErrors({
                    username: res.error || "Error!",
                });
                return;
            }

            events.user.signIn({
                session: res.sessionId,
            });

            if (values.isRememberMe) {
                storage.local.saveSession(res.sessionId ?? "");
                storage.session.saveSession("");
            } else {
                storage.local.saveSession("");
                storage.session.saveSession(res.sessionId ?? "");
            }

            dispatch(setUserSession(res.sessionId || ""));
            dispatch(setUser(res.user));

            history.push("/");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onConfirmRegistration = (newPassword) => {
        api.user.confirmRegistration({
            secret: secretKey,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                history.push("/");
            } else {
                setKeyState((prev) => ({
                    ...prev,
                    error: res.error || "Error!",
                }));
            }
        });
    };

    const onCloseConfirmRegistration = () => {
        history.push("/user/sign-in");
    };

    /* --- */

    const renderConfirmRegistration = () => {
        if (!secretKey) {
            return null;
        }

        return (
            <PopupUserResetPassword
                passwordMinLength={settings.password.minLength}
                isLoaded={keyState.isLoaded}
                error={keyState.error}
                onSubmit={onConfirmRegistration}
                onClose={onCloseConfirmRegistration}
            />
        );
    };
    const renderForm = () => {
        const initialValues = {
            username: "",
            password: "",
            isRememberMe: true,
        };

        return (
            <UserSignInForm
                initialValues={initialValues}
                onSubmit={onSignIn}
            />
        );
    };

    const renderLink = (params = {}) => {
        return (
            <a
                className={styles.accountLinkSignUp}
                href={params.href}
                target="_blank"
                rel="noreferrer"
            >
                {params.label}
            </a>
        );
    };

    const renderAccountLinks = () => {
        return (
            <div className={styles.accountLinks}>
                <Link
                    to="/user/sign-up"
                    className={styles.accountLinkCode}
                >
                    Have a class code? Enter it here.
                </Link>
                {renderLink({
                    href: settings.signUpLink,
                    label: "Need an account? Sign Up here.",
                })}
                {renderLink({
                    href: settings.supportLink,
                    label: "Need help? Contact us here.",
                })}
            </div>
        );
    };

    return (
        <>
            {renderConfirmRegistration()}

            <div className={styles.signIn}>
                <div className={styles.content}>
                    <div className={styles.contentHeader}>
                        <LogoRound />

                        <div className={styles.formName}>
                            Sign In
                        </div>
                    </div>

                    {renderForm()}
                    {renderAccountLinks()}

                    <div className={styles.footer}>
                        <Footer
                            copyright={settings.copyright}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignIn;
