import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFAB from "juice-base/components/button-fab/index.js";
import IconStatus from "juice-base/components/icon-status/index.js";
import DLCustom from "juice-base/components/dl-custom/index.js";

import styles from "./styles.module.css";


const TeacherStudentJuices = (props) => {
    const [isAllJuicesVisible, setIsAllJuicesVisible] = useState(false);
    const [juiceVisibility, setJuiceVisibility] = useState({});

    const onToggleJuice = (index) => {
        setJuiceVisibility((prev) => {
            return {
                ...prev,
                [index]: !prev[index],
            };
        });
    };

    const onToggleAllJuices = () => {
        setIsAllJuicesVisible((val) => !val);

        setJuiceVisibility((prev) => {
            const keys = Object.keys(prev);

            const ret = {};

            keys.forEach((key) => {
                ret[key] = false;
            });

            return ret;
        });
    };

    /* --- */

    const renderStatuses = (ss) => {
        return (ss || []).map((statusId) => <IconStatus statusId={statusId} />);
    };

    const renderLists = (index, row) => {
        const values = [
            { label: "Quiz Status", value: <IconStatus statusId={row.quizStatus} /> },
            { label: "Quiz Score", value: row.quizScore },
            { label: "Extra Juice", value: renderStatuses(row.extraJuices) },
            { label: "Video", value: renderStatuses(row.videos) },
        ];

        let isExpanded = false;

        if (isAllJuicesVisible) {
            isExpanded = true;
        } else {
            isExpanded = juiceVisibility[index];
        }

        return (
            <div
                key={index}
                className={styles.dlist}
            >
                <DLCustom
                    title={row.date}
                    values={values}
                    isExpanded={isExpanded}
                    onToggle={() => {
                        onToggleJuice(index);
                    }}
                />
            </div>
        );
    };

    /* --- */

    const renderTableRows = () => {
        if (props.error) {
            return (
                <div className={styles.tableError}>
                    {props.error}
                </div>
            );
        }

        if (props.data.length === 0 && props.isLoading) {
            return (
                <div className={styles.tableError}>
                    <RequestLoader />
                </div>
            );
        }

        if (props.data.length === 0) {
            return (
                <div className={styles.tableError}>
                    Student has no daily juices.
                </div>
            );
        }

        return props.data.map((rowData) => {
            return (
                <tr className={styles.tableTr}>
                    <td className={styles.tableTd}>
                        {rowData.date}
                    </td>
                    <td className={styles.tableTd}>
                        <IconStatus statusId={rowData.quizStatus} />
                    </td>
                    <td className={styles.tableTd}>
                        {rowData.quizScore}
                    </td>
                    <td className={styles.tableTd}>
                        {renderStatuses(rowData.extraJuices)}
                    </td>
                    <td className={styles.tableTd}>
                        {renderStatuses(rowData.videos)}
                    </td>
                </tr>
            );
        });
    };

    const renderTable = () => {
        return (
            <table className={styles.table}>
                <thead className={styles.thead}>
                    <tr className={styles.theadTr}>
                        <td>Date</td>
                        <td>Quiz Status</td>
                        <td>Quiz Score</td>
                        <td>Extra Juice</td>
                        <td>Video</td>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </table>
        );
    };

    const renderLoadMoreButton = () => {
        if (!props.hasMore) {
            return null;
        }

        if (props.isLoading) {
            return (
                <div className={styles.juicesLoader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <div
                tabIndex="-1"
                role="button"
                onClick={props.onLoadMoreJuices}
                onKeyPress={props.onLoadMoreJuices}
                className={styles.loadMoreButton}
            >
                Load More Juices
            </div>
        );
    };

    let content = null;

    if (props.isMobile) {
        const lists = props.data.map((row, i) => renderLists(i, row));

        const icon = {
            src: staticFiles.iconMenuUnfurl,
            alt: "Unfurl all",
        };

        if (isAllJuicesVisible) {
            icon.src = staticFiles.iconMenuFurl;
            icon.alt = "Furl all";
        }

        content = (
            <>
                {lists}
                <ButtonFAB
                    icon={icon}
                    onClick={onToggleAllJuices}
                />
            </>
        );
    } else {
        content = renderTable();
    }

    return (
        <div
            className={styles.teacherStudentJuices}
            data-comment="teacher-student-juices"
        >
            {content}
            {renderLoadMoreButton()}
        </div>
    );
};

TeacherStudentJuices.defaultProps = {
    data: [],
    hasMore: false,
    isLoading: false,
    isMobile: false,
    error: "",

    onLoadMoreJuices: () => {},
};

export default TeacherStudentJuices;
