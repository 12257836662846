import * as types from "./types.js";


export const setClasses = (payload) => ({
    type: types.SET_TEACHER_CLASSES,
    payload,
});

export const setClassStudentsById = (payload) => ({
    type: types.SET_TEACHER_CLASS_STUDENTS,
    payload,
});

export const setLoadingClassStudentsById = (payload) => ({
    type: types.SET_LOADING_TEACHER_CLASS_STUDENTS,
    payload,
});

export const setClassCode = (payload) => ({
    type: types.SET_TEACHER_CLASS_CODE,
    payload,
});

export const setTeacherSelectedClass = (payload) => ({
    type: types.SET_TEACHER_SELECTED_CLASS,
    payload,
});

export const setTeacherStatsDate = (payload) => ({
    type: types.SET_TEACHER_STATS_DATE,
    payload,
});

/* --- */

export const setTeacherStudentWithForgottenPasswords = ({ students }) => ({
    type: types.SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS,
    payload: {
        students,
    },
});

export const deleteTeacherStudentWithForgottenPassword = ({ studentId }) => ({
    type: types.DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD,
    payload: {
        studentId,
    },
});

export const setTeacherStudentsWithPendingStatus = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    payload,
});

export const setTeacherStudentsWithPendingStatusLoading = () => ({
    type: types.SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING,
});

export const clearTeacherStudentsWithPendingStatus = () => ({
    type: types.CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS,
});

export const deleteTeacherStudentWithPendingStatusById = (payload) => ({
    type: types.DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID,
    payload,
});
