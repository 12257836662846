import React from "react";
import { Formik } from "formik";

import { hasEmptyValue } from "juice-base/lib/object.js";
import * as v from "juice-base/lib/form-validators.js";

import FormRow from "juice-base/components/forms/form-row/index.js";

import Input from "juice-base/components/forms/input-custom/index.js";
import Password from "juice-base/components/forms/password-custom/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const UserSignUpTrialCreateAccount = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return hasEmptyValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const passwordMinLen = 8;

        const errorsFirstName = v.validate(values.firstname, [
            v.required("First name is required."),
        ]);

        const errorsLastName = v.validate(values.lastname, [
            v.required("Last name is required."),
        ]);

        const errorsEmail = v.validate(values.email, [
            v.required("Please enter email address."),
            v.email("Please enter a valid email address."),
        ]);

        const errorsPassword = v.validate(values.password, [
            v.required("Please enter password."),
            v.min(`Password must be at least ${passwordMinLen} characters long.`, passwordMinLen),
            v.hasUpperCase("Please use at least one upper case letter"),
            v.hasLowerCase("Please use at least one lower case letter"),
            v.hasSymbol("Please use at least one symbol letter"),
        ]);

        const errors = {};

        if (errorsFirstName) {
            errors.firstname = errorsFirstName;
        }

        if (errorsLastName) {
            errors.lastname = errorsLastName;
        }

        if (errorsEmail) {
            errors.email = errorsEmail;
        }

        if (errorsPassword) {
            errors.password = errorsPassword;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <FormRow>
                    <Input
                        name="firstname"
                        label="First Name *"
                        placeholder="First Name"
                        autoComplete="firstname"
                        value={values.firstname}
                        error={errors.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Input
                        name="lastname"
                        label="Last Name *"
                        placeholder="Last Name"
                        autoComplete="lastname"
                        value={values.lastname}
                        error={errors.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormRow>

                <Input
                    name="email"
                    label="Email Address *"
                    placeholder="Email Address"
                    autoComplete="email"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Password
                    name="password"
                    label="Password *"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className={styles.formLinksSection}>
                    <Checkbox
                        name="isRememberMe"
                        label="Remember me"
                        checked={values.isRememberMe}
                        onChange={handleChange}
                    />
                </div>

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Create
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpTrialCreateAccount.defaultProps = {
    initialValues: {},
    onSubmit: () => {},
};

export default UserSignUpTrialCreateAccount;
