import React, { useState } from "react";

import "react-dates/initialize.js";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

import styles from "./styles.module.css";


const DateRangeCustom = (props) => {
    const [range, setRange] = useState(() => ({
        startDate: props.startDate,
        endDate: props.endDate,
    }));

    const [focusedInput, setFocusedInput] = useState(null);

    const onFocusChange = (val) => {
        setFocusedInput(val);
    };

    const onRangeChange = ({ startDate, endDate }) => {
        setRange((prev) => ({
            ...prev,
            startDate,
            endDate,
        }));

        props.onChange({
            startDate,
            endDate,
        });
    };

    return (
        <DateRangePicker
            startDate={range.startDate}
            startDateId={`${props.name}-start-date-id`}
            endDate={range.endDate}
            endDateId={`${props.name}-end-date-id`}
            onDatesChange={onRangeChange}
            minDate={props.minDate}
            maxDate={props.maxDate}
            isOutsideRange={() => false}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            minimumNights={0}
            anchorDirection="right"
            enableOutsideDays
            disabled={props.isDisabled}
            renderDayContents={(date) => {
                const day = new Date(date).getDate();

                return (
                    <div className={styles.customDay}>
                        {day}
                    </div>
                );
            }}
        />
    );
};

DateRangeCustom.defaultProps = {
    name: "",
    startDate: null,
    endDate: null,
    minDate: null,
    maxDate: null,
    onChange: () => {},
    isDisabled: false,
};

export default DateRangeCustom;
