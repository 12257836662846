import React from "react";

import PopupCommon from "juice-base/components/popup-common/index.js";

import staticFiles from "juice-base/static-files.js";
import DailyJuice from "juice-base/project/daily-juice.js";

import styles from "./styles.module.css";


const PopupDailyJuiceStatesLegend = (props) => {
    const renderLegend = () => {
        const icons = [];

        Object.keys(DailyJuice.StoryStatesLegend).forEach((key) => {
            const iconTitle = DailyJuice.StoryStatesLegend[key];

            icons.push(
                <div>
                    <img
                        className={styles.icon}
                        src={staticFiles[key]}
                        alt={iconTitle}
                        title={iconTitle}
                    />
                    {iconTitle}
                </div>,
            );
        });

        return (
            <div className={styles.legend}>
                {icons}
            </div>
        );
    };

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
            closeOnLayoutClick
        >
            <div className={styles.popup}>
                {renderLegend()}
            </div>
        </PopupCommon>
    );
};

PopupDailyJuiceStatesLegend.defaultProps = {
    onClose: () => {},
};

export default PopupDailyJuiceStatesLegend;
