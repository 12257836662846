// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7mZYXdmpd8XX1Wh2qV0 {}\n\n.Jqd0U0tcMJK6BzlBSe_P {\n    box-shadow: 0rem 0.4rem 0.6rem 0.2rem #c4c4c46e;\n}\n\n.IFRzhzFzGTIHd7FRHYXK {\n    min-width: 29rem;\n\n    font-family: \"Soleil-Semibold\";\n    letter-spacing: 0.1rem;\n\n    color: var(--grey);\n\n    padding: 2rem 2rem;\n\n    border-bottom: solid 0.2rem transparent;\n\n    text-align: center;\n    text-transform: uppercase;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n\n    cursor: pointer;\n    outline: none;\n}\n\n._gCbTUwcikcTWTtJihjX {\n    color: rgb(var(--cerulean));\n    border-bottom: 0.2rem solid rgb(var(--cerulean));\n}\n\n/* ------ */\n\n.p1RBmJplSqOrAXJPpNQT {\n    width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./base/components/tabs-scrolling/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,+CAA+C;AACnD;;AAEA;IACI,gBAAgB;;IAEhB,8BAA8B;IAC9B,sBAAsB;;IAEtB,kBAAkB;;IAElB,kBAAkB;;IAElB,uCAAuC;;IAEvC,kBAAkB;IAClB,yBAAyB;;IAEzB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;;IAEhB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B,gDAAgD;AACpD;;AAEA,WAAW;;AAEX;IACI,WAAW;AACf","sourcesContent":[".tabs {}\n\n.tabsWithShadow {\n    box-shadow: 0rem 0.4rem 0.6rem 0.2rem #c4c4c46e;\n}\n\n.option {\n    min-width: 29rem;\n\n    font-family: \"Soleil-Semibold\";\n    letter-spacing: 0.1rem;\n\n    color: var(--grey);\n\n    padding: 2rem 2rem;\n\n    border-bottom: solid 0.2rem transparent;\n\n    text-align: center;\n    text-transform: uppercase;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.optionSelected {\n    color: rgb(var(--cerulean));\n    border-bottom: 0.2rem solid rgb(var(--cerulean));\n}\n\n/* ------ */\n\n.menuItem {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "_7mZYXdmpd8XX1Wh2qV0",
	"tabsWithShadow": "Jqd0U0tcMJK6BzlBSe_P",
	"option": "IFRzhzFzGTIHd7FRHYXK",
	"optionSelected": "_gCbTUwcikcTWTtJihjX",
	"menuItem": "p1RBmJplSqOrAXJPpNQT"
};
export default ___CSS_LOADER_EXPORT___;
