import * as app from "./app/actions.js";
import * as device from "./device/actions.js";
import * as navigation from "./navigation/actions.js";

import * as info from "./info/actions.js";
import * as user from "./user/actions.js";

import * as t2s from "./text2speech/actions.js";
import * as tutorials from "./tutorials/actions.js";
import * as templates from "./templates/actions.js";
import * as vocabulary from "./vocabulary/actions.js";

import * as juices from "./juices/actions.js";
import * as juiceStories from "./juice-stories/actions.js";
import * as juicesVideos from "./juices-videos/actions.js";
import * as extraJuices from "./extra-juices/actions.js";

import * as teacher from "./teacher/actions.js";
import * as teacherStudents from "./teacher-students/actions.js";
import * as guardian from "./guardian/actions.js";
import * as student from "./student/actions.js";
import * as studentTodo from "./student-todo/actions.js";

import * as geo from "./geo/actions.js";


export default {
    app,
    device,
    navigation,

    info,
    user,

    t2s,
    tutorials,
    templates,
    vocabulary,

    juices,
    juiceStories,
    juicesVideos,
    extraJuices,

    teacher,
    teacherStudents,
    guardian,
    student,
    studentTodo,

    geo,
};
