import React, { useEffect, useRef, useState } from "react";

import staticFiles from "juice-base/static-files.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import SwiperPopupPages from "juice-base/components/swiper-popup-pages/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Video from "juice-base/components/video/index.js";
import JuiceContent from "juice-base/components/juice-content/index.js";
import Icon from "juice-base/components/icon/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupDailyJuiceExplore = (props) => {
    const [swipperPageIndex, setSwipperPageIndex] = useState(0);

    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    const popupRef = useRef(null);

    const [defaultPopupWidth, setDefaultPopupWidth] = useState(null);

    useEffect(() => {
        if (popupRef?.current?.clientWidth) {
            setDefaultPopupWidth(popupRef.current.clientWidth);
        }
    }, [popupRef]);

    const renderPopupTitle = () => {
        if (props.selectedExtraJuice?.id) {
            return (
                <div className={styles.headerExtraJuice}>
                    <img
                        src={staticFiles.iconOrange}
                        alt="Orange"
                        title="Orange"
                        className={styles.headerExtraJuiceImg}
                    />
                    <div className={styles.headerExtraJuiceText}>
                        {props.selectedExtraJuice.title}
                    </div>
                </div>
            );
        }

        if (props.selectedVideo?.id) {
            return props.selectedVideo.title;
        }

        return "Explore";
    };

    const renderBackArrow = () => {
        if (props.selectedExtraJuice?.id || props.selectedVideo?.id) {
            return (
                <div
                    className={styles.headerBackToList}
                    role="button"
                    tabIndex="-1"
                    onClick={props.onBackToList}
                    onKeyPress={props.onBackToList}
                >
                    <img
                        src={staticFiles.boldArrow}
                        alt="Back to story"
                    />
                </div>
            );
        }

        return (<div />);
    };

    const renderRow = (params) => {
        let image = (<div className={styles.rowFeatured} />);

        if (params.imageSrc) {
            image = (
                <img
                    className={styles.rowFeatured}
                    src={params.imageSrc}
                    alt="Featured"
                />
            );
        } else if (params.imageDefault) {
            image = (
                <img
                    className={styles.rowFeatured}
                    src={params.imageDefault}
                    alt="Featured"
                    title="Featured"
                />
            );
        }

        let icon = (<div />);

        if (params.iconSrc) {
            icon = (
                <img
                    className={styles.rowIcon}
                    src={params.iconSrc}
                    alt="icon"
                />
            );
        }

        return (
            <div
                className={styles.row}
                role="button"
                tabIndex="-1"
                onClick={params.onClick}
                onKeyPress={params.onClick}
            >
                {image}
                <div className={styles.rowTitleWithIcon}>
                    <div className={styles.rowTitle}>
                        {params.title}
                    </div>
                    {icon}
                </div>
            </div>
        );
    };

    const renderLoadMoreButton = (isLoading, showButton, onClick) => {
        if (isLoading) {
            return (
                <RequestLoader />
            );
        }

        let loadMoreButton = null;

        if (showButton) {
            loadMoreButton = (
                <div className={styles.loadMoreButtonSection}>
                    <div
                        className={styles.loadMoreButton}
                        onClick={onClick}
                        onKeyPress={onClick}
                        role="button"
                        tabIndex="-1"
                    >
                        Load More
                    </div>
                </div>
            );
        }

        return (loadMoreButton);
    };

    const renderExtraJuicePage = () => {
        const extraJuices = props.extraJuices.map((ej) => {
            return (renderRow({
                imageSrc: ej.image,
                imageDefault: staticFiles.iconOrange,
                title: ej.title,
                iconSrc: staticFiles.notebookOrange,
                onClick: () => {
                    props.onExtraJuiceClick(ej.id);
                },
            }));
        });

        return (
            <div className={styles.explorePage}>
                {extraJuices}
                {renderLoadMoreButton(
                    props.isExtraJuicesLoading,
                    props.showLoadMoreExtraJuices,
                    props.onLoadMoreExtraJuices,
                )}
            </div>
        );
    };

    const renderVideoPage = () => {
        const videos = props.videos.map((video) => {
            let featured = null;

            if (video?.featuredImage?.sizes?.thumbnail?.src) {
                featured = video.featuredImage.sizes.thumbnail.src;
            }

            return (renderRow({
                imageSrc: featured,
                title: video.newsTitle,
                iconSrc: staticFiles.iconPlayOrange,
                onClick: () => {
                    props.onVideoClick(video.videoID);
                },
            }));
        });

        return (
            <div className={styles.explorePage}>
                {videos}
                {renderLoadMoreButton(
                    props.isVideosLoading,
                    props.showLoadMoreVideos,
                    props.onLoadMoreVideos,
                )}
            </div>
        );
    };

    const renderPages = () => {
        const pages = [
            renderExtraJuicePage(),
            renderVideoPage(),
        ];

        return (pages);
    };

    const renderToggler = () => {
        const buttons = ["Extra Juice", "Video"];

        const togglers = buttons.map((btn, idx) => {
            const buttonClassName = classNames({
                [styles.pageToggleButton]: true,
                [styles.pageToggleButtonSelected]: idx === swipperPageIndex,
            });

            return (
                <div
                    className={buttonClassName}
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                        setSwipperPageIndex(idx);
                    }}
                    onKeyPress={() => {
                        setSwipperPageIndex(idx);
                    }}
                >
                    {btn}
                </div>
            );
        });

        return (
            <div className={styles.togglers}>
                {togglers}
            </div>
        );
    };

    const renderPlayer = () => {
        if (!props.audio || !props.selectedExtraJuice) {
            return null;
        }

        let playerImgSrc = null;

        if (props.selectedExtraJuice?.image) {
            playerImgSrc = props.selectedExtraJuice.image;
        }

        return (
            <PlayerAudio
                image={playerImgSrc}
                title={props.selectedExtraJuice.title}
                audio={props.audio}
                isExpanded={isPlayerExpanded}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                whitePlayer
                roundedPlayer
                onExpand={() => {
                    setIsPlayerExpanded((prev) => !prev);
                }}
                onClose={props.onPlayerClose}
            />
        );
    };

    const renderPopupContent = () => {
        if (props.selectedExtraJuice?.id) {
            return (
                <div className={styles.popupContent}>
                    <JuiceContent
                        storyId={props.selectedExtraJuice.id}
                        content={props.selectedExtraJuice.content}
                        onWordClick={props.onWordClick}
                    />
                </div>
            );
        }

        if (props.selectedVideo?.id) {
            if (props.selectedVideo.isVideoLoading) {
                return (
                    <div className={styles.popupContent}>
                        <RequestLoader />
                    </div>
                );
            }

            return (
                <div className={styles.popupContent}>
                    <Video
                        videoId={props.selectedVideo.id}
                        captionSrc={props.selectedVideo.captionSrc}
                        src={props.selectedVideo.videoUrl}
                        poster={props.selectedVideo.poster}
                        type={props.selectedVideo.mimeType}
                        videoHeight={410}
                        withPosterCover={false}
                        isDefault={props.isDefaultVideo}
                    />
                </div>
            );
        }

        let popupWidth = null;

        if (popupRef?.current?.clientWidth) {
            popupWidth = popupRef.current.clientWidth;
        }

        return (
            <>
                {renderToggler()}
                <SwiperPopupPages
                    page={swipperPageIndex}
                    popupWidth={popupWidth || defaultPopupWidth}
                >
                    {renderPages()}
                </SwiperPopupPages>
            </>
        );
    };

    let audioPlayer = null;

    if (props.selectedExtraJuice?.id) {
        audioPlayer = (
            <Icon
                url={staticFiles.iconPlayerHeadphone}
                name="Headphone"
                className={styles.topTitleHeadphone}
                onClick={() => {
                    if (!props.audio) {
                        props.onAudioPlay();
                    }
                }}
            />
        );
    }

    return (
        <PopupWindow
            title={renderPopupTitle()}
            leftControl={[renderBackArrow()]}
            rightControl={[audioPlayer]}
            player={renderPlayer()}
            playerExpanded={isPlayerExpanded}
            onClose={props.onClose}
        >
            <div ref={popupRef}>
                {renderPopupContent()}
            </div>
        </PopupWindow>
    );
};

PopupDailyJuiceExplore.defaultProps = {
    audio: null,

    extraJuices: [],
    videos: [],
    isDefaultVideo: true,

    selectedExtraJuice: {},
    selectedVideo: {},

    showLoadMoreExtraJuices: false,
    isExtraJuicesLoading: false,

    showLoadMoreVideos: false,
    isVideosLoading: false,

    onBackToList: () => {},

    onVideoClick: () => {},
    onExtraJuiceClick: () => {},

    onLoadMoreVideos: () => {},
    onLoadMoreExtraJuices: () => {},

    onWordClick: () => {},

    onAudioPlay: () => {},
    onFirstPlay: () => {},
    onPlay: () => {},
    onPause: () => {},
    onRewind: () => {},
    onForward: () => {},
    onChangeRate: () => {},
    onPlayerClose: () => {},

    onClose: () => {},
};

export default PopupDailyJuiceExplore;
