import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import {
    newDateUTC,
    tryFormatDate,
    getShortMonth,
    formatDay,
} from "juice-base/lib/date.js";

import styles from "./styles.module.css";


const ChartBar = (props) => {
    const chartRef = useRef();

    const [groupIndex, setGroupIndex] = useState(0);

    const rectStyles = {
        className: "bar",
        fill: "#00B4F5",
        stroke: "#000",
        strokeWidth: 2.5,
        duration: 300,
    };

    const getDataset = (index = groupIndex) => {
        const dataGroup = props.dataGroups[index];

        if (dataGroup && dataGroup.data) {
            const data = [];

            if (dataGroup.data.length > 0) {
                dataGroup.data.forEach((d) => {
                    let label = "";

                    if (index === 0) {
                        label = tryFormatDate(d.date, formatDay).slice(0, 3);
                    } else if (index === 1) {
                        label = newDateUTC(d.date).getDate();
                    } else if (index === 2) {
                        label = newDateUTC(d.date).getDate();
                    } else if (index === 3) {
                        label = tryFormatDate(d.date, getShortMonth);
                    }

                    data.push({
                        value: d.value,
                        label,
                    });
                });
            }

            return data;
        }

        return [];
    };

    const initOrUpdateChart = (elem, dataset, gIndex = groupIndex) => {
        if (!elem) {
            return;
        }

        const svg = d3.select(elem);

        const width = props.width - 2 * props.chartMargin;
        const height = props.height - 2 * props.chartMargin;

        const drawVerticalLines = gIndex === 2;

        svg.selectAll("*").remove();

        const chart = svg.append("g")
            .attr("transform", `translate(${props.chartMargin}, ${props.chartMargin})`);

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(dataset.map((d) => d.label))
            .padding(0.3);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, 100]);

        const makeXLines = () => d3.axisBottom()
            .scale(xScale)
            .tickValues(xScale.domain().filter((d, idx) => {
                if (drawVerticalLines) {
                    return idx % 5 === 0;
                }

                return true;
            }));

        const makeYLines = () => d3.axisLeft()
            .scale(yScale);

        let chartBottomAxixClassName = null;

        if (gIndex === 2) {
            chartBottomAxixClassName = styles.chartSmallBottomText;
        }

        chart.append("g")
            .attr("transform", `translate(0, ${height})`)
            .attr("class", chartBottomAxixClassName)
            .call(
                d3.axisBottom(xScale)
                    .tickFormat((domain, idx) => {
                        if (drawVerticalLines) {
                            return idx % 5 === 0 ? domain : "";
                        }

                        return domain;
                    }),
            );

        chart.append("g")
            .call(d3.axisLeft(yScale)
                .ticks(props.axisLeftTicks));

        if (drawVerticalLines) {
            chart.append("g")
                .attr("class", "grid")
                .attr("transform", `translate(0, ${height})`)
                .call(makeXLines()
                    .tickSize(-height, 0, 0)
                    .tickFormat(""));
        }

        chart.append("g")
            .attr("class", "grid")
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat("")
                .ticks(props.axisLeftTicks));

        const barGroups = chart.selectAll()
            .data(dataset)
            .enter()
            .append("g");

        barGroups
            .append("rect")
            .attr("class", rectStyles.className)
            .style("fill", rectStyles.fill)
            .attr("x", (g) => xScale(g.label))
            .attr("y", (g) => yScale(g.value))
            .attr("height", (g) => height - yScale(g.value))
            .attr("width", xScale.bandwidth());
    };

    useEffect(() => {
        const dataset = getDataset();

        initOrUpdateChart(chartRef.current, dataset);
    }, [props.dataGroups]);

    const onDateRangeChange = (index) => {
        setGroupIndex(index);

        const dataset = getDataset(index);

        if (chartRef.current) {
            initOrUpdateChart(chartRef.current, dataset, index);
        }
    };

    const renderDateRangeSelector = () => {
        const dateRanges = props.dataGroups.map((group, i) => {
            const rClasses = [styles.range];

            if (i === groupIndex) {
                rClasses.push(styles.selectedRange);
            }

            return (
                <div
                    className={rClasses.join(" ")}
                    onClick={() => {
                        onDateRangeChange(i);
                    }}
                    onKeyPress={() => {
                        onDateRangeChange(i);
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    {group.name}
                </div>
            );
        });

        return (
            <div className={styles.dateRanges}>
                {dateRanges}
            </div>
        );
    };

    const renderAverageScore = () => {
        const dataGroup = props.dataGroups[groupIndex];

        let score = 0;

        if (dataGroup?.data && dataGroup.data.length !== 0) {
            const maxAvailablePoints = dataGroup.data.filter((val) => {
                if (groupIndex === 3 && val.isCompletedJuiceExist) {
                    return true;
                }

                if (val.isJuiceExist && val.isQuizExist && val.isJuiceCompleted) {
                    return true;
                }

                return false;
            }).length * 100;

            let totalPoints = 0;
            dataGroup.data.forEach((val) => {
                if ((val.isJuiceExist && val.isQuizExist && val.isJuiceCompleted)
                    || groupIndex === 3) {
                    totalPoints += val.value;
                }
            });

            score = (totalPoints * 100) / maxAvailablePoints;
        }

        if (Number.isNaN(score)) {
            score = "0.00";
        }

        score = Number(score).toFixed(2);

        return (
            <div className={styles.averageScore}>
                <div>Average score</div>
                <div>{`${score}%`}</div>
            </div>
        );
    };

    const renderDateRange = () => {
        if (!props.dataGroups[groupIndex]
            || !props.dataGroups[groupIndex].dates) {
            return null;
        }

        const {
            from,
            to,
        } = props.dataGroups[groupIndex].dates;

        let title = "";

        if (from.getFullYear() !== to.getFullYear()) {
            title = `${getShortMonth(from)} ${from.getFullYear()} - ${getShortMonth(to)} ${to.getFullYear()}`;
        } else if (from.getMonth() === to.getMonth()) {
            title = `${getShortMonth(from)} ${from.getDate()}-${to.getDate()}, ${from.getFullYear()}`;
        } else if (to.getMonth() - from.getMonth() === 1) {
            title = `${getShortMonth(from)}  ${from.getDate()} - ${getShortMonth(to)}  ${to.getDate()}, ${from.getFullYear()}`;
        } else if (to.getMonth() - from.getMonth() > 1) {
            title = `${getShortMonth(from)} - ${getShortMonth(to)}, ${from.getFullYear()}`;
        }

        return (
            <div className={styles.selectedRangeDate}>
                {title}
            </div>
        );
    };

    const dataset = getDataset();

    let chartMessage = null;

    const svgClasses = [styles.chart];

    if (dataset.length < 2) {
        chartMessage = (
            <div className={styles.chartMissingData}>
                Looks like we don’t have enough data to show you quiz results yet.
                Check back again soon!
            </div>
        );

        svgClasses.push(styles.hiddenChart);
    }

    return (
        <div className={styles.chartBar}>

            {renderDateRangeSelector()}

            <div>
                {renderAverageScore()}
                {renderDateRange()}
            </div>

            <div
                className={svgClasses.join(" ")}
                style={{
                    width: props.width,
                    height: props.height,
                }}
            >
                {chartMessage}
                <svg ref={chartRef} />
            </div>

        </div>
    );
};

ChartBar.defaultProps = {
    dataGroups: [],
    height: 250,
    width: 350,
    chartMargin: 40,
    axisLeftTicks: 5,
};

export default ChartBar;
