import React from "react";

import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const UserMainFooter = (props) => {
    return (
        <div className={styles.footer}>
            <div
                className={styles.button}
                onClick={props.onSettingsClick}
                onKeyPress={props.onSettingsClick}
                tabIndex="-1"
                role="button"
            >
                Account settings
            </div>
            <div
                className={styles.button}
                tabIndex="-1"
                role="button"
                onClick={props.onLogout}
                onKeyPress={props.onLogout}
            >
                Sign Out
            </div>

            <img
                src={staticFiles.juiceLogoV1}
                className={styles.footerLogo}
                alt="Juice"
                title="Juice"
            />

            <div className={styles.footerPoweredBy}>
                Powered by
                <strong>News and News</strong>
            </div>
            <div className={[styles.footerCopyright, styles.copyrightSection].join(" ")}>
                <div>Copyright &copy;</div>
                <div>{`${props.copyright}.`}</div>
                <div>All rights reserved.</div>
            </div>
            <a
                className={styles.footerCopyright}
                href={props.privacyPolicyLink}
                target="_blank"
                rel="noreferrer"
                data-comment="privacy-policy"
            >
                Privacy Policy.
            </a>
            <a
                className={styles.footerCopyright}
                href={props.supportLink}
                target="_blank"
                rel="noreferrer"
                data-comment="support"
            >
                Support
            </a>
        </div>
    );
};

UserMainFooter.defaultProps = {
    title: "",
    children: null,
    privacyPolicyLink: "/page/privacy-policy",
    copyright: "The Juice",
    supportLink: "/page/support",
    onSettingsClick: () => {},
    onLogout: () => {},
};

export default UserMainFooter;
