import {
    SET_SPONSORS,
} from "./types.js";


export const setSponsors = (sponsors) => ({
    type: SET_SPONSORS,
    payload: {
        sponsors,
    },
});

export default {};
