import React from "react";

import LoaderSmall from "../loader-small/index.js";

import styles from "./styles.module.css";


const ButtonDefault = (props) => {
    const classes = [styles.button, props.buttonClasses];

    if (props.disabled) {
        classes.push(styles.buttonDisabled);
    }

    if (props.isPrimary) {
        classes.push(styles.buttonPrimary);
    } else if (props.isDelete) {
        classes.push(styles.buttonDelete);
    }

    return (
        <button
            className={classes.join(" ")}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            type="button"
            tabIndex="-1"
            disabled={props.disabled}
            data-comment={props.dataComment}
        >
            {props.children}
            {props.withLoader ? <LoaderSmall /> : null}
        </button>
    );
};

ButtonDefault.defaultProps = {
    buttonClasses: "", // TODO: DELETE
    dataComment: "button-default",
    isPrimary: false,
    isDelete: false,
    disabled: false,
    withLoader: false,
    onClick: () => {},
    children: null,
};

export default ButtonDefault;
