import { useRef, useState } from "react";


const useSnackbar = () => {
    const [state, setState] = useState([]);

    // NOTE: hack to open more than one snackbar without issues onClose
    const newState = useRef(state);
    newState.current = state;

    const initSnackbar = {
        isVisibleSnackbar: false,
        autoCloseInSeconds: 5,
        message: null,
        id: null,
        callback: () => {},
    };

    const add = (params = {}) => {
        const newSnackbars = [...state];

        newSnackbars.push({
            ...initSnackbar,
            isVisibleSnackbar: params.isVisibleSnackbar || true,
            isVisibleUndoButton: params.isVisibleUndoButton || false,
            autoCloseInSeconds: params.autoCloseInSeconds || 5,
            message: params.message || "",
            id: params.id || -1,
            callback: params.callback,
        });

        setState(newSnackbars);
    };

    const close = (index) => {
        const newSnackbars = [...newState.current];
        newSnackbars[index] = { ...initSnackbar };

        setState(newSnackbars);
    };

    const isVisibleSnackbarsExists = () => {
        let isExists = false;

        for (let i = 0; i < state.length; i += 1) {
            if (state[i].isVisibleSnackbar) {
                isExists = true;
                break;
            }
        }

        return isExists;
    };

    return {
        state,
        add,
        close,

        isVisibleSnackbarsExists,
    };
};

export default useSnackbar;
