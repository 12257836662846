import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupConfirmContent = (props) => {
    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentGrey]: props.isGray,
    });

    return (
        <div className={contentClassName}>
            {props.children}
        </div>
    );
};

PopupConfirmContent.defaultProps = {
    children: null,
    isGray: false,
};

export default PopupConfirmContent;
