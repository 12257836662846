// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kkVHQUuLfobPNY32hWL_ {\n    display: grid;\n}\n\n._TaXmjMCmH8fzwwstx83 {\n    margin: 0 auto;\n    width: 10rem;\n}\n\n._8EHercroJ3y__tYoqT3 {\n    margin: 2rem 0;\n\n    font-family: \"Soleil-Semibold\";\n    font-size: 2rem;\n    text-align: center;\n}\n\n.BoVzUnH0sHeoLcQyDgfW {\n    color: #757575;\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./base/components/message-with-emoji/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,cAAc;;IAEd,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".messageWithEmoji {\n    display: grid;\n}\n\n.emojiImg {\n    margin: 0 auto;\n    width: 10rem;\n}\n\n.header {\n    margin: 2rem 0;\n\n    font-family: \"Soleil-Semibold\";\n    font-size: 2rem;\n    text-align: center;\n}\n\n.message {\n    color: #757575;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageWithEmoji": "kkVHQUuLfobPNY32hWL_",
	"emojiImg": "_TaXmjMCmH8fzwwstx83",
	"header": "_8EHercroJ3y__tYoqT3",
	"message": "BoVzUnH0sHeoLcQyDgfW"
};
export default ___CSS_LOADER_EXPORT___;
