import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grades from "juice-base/project/grades.js";

import staticFiles from "juice-base/static-files.js";

import device from "juice-base/lib/device.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import WizardMultiStep from "juice-base/components/wizard-multi-step/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import actions from "juice-base/store/actions.js";

import TeacherSchoolDetailsForm from "juice-base/forms/teacher-school-details/index.js";
import TeacherClassSetUpForm from "juice-base/forms/teacher-class-set-up/index.js";
import PopupConfirmError from "juice-base/business/popup-confirm-error/index.js";
import PopupTourVideo from "juice-base/business/popup-tour-video/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const TeacherPopupWelcome = (props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isWizardDisabledPrevSteps, setIsWizardDisabledPrevSteps] = useState(false);

    const [errorsState, setErrorState] = useState({
        isOpen: false,
        error: null,
    });

    const [districtsOptions, setDistrictsOptions] = useState([]);
    const [schoolNamesOptions, setSchoolNamesOptions] = useState([]);
    const [departmentsOptions, setDepartmentsOptions] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedSchool, setSelectedSchool] = useState(null);

    const [schoolDetailsFromState, setSchoolDetailsFormState] = useState({});

    const [isVisibleTourPopup, setIsVisibleTourPopup] = useState(false);

    const [toursLinks, setToursLinks] = useState({
        isLoaded: false,

        mobile: null,
        desktop: null,
    });

    const store = useSelector((state) => ({
        session: state.user.session,
        dimensions: state.device.dimensions,
        geo: state.geo,
    }));

    const dispatch = useDispatch();

    /* ----------- */

    const loadCountries = () => {
        api.geo.getCountries({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.geo.setCountries({
                    countries: res.countries,
                }));
            }
        });
    };

    const loadSubdivisionsByAplha2 = (alpha2) => {
        dispatch(actions.geo.setSubdivisionsByAlpha2Loading({
            alpha2,
        }));

        api.geo.getSubdivisions({
            session: store.session,
            alpha2,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.geo.setSubdivisionsByAlpha2({
                    alpha2,
                    subdivisionName: res.subdivisionName,
                    subdivisions: res.subdivisions,
                }));
            }
        });
    };

    /* ----------- */

    const loadDefaultDistricts = (subdivision) => {
        api.geo.getDistricts({
            session: store.session,
            search: "",
            subdivision,
        }).then((res) => {
            if (!res.ok) {
                setDistrictsOptions([]);
                return;
            }

            const districts = (res.districts || []).map((d) => ({
                value: d.id,
                label: d.name,
            }));

            setDistrictsOptions(districts);
        });
    };

    const loadDefaultSchoolNames = (district) => {
        api.geo.getSchools({
            session: store.session,
            search: "",
            districtId: district,
        }).then((res) => {
            if (!res.ok) {
                setSchoolNamesOptions([]);
                return;
            }

            const schools = (res.schools || []).map((s) => ({
                value: s.id,
                label: s.name,
            }));

            setSchoolNamesOptions(schools);
        });
    };

    const loadDefaultDepartments = () => {
        api.geo.getDepartments({
            session: store.session,
            search: "",
            schoolId: selectedSchool,
        }).then((res) => {
            if (!res.ok) {
                setDepartmentsOptions([]);
                return;
            }

            const departments = (res.departments || []).map((d) => ({
                value: d,
                label: d,
            }));

            setDepartmentsOptions(departments);
        });
    };

    /* ----------- */

    const loadTourVideoLink = () => {
        api.tutorials.getTutorialVideoLink({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                setToursLinks({
                    isLoaded: true,
                    mobile: res.data.mobile,
                    desktop: res.data.desktop,
                });
            }
        });
    };

    useEffect(() => {
        if (store.session) {
            loadCountries();
            loadTourVideoLink();
        }
    }, [store.session]);

    /* ----------- */

    const onCountryChange = (alpha2) => {
        setSelectedCountry(alpha2);
        loadSubdivisionsByAplha2(alpha2);
    };

    const onSubdivisionsChange = (value) => {
        if (value) {
            loadDefaultDistricts(value);
        }
    };

    const onSelectDistrict = (values) => {
        if (values && !values.isNew) {
            loadDefaultSchoolNames(values.value);
        }
    };

    const onSelectSchoolName = (value) => {
        if (value) {
            setSelectedSchool(value);
        }
    };

    /* ----------- */

    const onTakeTour = () => {
        setIsVisibleTourPopup(true);
        props.onTakeTour();
    };

    /* ----------- */

    const getDistrictNameById = (id) => {
        if (typeof (id) === "string") {
            return id;
        }

        let name = "";

        for (let i = 0; i < districtsOptions.length; i += 1) {
            if (districtsOptions[i].value === id) {
                name = districtsOptions[i].label;
            }
        }

        return name || id;
    };

    const getSchoolNameById = (id) => {
        if (typeof (id) === "string") {
            return id;
        }

        let name = "";

        for (let i = 0; i < schoolNamesOptions.length; i += 1) {
            if (schoolNamesOptions[i].value === id) {
                name = schoolNamesOptions[i].label;
                break;
            }
        }

        return name || id;
    };

    /* ----------- */

    const onSchoolDetailsSubmit = (values) => {
        setCurrentStep(1);

        setSchoolDetailsFormState(values);
        loadDefaultDepartments();
    };

    const onClassSetUpSubmit = (values, { setSubmitting }) => {
        const classSetUpState = values;

        const distrcitName = getDistrictNameById(schoolDetailsFromState.schoolDistrict);
        const schoolName = getSchoolNameById(schoolDetailsFromState.schoolName);

        setIsWizardDisabledPrevSteps(true);

        api.classes.setUpTeacherClass({
            session: store.session,
            country: schoolDetailsFromState.country,
            subdivision: schoolDetailsFromState.subdivision,
            district: distrcitName,
            school: schoolName,
            department: classSetUpState.department,
            class: classSetUpState.className,
            grades: classSetUpState.grades,
        }).then((res) => {
            if (res.ok) {
                setCurrentStep(2);
            } else {
                setSubmitting(false);

                setIsWizardDisabledPrevSteps(false);

                setErrorState({
                    isOpen: true,
                    error: res.error || "Error!",
                });
            }
        });
    };

    /* ----------- */

    const onGoToStep = (newStepIndex) => {
        setCurrentStep(newStepIndex);
    };

    /* ----------- */

    const renderPopupTitle = () => {
        return (
            <div>Welcome to The Juice!</div>
        );
    };

    const renderSchoolDetails = () => {
        if (!store.geo.isCountriesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const initialValues = {
            country: "",
            subdivision: "",
            schoolDistrict: "",
            schoolName: "",
        };

        const countries = store.geo.countries.map((country) => {
            return {
                label: country.name,
                value: country.alpha2,
            };
        });

        let subdivisionName = "State";
        let subdivisions = [];

        if (selectedCountry && store.geo.subdivisionsByAlpha2?.[selectedCountry]?.isLoaded) {
            subdivisionName = store.geo.subdivisionsByAlpha2[selectedCountry].subdivisionName;

            subdivisions = store.geo.subdivisionsByAlpha2[selectedCountry].subdivisions
                .map((state) => {
                    return {
                        label: state.name,
                        value: state.code,
                    };
                });
        }

        return (
            <div className={styles.wizardPage}>
                <div className={styles.schoolDetailsLabel}>
                    Let&apos;s finish setting up your account. Where do you teach?
                </div>
                <TeacherSchoolDetailsForm
                    initialValues={initialValues}
                    districtsOptions={districtsOptions}
                    schoolNamesOptions={schoolNamesOptions}
                    countries={countries}
                    subdivisionName={subdivisionName}
                    subdivisions={subdivisions}
                    onCountryChange={onCountryChange}
                    onSubdivisionsChange={onSubdivisionsChange}
                    onSelectDistrict={onSelectDistrict}
                    onSelectSchoolName={onSelectSchoolName}
                    onSubmit={onSchoolDetailsSubmit}
                />
            </div>
        );
    };

    const renderClassSetUp = () => {
        const initialValues = {
            department: "",
            className: "",
            grades: "",
        };

        const grades = Grades.getGrades()
            .map((grade) => ({
                label: Grades.getGradeGroup([grade.value.split("-")[0]]),
                value: grade.range,
            }));

        return (
            <div className={styles.wizardPage}>
                <div className={styles.schoolDetailsLabel}>
                    Almost done. You just need to set up your first class.
                </div>
                <TeacherClassSetUpForm
                    initialValues={initialValues}
                    grades={grades}
                    departmentsOptions={departmentsOptions}
                    onSubmit={onClassSetUpSubmit}
                />
            </div>
        );
    };

    const renderConfirmation = () => {
        let takeTourButton = null;

        if (toursLinks.isLoaded) {
            takeTourButton = (
                <ButtonBig
                    uppercase
                    onClick={onTakeTour}
                >
                    Take the tour
                </ButtonBig>
            );
        }

        return (
            <div className={styles.wizardPage}>
                <div className={styles.confirmEmoji}>
                    <img
                        src={staticFiles.emojisHappy}
                        alt="Happy Emoji"
                        title="Happy Emoji"
                    />
                </div>

                <div className={styles.confirmText}>
                    You&apos;re all set! Would you like to take a brief tour of the teacher portal?
                </div>

                {takeTourButton}

                <ButtonBig
                    uppercase
                    outlined
                    onClick={props.onExploreOnMyOwn}
                >
                    Explore on my own
                </ButtonBig>
            </div>
        );
    };

    const renderContent = () => {
        const wizardSteps = [
            { name: "School Details", content: renderSchoolDetails() },
            { name: "Class Set Up", content: renderClassSetUp() },
            { name: "Confirmation", content: renderConfirmation() },
        ];

        return (
            <WizardMultiStep
                disablePrevSteps={isWizardDisabledPrevSteps}
                step={currentStep}
                steps={wizardSteps}
                onStepChange={onGoToStep}
            />
        );
    };

    const renderErrorPopup = () => {
        if (!errorsState.isOpen) {
            return null;
        }

        return (
            <PopupConfirmError
                error={errorsState.error}
                onClose={() => {
                    setErrorState({
                        isOpen: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderTourPopup = () => {
        if (!isVisibleTourPopup) {
            return null;
        }

        const isMobile = store.dimensions.width < 600;

        let video = toursLinks.desktop;
        let videoCaptionSrc = null;

        if (isMobile) {
            video = toursLinks.mobile;
        }

        if (video?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: video?.id,
                session: store.session,
            });
        }

        return (
            <PopupTourVideo
                isDefaultVideo={!device.isChrome}
                isMobile={isMobile}
                video={video}
                videoCaptionSrc={videoCaptionSrc}
                onClose={() => {
                    setIsVisibleTourPopup(false);
                }}
            />
        );
    };

    return (
        <>
            <PopupWindow
                orangeBar
                hideClose
                title={renderPopupTitle()}
            >
                <div className={styles.content}>
                    {renderContent()}
                </div>
            </PopupWindow>

            {renderTourPopup()}
            {renderErrorPopup()}
        </>
    );
};

TeacherPopupWelcome.defaultProps = {
    onTakeTour: () => {},
    onExploreOnMyOwn: () => {},
};

export default TeacherPopupWelcome;
