// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iCA13aeqHDxitOOG2Jor {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n._zCAbaY1sVKGGh99JhJm {\n    width: 100%;\n    max-height: 5.5rem;\n}\n\n.ncOO9oTXRI7VImw8zBkd {\n    display: block;\n    cursor: pointer;\n    outline: none;\n}\n\n._jIaGSfQ3wRt90IxMP2m {}\n", "",{"version":3,"sources":["webpack://./base/components/section-sponsor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,aAAa;AACjB;;AAEA,uBAAiB","sourcesContent":[".sponsor {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n.sponsorImg {\n    width: 100%;\n    max-height: 5.5rem;\n}\n\n.sponsorLink {\n    display: block;\n    cursor: pointer;\n    outline: none;\n}\n\n.sponsorContent {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsor": "iCA13aeqHDxitOOG2Jor",
	"sponsorImg": "_zCAbaY1sVKGGh99JhJm",
	"sponsorLink": "ncOO9oTXRI7VImw8zBkd",
	"sponsorContent": "_jIaGSfQ3wRt90IxMP2m"
};
export default ___CSS_LOADER_EXPORT___;
