import {
    SET_SPONSORS,
} from "./types.js";


const initialState = {
    isSponsorsLoaded: false,
    sponsors: [],
};

export default (state, action) => {
    switch (action.type) {
        case SET_SPONSORS: {
            return {
                ...state,
                isSponsorsLoaded: true,
                sponsors: action.payload.sponsors || {},
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
