import React from "react";

import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const IconStatus = (props) => {
    let title = "";
    const iconClasses = [];

    if (props.statusId === -1) {
        title = "-";
    }

    if (props.statusId === 0) {
        iconClasses.push(styles.iconUnopened);
        title = "Unopened";
    }

    if (props.statusId === 1) {
        iconClasses.push(styles.iconInProgress);
        title = "In Progress";
    }

    if (props.statusId === 2) {
        iconClasses.push(styles.iconComplete);
        title = "Complete";
    }

    if (props.statusId === 3) {
        iconClasses.push(styles.iconOpened);
        title = "Opened";
    }

    if (props.statusId === 4) {
        return (
            <div className={styles.iconCheck}>
                <img
                    src={staticFiles.circleCheckGreen}
                    alt="Completed"
                    title="Completed"
                />
            </div>
        );
    }

    if (props.statusId === 5) {
        return (
            <div className={styles.iconUncheck}>
                <img
                    src={staticFiles.circlePending}
                    alt="Pending"
                    title="Pending"
                />
            </div>
        );
    }

    if (props.statusId === 6) {
        iconClasses.push(styles.iconUnopened);
        title = "Missing Assignments";
    }

    if (props.statusId === 7) {
        iconClasses.push(styles.iconComplete);
        title = "All complete";
    }

    return (
        <div className={iconClasses.join(" ")}>
            {title}
        </div>
    );
};

IconStatus.defaultProps = {
    statusId: -1,
};

export default IconStatus;
