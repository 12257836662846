import {
    SET_LOCATION,
} from "./types.js";


export const setLocation = (location) => ({
    type: SET_LOCATION,
    payload: {
        location,
    },
});

export default setLocation;
