import {
    SET_VOCABULARY_WORDS,
    SET_VOCABULARY_WORD,
} from "./types.js";


const initialState = {
    isWordsLoaded: false,
    words: [],

    wordsById: {},
    wordsByName: {},
};

export default (state, action) => {
    switch (action.type) {
        case SET_VOCABULARY_WORDS: {
            return {
                ...state,
                isWordsLoaded: true,
                words: action.payload.words,
            };
        }

        case SET_VOCABULARY_WORD: {
            const { word, definitions } = action.payload;

            const { wordsById, wordsByName } = state;

            const newWord = {
                word,
                definitions,
            };

            return {
                ...state,
                wordsById: {
                    ...wordsById,
                    [word.id]: newWord,
                },
                wordsByName: {
                    ...wordsByName,
                    [word.word.toLowerCase()]: newWord,
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
