// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ap17mx_yUq0QINR9_YU {}\n\n.oLu9NU1gF3Cgu983KHI9 {\n    margin-top: 2.5rem;\n\n    display: grid;\n}\n\n.VGjaPfiB5fPXTGa4vIHe {\n    margin-top: 18.3rem; /* TODO: height + align */\n\n    display: inline-grid; /* TODO: why? */\n}\n\n@media only screen and (min-width: 500px) { /* TODO: */\n    .pdCGmobtMrBMpuo59fIt {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-sign-up-trial-roles/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,kBAAkB;;IAElB,aAAa;AACjB;;AAEA;IACI,mBAAmB,EAAE,yBAAyB;;IAE9C,oBAAoB,EAAE,eAAe;AACzC;;AAEA,4CAA4C,UAAU;IAClD;QACI,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;IACvB;AACJ","sourcesContent":[".form {}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n\n    display: grid;\n}\n\n.submitButtonBig {\n    margin-top: 18.3rem; /* TODO: height + align */\n\n    display: inline-grid; /* TODO: why? */\n}\n\n@media only screen and (min-width: 500px) { /* TODO: */\n    .formLinksSection {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_ap17mx_yUq0QINR9_YU",
	"submitButtonBlock": "oLu9NU1gF3Cgu983KHI9",
	"submitButtonBig": "VGjaPfiB5fPXTGa4vIHe",
	"formLinksSection": "pdCGmobtMrBMpuo59fIt"
};
export default ___CSS_LOADER_EXPORT___;
