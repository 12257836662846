import React, { useRef, useEffect, useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import useElemDimensions from "juice-base/hooks/use-elem-dimensions/index.js";
import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";


const WithScroll = (props) => {
    const ref = useRef(null);
    const [overflow, setOverflow] = useState("auto");

    const dimensions = useElemDimensions(ref.current);

    useEffect(() => {
        if (ref.current) {
            const { clientHeight, scrollHeight } = ref.current;
            const isScrollable = clientHeight < scrollHeight;
            const newValue = isScrollable ? "scroll" : "auto";

            if (overflow !== newValue) {
                setOverflow(newValue);
            }
        }
    }, [
        ref.current,
        dimensions.height,
        dimensions.width,
    ]);

    const style = {
        overflowY: overflow,
        ...props.style,
    };

    const divClassName = classNames({
        [juiceScrollbarStyles.scrollbar]: true,
        [props.className]: props.className,
    });

    return (
        <div
            ref={ref}
            style={style}
            className={divClassName}
        >
            {props.children}
        </div>
    );
};

WithScroll.defaultProps = {
    className: "",
    style: {},
    children: null,
};

export default WithScroll;
