import React from "react";

import styles from "./styles.module.css";


// NOTE: FAB - floating action button
const ButtonFAB = (props) => {
    if (!props.icon) {
        return null;
    }

    return (
        <div
            className={styles.fab}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        >
            <img
                className={styles.fabIconImg}
                src={props.icon.src}
                alt={props.icon.alt}
            />
        </div>
    );
};

ButtonFAB.defaultProps = {
    icon: null,
    onClick: () => {},
};

export default ButtonFAB;
