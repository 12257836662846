export default (apiURL, methods) => {
    return {
        getWords(params = {}) {
            const url = `${apiURL}/vocabulary/words`;

            return methods.get(url, {
                page: params.page,
            });
        },

        searchWords(params = {}) {
            const url = `${apiURL}/vocabulary/words`;

            return methods.get(url, {
                session_id: params.session,
                starts_with: params.value,
            });
        },

        getWord(params = {}) {
            const url = `${apiURL}/vocabulary/words/${params.wordId}`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        getWordByText(params = {}) {
            const url = `${apiURL}/vocabulary/words/by-text`;

            return methods.get(url, {
                session_id: params.session,
                word: params.word,
            });
        },
    };
};
