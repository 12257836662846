import React from "react";

import Skeleton from "juice-base/components/skeleton/index.js";

import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
} from "juice-base/components/section-teacher/index.js";

import styles from "./styles.module.css";


const TeacherQuickLinks = (props) => {
    const renderLinks = () => {
        return props.links.map((link) => (
            <div
                className={styles.quickLink}
                onClick={link.callback}
                onKeyPress={link.callback}
                role="button"
                tabIndex="-1"
                data-comment={link.dataComment || ""}
            >
                {link.name}
            </div>
        ));
    };

    let quickLink = "Quick Link";

    let explanationMark = (
        <SectionTeacherExplanation
            pageWidth={props.pageWidth}
            alignLeft
        >
            Use quick links to customize your class&apos;s edition of The Juice with a unique image.
        </SectionTeacherExplanation>
    );

    let links = renderLinks();

    if (props.isSkeleton) {
        quickLink = <Skeleton variant="text" />;
        explanationMark = <Skeleton variant="circle" height={2} width={2} />;

        links = <Skeleton variant="rect" height={4} fullWidth />;
    }

    return (
        <SectionTeacher>
            <SectionTeacherHeader
                label={quickLink}
                rightControls={[explanationMark]}
            />
            <div className={styles.quickLinks}>
                {links}
            </div>
        </SectionTeacher>
    );
};

TeacherQuickLinks.defaultProps = {
    links: [],
    isSkeleton: true,
};

export default TeacherQuickLinks;
