import React from "react";

import staticFiles from "juice-base/static-files.js";
import Icon from "juice-base/components/icon/index.js";
import MenuContentRow from "juice-base/components/menu-content-row/index.js";


const StudentAccountInfo = (props) => {
    if (!props.account) {
        return null;
    }

    const renderAccount = () => {
        const icon = (
            <Icon
                url={staticFiles.iconTrashV1}
                name="Delete Account"
                onClick={props.onDeleteAccount}
            />
        );

        return (
            <MenuContentRow
                label="Account"
                icon={icon}
                labelOnly
            />
        );
    };

    const renderName = () => {
        const icon = (
            <Icon
                url={staticFiles.iconPencilFilled}
                name="Edit name"
                onClick={props.onEditName}
            />
        );

        return (
            <MenuContentRow
                label="Name"
                value={props.account?.fullName || ""}
                icon={icon}
            />
        );
    };

    const renderEmail = () => {
        const icon = (
            <Icon
                url={staticFiles.iconPencilFilled}
                name="Edit email"
                onClick={props.onEditEmail}
            />
        );

        return (
            <MenuContentRow
                label="Email"
                value={props.account?.email || ""}
                icon={icon}
            />
        );
    };

    const renderUsername = () => {
        return (
            <MenuContentRow
                label="Username"
                value={props.account?.userName || ""}
            />
        );
    };

    const renderPassword = () => {
        const icon = (
            <Icon
                url={staticFiles.iconReset}
                name="Edit password"
                onClick={props.onEditPassword}
            />
        );

        return (
            <MenuContentRow
                label="Password"
                value="***********"
                icon={icon}
            />
        );
    };

    return (
        <div>
            {renderAccount()}
            {renderName()}
            {renderEmail()}
            {renderUsername()}
            {renderPassword()}
        </div>
    );
};

StudentAccountInfo.defaultProps = {
    account: null,
    onDeleteAccount: () => {},
    onEditName: () => {},
    onEditEmail: () => {},
    onEditPassword: () => {},
};

export default StudentAccountInfo;
