import React from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const DLCustom = (props) => {
    const renderDL = () => {
        const dlClassName = classNames({
            [styles.dl]: true,
            [styles.dlVisible]: props.isExpanded,
        });

        const values = props.values.map((val) => (
            <>
                <dt className={styles.dt}>{val.label}</dt>
                <dd className={styles.dd}>{val.value}</dd>
            </>
        ));

        return (
            <dl className={dlClassName}>
                {values}
            </dl>
        );
    };

    const togglerImgClassName = classNames({
        [styles.headerTogglerImg]: true,
        [styles.headerTogglerImgUp]: props.isExpanded,
    });

    return (
        <div className={styles.dlCustom}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    {props.title}
                </div>
                <div
                    className={styles.headerToggler}
                    onClick={props.onToggle}
                    onKeyPress={props.onToggle}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        className={togglerImgClassName}
                        src={staticFiles.arrowRight}
                        alt="Toggle List"
                    />
                </div>
            </div>
            {renderDL()}
        </div>
    );
};

DLCustom.defaultProps = {
    title: "",
    values: [
        { label: "Quiz Status", value: "complete" },
        { label: "Quiz Status", value: "complete" },
        { label: "Quiz Status", value: "complete" },
    ],
    isExpanded: false,
    onToggle: () => {},
};

export default DLCustom;
