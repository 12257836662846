// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".So5rCuila9sSBtPgamM9 {\n    background: white;\n\n    border-radius: 1rem;\n\n    min-width: 25rem;\n    max-width: 41rem;\n\n    overflow: hidden;\n}\n\n\n.h_BS3Qj_pXVGIupxqfIO {\n\n}\n\n.Nzns4G637h_q9FU_D5D5 {\n    display: grid;\n    grid-gap: 1rem;\n\n    padding: 2rem;\n\n    max-height: 50vh;\n    min-height: 10rem;\n    overflow: auto;\n}\n\n._VQdzpUsSWs1UCpDKM8y {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.QJEjGcW04EDWTM1veKfT {\n    text-align: center;\n\n    margin: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-add-students-progress/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;IAEjB,mBAAmB;;IAEnB,gBAAgB;IAChB,gBAAgB;;IAEhB,gBAAgB;AACpB;;;AAGA;;AAEA;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,aAAa;;IAEb,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;;IAElB,YAAY;AAChB","sourcesContent":[".popup {\n    background: white;\n\n    border-radius: 1rem;\n\n    min-width: 25rem;\n    max-width: 41rem;\n\n    overflow: hidden;\n}\n\n\n.content {\n\n}\n\n.studentsList {\n    display: grid;\n    grid-gap: 1rem;\n\n    padding: 2rem;\n\n    max-height: 50vh;\n    min-height: 10rem;\n    overflow: auto;\n}\n\n.studentRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.progress {\n    text-align: center;\n\n    margin: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "So5rCuila9sSBtPgamM9",
	"content": "h_BS3Qj_pXVGIupxqfIO",
	"studentsList": "Nzns4G637h_q9FU_D5D5",
	"studentRow": "_VQdzpUsSWs1UCpDKM8y",
	"progress": "QJEjGcW04EDWTM1veKfT"
};
export default ___CSS_LOADER_EXPORT___;
