// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D_SoXWo4Qd7dFs9uIFva {\n    height: 1.2rem;\n\n    margin: 0.2rem 0;\n\n    border-radius: 0.4rem;\n\n    opacity: 0.3;\n\n    background: var(--grey);\n}\n\n.vLvOHISSZpcmv4ESH23O {\n    animation: IpGLNVlArXYt7_ohRyWo 2s infinite;\n}\n\n.yy_2DqjdByluYJX2TWwK {\n    height: 1rem;\n    width: 12rem;\n}\n\n.be0fOreb53FmPMjVdW_Z {\n    border-radius: 50%;\n    height: 1rem;\n    width: 1rem;\n}\n\n.Pz_fPsZaC2ccAZI3fe2K {\n    border-radius: 0;\n    height: 2rem;\n    width: 10rem;\n}\n\n@keyframes IpGLNVlArXYt7_ohRyWo {\n    0% {\n        opacity: 0.3;\n    }\n    50% {\n        opacity: 0.1;\n    }\n    100% {\n        opacity: 0.3;\n    }\n}", "",{"version":3,"sources":["webpack://./base/components/skeleton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,gBAAgB;;IAEhB,qBAAqB;;IAErB,YAAY;;IAEZ,uBAAuB;AAC3B;;AAEA;IACI,2CAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".skeleton {\n    height: 1.2rem;\n\n    margin: 0.2rem 0;\n\n    border-radius: 0.4rem;\n\n    opacity: 0.3;\n\n    background: var(--grey);\n}\n\n.skeletonAnimation {\n    animation: opacity 2s infinite;\n}\n\n.text {\n    height: 1rem;\n    width: 12rem;\n}\n\n.circle {\n    border-radius: 50%;\n    height: 1rem;\n    width: 1rem;\n}\n\n.rect {\n    border-radius: 0;\n    height: 2rem;\n    width: 10rem;\n}\n\n@keyframes opacity {\n    0% {\n        opacity: 0.3;\n    }\n    50% {\n        opacity: 0.1;\n    }\n    100% {\n        opacity: 0.3;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "D_SoXWo4Qd7dFs9uIFva",
	"skeletonAnimation": "vLvOHISSZpcmv4ESH23O",
	"opacity": "IpGLNVlArXYt7_ohRyWo",
	"text": "yy_2DqjdByluYJX2TWwK",
	"circle": "be0fOreb53FmPMjVdW_Z",
	"rect": "Pz_fPsZaC2ccAZI3fe2K"
};
export default ___CSS_LOADER_EXPORT___;
