import React from "react";

import staticFiles from "juice-base/static-files.js";

import Text from "juice-base/components/text/index.js";
import Skeleton from "juice-base/components/skeleton/index.js";
import SkeletonHeader from "juice-base/components/skeleton-header/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
    SectionTeacherMessage,
} from "juice-base/components/section-teacher/index.js";

import styles from "./styles.module.css";


const TeacherClassAnnouncement = (props) => {
    const renderContent = () => {
        if (props.content) {
            return <Text>{props.content}</Text>;
        }

        return (
            <SectionTeacherMessage>
                No content for this class.
            </SectionTeacherMessage>
        );
    };

    const renderNote = () => {
        if (!props.content) {
            return null;
        }

        return (
            <div className={styles.classAnnouncementNote}>
                <img
                    src={staticFiles.iconCheck}
                    alt="Check"
                    title="Check"
                />
                Message will display to students every day this week
            </div>
        );
    };

    let headerTitle = <div>Class Announcement</div>;

    let explanationMark = (
        <SectionTeacherExplanation
            pageWidth={props.pageWidth}
            alignLeft={props.pageWidth < 920}
        >
            Write a custom message your students will see every day in the Daily Juice
        </SectionTeacherExplanation>
    );

    let headerEditButton = (
        <div
            className={styles.editButton}
            onClick={props.onEdit}
            onKeyPress={props.onEdit}
            tabIndex="-1"
            role="button"
        >
            <img
                src={staticFiles.iconPencilOrange}
                alt="Edit"
                title="Edit"
            />
        </div>
    );

    let content = renderContent();
    let note = renderNote();

    if (props.isSkeleton) {
        headerTitle = <SkeletonHeader />;
        headerEditButton = null;
        explanationMark = <Skeleton variant="circle" height={2} width={2} />;

        content = (
            <div className={styles.skeletonContent}>
                <Skeleton variant="text" />
                <Skeleton variant="text" fullWidth />
                <Skeleton variant="text" fullWidth />
                <Skeleton variant="text" fullWidth />
                <Skeleton variant="text" fullWidth />
            </div>
        );

        note = null;
    }

    let contentSection = <RequestLoader />;

    if (props.isLoaded || props.isSkeleton) {
        contentSection = (
            <div>
                <div className={styles.content}>
                    {content}
                </div>

                {note}
            </div>
        );
    }

    return (
        <SectionTeacher dataComment="daily-juice-class-announcement">
            <SectionTeacherHeader
                label={headerTitle}
                rightControls={[headerEditButton, explanationMark]}
            />
            {contentSection}
        </SectionTeacher>
    );
};

TeacherClassAnnouncement.defaultProps = {
    pageWidth: 0,
    content: null,
    isSkeleton: true,
    isLoaded: false,
    onEdit: () => {},
};

export default TeacherClassAnnouncement;
