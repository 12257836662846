// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x_yEkXgNAbA_czoHLHPK {\n    display: grid;\n    grid-gap: 0.5rem;\n\n    justify-content: center;\n}\n\n.O_g4nv74YGfgffa46b92 {\n    font-weight: 600;\n    font-size: 2rem;\n    word-break: break-all;\n}\n\n._VGqqO4PbkkSe2QWG4P8 {\n    margin: 0 0 1.5rem 0;\n}\n\n@media only screen and (min-width: 650px) {\n    .O_g4nv74YGfgffa46b92 {\n        white-space: nowrap;\n        word-break: initial;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-confirm-generate-class-code/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;;IAEhB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,mBAAmB;QACnB,mBAAmB;IACvB;AACJ","sourcesContent":[".classCode {\n    display: grid;\n    grid-gap: 0.5rem;\n\n    justify-content: center;\n}\n\n.classCodeLink {\n    font-weight: 600;\n    font-size: 2rem;\n    word-break: break-all;\n}\n\n.contentTitle {\n    margin: 0 0 1.5rem 0;\n}\n\n@media only screen and (min-width: 650px) {\n    .classCodeLink {\n        white-space: nowrap;\n        word-break: initial;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classCode": "x_yEkXgNAbA_czoHLHPK",
	"classCodeLink": "O_g4nv74YGfgffa46b92",
	"contentTitle": "_VGqqO4PbkkSe2QWG4P8"
};
export default ___CSS_LOADER_EXPORT___;
