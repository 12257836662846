import React, { useRef, useEffect, useState } from "react";

import staticFiles from "juice-base/static-files.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Standards from "juice-base/project/standards.js";

import timer from "juice-base/lib/timer.js";

import Text from "juice-base/components/text/index.js";
import QuizQuestionForm from "juice-base/forms/quiz-question/index.js";
import QuizQuestionAnswers from "juice-base/business/quiz-question-answers/index.js";

import PopupLearningStandards from "juice-base/business/popup-learning-standards/index.js";

import styles from "./styles.module.css";


const QuizQuestion = (props) => {
    const [isVisibleStandardsPopup, setIsVisibleStandardsPopup] = useState(false);

    const { question } = props;

    if (!question) {
        return null;
    }

    const quizTimer = useRef(null);

    useEffect(() => {
        if (props.isVisible) {
            quizTimer.current = timer.startTimer();
        } else {
            quizTimer.current = null;
        }
    }, [props.isVisible]);

    const getJuiceStandard = () => {
        const juiceStandards = Standards.getStandardsByType(props.standards, "juice-standard");

        if (juiceStandards.length === 0) {
            return null;
        }

        return juiceStandards[0]?.standard;
    };

    const onStandardsClick = () => {
        setIsVisibleStandardsPopup(true);
    };

    const onSubmit = ({ answer }) => {
        const diffSeconds = quizTimer.current.getDiffSeconds() || 0;

        const isCorrect = DailyJuice.isValidQuiz(question);

        props.onSubmit({
            quizId: question.quizId,
            questionId: question.id,
            answerId: parseInt(answer, 10),
            timeTook: diffSeconds,
            isCorrect,
        });
    };

    const renderStandardsPopup = () => {
        if (!isVisibleStandardsPopup) {
            return null;
        }

        return (
            <PopupLearningStandards
                standards={props.standards}
                onClose={() => {
                    setIsVisibleStandardsPopup(false);
                }}
            />
        );
    };

    const renderContent = () => {
        let content = null;

        const isAnswered = DailyJuice.isAnsweredQuiz(question);

        if (isAnswered || props.showAnswers) {
            const answers = (question.answers || []).map((ans) => {
                let isUserChoice = ans.is_user_choice;

                if (props.showAnswers) {
                    isUserChoice = true;
                }

                return {
                    ...ans,
                    isUserChoice,
                    isCorrect: ans.is_correct_answer,
                };
            });

            content = (
                <QuizQuestionAnswers
                    quizId={question.quizId}
                    answers={answers}
                />
            );
        } else {
            const initialValues = {
                answer: null,
            };

            if (props.allowAnswering) {
                content = (
                    <QuizQuestionForm
                        questionId={question.id}
                        answers={question.answers || []}
                        initialValues={initialValues}
                        allowAnswering={props.allowAnswering}
                        onSelectAnswer={(answerId) => {
                            props.onSelectAnswer({
                                quizId: question.quizId,
                                answerId,
                            });
                        }}
                        onSubmit={onSubmit}
                    />
                );
            } else {
                content = (
                    <QuizQuestionAnswers
                        quizId={question.quizId}
                        answers={question.answers || []}
                    />
                );
            }
        }

        return content;
    };

    const renderQuestion = () => {
        let standardsButtonMobile = null;
        let standardsButton = null;

        if (props.showStandards) {
            if (props.isMobile) {
                standardsButtonMobile = (
                    <div
                        className={styles.standardsButton}
                        onClick={onStandardsClick}
                        onKeyPress={onStandardsClick}
                        role="button"
                        tabIndex="-1"
                    >
                        <img
                            src={staticFiles.standardsFlamingo}
                            alt="Show Standards"
                            title="Show Standards"
                        />
                    </div>
                );
            } else {
                const standard = getJuiceStandard();

                if (standard) {
                    standardsButton = (
                        <div
                            className={styles.standard}
                            onClick={onStandardsClick}
                            onKeyPress={onStandardsClick}
                            role="button"
                            tabIndex="-1"
                        >
                            {standard}
                        </div>
                    );
                }
            }
        }

        return (
            <div className={styles.questionSection}>
                <div className={styles.questionSectionQuestion}>
                    <Text className={styles.question}>
                        {question.question}
                    </Text>
                    {standardsButton}
                </div>
                {standardsButtonMobile}
            </div>
        );
    };

    return (
        <>
            {renderStandardsPopup()}
            <div className={styles.quizQuestion}>
                <div className={styles.header}>
                    Question
                </div>

                {renderQuestion()}

                {renderContent()}
            </div>
        </>
    );
};

QuizQuestion.defaultProps = {
    question: null,
    standards: [],
    allowAnswering: true,
    showStandards: false,
    isMobile: false,
    showAnswers: false,
    onSelectAnswer: () => {},
    onSubmit: () => {},
};

export default QuizQuestion;
