
export const getTouch = (from, to, move = 10) => {
    let up = false;
    let down = false;
    let left = false;
    let right = false;

    const xDiff = Math.abs(from.x - to.x);
    const yDiff = Math.abs(from.y - to.y);

    const isVertical = yDiff > xDiff;

    if (isVertical) {
        up = from.y < to.y - move;
        down = from.y > to.y + move;
    } else {
        left = from.x > to.x + move;
        right = from.x < to.x - move;
    }

    return {
        up,
        down,
        left,
        right,
    };
};

export const getTouchByCoords = (coords = []) => {
    if (coords.length < 2) {
        return null;
    }

    let isUp = false;

    for (let i = 0; i < coords.length - 1; i += 1) {
        const { y } = coords[i];

        isUp = y < coords[i + 1].y;
    }

    return {
        up: isUp,
        down: !isUp,
    };
};
