import { useEffect, useRef, useState } from "react";

import AudioManager from "juice-base/lib/audio-manager/index.js";


const useAudioManager = (options) => {
    const opts = options || {
        isMac: false,
    };

    const audioManager = useRef(null);
    const [audioManagerState, setAudioManagerState] = useState({});

    const setFiles = (files) => {
        audioManager.current.setFiles(files);
    };

    const playerPlayNext = (groupName, trackId) => {
        // NOTE: hack, because audio autoplay disabled on IOS
        if (opts.isMac) {
            return;
        }

        if (audioManager.current) {
            audioManager.current.setPlayNext(groupName, trackId);
        }
    };

    const playerPlay = (groupName, trackId) => {
        if (audioManager.current) {
            audioManager.current.play(groupName, trackId);
        }
    };

    const playerPause = (groupName, trackId) => {
        if (audioManager.current) {
            audioManager.current.pause(groupName, trackId);
        }
    };

    const playerRewind = (groupName, trackId) => {
        if (audioManager.current) {
            audioManager.current.rewind(groupName, trackId);
        }
    };

    const playerForward = (groupName, trackId) => {
        if (audioManager.current) {
            audioManager.current.forward(groupName, trackId);
        }
    };

    const playerStop = (groupName, trackId) => {
        if (audioManager.current) {
            audioManager.current.stop(groupName, trackId);
        }
    };

    const playerChangeRate = (groupName, trackId, rate) => {
        if (audioManager.current) {
            audioManager.current.setPlaybackRate(groupName, trackId, rate);
        }
    };

    const playerPauseAll = () => {
        if (audioManager.current) {
            audioManager.current.pauseAll();
        }
    };

    const playerStopAll = (groupName, trackIds) => {
        if (audioManager.current) {
            audioManager.current.stopAllTracks(groupName, trackIds);
        }
    };

    useEffect(() => {
        audioManager.current = new AudioManager((state) => {
            setAudioManagerState(state);
        });
    }, []);

    return {
        state: audioManagerState,
        setFiles,
        playerPlayNext,
        playerPlay,
        playerPause,
        playerRewind,
        playerForward,
        playerStop,
        playerChangeRate,
        playerPauseAll,
        playerStopAll,
    };
};

export default useAudioManager;
