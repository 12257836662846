// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._lF0EAM7swlrNJpd0v_h {\n    max-width: 130rem;\n    width: 100%;\n\n    margin: 0 auto;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.jHGd_NnM0_baWn6XoJEB {\n    color: crimson;\n\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    ._lF0EAM7swlrNJpd0v_h {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/containers/guardian-daily-juices/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;;IAEX,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,cAAc;;IAEd,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".index {\n    max-width: 130rem;\n    width: 100%;\n\n    margin: 0 auto;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.errorMessage {\n    color: crimson;\n\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    .index {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "_lF0EAM7swlrNJpd0v_h",
	"errorMessage": "jHGd_NnM0_baWn6XoJEB"
};
export default ___CSS_LOADER_EXPORT___;
