import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";
import {
    tryFormatDate,
    formatMonthDayYearDate,
} from "juice-base/lib/date.js";

import ClassroomShareButton from "juice-base/components/google-classroom-share-button/index.js";
import JuiceContentFirstLines from "juice-base/components/juice-content-first-lines/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";

import styles from "./styles.module.css";


const SearchCard = (props) => {
    const hasFeaturedImage = props?.featuredImage?.src;

    const [isPlayerVisible, setPlayerVisible] = useState(false);

    const onClosePlayer = () => {
        props.onClose();
        setPlayerVisible(false);
    };

    const onOpenPlayer = () => {
        setPlayerVisible(true);
    };

    const renderShareButton = () => {
        if (!props.withShareButton) {
            return null;
        }

        let shareUrl = "";

        if (props.type === "extra juice") {
            shareUrl = `/extra-juice/${props.storyId}`;
        } else {
            shareUrl = `/story/${props.storyId}`;
        }

        return (
            <ClassroomShareButton
                title={props.title}
                url={shareUrl}
            />
        );
    };

    const renderFeaturedImage = () => {
        if (!hasFeaturedImage) {
            return null;
        }

        const isInfographicImage = props.featuredImage && props.featuredImage.infographic;

        const imgClassName = classNames({
            [styles.featuredImageList]: !props.isCardsView,

            [styles.infographicImageCard]: props.isCardsView && isInfographicImage,
            [styles.featuredImageCard]: props.isCardsView && !isInfographicImage,
        });

        return (
            <div
                className={imgClassName}
                onClick={props.onCardClick}
                onKeyPress={props.onCardClick}
                tabIndex="-1"
                role="button"
            >
                <img
                    src={props.featuredImage.src}
                    alt="Featured"
                    title="Featured"
                />
            </div>
        );
    };

    const renderListenButton = () => {
        if (props.type === "video") {
            return null;
        }

        if (!props.isCardsView) {
            return (
                <div className={styles.listenButton}>
                    <img
                        src={staticFiles.iconPlayerHeadphone}
                        alt="Headphone"
                        title="Headphone"
                    />
                </div>
            );
        }

        return (
            <div
                className={styles.listenButtonCards}
                onKeyPress={onOpenPlayer}
                onClick={onOpenPlayer}
                role="button"
                tabIndex="-1"
            >
                <img
                    src={staticFiles.iconPlayerHeadphone}
                    alt="Headphone"
                    title="Headphone"
                />
            </div>
        );
    };

    const renderCategory = () => {
        let categoryTitle = null;

        if (props.category) {
            categoryTitle = props.category;
        }

        if (props.type === "extra juice" && !props.isCardsView) {
            categoryTitle = "Extra juice";
        }

        if (!categoryTitle) {
            return null;
        }

        return (
            <div className={styles.categoryTitle}>
                {categoryTitle}
            </div>
        );
    };

    const renderDatePublished = () => {
        const dateClassName = classNames({
            [styles.datePublished]: true,
            [styles.datePublishedList]: !props.isCardsView,
        });

        const date = tryFormatDate(props.datePublished, formatMonthDayYearDate);

        return (
            <div className={dateClassName}>
                {date}
            </div>
        );
    };

    const renderTitle = (withEJCategory = false) => {
        let title = (
            <div className={styles.cardTitle}>
                {props.title}
            </div>
        );

        if (props.type === "extra juice") {
            let category = null;

            if (withEJCategory) {
                category = (renderCategory());
            }

            const extraJuiceTitleClassName = classNames({
                [styles.extraJuiceTitle]: true,
                [styles.listExtraJuiceTitle]: !props.isCardsView,
            });

            let datePublished = null;

            if (!props.isCardsView) {
                datePublished = renderDatePublished();
            }

            title = (
                <div className={extraJuiceTitleClassName}>
                    <img
                        src={staticFiles.iconOrange}
                        alt="Extra Juice"
                        title="Extra Juice"
                    />
                    <div className={styles.storyTitle}>
                        {category}
                        {props.title}
                        {datePublished}
                    </div>
                </div>
            );
        }

        return title;
    };

    const renderAudioPlayer = () => {
        if (!isPlayerVisible) {
            return null;
        }

        let imageUrl = null;

        if (props?.featuredImage?.src) {
            imageUrl = props.featuredImage.src;
        }

        return (
            <PlayerAudio
                key={`player-audio-story-${props.cardId}`}
                image={imageUrl}
                title={props.title}
                category={props.category}
                audio={props.audioData}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                onClose={onClosePlayer}
            />
        );
    };

    const shareButton = renderShareButton();

    if (!props.isCardsView) {
        const storyClassName = classNames({
            [styles.storyList]: true,
            [styles.storyListWithImage]: hasFeaturedImage,
        });

        const storyListClassName = classNames({
            [styles.storyListContent]: true,
            [styles.storyListContentWithShareButton]: shareButton,
        });

        let category = null;

        let datePublished = null;

        if (props.type !== "extra juice") {
            category = renderCategory();
            datePublished = renderDatePublished();
        }

        return (
            <div
                className={storyClassName}
                onClick={props.onCardClick}
                onKeyPress={props.onCardClick}
                role="button"
                tabIndex="-1"
            >
                {renderFeaturedImage()}
                <div className={storyListClassName}>
                    <div className={styles.listContentBody}>
                        {category}

                        {renderTitle(true)}

                        {datePublished}
                    </div>

                    {renderListenButton()}

                    {shareButton}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.storyCard}>
            {renderFeaturedImage()}

            <div className={styles.storyCardContent}>
                <div
                    className={styles.cardContentBody}
                    onClick={props.onCardClick}
                    onKeyPress={props.onCardClick}
                    tabIndex="-1"
                    role="button"
                >
                    {renderCategory()}

                    {renderTitle()}

                    <div className={styles.cardStoryContent}>
                        <JuiceContentFirstLines
                            content={props.content}
                        />
                    </div>
                </div>

                <div className={styles.cardListenButton}>
                    {renderDatePublished()}

                    <div className={styles.cardControls}>
                        {renderListenButton()}
                        {shareButton}
                    </div>
                </div>

                <div className={styles.audioPlayer}>
                    {renderAudioPlayer()}
                </div>
            </div>
        </div>
    );
};

SearchCard.defaultProps = {
    cardId: null,
    datePublished: null,
    type: "article",
    featuredImage: {},
    category: null,
    title: "",
    content: "",
    status: "",
    isCardsView: false,
    withShareButton: false,
    onCardClick: () => {},
};

export default SearchCard;
