import React from "react";

import PopupCommon from "../popup-common/index.js";
import JuiceStoryAds from "../juice-story-ads/index.js";

import styles from "./styles.module.css";


const PopupAdsPreview = (props) => {
    if (!props.data) {
        return null;
    }

    const classes = [
        styles.popupJuiceStoryAds,
    ];

    return (
        <PopupCommon
            onClose={props.onClose}
            closeOnLayoutClick
        >
            <div className={classes.join(" ")}>
                <JuiceStoryAds
                    data={props.data}
                    isVisibleSwipeUp={false}
                />
            </div>
        </PopupCommon>
    );
};

PopupAdsPreview.defaultProps = {
    data: null,
    onClose: () => {},
};

export default PopupAdsPreview;
