import React, { useState, useEffect, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Snackbar = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    const visibleRef = useRef(isVisible);
    visibleRef.current = isVisible;

    const onAutoClose = (closeParams) => {
        if (!visibleRef.current) {
            return;
        }

        setIsVisible(false);

        window.setTimeout(() => {
            props.onClose(closeParams);
        }, 1000);
    };

    const onClose = (closeParams) => {
        if (!isVisible) {
            return;
        }

        setIsVisible(false);

        window.setTimeout(() => {
            props.onClose(closeParams);
        }, 1000);
    };

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        if (props.autoClose) {
            window.setTimeout(() => {
                onAutoClose({
                    withUndo: false,
                });
            }, 1000 * props.autoCloseInSeconds);
        }
    }, []);

    const onUndoClick = () => {
        onClose({
            withUndo: true,
        });
    };

    const renderControls = () => {
        if (!props.isVisibleUndoButton) {
            return null;
        }

        return (
            <div
                className={styles.undoButton}
                onClick={onUndoClick}
                onKeyPress={onUndoClick}
                tabIndex="-1"
                role="button"
            >
                Undo
            </div>
        );
    };

    const snackbarClassName = classNames({
        [styles.snackbar]: true,
        [styles.snackbarVisible]: isVisible,
    });

    return (
        <div className={snackbarClassName}>
            <div className={styles.message}>
                {props.message}
            </div>
            <div className={styles.rightControls}>
                {renderControls()}
            </div>
        </div>
    );
};

Snackbar.defaultProps = {
    message: "",
    isVisibleUndoButton: false,
    autoClose: true,
    autoCloseInSeconds: 5,
    onClose: () => {},
};

export default Snackbar;
