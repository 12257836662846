// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w1P1CM00jaAzo0VdaM_F {\n    display: flex;\n}\n\n.vLvxq_fIBaUzENazFula div {\n    cursor: pointer;\n}\n\n/* --------- */\n\n._EzqLQDJ1aOIxGTEkNql {\n    cursor: grabbing;\n}\n\n/* --------- */\n\n.vNG0z3L64b78_Fx09al9 {\n    padding: 0 3rem;\n\n    cursor: pointer;\n}\n\n.Z9mTaypl_ArBwoAroeDQ,\n.osa3U99tWOFApbV3WGBN {\n    width: 3rem;\n}\n\n.osa3U99tWOFApbV3WGBN {\n    transform: scaleX(-1);\n}\n\n._OF_WXYg1nW8_Min8j6f {\n    opacity: 0.4;\n}\n\n._y8IYck38c3sLE1VgG9u {\n    display: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/horizontal-scrolling-menu/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA,cAAc;;AAEd;IACI,gBAAgB;AACpB;;AAEA,cAAc;;AAEd;IACI,eAAe;;IAEf,eAAe;AACnB;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".innerWrapperClass {\n    display: flex;\n}\n\n.elementsWithCursorPointer div {\n    cursor: pointer;\n}\n\n/* --------- */\n\n.cursorGrabbing {\n    cursor: grabbing;\n}\n\n/* --------- */\n\n.arrow {\n    padding: 0 3rem;\n\n    cursor: pointer;\n}\n\n.arrowRight,\n.arrowLeft {\n    width: 3rem;\n}\n\n.arrowLeft {\n    transform: scaleX(-1);\n}\n\n.arrowDisabled {\n    opacity: 0.4;\n}\n\n.arrowHidden {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerWrapperClass": "w1P1CM00jaAzo0VdaM_F",
	"elementsWithCursorPointer": "vLvxq_fIBaUzENazFula",
	"cursorGrabbing": "_EzqLQDJ1aOIxGTEkNql",
	"arrow": "vNG0z3L64b78_Fx09al9",
	"arrowRight": "Z9mTaypl_ArBwoAroeDQ",
	"arrowLeft": "osa3U99tWOFApbV3WGBN",
	"arrowDisabled": "_OF_WXYg1nW8_Min8j6f",
	"arrowHidden": "_y8IYck38c3sLE1VgG9u"
};
export default ___CSS_LOADER_EXPORT___;
