import React from "react";

import staticFiles from "../../static-files.js";
import Text from "../text/index.js";
import SwipeUp from "../swipe-up/index.js";

import classAnnouncementStyles from "../class-announcement/styles.module.css";
import styles from "./styles.module.css";


const JuiceStoryAds = (props) => {
    const { data } = props;

    if (!data) {
        return null;
    }

    const renderAdsTitle = (title) => {
        return (
            <div className={styles.adsBlockTitle}>
                <div className={styles.adsBlockTitleName}>
                    {title}
                </div>
                <img
                    src={staticFiles.shapeHighlighter}
                    alt={title}
                    title={title}
                    className={styles.adsBlockTitleBG}
                />
            </div>
        );
    };

    const renderSponsor = () => {
        if (!data.sponsorContent && !data.sponsorImage) {
            return null;
        }

        let sponsorImg = null;

        if (data.sponsorImage) {
            const sponsorImgTitle = "Sponsor image";

            sponsorImg = (
                <img
                    src={data.sponsorImage}
                    alt={sponsorImgTitle}
                    title={sponsorImgTitle}
                    className={styles.adsBlockImage}
                />
            );

            if (data.sponsorImageUrl) {
                sponsorImg = (
                    <a
                        href={data.sponsorImageUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {sponsorImg}
                    </a>
                );
            }
        }

        let content = null;

        if (data.sponsorContent) {
            content = (
                <Text className={classAnnouncementStyles.content}>
                    {data.sponsorContent}
                </Text>
            );
        }

        const title = data.sponsoredByTitle || "Sponsored by";

        return (
            <div className={styles.adsBlock}>
                {renderAdsTitle(title)}
                {sponsorImg}
                {content}
            </div>
        );
    };

    const renderClassAnnouncement = () => {
        if (!data.announcementContent) {
            return null;
        }

        const title = data.announcementTitle || "Class announcement";

        return (
            <div className={styles.adsBlock}>
                {renderAdsTitle(title)}
                <Text className={classAnnouncementStyles.content}>
                    {data.announcementContent}
                </Text>
            </div>
        );
    };

    const adsStyle = {
        backgroundImage: `url(${staticFiles.juiceGradientAds})`,
    };

    return (
        <div
            className={styles.juiceStoryAds}
            style={adsStyle}
        >
            {renderSponsor()}
            {renderClassAnnouncement()}

            {props.isVisibleSwipeUp ? <SwipeUp small /> : null}
        </div>
    );
};

JuiceStoryAds.defaultProps = {
    data: null,
    isVisibleSwipeUp: true,
};

export default JuiceStoryAds;
