const routeDailyJuice = {
    to: "/daily-juice",
    name: "Daily Juice",
    iconDesktop: "iconJuiceCupGrey",
    iconDesktopSelected: "iconJuiceCupOrange",
    icon: "iconJuiceCup",
    dataComment: "link-daily-juice",
};

const routeClass = {
    to: "/class",
    name: "Class",
    iconDesktop: "iconUsersGrey",
    iconDesktopSelected: "iconUsersOrange",
    icon: "iconUsers",
    dataComment: "link-class",
};

const routeStudent = {
    to: "/student",
    name: "Student",
    iconDesktop: "iconBarGraphGrey",
    iconDesktopSelected: "iconBarGraphOrange",
    icon: "iconBarGraph",
    dataComment: "link-student",
};

const routeSearch = {
    to: "/search",
    name: "Search",
    iconDesktop: "iconSearchGrey",
    iconDesktopSelected: "iconSearchOrange",
    icon: "iconSearch",
    dataComment: "link-search",
};


const sectionsByRoles = {
    home: {
        student: [
            routeDailyJuice,
            routeSearch,
        ],
        guardian: [
            routeDailyJuice,
            routeStudent,
            routeSearch,
        ],
        teacher: [
            routeDailyJuice,
            routeClass,
            routeSearch,
        ],
    },
    trial: {
        student: [
            routeDailyJuice,
        ],
    },
    free: {
        student: [
            routeDailyJuice,
            routeSearch,
        ],
        teacher: [
            routeDailyJuice,
            routeClass,
            routeSearch,
        ],
    },
    regular: {
        student: [
            routeDailyJuice,
            routeSearch,
        ],
        guardian: [
            routeDailyJuice,
            routeStudent,
            routeSearch,
        ],
        teacher: [
            routeDailyJuice,
            routeClass,
            routeSearch,
        ],
    },
};

export default sectionsByRoles;
