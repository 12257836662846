import React from "react";

import PopupCommon from "../popup-common/index.js";

import styles from "./styles.module.css";


const PopupUserLogout = (props) => {
    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
        >
            <div className={styles.popupContainer}>
                <div className={styles.popup}>
                    <div className={styles.title}>
                        Are you sure want to sign out of The Juice?
                    </div>
                    <div className={styles.controls}>
                        <div
                            role="button"
                            tabIndex="-1"
                            onClick={props.onClose}
                            onKeyPress={props.onClose}
                            className={styles.cancelButton}
                        >
                            Cancel
                        </div>
                        <div
                            role="button"
                            tabIndex="-1"
                            onClick={props.onLogout}
                            onKeyPress={props.onLogout}
                            className={styles.logoutButton}
                        >
                            Sign Out
                        </div>
                    </div>
                </div>
            </div>
        </PopupCommon>
    );
};

PopupUserLogout.defaultProps = {
    onClose: () => {},
    onLogout: () => {},
};

export default PopupUserLogout;
