// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BPBAjHwxGZJu4LnEkShZ {\n    padding: 2rem 3rem;\n}\n\n.BPBAjHwxGZJu4LnEkShZ ul {\n    padding: 0 2rem;\n}\n\n._x3c1UIEySMQ5GrfmGe_ img {\n    width: 2rem;\n    margin-right: 0.5rem;\n}\n._x3c1UIEySMQ5GrfmGe_ {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: flex-start;\n    align-items: center;\n    color: #00d000;\n    margin: 1rem 2rem 2rem;\n    font-size: 1.2rem;\n}\n\n.HqQNQ_3O7tJGim_ZMmlZ {\n    display: grid;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.HqQNQ_3O7tJGim_ZMmlZ img {\n    width: 2rem;\n}\n\n/* --------- */\n\n.yqFjRuqaZMcE55vHbNUl {\n    display: grid;\n    grid-gap: 2rem;\n}\n\n/* --------- */", "",{"version":3,"sources":["webpack://./base/business/teacher-class-announcement/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,cAAc;IACd,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,aAAa;;IAEb,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA,cAAc;;AAEd;IACI,aAAa;IACb,cAAc;AAClB;;AAEA,cAAc","sourcesContent":[".content {\n    padding: 2rem 3rem;\n}\n\n.content ul {\n    padding: 0 2rem;\n}\n\n.classAnnouncementNote img {\n    width: 2rem;\n    margin-right: 0.5rem;\n}\n.classAnnouncementNote {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: flex-start;\n    align-items: center;\n    color: #00d000;\n    margin: 1rem 2rem 2rem;\n    font-size: 1.2rem;\n}\n\n.editButton {\n    display: grid;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.editButton img {\n    width: 2rem;\n}\n\n/* --------- */\n\n.skeletonContent {\n    display: grid;\n    grid-gap: 2rem;\n}\n\n/* --------- */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "BPBAjHwxGZJu4LnEkShZ",
	"classAnnouncementNote": "_x3c1UIEySMQ5GrfmGe_",
	"editButton": "HqQNQ_3O7tJGim_ZMmlZ",
	"skeletonContent": "yqFjRuqaZMcE55vHbNUl"
};
export default ___CSS_LOADER_EXPORT___;
