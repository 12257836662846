import React from "react";

import SectionStudent from "juice-base/components/section-student/index.js";
import Text from "juice-base/components/text/index.js";

import classAnnouncementStyles from "juice-base/components/class-announcement/styles.module.css";


const SectionAnnouncement = (props) => {
    const content = (
        <Text className={classAnnouncementStyles.content}>
            {props.content}
        </Text>
    );

    if (props.teacherTheme) {
        return (
            <div>
                <div className={classAnnouncementStyles.title}>
                    {props.title}
                </div>
                <div>
                    {content}
                </div>
            </div>
        );
    }

    return (
        <SectionStudent
            title={props.title}
            dataComment="section-announcement"
            showSeeMore={false}
        >
            {content}
        </SectionStudent>
    );
};

SectionAnnouncement.defaultProps = {
    title: "Class announcement",
    teacherTheme: false,
    content: null,
};

export default SectionAnnouncement;
