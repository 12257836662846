// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._EoDVskGIV__4GoEbWNn {\n    display: grid;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.pR4zfUe1L6w_jx2kzkoB {\n    width: 100%;\n    max-width: 135rem;\n\n    justify-self: center;\n}\n\n.YkGrVO7TryyXu0oiSFmr {\n    display: grid;\n    justify-content: start;\n}\n\n.OVsmuM_duvJRwY3XkVkm {\n    width: 100%;\n}\n\n.bNumfoH1EpYisqmux7mh {\n    color: crimson;\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    .pR4zfUe1L6w_jx2kzkoB {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/student/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,iBAAiB;;IAEjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["\n.indexWrapper {\n    display: grid;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.index {\n    width: 100%;\n    max-width: 135rem;\n\n    justify-self: center;\n}\n\n.studentsList {\n    display: grid;\n    justify-content: start;\n}\n\n.table {\n    width: 100%;\n}\n\n.errorMessage {\n    color: crimson;\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    .index {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indexWrapper": "_EoDVskGIV__4GoEbWNn",
	"index": "pR4zfUe1L6w_jx2kzkoB",
	"studentsList": "YkGrVO7TryyXu0oiSFmr",
	"table": "OVsmuM_duvJRwY3XkVkm",
	"errorMessage": "bNumfoH1EpYisqmux7mh"
};
export default ___CSS_LOADER_EXPORT___;
