// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ex_QaqMnlq98i5BIrn5K {\n    padding: 1rem;\n}\n\n.pt9Jz1GXzLonbebRcHGz {\n    padding: 1rem 0;\n\n    border-bottom: 0.1rem solid grey;\n\n    font-weight: 600;\n    font-size: 2rem;\n}\n\n.a3PzciqZSmh9JYhWhquk {\n    font-weight: 600;\n\n    margin-left: 3rem;\n\n    word-break: break-all;\n}\n\n.luFV3UXK7ha3Rg2MBm6C {\n    padding: 1.5rem 0;\n\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n\n    border-bottom: 0.1rem grey solid;\n}\n\n.ouri3MGI3sGLezC28wtA {\n    color: grey;\n}\n\n.jXgIUAl3gSIv32__Hgr9 {\n    margin: 1rem 0;\n}\n\n.jXgIUAl3gSIv32__Hgr9 a {\n    color: rgb(0, 89, 255);\n    text-decoration: none;\n}\n\n", "",{"version":3,"sources":["webpack://./base/components/account-settings/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;;IAEf,gCAAgC;;IAEhC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;;IAEhB,iBAAiB;;IAEjB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;;IAEjB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;;IAE9B,gCAAgC;AACpC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":[".sectionContent {\n    padding: 1rem;\n}\n\n.title {\n    padding: 1rem 0;\n\n    border-bottom: 0.1rem solid grey;\n\n    font-weight: 600;\n    font-size: 2rem;\n}\n\n.settingValue {\n    font-weight: 600;\n\n    margin-left: 3rem;\n\n    word-break: break-all;\n}\n\n.oneSetting {\n    padding: 1.5rem 0;\n\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n\n    border-bottom: 0.1rem grey solid;\n}\n\n.settingTitle {\n    color: grey;\n}\n\n.changePassword {\n    margin: 1rem 0;\n}\n\n.changePassword a {\n    color: rgb(0, 89, 255);\n    text-decoration: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionContent": "ex_QaqMnlq98i5BIrn5K",
	"title": "pt9Jz1GXzLonbebRcHGz",
	"settingValue": "a3PzciqZSmh9JYhWhquk",
	"oneSetting": "luFV3UXK7ha3Rg2MBm6C",
	"settingTitle": "ouri3MGI3sGLezC28wtA",
	"changePassword": "jXgIUAl3gSIv32__Hgr9"
};
export default ___CSS_LOADER_EXPORT___;
