// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".talIkaUbAQWX_1KK047S {\n    width: 100%;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n\n    border: none;\n\n    cursor: pointer;\n    outline: none;\n\n    color: rgb(var(--cerulean));\n    background-color: #fff;\n\n    text-align: center;\n    text-decoration: none;\n    font-family: \"Soleil-Regular\";\n    font-size: 1.5rem;\n    font-weight: 600;\n}\n\n.talIkaUbAQWX_1KK047S:hover {\n    background-color: #eee;\n}\n\n.talIkaUbAQWX_1KK047S:disabled {\n    color: var(--grey);\n    cursor: not-allowed;\n}\n\n/* ------ */\n\n._MMXfjsRQt5ai7LSRokZ {\n    text-transform: uppercase;\n}\n\n.mg5CgLwzGf4tW9110mFB {\n    padding: 1.5rem 3rem;\n}", "",{"version":3,"sources":["webpack://./base/components/button-flat/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,oBAAoB;;IAEpB,YAAY;;IAEZ,eAAe;IACf,aAAa;;IAEb,2BAA2B;IAC3B,sBAAsB;;IAEtB,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA,WAAW;;AAEX;IACI,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".button {\n    width: 100%;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n\n    border: none;\n\n    cursor: pointer;\n    outline: none;\n\n    color: rgb(var(--cerulean));\n    background-color: #fff;\n\n    text-align: center;\n    text-decoration: none;\n    font-family: \"Soleil-Regular\";\n    font-size: 1.5rem;\n    font-weight: 600;\n}\n\n.button:hover {\n    background-color: #eee;\n}\n\n.button:disabled {\n    color: var(--grey);\n    cursor: not-allowed;\n}\n\n/* ------ */\n\n.uppercase {\n    text-transform: uppercase;\n}\n\n.hugePadding {\n    padding: 1.5rem 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "talIkaUbAQWX_1KK047S",
	"uppercase": "_MMXfjsRQt5ai7LSRokZ",
	"hugePadding": "mg5CgLwzGf4tW9110mFB"
};
export default ___CSS_LOADER_EXPORT___;
