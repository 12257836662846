// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alN0O2PVc1eBrPHf5Rto {\n    position: fixed;\n    bottom: 2rem;\n    right: -98vw;\n\n    width: 7rem;\n    height: 7rem;\n\n    display: grid;\n    place-content: center;\n\n    background-color: rgb(var(--cerulean));\n\n    border-radius: 50%;\n    box-shadow: 0rem 0.3rem 0.5rem 0.1rem var(--grey-light);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.V7JyyLtX7mBVYQRk6etD {\n    width: 2rem;\n    max-width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-fab/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;;IAEZ,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,qBAAqB;;IAErB,sCAAsC;;IAEtC,kBAAkB;IAClB,uDAAuD;;IAEvD,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".fab {\n    position: fixed;\n    bottom: 2rem;\n    right: -98vw;\n\n    width: 7rem;\n    height: 7rem;\n\n    display: grid;\n    place-content: center;\n\n    background-color: rgb(var(--cerulean));\n\n    border-radius: 50%;\n    box-shadow: 0rem 0.3rem 0.5rem 0.1rem var(--grey-light);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.fabIconImg {\n    width: 2rem;\n    max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fab": "alN0O2PVc1eBrPHf5Rto",
	"fabIconImg": "V7JyyLtX7mBVYQRk6etD"
};
export default ___CSS_LOADER_EXPORT___;
