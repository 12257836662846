// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OwVMRvVGr4J0wbZHn9Lj {\n    min-height: 8.4rem;\n    width: 100%;\n\n    padding: 0;\n    margin: 0 0 1rem 0;\n}\n\n.fJp0fEeU6qCeoLcvlCbV {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: start;\n    grid-column-gap: 1rem;\n}\n\n.FQeRiflcRkL7gVocRvxL {}\n\n.pA8vidgXzbCt_CaFYII_ {\n    transform: rotate(180deg);\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/select/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;;IAEX,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA,uBAAQ;;AAER;IACI,yBAAyB;AAC7B","sourcesContent":[".field {\n    min-height: 8.4rem;\n    width: 100%;\n\n    padding: 0;\n    margin: 0 0 1rem 0;\n}\n\n.selectedValue {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: start;\n    grid-column-gap: 1rem;\n}\n\n.caret {}\n\n.caretUp {\n    transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "OwVMRvVGr4J0wbZHn9Lj",
	"selectedValue": "fJp0fEeU6qCeoLcvlCbV",
	"caret": "FQeRiflcRkL7gVocRvxL",
	"caretUp": "pA8vidgXzbCt_CaFYII_"
};
export default ___CSS_LOADER_EXPORT___;
