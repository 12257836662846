import React from "react";

import staticFiles from "juice-base/static-files.js";

import classNames from "juice-base/lib/class-names.js";

import ButtonWithMessage from "juice-base/components/button-with-message/index.js";

import styles from "./styles.module.css";


const SectionTeacherExplanation = (props) => {
    const messageClassName = classNames({
        [styles.messageBlock]: true,
        [styles.messageBlockAlignLeft]: props.alignLeft,
    });

    const triangleClassName = classNames({
        [styles.messageTriangle]: true,
        [styles.messageTriangleAlignLeft]: props.alignLeft,
    });

    return (
        <ButtonWithMessage
            message={props.children}
            messageClassName={messageClassName}
            triangleClassName={triangleClassName}
            pageWidth={props.pageWidth}
        >
            <img
                className={styles.informationImg}
                src={staticFiles.informationOrangeOutlined}
                alt="information"
                title="information"
            />
        </ButtonWithMessage>
    );
};

SectionTeacherExplanation.defaultProps = {
    children: null,
    pageWidth: null,
    alignLeft: false,
};

export default SectionTeacherExplanation;
