// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kruqPOV39UgSf0DfHJgS {\n    background-color: #fff;\n}\n\n/* --- */\n\n.hUYjs5LxQEyHVtAVT5aW {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.j4MxuctBX8okrCOpEaVJ {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.N_SGLBgTd63uRSHFdzgL {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.w7h5TWB29apuczCTNsbH {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .hUYjs5LxQEyHVtAVT5aW,\n    .j4MxuctBX8okrCOpEaVJ,\n    .N_SGLBgTd63uRSHFdzgL,\n    .w7h5TWB29apuczCTNsbH {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/extra-juice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;;;;QAII,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".extraJuice {\n    background-color: #fff;\n}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.headline {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.playerAudioToggleButton {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .headline,\n    .playerAudioToggleButton,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraJuice": "kruqPOV39UgSf0DfHJgS",
	"category": "hUYjs5LxQEyHVtAVT5aW",
	"headline": "j4MxuctBX8okrCOpEaVJ",
	"playerAudioToggleButton": "N_SGLBgTd63uRSHFdzgL",
	"content": "w7h5TWB29apuczCTNsbH"
};
export default ___CSS_LOADER_EXPORT___;
