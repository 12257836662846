// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LmFkMiLcoAZKezrTxO6r {\n    display: block;\n    margin-top: 1.6rem;\n    margin-left: auto;\n    margin-right: auto;\n\n    max-width: 100%;\n\n    border-radius: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-sponsor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;;IAElB,eAAe;;IAEf,mBAAmB;AACvB","sourcesContent":[".sponsorImg {\n    display: block;\n    margin-top: 1.6rem;\n    margin-left: auto;\n    margin-right: auto;\n\n    max-width: 100%;\n\n    border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsorImg": "LmFkMiLcoAZKezrTxO6r"
};
export default ___CSS_LOADER_EXPORT___;
