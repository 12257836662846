import ReactDOM from "react-dom";

import configureStore from "juice-base/store/index.js";
import configureRoutes from "./routes/index.js";
import { preInit, postInit } from "./init.js";


const main = () => {
    const store = configureStore();
    const routes = configureRoutes(store);

    preInit(store);

    const rootElement = document.getElementById("app");

    ReactDOM.render(routes, rootElement, () => {
        postInit();
    });
};

main();
