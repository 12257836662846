import React from "react";

import VideoDefault from "juice-base/components/video-default/index.js";
import VideoCustom from "juice-base/components/video-custom/index.js";


const Video = (props) => {
    if (props.isDefault) {
        return (
            <VideoDefault
                videoId={props.videoId}
                src={props.src}
                type={props.type}
                poster={props.poster}
                videoHeight={props.videoHeight}
                canPlay={props.canPlay}
                withPosterCover={props.withPosterCover}
                captionSrc={props.captionSrc}
                onPlayStart={props.onPlayStart}
                onPlayEnd={props.onPlayEnd}
                onPause={props.onPause}
            />
        );
    }

    return (
        <VideoCustom
            videoId={props.videoId}
            src={props.src}
            type={props.type}
            poster={props.poster}
            videoHeight={props.videoHeight}
            canPlay={props.canPlay}
            withPosterCover={props.withPosterCover}
            captionSrc={props.captionSrc}
            onPlayStart={props.onPlayStart}
            onPlayEnd={props.onPlayEnd}
            onPause={props.onPause}
        />
    );
};

Video.defaultProps = {
    videoId: -1,
    src: "",
    type: "",
    poster: "",
    videoHeight: "auto",
    canPlay: true,
    withPosterCover: true,
    captionSrc: null,
    onPlayStart: () => {},
    onPlayEnd: () => {},
    onPause: () => {},

    isDefault: true,
};

export default Video;
