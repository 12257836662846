import React from "react";

import staticFiles from "juice-base/static-files.js";

import Dropdown from "juice-base/components/dropdown/index.js";

import styles from "./styles.module.css";


const DropdownStandards = (props) => {
    let unfurlIcon = (
        <img
            src={staticFiles.iconUnfurl}
            alt="Unfurl"
            title="Unfurl"
        />
    );

    if (props.isExtended) {
        unfurlIcon = (
            <img
                src={staticFiles.iconCollapse}
                alt="Collapse"
                title="Collapse"
            />
        );
    }

    return (
        <div className={styles.standardTypeSelector}>
            <Dropdown
                selectedValue={props.selected}
                values={props.values}
                icon={props.icon}
                flamingoTheme
                onChange={props.onChange}
            />
            <div
                className={styles.unfurlButton}
                onClick={props.onUnfurlStandards}
                onKeyPress={props.onUnfurlStandards}
                role="button"
                tabIndex="-1"
            >
                {unfurlIcon}
            </div>
        </div>
    );
};

DropdownStandards.defaultProps = {
    values: [],

    icon: {
        src: staticFiles.standardsFlamingo,
        alt: "Standards",
        title: "Standards",
    },

    selected: null,

    isExtended: false,
    customIndicator: null,

    onUnfurlStandards: () => {},
    onChange: () => {},
};

export default DropdownStandards;
