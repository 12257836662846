import * as types from "./types.js";


export const setVersion = (version) => ({
    type: types.SET_VERSION,
    payload: {
        version,
    },
});

export default {};
