import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import staticFiles from "juice-base/static-files.js";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const UserPopupConfirmExpiration = (props) => {
    const store = useSelector((state) => ({
        session: state.user.session,
        user: state.user.user,
        location: state.navigation.location,
    }));

    const history = useHistory();

    if (!store.user.isExpired) {
        return null;
    }

    const onSubscribe = () => {
        history.push("/user/subscribe");
    };

    let content = `
        Subscribe to one of our plans to keep using The Juice.
        Don’t worry, we’ll hold on to your data for 60 days.
        Then, we’ll erase it from our system.
    `;

    if (props.isStudent) {
        content = `
            Don’t worry, your data has been saved and will always remain secure.
            Tell your teacher to subscribe so you can keep reading The Juice!
        `;
    }

    const buttons = [
        <ButtonFlat
            uppercase
            onClick={props.onSignOut}
        >
            Log out
        </ButtonFlat>,
    ];

    if (props.isTeacher) {
        buttons.push(
            <ButtonFlat
                uppercase
                onClick={onSubscribe}
            >
                Subscribe
            </ButtonFlat>,
        );
    }

    return (
        <PopupConfirm>
            <PopupConfirmIcon
                big
                src={staticFiles.emojisSad}
                alt="emoji sad"
            />

            <PopupConfirmTitle>
                Nooooo! Your free trial expired!
            </PopupConfirmTitle>

            <PopupConfirmContent>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                {buttons}
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

UserPopupConfirmExpiration.defaultProps = {
    isTeacher: false,
    isStudent: false,
    onSignOut: () => {},
};

export default UserPopupConfirmExpiration;
