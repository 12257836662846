import * as types from "./types.js";


export const setCountries = (payload) => ({
    type: types.SET_COUNTRIES,
    payload,
});

export const setSubdivisionsByAlpha2Loading = (payload) => ({
    type: types.SET_SUBDIVISIONS_BY_APLHA2_LOADING,
    payload,
});

export const setSubdivisionsByAlpha2 = (payload) => ({
    type: types.SET_SUBDIVISIONS_BY_APLHA2,
    payload,
});
