import React from "react";
import CreatableSelect from "react-select/creatable"; // eslint-disable-line import/extensions

import styles from "./styles.module.css";


const selectStyles = {
    dropdownIndicator: () => ({
        display: "none",
    }),

    indicatorSeparator: () => ({
        display: "none",
    }),

    placeholder: () => ({
        fontSize: "1.6rem",
        color: "#000",
        marginLeft: "0.2rem",
    }),

    control: (provided, state) => ({
        minHeight: "5.8rem",
        display: "flex",
        backgroundColor: "#fff",
        border: state.hasValue
            ? "0.2rem solid #0ea5e9"
            : "0.2rem solid hsl(0deg 0% 84%)",
        borderRadius: 4,
        opacity: state.selectProps.controlStyles.opacity,
        cursor: "pointer",
    }),

    singleValue: (provided) => ({
        ...provided,
        height: "100%",
        display: "grid",
        alignItems: "center",
    }),

    menu: () => ({
        zIndex: 999999,
        position: "absolute",
        left: "0",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: "0.1rem 0.1rem 0.3rem 0.1rem #d5d5d5",
        borderRadius: "0.4rem",
    }),

    option: (provided, state) => {
        return {
            ...provided,
            height: "3.6rem",
            cursor: "pointer",
            color: state.isSelected ? "#0ea5e9" : "#585858",
            fontWeight: 600,
            backgroundColor: "",
            ":active": {
                backgroundColor: "",
            },
        };
    },
};

const SelectCreatable = (props) => {
    const onChange = (val) => {
        props.onChange(val);
    };

    const controlStyles = {
        opacity: props.isDisabled ? 0.5 : 1,
    };

    let maxMenuHeight = "300";

    if (props.isShort) {
        maxMenuHeight = "12rem";
    }

    const selectProps = {};

    if (props.isSearchable) {
        // NOTE: if isSearchable false then create function disabled
        selectProps.isSearchable = props.isSearchable;
    }

    // TODO: add check symbols (see: select)
    return (
        <div className={styles.field}>
            <CreatableSelect
                ref={props.selectRef}
                name={props.name}
                placeholder={props.placeholder}
                options={props.values}
                isClearable
                isDisabled={props.isDisabled}
                styles={selectStyles}
                controlStyles={controlStyles}
                maxMenuHeight={maxMenuHeight}
                onChange={onChange}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...selectProps}
            />
        </div>
    );
};

SelectCreatable.defaultProps = {
    selectRef: null,

    name: "",
    values: [],

    placeholder: "Select...",
    dataComment: "",

    isShort: false,
    isSearchable: false,
    isDisabled: false,
    onChange: () => {},
};

export default SelectCreatable;
