import moment from "moment";

import sections from "juice-app/settings/sections.js";


export default {
    appName: "The Juice",
    copyright: "The Juice 2021",

    previewCode: "ba6q7efg2a",

    sectionsByRoles: sections,

    mobileHeaderHiddenPaths: [
        "/daily-juice/:juiceId/:storyId",
    ],

    grades: [
        { value: "G5-G6", label: "Level 5-6" },
        { value: "G7-G8", label: "Level 7-8" },
        { value: "G9-G10", label: "Level 9-10" },
        { value: "G11-G12", label: "Level 11+" },
    ],

    calendarDates: {
        min: moment().subtract(120, "days"),
        max: moment().add(60, "days"),
    },

    password: {
        minLength: 8,
    },

    signUpByCodePath: "/user/sign-up",

    landingSite: {
        domain: "//thejuicelearning.com",
        routeSignUp: "/signup",
    },

    signUpLink: "//thejuicelearning.com/signup",

    privacyPolicyLink: "//thejuicelearning.com/privacy-policy",

    supportLink: "//thejuicelearning.com/support",
};
