export const DATES = {
    today: {
        label: "Today",
        value: "today",
    },
    yesterday: {
        label: "Yesterday",
        value: "yesterday",
    },
    oneWeek: {
        label: "1 Week",
        value: "7-days",
    },
    twoWeeks: {
        label: "2 Week",
        value: "14-days",
    },
    thisWeek: {
        label: "This Week",
        value: "this-week",
    },
    lastWeek: {
        label: "Last Week",
        value: "last-week",
    },
    oneMonth: {
        label: "1 Month",
        value: "30-days",
    },
    threeMonths: {
        label: "3 Months",
        value: "90-days",
    },
    thisMonth: {
        label: "This Month",
        value: "this-month",
    },
    past3Months: {
        label: "Past 3 Months",
        value: "past-3-months",
    },
};

export const DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const SHORT_DAYS = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const SHORT_MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

/* --- */

const nth = (d) => {
    if (d > 3 && d < 21) {
        return "th";
    }

    switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
};

export const newDate = (dateStr) => {
    const date = new Date(dateStr);

    if (date.toString() !== "Invalid Date") {
        return date;
    }

    const date2 = new Date(dateStr.replace(/-/g, "/"));

    if (date2.toString() !== "Invalid Date") {
        return date2;
    }

    return null;
};

export const newDateUTC = (dateStr) => {
    const d = newDate(dateStr);

    if (!d) {
        return null;
    }

    return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
};

/* --- */

const getDiffSeconds = (dateStart, dateEnd) => {
    const time = Math.abs(dateEnd.getTime() - dateStart.getTime());
    return Math.round(time / 1000);
};

/* --- */

export const formatDay = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();

    return `${DAYS[dayOfWeek]}`;
};

export const formatDayDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const day = date.getDate();

    return `${DAYS[dayOfWeek]}, ${MONTHS[month]} ${day}`;
};

export const formatDayShortMonthDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const day = date.getDate();

    return `${SHORT_DAYS[dayOfWeek]}, ${SHORT_MONTHS[month]} ${day}`;
};

export const formatDayMonthYearDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${DAYS[dayOfWeek]}, ${MONTHS[month]} ${day}, ${year}`;
};

export const formatMonthDayYearDate = (date) => {
    if (!date) {
        return "";
    }

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${MONTHS[month]} ${day}, ${year}`;
};

export const formatMonthDayDate = (date, withEnding = true) => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();
    let day = date.getDate();

    if (withEnding) {
        day = `${day}${nth(day)}`;
    }

    return `${MONTHS[month]} ${day}`;
};

export const tryFormatDate = (dateStr, formatFn) => {
    const date = newDate(dateStr);

    if (!date) {
        return "";
    }

    return formatFn(date);
};

// TODO: rename to format
export const getDateFromDate = (date, separator = "-") => {
    let today = newDate(date);

    if (!today) {
        return "";
    }

    const dd = String(today.getDate()).padStart(2, "0"); // eslint-disable-line compat/compat
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // eslint-disable-line compat/compat
    const yyyy = today.getFullYear();

    today = `${yyyy}${separator}${mm}${separator}${dd}`;

    return today;
};

/* --- */

const stringToDayDate = (date) => {
    return (date || "").split(" ")[0] || "";
};

export const isToday = (date) => {
    const now = new Date();

    return now.getDate() === date.getDate()
        && now.getMonth() === date.getMonth()
        && now.getYear() === date.getYear();
};

export const getStartOfWeekDate = (date) => {
    const curr = newDate(date);
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay()));

    return firstDayOfWeek;
};

export const getStartOfMonthDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getShortMonth = (date) => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();

    return `${SHORT_MONTHS[month]}`;
};

export const getDatesByRange = (date, range) => {
    let dateFrom = newDate(date);
    let dateTo = newDate(date);

    if (range === DATES.yesterday.value) {
        dateFrom.setDate(dateFrom.getDate() - 1);
        dateTo.setDate(dateTo.getDate() - 1);
    } else if (range === DATES.thisWeek.value) {
        dateFrom = new Date(getStartOfWeekDate(dateFrom));
        dateFrom.setDate(dateFrom.getDate() + 1);
    } else if (range === DATES.lastWeek.value) {
        dateFrom = new Date(getStartOfWeekDate(dateFrom));
        dateFrom.setDate(dateFrom.getDate() - 6);
        dateTo = new Date(dateFrom);
        dateTo.setDate(dateTo.getDate() + 6);
    } else if (range === DATES.thisMonth.value) {
        dateFrom = new Date(getStartOfMonthDate(dateFrom));
    } else if (range === DATES.past3Months.value) {
        dateFrom.setMonth(dateTo.getMonth() - 3);
    } else if (range === DATES.oneWeek.value) {
        dateFrom.setDate(dateFrom.getDate() - 7);
    } else if (range === DATES.twoWeeks.value) {
        dateFrom.setDate(dateFrom.getDate() - 14);
    } else if (range === DATES.oneMonth.value) {
        dateFrom.setDate(dateFrom.getDate() - 30);
    } else if (range === DATES.threeMonths.value) {
        dateFrom.setDate(dateFrom.getDate() - 90);
    } else if (range === "all") {
        dateFrom = "all";
        dateTo = "";
    }

    return {
        dateFrom,
        dateTo,
    };
};

export default {
    DATES,

    newDate,

    getDiffSeconds,

    tryFormatDate,
    formatDayDate,
    formatDayShortMonthDate,
    getDateFromDate,

    stringToDayDate,

    getDatesByRange,
};
