import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";

import Content from "./content.js";

import styles from "./styles.module.css";


const MenuWithContent = (props) => {
    const [currentMenuItem, setCurrentMenuItem] = useState(props.defaultSelected);

    const getMenu = () => {
        if (props.children) {
            if (Array.isArray(props.children)) {
                return props.children.map((child) => {
                    return child.props.name;
                });
            }

            return [props.children.props.name];
        }

        return [];
    };

    const onChangeItem = (index) => {
        setCurrentMenuItem(index);

        props.onMenuClick();
    };

    /* --- */

    const renderMenuItems = () => {
        const menu = getMenu();

        return menu.map((name, i) => {
            const itemClassName = classNames({
                [styles.menuItem]: true,
                [styles.menuItemSelected]: i === currentMenuItem && !props.isMobile,
            });

            return (
                <div
                    className={itemClassName}
                    onClick={() => { onChangeItem(i); }}
                    onKeyPress={() => { onChangeItem(i); }}
                    role="button"
                    tabIndex="-1"
                >
                    <div>{name}</div>
                    <img
                        src={staticFiles.arrowRight}
                        alt="Menu"
                    />
                </div>
            );
        });
    };

    const renderContent = () => {
        if (props.children && Array.isArray(props.children)) {
            return props.children[currentMenuItem];
        }

        return props.children;
    };

    if (props.isMobile) {
        const sliderStyles = {};

        if (!props.isMobileMenuVisible) {
            sliderStyles.transform = "translateX(-100vw)";
        }

        const mobileContentClassName = [
            juiceScrollbarStyles.scrollbar,
            styles.contentMobile,
        ].join(" ");

        return (
            <div className={styles.menuWithContentMobile}>
                <div
                    className={styles.slider}
                    style={sliderStyles}
                >
                    <div className={styles.menuMobile}>
                        {renderMenuItems()}
                    </div>
                    <div className={mobileContentClassName}>
                        {renderContent()}
                    </div>
                </div>
            </div>
        );
    }

    const contentClassName = [
        juiceScrollbarStyles.scrollbar,
        styles.content,
    ].join(" ");

    return (
        <div className={styles.menuWithContent}>
            <div className={styles.menu}>
                {renderMenuItems()}
            </div>
            <div className={contentClassName}>
                {renderContent()}
            </div>
        </div>
    );
};

MenuWithContent.defaultProps = {
    onMenuClick: () => {},
    defaultSelected: 0,
    isMobile: false,
    isMobileMenuVisible: true,
    children: null,
};

export const MenuContent = Content;
export default MenuWithContent;
