import React, { useEffect, useRef, useState } from "react";

import staticFiles from "juice-base/static-files.js";

import JuiceContent from "juice-base/components/juice-content/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";
import Video from "juice-base/components/video/index.js";
import ImageWithZoom from "juice-base/components/image-with-zoom/index.js";
import ClassroomShareButton from "juice-base/components/google-classroom-share-button/index.js";

import styles from "./styles.module.css";


const PopupJuiceStorySearch = (props) => {
    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    const popupRef = useRef(null);

    useEffect(() => {
        if (props.isExtraJuiceView && popupRef && popupRef.current) {
            popupRef.current.scrollTo(0, 0);
        }
    }, [props.isExtraJuiceView]);

    const { story } = props;

    if (!story) {
        return null;
    }

    const onFullscreenImageClick = () => {
        props.onFullscreenImageClick();
    };

    const onListenStory = () => {
        props.onAudioPlay(story);
    };

    const onBackToStory = () => {
        if (props.isInfographicView) {
            props.onCloseInfographic();
        } else if (props.isExtraJuiceView) {
            props.onCloseExtraJuice();
        }
    };

    const renderFeaturedImageOrVideo = () => {
        if (props.isExtraJuiceView || props.isInfographicView) {
            return null;
        }

        if (story.video) {
            return (
                <Video
                    videoId={story.video.id}
                    captionSrc={story.video.captionSrc}
                    src={story.video.url}
                    poster={story.video.poster}
                    type={story.video.mimeType}
                    videoHeight={410}
                    withPosterCover={false}
                    isDefault={props.isDefaultVideo}
                />
            );
        }

        if (story.featuredImage && story.featuredImage.src) {
            const imageClasses = [styles.featuredImage];
            let enlargeButton = null;

            if (story.featuredImage.infographic) {
                imageClasses.push(styles.featuredImageInfographic);
                enlargeButton = (
                    <img
                        src={staticFiles.iconFullscreenOrange}
                        className={styles.enlargeButton}
                        alt="Enlarge"
                    />
                );
            }

            return (
                <div
                    className={imageClasses.join(" ")}
                    onClick={onFullscreenImageClick}
                    onKeyPress={onFullscreenImageClick}
                    tabIndex="-1"
                    role="button"
                >
                    <div className={styles.featuredImageContainer}>
                        <img
                            className={styles.containerFeaturedImage}
                            src={story.featuredImage.src}
                            alt="Featured"
                        />
                        {enlargeButton}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderBackArrow = () => {
        if (props.isExtraJuiceView || props.isInfographicView) {
            return (
                <div
                    className={styles.headerBackToStory}
                    role="button"
                    tabIndex="-1"
                    onClick={onBackToStory}
                    onKeyPress={onBackToStory}
                >
                    <img
                        src={staticFiles.boldArrow}
                        alt="Back to story"
                    />
                </div>
            );
        }

        return (<div />);
    };

    const renderListenButton = () => {
        if (story.type !== "video") {
            return (
                <div
                    className={styles.listenButton}
                    tabIndex="-1"
                    role="button"
                    onKeyPress={onListenStory}
                    onClick={onListenStory}
                >
                    <img
                        src={staticFiles.iconPlayerHeadphone}
                        alt="Headphone"
                        title="Headphone"
                    />
                </div>
            );
        }

        return null;
    };

    const renderShareButton = () => {
        let title = "";
        let shareUrl = "";

        if (props.isExtraJuiceView) {
            title = props.extraJuice.title;
            shareUrl = `/extra-juice/${props.extraJuice.id}`;
        } else if (story.type === "extra juice") {
            title = props.story.title;
            shareUrl = `/extra-juice/${story.storyOrExtraJuiceId}`;
        } else {
            title = props.story.title;
            shareUrl = `/story/${story.storyOrExtraJuiceId}`;
        }

        return (
            <ClassroomShareButton
                key={shareUrl}
                title={title}
                url={shareUrl}
            />
        );
    };

    const renderStoryOrExtraJuiceAudioPlayer = () => {
        if (!props.audio) {
            return null;
        }

        let playerImgSrc = null;

        if (story.featuredImage && story.featuredImage.src) {
            playerImgSrc = story.featuredImage.src;
        }

        return (
            <PlayerAudio
                key={`player-audio-story-or-extra-juice-${props.trackId}`}
                image={playerImgSrc}
                title={story.title}
                category={story.category}
                audio={props.audio}
                isExpanded={isPlayerExpanded}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                whitePlayer
                roundedPlayer
                onExpand={() => {
                    setIsPlayerExpanded((prev) => !prev);
                }}
                onClose={props.onClosePlayer}
            />
        );
    };

    const renderContentCategory = () => {
        if (props.isExtraJuiceView || props.isInfographicView) {
            return null;
        }

        let categoryName = "";

        if (story.type === "extra juice") {
            categoryName = "Extra Juice";
        }

        if (story.category) {
            categoryName = story.category;
        }

        return (
            <div className={styles.contentCategory}>
                {categoryName}
            </div>
        );
    };

    const renderHeaderTitle = () => {
        let headerTitle = story.title;

        if ((props.isExtraJuiceView && props.extraJuice)
            || story.type === "extra juice") {
            let ejTitle = "";

            if (story.type === "extra juice") {
                ejTitle = story.title || "";
            } else if (props.isExtraJuiceView && props.extraJuice) {
                ejTitle = props.extraJuice.title || "";
            }

            headerTitle = (
                <div className={styles.extraJuiceTitle}>
                    <img
                        src={staticFiles.iconOrange}
                        alt="Extra Juice"
                    />
                    <div className={styles.headerTitle}>
                        {`Extra Juice: ${ejTitle}`}
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.headerTitle}>
                {headerTitle}
            </div>
        );
    };

    const renderContentTitle = () => {
        if (props.isExtraJuiceView || props.isInfographicView) {
            return null;
        }

        return (
            <div className={styles.contentTitle}>
                {story.title}
            </div>
        );
    };

    const renderInfographicView = () => {
        if (!props.isInfographicView || !props.infographic) {
            return null;
        }

        const containerStyle = {};
        const imageStyle = {};

        if (popupRef
            && popupRef.current
            && popupRef.current.clientHeight
            && popupRef.current.clientWidth) {
            containerStyle.minHeight = popupRef.current.clientHeight;
            containerStyle.minWidth = popupRef.current.clientWidth;
            imageStyle.maxHeight = popupRef.current.clientHeight - 72;
        }

        return (
            <ImageWithZoom
                src={props.infographic.url}
                title={props.infographic.title}
                containerStyle={containerStyle}
                imageStyle={imageStyle}
                containerClassName={styles.infographicContainer}
                className={styles.infographicImage}
            />
        );
    };

    const renderJuiceContent = () => {
        if (props.isInfographicView) {
            return null;
        }

        let juiceContent = story.content;

        if (props.isExtraJuiceView && props?.extraJuice?.content) {
            juiceContent = props.extraJuice.content;
        }

        return (
            <JuiceContent
                content={juiceContent}
                onWordClick={props.onWordClick}
                onExtraJuiceWordClick={props.onExtraJuiceWordClick}
            />
        );
    };

    const contentClassName = props.isInfographicView ? "" : styles.content;

    const rightControl = [
        renderListenButton(),
    ];

    if (props.withShareButton) {
        rightControl.push(renderShareButton());
    }

    return (
        <PopupWindow
            title={renderHeaderTitle()}
            leftControl={[renderBackArrow()]}
            rightControl={rightControl}
            player={renderStoryOrExtraJuiceAudioPlayer()}
            playerExpanded={isPlayerExpanded}
            onClose={props.onClose}
        >
            <div
                ref={popupRef}
                className={styles.popup}
                data-comment="popup-juice-story-search"
            >
                <div className={styles.popupContent}>
                    {renderFeaturedImageOrVideo()}
                    {renderInfographicView()}

                    <div className={contentClassName}>
                        {renderContentCategory()}

                        {renderContentTitle()}

                        {renderJuiceContent()}
                    </div>
                </div>
            </div>
        </PopupWindow>
    );
};

PopupJuiceStorySearch.defaultProps = {
    story: null,
    extraJuice: null,
    infographic: null,
    audio: {},
    trackId: null,
    isDefaultVideo: true,
    isExtraJuiceView: false,
    isInfographicView: false,
    withShareButton: false,
    onAudioPlay: () => {},
    onWordClick: () => {},
    onExtraJuiceWordClick: () => {},
    onFullscreenImageClick: () => {},
    onFirstPlay: () => {},
    onPlay: () => {},
    onPause: () => {},
    onRewind: () => {},
    onForward: () => {},
    onChangeRate: () => {},
    onClosePlayer: () => {},
    onCloseExtraJuice: () => {},
    onCloseInfographic: () => {},
    onClose: () => {},
};

export default PopupJuiceStorySearch;
