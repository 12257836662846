// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a5v_lK5LJwMRC5dVnqTL {\n    margin: 2rem;\n\n    display: grid;\n    justify-content: center;\n\n    color: var(--grey);\n    font-size: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/footer/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,aAAa;IACb,uBAAuB;;IAEvB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".footer {\n    margin: 2rem;\n\n    display: grid;\n    justify-content: center;\n\n    color: var(--grey);\n    font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "a5v_lK5LJwMRC5dVnqTL"
};
export default ___CSS_LOADER_EXPORT___;
