import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import LogoRound from "juice-base/components/logo-round/index.js";
import Footer from "juice-base/components/footer/index.js";

import UserSignUpTrialRoles from "juice-base/forms/user-sign-up-trial-roles/index.js";

import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const SignUpTrialRoles = () => {
    const history = useHistory();
    const params = useParams();

    const onSubmit = (values) => {
        if (values.role === "teacher") {
            let refCode = "";

            if (params?.referralCode) {
                refCode = params.referralCode;
            }

            history.push(`/sign-up/trial/${refCode}`);
        } else {
            window.open(settings.signUpLink);
        }
    };

    const renderForm = () => {
        const initialValues = {
            role: "",
        };

        return (
            <UserSignUpTrialRoles
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        );
    };

    return (
        <div className={styles.signUp}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        Let&apos;s get you set up!
                    </div>
                    <div className={styles.formName}>
                        Whats your role?
                    </div>
                </div>

                {renderForm()}

                <div className={styles.links}>
                    <Link to="/" className={styles.link}>
                        Already have an account? Sign in
                    </Link>
                </div>

                <div className={styles.footer}>
                    <Footer
                        copyright={settings.copyright}
                    />
                </div>
            </div>
        </div>
    );
};

export default SignUpTrialRoles;
