import * as types from "./types.js";


const initialState = {
    juiceStories: {},
    extraJuices: {},
    words: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.T2S_SET_JUICE_STORY_LOADING: {
            const { juiceStories } = state;
            const { storyId } = action.payload;
            const juiceStory = juiceStories[storyId] || {};

            return {
                ...state,
                juiceStories: {
                    ...juiceStories,
                    [storyId]: {
                        ...juiceStory,
                        isLoading: true,
                    },
                },
            };
        }

        case types.T2S_SET_JUICE_STORY: {
            const { juiceStories } = state;
            const { juiceId, storyId, audioFiles } = action.payload;
            const juiceStory = juiceStories[storyId] || {};

            return {
                ...state,
                juiceStories: {
                    ...juiceStories,
                    [storyId]: {
                        ...juiceStory,
                        isLoading: false,
                        juiceId: parseInt(juiceId, 10),
                        storyId,
                        audioFiles,
                    },
                },
            };
        }

        case types.T2S_SET_EXTRA_JUICE_LOADING: {
            const { extraJuices } = state;
            const { extraJuiceId } = action.payload;
            const juice = extraJuices[extraJuiceId] || {};

            return {
                ...state,
                extraJuices: {
                    ...extraJuices,
                    [extraJuiceId]: {
                        ...juice,
                        isLoading: true,
                    },
                },
            };
        }

        case types.T2S_SET_EXTRA_JUICE: {
            const { extraJuices } = state;
            const { juiceId, extraJuiceId, audioFiles } = action.payload;
            const juice = extraJuices[extraJuiceId] || {};

            return {
                ...state,
                extraJuices: {
                    ...extraJuices,
                    [extraJuiceId]: {
                        ...juice,
                        isLoading: false,
                        juiceId: parseInt(juiceId, 10),
                        extraJuiceId,
                        audioFiles,
                    },
                },
            };
        }

        case types.T2S_SET_WORD_LOADING: {
            const { words } = state;
            const { text } = action.payload;
            const word = words[text] || {};

            return {
                ...state,
                words: {
                    ...words,
                    [text]: {
                        ...word,
                        isLoading: true,
                    },
                },
            };
        }

        case types.T2S_SET_WORD: {
            const { words } = state;
            const { text, audioFiles } = action.payload;
            const word = words[text] || {};

            return {
                ...state,
                words: {
                    ...words,
                    [text]: {
                        ...word,
                        isLoading: false,
                        audioFiles,
                    },
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
