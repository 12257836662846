import * as types from "./types.js";


export const setJuiceStoryLoading = (payload) => ({
    type: types.T2S_SET_JUICE_STORY_LOADING,
    payload,
});

export const setJuiceStory = (payload) => ({
    type: types.T2S_SET_JUICE_STORY,
    payload,
});

export const setExtraJuiceLoading = (payload) => ({
    type: types.T2S_SET_EXTRA_JUICE_LOADING,
    payload,
});

export const setExtraJuice = (payload) => ({
    type: types.T2S_SET_EXTRA_JUICE,
    payload,
});

export const setWordLoading = (payload) => ({
    type: types.T2S_SET_WORD_LOADING,
    payload,
});

export const setWord = (payload) => ({
    type: types.T2S_SET_WORD,
    payload,
});
