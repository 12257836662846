import React from "react";

import classNames from "juice-base/lib/class-names.js";
import SliderRange from "juice-base/components/slider-range/index.js";

import styles from "./styles.module.css";


const ProgressValue = (props) => {
    let subtitle = null;

    if (props.subtitle) {
        subtitle = (
            <div className={styles.subtitle}>{props.subtitle}</div>
        );
    }

    const infoClassName = classNames({
        [styles.info]: true,
        [styles.infoWithSubtitle]: props.subtitle,
    });

    return (
        <div className={styles.progressValue}>
            <div className={infoClassName}>
                <div className={styles.title}>
                    {props.title}
                </div>
                {subtitle}
                <div className={styles.value}>
                    {`${props.value}%`}
                </div>
            </div>
            <SliderRange
                min={0}
                max={props.value}
                noHandlers
            />
        </div>
    );
};

ProgressValue.defaultProps = {
    title: "Title",
    subtitle: "",
    value: 0,
};


export default ProgressValue;
