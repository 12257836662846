import React from "react";

import classNames from "juice-base/lib/class-names.js";

import SliderRange from "juice-base/components/slider-range/index.js";

import styles from "./styles.module.css";


const SectionRange = (props) => {
    const sectionClassName = classNames({
        [styles.section]: true,
        [styles.sectionBorderTop]: props.borderTop,
        [styles.sectionNoBorders]: props.noBorder,
        [styles.sectionNoPadding]: props.noPadding,
    });

    const min = props.min || 0;
    const max = props.max || 0;

    let labelPercents = `${min}% to ${max}%`;

    if (props.noMinHandle || props.noHandlers) {
        labelPercents = `${max}%`;
    }

    let bottomData = (
        <div className={styles.minMax}>
            <div>0</div>
            <div>100</div>
        </div>
    );

    if (props.noBottomData) {
        bottomData = null;
    }

    return (
        <div className={sectionClassName}>
            <div className={styles.labelSection}>
                <div>{props.label}</div>
                <div>{labelPercents}</div>
            </div>
            <div>
                <SliderRange
                    min={min}
                    max={max}
                    noMinHandle={props.noMinHandle}
                    noHandlers={props.noHandlers}
                />
            </div>
            {bottomData}
        </div>
    );
};

SectionRange.defaultProps = {
    label: "",
    min: 20,
    max: 100,

    noMinHandle: false,
    noBottomData: false,
    noHandlers: false,

    borderTop: true,
    noBorder: false,
    noPadding: false,
};

export default SectionRange;
