import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import api from "juice-app/api.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Text from "juice-base/components/text/index.js";

import { setTemplateBySlug } from "juice-base/store/templates/actions.js";

import styles from "./styles.module.css";


const Page = () => {
    const dispatch = useDispatch();

    const {
        templatesBySlug,
    } = useSelector((state) => ({
        templatesBySlug: state.templates.templatesBySlug,
    }));

    const { slug } = useParams();

    const [pageErrors, setPageError] = useState({
        error: null,
    });

    useEffect(() => {
        api.templates.getTemplate({ slug }).then((res) => {
            if (res.ok) {
                dispatch(setTemplateBySlug({
                    slug,
                    template: res.template,
                }));
            } else {
                setPageError({
                    error: res.error || "Failed to load page",
                });
            }
        });
    }, [slug]);

    const renderContent = () => {
        if (pageErrors.error) {
            return (
                <div className={styles.content}>
                    {pageErrors.error}
                </div>
            );
        }

        let content = null;

        if (templatesBySlug[slug].data && templatesBySlug[slug].data.content) {
            content = (
                <Text>
                    {templatesBySlug[slug].data.content}
                </Text>
            );
        }

        return (
            <div className={styles.content}>
                {content}
            </div>
        );
    };

    if (!templatesBySlug[slug] && !pageErrors.error) {
        return (
            <RequestLoader />
        );
    }

    return (
        <div className={styles.index}>
            {renderContent()}
        </div>
    );
};


export default Page;
