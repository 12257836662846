import * as types from "./types.js";


const initialState = {
    studentsById: {},
    quizPerformancesByStudentId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENT_BY_ID: {
            const { account, overview } = action.payload.student;
            const studentId = account.ID;

            return {
                ...state,
                studentsById: {
                    ...state.studentsById,
                    [studentId]: {
                        isLoaded: true,
                        account,
                        overview,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_LOADING_BY_ID: {
            const { studentId, range } = action.payload;

            const student = state.quizPerformancesByStudentId[studentId] || {};

            return {
                ...state,
                quizPerformancesByStudentId: {
                    ...state.quizPerformancesByStudentId,
                    [studentId]: {
                        ...student,
                        [range]: {
                            isLoading: true,
                            data: {},
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_BY_ID: {
            const { studentId, range, data } = action.payload;

            const student = state.quizPerformancesByStudentId[studentId] || {};

            return {
                ...state,
                quizPerformancesByStudentId: {
                    ...state.quizPerformancesByStudentId,
                    [studentId]: {
                        ...student,
                        [range]: {
                            isLoading: false,
                            data,
                        },
                    },
                },
            };
        }

        case types.CLEAR_TEACHER_STUDENT_QUIZ_PERFORMANCES: {
            return {
                ...state,
                quizPerformancesByStudentId: {},
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
