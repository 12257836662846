import React from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupResetPassword = (props) => {
    const renderMessages = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div>
                {props.message}
            </div>
        );
    };

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {renderMessages()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.isLoading}
                    onClick={props.onClose}
                >
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupResetPassword.defaultProps = {
    isLoading: false,
    message: "",
    onClose: () => {},
};

export default PopupResetPassword;
