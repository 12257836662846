const keys = {
    session: "session",
};

export const saveSession = (sessionId) => {
    if (window.sessionStorage) {
        window.sessionStorage.setItem(keys.session, sessionId);
    }
};

export const loadSession = () => {
    if (window.sessionStorage) {
        return sessionStorage.getItem(keys.session) || "";
    }

    return "";
};
