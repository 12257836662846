import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

import styles from "./styles.module.css";


const ChartPie = (props) => {
    const chartRef = useRef();

    const initChart = (elem, dataset) => {
        if (!elem) {
            return;
        }

        const svg = d3.select(elem);

        const radius = Math.min(props.width, props.height) / 2;
        const color = d3.scaleOrdinal(props.colors);

        svg
            .attr("width", props.width)
            .attr("height", props.height);

        const g = svg
            .append("g")
            .attr("transform", `translate(${props.width / 2}, ${props.height / 2})`);

        const chartPie = d3.pie()
            .value((d) => d.value)
            .sort(null);

        const chartArc = d3.arc()
            .innerRadius(radius - 16)
            .outerRadius(radius);

        const chartArcs = g.selectAll("arc")
            .data(chartPie(dataset))
            .enter()
            .append("g")
            .attr("class", "arc");

        chartArcs.append("path")
            .attr("fill", (d, i) => color(i))
            .attr("d", chartArc);

        if (props.showDataInCircle) {
            let valueText = "";

            if (dataset.length > 0) {
                valueText = `${dataset[0].value || "0"}%`;
            }

            const valueX = props.width / 2;
            const valueY = props.height / 2 - 8;

            svg.append("g")
                .attr("transform", `translate(${valueX}, ${valueY})`)
                .append("text")
                .attr("text-anchor", "middle")
                .style("font-size", 40)
                .text(valueText);

            const labelX = props.width / 2;
            const labelFirstY = props.height / 2 + 17;

            svg.append("g")
                .attr("transform", `translate(${labelX}, ${labelFirstY})`)
                .append("text")
                .attr("text-anchor", "middle")
                .style("font-size", 14)
                .style("text-transform", "uppercase")
                .style("font-weight", "600")
                .text("completion");

            const labelSecondY = props.height / 2 + 37;

            svg.append("g")
                .attr("transform", `translate(${labelX}, ${labelSecondY})`)
                .append("text")
                .attr("text-anchor", "middle")
                .style("font-size", 14)
                .style("text-transform", "uppercase")
                .style("font-weight", "600")
                .text("rate");
        }
    };

    useEffect(() => {
        initChart(chartRef.current, props.data);
    }, []);

    const renderLegend = () => {
        const color = d3.scaleOrdinal(props.colors);

        const ds = props.data.map((datum, i) => {
            let val = `${datum.value}%`;

            if (datum.value === "") {
                val = "--";
            }

            return (
                <div className={styles.legendItem}>
                    <div
                        className={styles.legendColor}
                        style={{
                            backgroundColor: color(i),
                        }}
                    />
                    <div className={styles.legendLabel}>
                        {datum.label}
                    </div>
                    <div className={styles.legendValue}>
                        {val}
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.legend}>
                {ds}
            </div>
        );
    };

    return (
        <div className={styles.chartPie}>
            <div className={styles.chartContent}>
                <div className={styles.chart}>
                    <svg ref={chartRef} />
                </div>
                {renderLegend()}
            </div>
        </div>
    );
};

ChartPie.defaultProps = {
    width: 220,
    height: 220,
    colors: [
        "#10B981",
        "#F59E0B",
        "#F43F5E",
    ],
    showDataInCircle: true,
    data: [],
};

export default ChartPie;
