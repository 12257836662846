import { useState } from "react";


const getPopupDefaultState = () => ({
    isOpen: false,
    inProgress: false,
    totalLoaded: 0,
    students: [],
    currentLoading: {
        index: null,
        errorCode: -1,
    },
});

const useProgressPopupAddStudents = () => {
    const [state, setState] = useState(getPopupDefaultState());

    const open = () => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
        }));
    };

    const close = () => {
        setState(getPopupDefaultState());
    };

    const setInProgress = (inProgress) => {
        setState((prev) => ({
            ...prev,
            inProgress,
        }));
    };

    const setStudents = (params = {}) => {
        setState((prev) => ({
            ...prev,
            inProgress: true,
            students: params.students,
            currentLoading: {
                index: params.index,
                errorCode: -1,
            },
        }));
    };

    const setStudentsLoadedSuccessfully = (params = {}) => {
        setState((prev) => ({
            ...prev,
            students: params.students,
            totalLoaded: prev.totalLoaded + 1,
        }));
    };

    const setStudentsFailedLoaded = (params = {}) => {
        setState((prev) => ({
            ...prev,
            students: params.students,
            currentLoading: {
                ...prev.currentLoading,
                errorCode: params.errorCode,
            },
        }));
    };

    const setStudentsSkippedStatus = (params = {}) => {
        setState((prev) => ({
            ...prev,
            students: params.students,
            totalLoaded: prev.totalLoaded + 1,
            currentLoading: {
                ...prev.currentLoading,
                errorCode: -1,
            },
        }));
    };

    return {
        state,
        open,
        close,
        setStudents,
        setInProgress,
        setStudentsLoadedSuccessfully,
        setStudentsFailedLoaded,
        setStudentsSkippedStatus,
    };
};

export default useProgressPopupAddStudents;
