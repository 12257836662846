import React from "react";

import { Formik } from "formik";

import Input from "juice-base/components/forms/input-custom/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Select from "juice-base/components/forms/select/index.js";
import SelectCreatable from "juice-base/components/forms/select-creatable/index.js";

import * as v from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";


const TeacherClassSetUpForm = (props) => {
    const validateForm = (values) => {
        const errorsClassName = v.validate(values.className, [
            v.required("Please enter class name"),
        ]);

        const errors = {};

        if (errorsClassName) {
            errors.className = errorsClassName;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.fields}>
                    <SelectCreatable
                        name="department"
                        placeholder="Department *"
                        values={props.departmentsOptions}
                        onChange={(dValues) => {
                            const value = dValues.value || dValues.label;

                            setFieldValue("department", value);
                        }}
                    />

                    <Input
                        name="className"
                        label="Class Name *"
                        placeholder="Class Name *"
                        value={values.className}
                        error={errors.className}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Select
                        name="grades"
                        values={props.grades}
                        defaultValueLabel="Default Reading Level *"
                        isShort
                        onChange={(dValues) => {
                            setFieldValue("grades", dValues.value);
                        }}
                    />
                </div>

                <div className={styles.submitButton}>
                    <ButtonBig
                        disabled={!values.department
                            || !values.className
                            || !values.grades
                            || isSubmitting}
                        type="submit"
                    >
                        Finish
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

TeacherClassSetUpForm.defaultProps = {
    initialValues: {},

    grades: [],
    departmentsOptions: [],

    onSubmit: () => {},
};

export default TeacherClassSetUpForm;
