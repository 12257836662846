// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NtMHRpvghUm3nUmtMA88 {\n    display: grid;\n    justify-content: center;\n\n    position: relative;\n\n    min-height: 50rem;\n    padding: 1rem;\n\n    overflow: auto;\n}\n\n.kzi9wy_S_LwZ32T9Ak5x {\n    position: absolute;\n    bottom: 1rem;\n    right: 1rem;\n}", "",{"version":3,"sources":["webpack://./base/components/popup-date-range-custom/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;;IAEvB,kBAAkB;;IAElB,iBAAiB;IACjB,aAAa;;IAEb,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".range {\n    display: grid;\n    justify-content: center;\n\n    position: relative;\n\n    min-height: 50rem;\n    padding: 1rem;\n\n    overflow: auto;\n}\n\n.applyButton {\n    position: absolute;\n    bottom: 1rem;\n    right: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"range": "NtMHRpvghUm3nUmtMA88",
	"applyButton": "kzi9wy_S_LwZ32T9Ak5x"
};
export default ___CSS_LOADER_EXPORT___;
