import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const MessageDanger = (props) => {
    const imgTitle = "Pay attention!";

    return (
        <div className={styles.message}>
            <img
                src={staticFiles.iconDanger}
                alt={imgTitle}
                title={imgTitle}
            />
            {props.children}
        </div>
    );
};

MessageDanger.defaultProps = {
    children: null,
};

export default MessageDanger;
