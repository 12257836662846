import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const ImageWithZoom = (props) => {
    const {
        src,
        title,
        buttonZoomLevel,
    } = props;

    const renderComponent = ({ zoomIn, zoomOut }) => {
        const imgClassName = [
            props.className,
            styles.imageWithZoom,
        ].join(" ");

        const toolsClassName = [
            styles.tools,
            props.toolsClassName,
        ].join(" ");

        return (
            <div
                style={props.containerStyle}
                className={props.containerClassName}
            >
                <TransformComponent>
                    <img
                        src={src}
                        alt={title}
                        title={title}
                        style={props.imageStyle}
                        className={imgClassName}
                    />
                </TransformComponent>

                <div className={toolsClassName}>
                    <button type="button" onClick={() => { zoomIn(); }}>
                        <img
                            src={staticFiles.zoomIn}
                            alt="Zoom in"
                            title="Zoom in"
                        />
                    </button>
                    <button type="button" onClick={() => { zoomOut(); }}>
                        <img
                            src={staticFiles.zoomOut}
                            alt="Zoom out"
                            title="Zoom out"
                        />
                    </button>
                </div>
            </div>
        );
    };

    return (
        <TransformWrapper
            zoomIn={{
                step: buttonZoomLevel,
            }}
            zoomOut={{
                step: buttonZoomLevel,
            }}
        >
            {renderComponent}
        </TransformWrapper>
    );
};

ImageWithZoom.defaultProps = {
    src: "",
    title: "",
    className: "",
    toolsClassName: "",
    containerStyle: {},
    imageStyle: {},
    containerClassName: "",
    buttonZoomLevel: 10,
};

export default ImageWithZoom;
