// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LLUBEgFMVX9jiNHdxMQv {}\n\n._WyVoXtpRfTLvvEGP26D {\n    margin-top: 2.5rem;\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-sign-up-class-code/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".form {}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "LLUBEgFMVX9jiNHdxMQv",
	"submitButtonBlock": "_WyVoXtpRfTLvvEGP26D"
};
export default ___CSS_LOADER_EXPORT___;
