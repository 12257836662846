import React, { useEffect, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";
import scroll from "juice-base/lib/scroll.js";
import Close from "juice-base/components/close/index.js";

import styles from "./styles.module.css";


const PopupCommon = (props) => {
    const bgLayout = useRef(null);

    useEffect(() => {
        if (props.hideBodyScrollOnOpen) {
            scroll.hideBodyScroll();
        }

        return () => {
            if (props.hideBodyScrollOnOpen) {
                scroll.unhideBodyScroll();
            }
        };
    }, [props.hideBodyScrollOnOpen]);

    const onCrossClose = () => {
        if (props.hideBodyScrollOnOpen) {
            scroll.unhideBodyScroll();
        }

        props.onClose();
    };

    const onClose = (evt) => {
        if (props.closeOnLayoutClick && bgLayout && bgLayout.current === evt.target) {
            scroll.unhideBodyScroll();
            props.onClose();
        }
    };

    let close = null;

    if (props.showClose) {
        close = (
            <Close
                className={styles.popupClose}
                onClose={onCrossClose}
            />
        );
    }

    const popupClassName = classNames({
        [styles.popup]: true,
        [props.className]: props.className,
    });

    return (
        <div
            data-comment="popup-common"
            className={popupClassName}
            ref={bgLayout}
            onClick={onClose}
            onKeyPress={onClose}
            role="button"
            tabIndex={props.disableTabIndex ? "-1" : ""}
        >
            {close}
            {props.children}
        </div>
    );
};

PopupCommon.defaultProps = {
    className: "",
    showClose: true,
    children: null,
    closeOnLayoutClick: false,
    hideBodyScrollOnOpen: false,
    disableTabIndex: true,
    onClose: () => {},
};

export default PopupCommon;
