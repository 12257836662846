import React, { useState, useEffect } from "react";

import staticFiles from "juice-base/static-files.js";

import classNames from "juice-base/lib/class-names.js";
import scroll from "juice-base/lib/scroll.js";
import Close from "juice-base/components/close/index.js";

import styles from "./styles.module.css";


const PopupFullScreen = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        // NOTE: fix left-right content shift
        window.setTimeout(() => {
            scroll.hideBodyScroll();
        }, 300);

        return () => {
            scroll.unhideBodyScroll();
        };
    }, []);

    useEffect(() => {
        if (props.isArrowBackVisible) {
            props.onArrowBack(true);
        }
    }, [props.isMobile]);

    const onClose = () => {
        if (!isVisible) {
            return;
        }

        setIsVisible(false);

        scroll.unhideBodyScroll();

        window.setTimeout(() => {
            props.onClose();
        }, 300);
    };

    const onArrowBack = () => {
        props.onArrowBack(true);
    };

    /* --- */

    const renderBackArrow = () => {
        if (props.isMobile && props.isArrowBackVisible) {
            return (
                <div
                    className={styles.arrowBack}
                    onClick={onArrowBack}
                    onKeyPress={onArrowBack}
                    tabIndex="-1"
                    role="button"
                >
                    <img
                        src={staticFiles.boldArrow}
                        className={styles.arrowBackImg}
                        alt="Go back"
                    />
                </div>
            );
        }

        return null;
    };

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupVisible]: isVisible,
    });

    const headerClassName = classNames({
        [styles.header]: true,
        [styles.headerWithSubheader]: props.subheader,
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentWithSubheader]: props.subheader,
    });

    return (
        <div className={popupClassName}>
            <div className={headerClassName}>
                <div className={styles.headerMain}>
                    <div>
                        {renderBackArrow()}
                    </div>
                    <div className={styles.headerTitle}>
                        {props.title}
                    </div>
                    <Close
                        onClose={onClose}
                    />
                </div>
                {props.subheader}
            </div>
            <div className={contentClassName}>
                {props.children}
            </div>
        </div>
    );
};

PopupFullScreen.defaultProps = {
    title: "",
    subheader: null,
    children: null,
    isArrowBackVisible: false,
    isMobile: false,
    onArrowBack: () => {},
    onClose: () => {},
};

export default PopupFullScreen;
