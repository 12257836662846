import * as types from "./types.js";


const initialState = {
    countries: [],
    isCountriesLoaded: false,
    subdivisionsByAlpha2: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_COUNTRIES: {
            return {
                ...state,
                isCountriesLoaded: true,
                countries: action.payload.countries,
            };
        }

        case types.SET_SUBDIVISIONS_BY_APLHA2_LOADING: {
            return {
                ...state,
                subdivisionsByAlpha2: {
                    ...state.subdivisionsByAlpha2,
                    [action.payload.alpha2]: {
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_SUBDIVISIONS_BY_APLHA2: {
            return {
                ...state,
                subdivisionsByAlpha2: {
                    ...state.subdivisionsByAlpha2,
                    [action.payload.alpha2]: {
                        isLoaded: true,
                        subdivisionName: action.payload.subdivisionName,
                        subdivisions: action.payload.subdivisions,
                    },
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
