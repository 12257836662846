import React from "react";

import Skeleton from "juice-base/components/skeleton/index.js";

import styles from "./styles.module.css";


const SkeletonTabsSelector = () => {
    return (
        <div className={styles.skeletonDateRange}>
            <Skeleton variant="text" height={2} fullWidth />
            <Skeleton variant="text" height={2} fullWidth />
            <Skeleton variant="text" height={2} fullWidth />
            <Skeleton variant="text" height={2} fullWidth />
            <Skeleton variant="text" height={2} fullWidth />
        </div>
    );
};

SkeletonTabsSelector.defaultProps = {};

export default SkeletonTabsSelector;
