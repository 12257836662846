import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmError = (props) => {
    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {props.error}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose}>
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmError.defaultProps = {
    error: null,
    onClose: () => {},
};

export default PopupConfirmError;
