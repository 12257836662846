import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import storage from "juice-base/lib/storage/index.js";
import actions from "juice-base/store/actions.js";

import LogoRound from "juice-base/components/logo-round/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";
import Footer from "juice-base/components/footer/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import UserSignUpTrialCreateAccount from "juice-base/forms/user-sign-up-trial-create-account/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const UserSignUpTrial = () => {
    const [referralCodeState, setReferralCodeState] = useState({
        isLoaded: false,
        isActive: false,
        error: null,
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        if (!params?.referralCode) {
            setReferralCodeState({
                isLoaded: true,
                isActive: false,
                error: null,
            });
            return;
        }

        api.signup.validateReferralCode({
            code: params.referralCode,
        }).then((res) => {
            let isActive = false;

            let error = null;

            if (res.ok) {
                isActive = res.data.isActive;
            } else {
                error = res.error || "Error!";
            }

            setReferralCodeState({
                isLoaded: true,
                isActive,
                error,
            });
        });
    }, []);

    const onCreateAccount = (values, { setSubmitting, setErrors }) => {
        api.signup.signUpByReferralCode({
            ...values,
            code: params.referralCode,
        }).then((res) => {
            if (!res.ok) {
                setErrors({
                    password: res.error || "Error!",
                });
                return;
            }

            if (values.isRememberMe) {
                storage.local.saveSession(res.sessionId ?? "");
                storage.session.saveSession("");
            } else {
                storage.local.saveSession("");
                storage.session.saveSession(res.sessionId ?? "");
            }

            dispatch(actions.user.setUserSession(res.sessionId || ""));
            dispatch(actions.user.setUser(res.user));

            history.push("/");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const renderForm = () => {
        const initialValues = {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            isRememberMe: true,
        };

        return (
            <UserSignUpTrialCreateAccount
                initialValues={initialValues}
                onSubmit={onCreateAccount}
            />
        );
    };

    const renderContent = () => {
        if (!params?.referralCode) {
            return (
                <div className={styles.message}>
                    <MessageDanger>
                        Referral code is missing.
                    </MessageDanger>
                </div>
            );
        }

        if (!referralCodeState.isLoaded) {
            return (
                <div className={styles.content}>
                    <RequestLoader />
                </div>
            );
        }

        if (referralCodeState.error) {
            return (
                <div className={styles.message}>
                    <MessageDanger>
                        {referralCodeState.error}
                    </MessageDanger>
                </div>
            );
        }

        if (!referralCodeState.isActive) {
            return (
                <div className={styles.message}>
                    <MessageDanger>
                        {`Referral code ${params.referralCode} is not active!`}
                    </MessageDanger>
                </div>
            );
        }

        return (
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        Create an account
                    </div>
                </div>

                {renderForm()}

                <div className={styles.links}>
                    <Link to="/" className={styles.link}>
                        Already have an account? Sign in
                    </Link>
                </div>

                <div className={styles.footer}>
                    <Footer
                        copyright={settings.copyright}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.signUp}>
            {renderContent()}
        </div>
    );
};

export default UserSignUpTrial;
