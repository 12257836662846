import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { formatDayDate, tryFormatDate } from "juice-base/lib/date.js";

import { withAuth } from "juice-base/components/auth/index.js";

import PopupQuizReview from "juice-base/business/popup-quiz-review/index.js";

import DailyJuiceTodoList from "juice-base/business/daily-juice-todo-list/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import actions from "juice-base/store/actions.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const StudentDailyJuicesRegular = () => {
    const [quizReviewPopup, setQuizReviewPopup] = useState({
        isOpen: false,
        isLoaded: false,
        quizzes: [],
    });

    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        session: state.user.session,
        juices: state.juices,
        studentTodo: state.studentTodo,
    }));

    const loadJuices = (page = 0) => {
        dispatch(actions.juices.setJuicesPage({
            juicesPage: page,
        }));

        dispatch(actions.juices.setJuicesLoading({
            isJuicesLoading: true,
        }));

        api.dailyJuices.getJuicesByPage({
            session: store.session,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setJuices({
                    juices: store.juices.juices.concat(res.juices),
                    hasMorePages: res.hasMore,
                }));
            }
        });
    };

    const loadIncompletedJuices = (page = 0) => {
        dispatch(actions.studentTodo.setIncompletedJuicesLoading());

        api.dailyJuices.getIncompletedJuices({
            session: store.session,
            page,
        }).then((res) => {
            let incompletedJuices = [];

            if (res.ok) {
                incompletedJuices = [
                    ...store.studentTodo.incompletedJuices,
                    ...res.juices,
                ];
            }

            // TODO: incorrect store usage
            dispatch(actions.studentTodo.setIncompletedJuices({
                hasMorePages: res.ok ? res.hasMore : false,
                incompletedJuices,
                page,
            }));
        });
    };

    useEffect(() => {
        if (!store.studentTodo.isIncompletedJuicesLoaded) {
            loadIncompletedJuices();
        }

        if (!store.juices.isJuicesLoaded) {
            loadJuices();
        }
    }, []);

    const onCloseQuizReview = () => {
        setQuizReviewPopup({
            isOpen: false,
            isLoaded: false,
            quizzes: [],
        });
    };

    const loadQuizzesByJuiceId = (juiceId, quizId) => {
        setQuizReviewPopup({
            isOpen: true,
            isLoaded: false,
            quizzes: [],
        });

        api.quizzes.getQuizzesByJuiceId({
            session: store.session,
            juiceId,
            quizId,
        }).then((res) => {
            if (res.ok) {
                setQuizReviewPopup({
                    isOpen: true,
                    isLoaded: true,
                    quizzes: res.data,
                });
            }
        });
    };

    const renderTodoList = () => {
        const incompleteTodos = store.studentTodo.incompletedJuices.map((juice) => {
            return {
                date: tryFormatDate(juice.juiceDate, formatDayDate),
                title: "Quiz Incomplete",
                imgUrl: null,
                juiceUrl: `/daily-juice/${juice.id}`,
                showImage: false,
            };
        });

        const pastIssues = store.juices.juices.map((juice) => {
            let quizScore = "0%";
            let completedOn = "-";
            let completed = false;
            let quizId = null;
            let withoutQuizzes = true;

            if (juice?.quizResult) {
                quizScore = `${juice.quizResult.score || "0"}%`;
                completedOn = juice.quizResult.dateOfLastAnswer;
                completed = juice.quizResult.isQuizCompleted;
                quizId = juice.quizResult.quizId;
                withoutQuizzes = !juice.quizResult.juiceWithQuiz;
            }

            return {
                title: juice.title,
                juiceId: juice.id,
                dailyJuiceLink: `/daily-juice/${juice.id}`,
                quizId,
                quizScore,
                completed,
                completedOn,
                withoutQuizzes,
            };
        });

        return (
            <DailyJuiceTodoList
                incompleteTodos={incompleteTodos}
                pastIssues={pastIssues}
                isTodosLoading={store.studentTodo.isIncompletedJuicesLoading}
                isIssuesLoading={store.juices.isJuicesLoading}
                hasMoreIssuesPages={store.juices.juicesHasMorePages}
                hasMoreTodoPages={store.studentTodo.hasMorePages}
                onReviewClick={loadQuizzesByJuiceId}
                onLoadMoreTodos={() => {
                    loadIncompletedJuices(store.studentTodo.page + 1);
                }}
                onLoadMorePastIssues={() => {
                    loadJuices(store.juices.juicesPage + 1);
                }}
            />
        );
    };

    const renderQuizReviewPopup = () => {
        if (!quizReviewPopup.isOpen) {
            return null;
        }

        const quistions = quizReviewPopup.quizzes.map((quiz) => {
            return {
                question: quiz.question,
                answers: quiz.answers,
            };
        });

        return (
            <PopupQuizReview
                isLoaded={quizReviewPopup.isLoaded}
                quistions={quistions}
                onClose={onCloseQuizReview}
            />
        );
    };

    return (
        <>
            <Tutorial name="student-daily-juices" />

            {renderQuizReviewPopup()}

            <div className={styles.index}>
                {renderTodoList()}
                <UserFooter />
            </div>
        </>
    );
};

export default withAuth(["student"])(StudentDailyJuicesRegular);
