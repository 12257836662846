import React from "react";

import { withAuth } from "juice-base/components/auth/index.js";

import StudentDailyJuice from "juice-app/containers/student-daily-juice/index.js";


const DailyJuice = () => {
    return (
        <StudentDailyJuice />
    );
};

export default withAuth([
    "student",
    "guardian",
])(DailyJuice);
