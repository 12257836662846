import React, { useRef } from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Accordion = (props) => {
    const contentRef = useRef(null);

    const contentPaddingPx = 15;

    const caretClassName = classNames({
        [styles.caretImg]: true,
        [styles.caretImgDown]: props.expanded,
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentExpanded]: props.expanded,
    });

    const contentStyle = {};

    if (props.expanded) {
        let height = "100%";

        if (contentRef?.current?.offsetHeight && contentRef.current.offsetHeight !== 0) {
            height = contentRef.current.offsetHeight;
        } else if (contentRef?.current?.scrollHeight) {
            height = `${contentRef.current.scrollHeight + (contentPaddingPx * 2)}px`;
        }

        contentStyle.height = height;
        contentStyle.padding = `${contentPaddingPx}px`;
    }

    return (
        <div className={styles.accordion}>
            <div
                className={styles.accordionLabel}
                onClick={props.onClick}
                onKeyPress={props.onClick}
                tabIndex="-1"
                role="button"
            >
                <div>{props.label}</div>
                <div className={styles.caret}>
                    <img
                        className={caretClassName}
                        src={staticFiles.caret}
                        alt="Toggle"
                    />
                </div>
            </div>
            <div
                ref={contentRef}
                className={contentClassName}
                style={contentStyle}
            >
                {props.children}
            </div>
        </div>
    );
};

Accordion.defaultProps = {
    label: "",
    children: null,

    expanded: false,

    onClick: () => {},
};

export default Accordion;
