import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";
import User from "juice-base/project/user.js";
import CssIcon from "juice-base/components/css-icon/index.js";

import styles from "./styles.module.css";


const AsideNav = (props) => {
    const onSignOut = () => {
        props.onClose();
        props.onSignOut();
    };

    const renderLink = (to, linkName) => {
        return (
            <Link
                key={linkName}
                to={to}
                className={styles.section}
                onClick={props.onClose}
            >
                {linkName}
            </Link>
        );
    };

    const renderLinkWithCssIcon = (to, linkName, iconUrl, inNewTab = false) => {
        let icon = null;

        if (iconUrl) {
            icon = (
                <CssIcon
                    url={iconUrl}
                    name={linkName}
                />
            );
        }

        if (inNewTab) {
            return (
                <a
                    className={styles.sectionWithIcon}
                    href={to}
                    key={linkName}
                    target="_blank"
                    rel="noreferrer"
                >
                    {icon}
                    <div className={styles.sectionName}>
                        {linkName}
                    </div>
                </a>
            );
        }

        return (
            <Link
                key={linkName}
                to={to}
                className={styles.sectionWithIcon}
                onClick={props.onClose}
            >
                {icon}
                <div className={styles.sectionName}>
                    {linkName}
                </div>
            </Link>
        );
    };

    const renderSections = () => {
        if (!props.user.userId) {
            return renderLink("/", "Home");
        }

        const sections = [];

        User.getNavSections(props.user, props.sectionsByRoles).forEach((sec) => {
            sections.push(renderLink(sec.to, sec.name));
        });

        return (
            <>
                {renderLink("/", "Home")}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        const supportLink = renderLinkWithCssIcon(props.supportLink, "Support", staticFiles.iconQuestionMark, true);

        if (!props.user.userId) {
            return (
                <>
                    {renderLinkWithCssIcon("/user/sign-in", "Sign In", staticFiles.iconInfo)}
                    {supportLink}
                </>
            );
        }

        const signOutClassName = [
            styles.sectionWithIcon,
            styles.signout,
        ].join(" ");

        return (
            <>
                {renderLinkWithCssIcon("/user/profile", props.user.fullName, staticFiles.iconUser)}
                {supportLink}
                <div
                    className={signOutClassName}
                    onClick={onSignOut}
                    onKeyPress={onSignOut}
                    tabIndex="-1"
                    role="button"
                >
                    <CssIcon
                        url={staticFiles.iconLogOut}
                        name="Sign Out"
                    />
                    Sign Out
                </div>
            </>
        );
    };

    /* eslint-disable */
    const layout = (
        <div
            className={styles.asideLayout}
            onClick={props.onClose}
            onKeyPress={props.onClose}
            tabIndex="-1"
            role="button"
        />
    );
    /* eslint-enable */

    return (
        <div className={styles.asideNav}>
            {layout}
            <aside className={styles.aside}>
                <div className={styles.header}>
                    <CssIcon
                        url={staticFiles.iconClose}
                        name="Close"
                        className={styles.close}
                        onClick={props.onClose}
                    />
                    <div className={styles.headerName}>
                        <img
                            src={staticFiles.juiceHomeLogo}
                            alt={props.name}
                            title={props.name}
                            className={styles.logoImg}
                        />
                    </div>
                </div>

                <div className={styles.sections}>
                    <div className={styles.sectionGroup}>
                        {renderSections()}
                    </div>
                    <div className={styles.sectionGroup}>
                        {renderUser()}
                    </div>
                </div>

                <div className={styles.sysinfo}>
                    {`Build: ${props.version}`}
                </div>
            </aside>
        </div>
    );
};

AsideNav.defaultProps = {
    name: "",
    version: "",
    user: {},
    sectionsByRoles: {},
    supportLink: "",
    onSignOut: () => {},
    onClose: () => {},
};

export default AsideNav;
