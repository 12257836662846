// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kHGxgVKA8sGigcykB4jg {\n    margin: 0 2rem 2rem 2rem;\n\n    display: grid;\n    justify-content: center;\n}\n\n.Vplg86nZWI5Vbkgz9P83 {\n    display: grid;\n    grid-row-gap: 1rem;\n    justify-content: center;\n\n    padding: 3rem 3rem;\n    border: 0.2rem solid #ccc;\n    border-radius: 1rem;\n\n    background-color: #eee;\n    color: #000000;\n\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    .kHGxgVKA8sGigcykB4jg {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/not-found/styles.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;IAExB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;;IAEvB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;;IAEnB,sBAAsB;IACtB,cAAc;;IAEd,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".notFound {\n    margin: 0 2rem 2rem 2rem;\n\n    display: grid;\n    justify-content: center;\n}\n\n.message {\n    display: grid;\n    grid-row-gap: 1rem;\n    justify-content: center;\n\n    padding: 3rem 3rem;\n    border: 0.2rem solid #ccc;\n    border-radius: 1rem;\n\n    background-color: #eee;\n    color: #000000;\n\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    .notFound {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": "kHGxgVKA8sGigcykB4jg",
	"message": "Vplg86nZWI5Vbkgz9P83"
};
export default ___CSS_LOADER_EXPORT___;
