const copyToClipboard = (text) => {
    const input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();

    const result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
};

export default copyToClipboard;
