import ReactGA from "react-ga";


export default class GTag {
    static isInitialized = false;

    static init(ua) {
        this.isInitialized = true;

        ReactGA.initialize(ua, {
            titleCase: false,
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    static event(category, action) {
        if (!this.isInitialized) {
            return;
        }

        ReactGA.pageview(window.location.pathname + window.location.search);

        const paths = window.location.pathname.split("/")
            .filter((item) => item !== "");

        let label = "/";

        if (paths.length > 0) {
            label = `/${paths[0]}`;

            if (paths[0] === "c") {
                label = `/c/${paths[1]}`;
            }
        }

        ReactGA.event({
            label,
            category,
            action,
        });
    }

    static dailyJuiceOpen(juiceId, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Open Daily Juice ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId}
            `,
        );
    }

    static dailyJuiceVideoPlay(juiceId, storyId, videoUrl, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on the Video ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                News story post id: ${storyId},
                Video url: ${videoUrl}
            `,
        );
    }

    static dailyJuiceVideoEnded(juiceId, storyId, videoUrl, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Video Ended ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                News story post id: ${storyId},
                Video url: ${videoUrl}
            `,
        );
    }

    static dailyJuiceFeaturedImage(juiceId, storyId, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on Featured image ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                News story post id: ${storyId}
            `,
        );
    }

    static dailyJuiceOnClickReadStory(juiceId, storyId, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on Read story ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                News story post id: ${storyId}
            `,
        );
    }

    static dailyJuiceOnClickVocabularyWord(juiceId, vocabWord, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on Vocabulary word ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                Word: ${vocabWord}
            `,
        );
    }

    static dailyJuiceOnPlayWordToSpeech(juiceId, vocabWord, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on Play word to speech ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                Word: ${vocabWord}
            `,
        );
    }

    static dailyJuiceOnPauseWordToSpeech(juiceId, vocabWord, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on Pause word to speech ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                Word: ${vocabWord}
            `,
        );
    }

    static dailyJuiceOnSubmitQuiz(juiceId, quizId, isCorrect, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        let answeredCorrectly = "NO";
        if (isCorrect) {
            answeredCorrectly = "YES";
        }

        GTag.event(
            `Clicked on Submit quiz ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId},
                Quiz id: ${quizId},
                Answered correctly: ${answeredCorrectly}
            `,
        );
    }

    static dailyJuicesOnClickHomePageSponsor(grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on home page sponsor ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
            `,
        );
    }

    static dailyJuiceOnClickFirstPageSponsor(juiceId, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on first page sponsor ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId}
            `,
        );
    }

    static dailyJuiceOnClickLastPageSponsor(juiceId, grades = [], classSlug = null) {
        const slug = classSlug ? `Class slug: ${classSlug},` : "";

        GTag.event(
            `Clicked on last page sponsor ${grades.join(", ")}`,
            `
                Grades: ${grades.join(", ")},
                ${slug}
                Juice id: ${juiceId}
            `,
        );
    }

    /* -------------- */

    static onSearch(params) {
        GTag.event(
            `On search ${params.grades.join(", ")}`,
            `
                User id: ${params.userId},
                Grades: ${params.grades.join(", ")},
                Word: ${params.word}
            `,
        );
    }

    static searchStoryClick(params) {
        GTag.event(
            `Clicked on story from search ${params.grades.join(", ")}`,
            `
                User id: ${params.userId},
                Grades: ${params.grades.join(", ")},
                Story id: ${params.storyId}
            `,
        );
    }

    static searchFilterClick(params) {
        GTag.event(
            `Clicked on search filter ${params.grades.join(", ")}`,
            `
                User id: ${params.userId},
                Grades: ${params.grades.join(", ")},
            `,
        );
    }
}
