import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";

import RootLayout from "juice-app/views/root-layout/index.js";


const configureRoutes = (store) => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Route path="/" component={RootLayout} />
            </BrowserRouter>
        </Provider>
    );
};

export default configureRoutes;
