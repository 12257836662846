import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";
import device from "juice-base/lib/device.js";

import User from "juice-base/project/user.js";
import Grades from "juice-base/project/grades.js";

import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupCategories from "juice-base/components/popup-categories";
import Pagination from "juice-base/components/pagination/index.js";
import PopupJuiceStorySearch from "juice-base/components/popup-juice-story-search/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Dropdown from "juice-base/components/dropdown/index.js";
import Input from "juice-base/components/forms/input/index.js";
import Tiles from "juice-base/components/tiles";
import SearchCard from "juice-base/business/search-card/index.js";

import * as events from "juice-base/events.js";
import actions from "juice-base/store/actions.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getPageState = () => ({
    isLoaded: false,
    resultsPerPage: 0,
    totalResults: 0,
    selectedPage: 0,
});

const getStoryState = () => ({
    isOpen: false,
    isExtraJuiceView: false,
    id: null,
    playerState: {
        isOpen: false,
        trackId: null,
        trackGroupName: "",
    },
});

const Search = () => {
    const allTypes = [
        "all",
        "article",
        "infographic",
        "extra juice",
        "video",
    ];

    const [pagesState, setPages] = useState(() => getPageState());

    const [stories, setStories] = useState({
        isLoading: false,
        error: null,
        cards: [],
        layoutView: "list",
    });

    const [searchState, setSearchState] = useState({
        isSubmitted: false,
        submittedValue: "",
        value: "",
    });

    const [storyPopupState, setStoryPopup] = useState(() => getStoryState());

    const [storyInfographicState, setStoryInfographicImage] = useState({
        isOpen: false,
        image: {
            url: null,
            title: "",
        },
    });

    const [wordPopupState, setWordPopup] = useState({
        isLoading: false,
        word: "",
        errors: {},
        isOpen: false,
    });

    const [selectedSortBy, setSelectedSortBy] = useState(null);

    const [categoriesState, setCategoriesState] = useState({
        isLoaded: false,
        categories: [],
        isOpenPopup: false,
        selectedCategories: [],
    });

    const [selectedGrades, setSelectedGrades] = useState(null);

    const [selectedType, setSelectedType] = useState(null);

    const store = useSelector((state) => ({
        session: state.user.session,
        wordsByName: state.vocabulary.wordsByName,
        user: state.user.user,
        t2sExtraJuices: state.text2speech.extraJuices,
        t2sJuiceStories: state.text2speech.juiceStories,
        t2sWords: state.text2speech.words,
        dimensions: state.device.dimensions,
    }));

    const dispatch = useDispatch();

    const am = useAudioManager();

    const history = useHistory();

    if (User.isTypeTrial(store.user)) {
        history.push("/daily-juice");
        return null;
    }

    let userGrades = selectedGrades ?? (store.user.grades || []);

    if ((User.hasRoleTeacher(store.user) || User.hasRoleGuardian(store.user)) && !selectedGrades) {
        userGrades = Grades.getMaxGrades();
    }

    const searchParams = {
        page: 0,
        orderBy: selectedSortBy,
        categories: categoriesState.selectedCategories,
        type: selectedType || "all",
        grades: userGrades,
    };

    useEffect(() => {
        am.setFiles({
            extraJuices: store.t2sExtraJuices,
            juiceStories: store.t2sJuiceStories,
            words: store.t2sWords,
        });
    }, [
        store.t2sExtraJuices,
        store.t2sJuiceStories,
        store.t2sWords,
    ]);

    /* ------ */

    const onSearch = (params = searchParams) => {
        events.onSearch({
            session: store.session,
            userId: store.user.userId,
            grades: searchParams.grades,
            word: searchState.value,
        });

        setSelectedGrades(params.grades);

        setSelectedType(params.type);

        setPages(getPageState());

        setStoryPopup(getStoryState());

        setSearchState((prev) => ({
            ...prev,
            isSubmitted: true,
            submittedValue: searchState.value,
        }));

        setStories((prev) => ({
            ...prev,
            isLoading: true,
            cards: [],
            error: null,
        }));

        setCategoriesState((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        let orderBy = "none";

        if (params.orderBy === "oldest") {
            orderBy = "asc";
        } else if (params.orderBy === "newest") {
            orderBy = "desc";
        }

        let sCategories = params.categories.join(",");

        if (!sCategories) {
            sCategories = "all";
        }

        let sGrades = "all";

        if (params.grades.length !== 0) {
            sGrades = params.grades.join(",").toLowerCase();
        }

        api.search.all({
            session: store.session,
            search: searchState.value,
            page: params.page,
            orderBy,
            categories: sCategories,
            type: params.type,
            grades: sGrades,
        }).then((res) => {
            setPages((prev) => ({
                ...prev,
                isLoaded: true,
                resultsPerPage: res.ok ? res.perPage : 0,
                totalResults: res.ok ? res.totalResults : 0,
                selectedPage: params.page,
            }));

            setCategoriesState((prev) => ({
                ...prev,
                isLoaded: true,
                categories: res.categories || [],
            }));

            setStories((prev) => ({
                ...prev,
                isLoading: false,
                cards: res.results || [],
                error: res.ok ? null : res.error,
            }));
        });
    };

    const onSearchByKey = (evt) => {
        if (evt.key === "Enter") {
            onSearch({
                ...searchParams,
                page: 0,
                categories: [],
            });
        }
    };

    const onSearchValueChange = (evt) => {
        setSearchState((prev) => ({
            ...prev,
            value: evt.target.value,
        }));
    };

    const onSearchByButton = () => {
        onSearch({
            ...searchParams,
            page: 0,
            categories: [],
        });
    };

    /* ------ */

    const onFullscreenImageClick = (story) => {
        setStoryInfographicImage({
            isOpen: true,
            image: {
                url: story.featuredImage.src,
                title: "Featured",
            },
        });
    };

    const onStoryPlay = (storyId) => {
        dispatch(actions.t2s.setJuiceStoryLoading({
            storyId,
        }));

        api.t2s.juice({
            juiceId: null,
            storyId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("juiceStories", storyId);

                dispatch(actions.t2s.setJuiceStory({
                    juiceId: null,
                    storyId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onExtraJuicePlay = (extraJuiceId) => {
        dispatch(actions.t2s.setExtraJuiceLoading({
            extraJuiceId,
        }));

        api.t2s.juice({
            juiceId: null,
            extraJuiceId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("extraJuices", extraJuiceId);

                dispatch(actions.t2s.setExtraJuice({
                    juiceId: null,
                    extraJuiceId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onSearchStoryPopupFirstPlay = (story) => {
        const selectedTrackGroupName = storyPopupState.playerState.trackGroupName;

        am.playerPauseAll();

        if (am.state[selectedTrackGroupName]
            && am.state[selectedTrackGroupName][storyPopupState.playerState.trackId]) {
            am.playerPlay(selectedTrackGroupName, storyPopupState.playerState.trackId);
        } else if (storyPopupState.isExtraJuiceView || story.type === "extra juice") {
            onExtraJuicePlay(storyPopupState.playerState.trackId);
        } else if (story.type === "article" || story.type === "infographic") {
            onStoryPlay(storyPopupState.playerState.trackId);
        }
    };

    const closeImagePopup = () => {
        setStoryInfographicImage({
            isOpen: false,
            image: {
                url: null,
                title: "",
            },
        });
    };

    const onExtraJuiceWordClick = (hasExtraJuices) => {
        if (hasExtraJuices) {
            setStoryPopup((prev) => ({
                ...prev,
                isExtraJuiceView: true,
                playerState: {
                    isOpen: false,
                    trackId: null,
                    trackGroupName: "",
                },
            }));
        }
    };

    const closeWordPopup = () => {
        setWordPopup({
            isOpen: false,
            isLoading: false,
            word: "",
            errors: {},
        });
    };

    const onWordClick = (word) => {
        setWordPopup({
            word,
            isLoading: true,
            isOpen: true,
        });

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));
            } else {
                setWordPopup((prev) => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        [word]: "Cannot load word",
                    },
                }));
            }

            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: wordValue,
            }));
        }).catch(() => {
            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: "",
                errors: {
                    ...prev.errors,
                    [word]: "Cannot load word",
                },
            }));
        });
    };

    const onCloseInfographicView = () => {
        setStoryInfographicImage({
            isOpen: false,
            image: {
                url: null,
                title: "",
            },
        });
    };

    const onSingleStoryListenClick = (story) => {
        let trackGroupName = "juiceStories";

        if (story.type === "extra juice" || storyPopupState.isExtraJuiceView) {
            trackGroupName = "extraJuices";
        }

        let trackId = story.storyOrExtraJuiceId;

        if (storyPopupState.isExtraJuiceView) {
            trackId = null;

            if (story.extraJuices && story.extraJuices[0]) {
                trackId = story.extraJuices[0].id;
            }
        }

        setStoryPopup((prev) => ({
            ...prev,
            playerState: {
                isOpen: true,
                trackId,
                trackGroupName,
            },
        }));
    };

    const closeSingleStoryView = () => {
        closeImagePopup();
        setStoryPopup(getStoryState());
    };

    const onOpenCategoriesPopup = () => {
        events.searchFilterClick({
            session: store.session,
            userId: store.user.userId,
            grades: searchParams.grades,
        });

        setCategoriesState((prev) => ({
            ...prev,
            isOpenPopup: true,
        }));
    };

    const onApplyCategories = (categories) => {
        onSearch({
            ...searchParams,
            categories,
        });

        setCategoriesState((prev) => ({
            ...prev,
            isOpenPopup: false,
            selectedCategories: categories,
        }));
    };

    const onCloseCategoriesPopup = () => {
        setCategoriesState((prev) => ({
            ...prev,
            isOpenPopup: false,
        }));
    };

    /* ------ */

    const onCardClick = (id, storyId) => {
        events.searchStoryClick({
            session: store.session,
            userId: store.user.userId,
            grades: searchParams.grades,
            storyId: storyId || 0,
        });

        setStoryPopup((prev) => ({
            ...prev,
            isOpen: true,
            id,
            playerState: {
                isOpen: false,
                trackId: null,
                trackGroupName: "",
            },
        }));
    };

    const onCloseExtraJuiceView = () => {
        setStoryPopup((prev) => ({
            ...prev,
            isExtraJuiceView: false,
            playerState: {
                isOpen: false,
                trackId: null,
                trackGroupName: "",
            },
        }));
    };

    /* ------ */

    const getStories = () => {
        const cards = [];

        stories.cards.forEach((story, id) => {
            let type = null;
            let storyOrExtraJuiceId = null;
            let featuredImage = null;
            const category = story.categoryName || null;
            let video = null;

            if (story.type === "video"
                && story?.featuredVideo?.featuredImage?.sizes?.large) {
                featuredImage = {
                    src: story.featuredVideo.featuredImage.sizes.large.src,
                    infographic: false,
                };

                const captionSrc = api.videos.getVideoCaptionURL({
                    id: story.featuredVideo.id,
                    session: store.session,
                });

                video = {
                    id: story.featuredVideo.id,
                    url: story.featuredVideo.url,
                    poster: story.featuredVideo.featuredImage.sizes.large.src,
                    captionSrc,
                    mimeType: story.featuredVideo.mimeType,
                };
            } else if (story.featuredImage
                && story?.featuredImage?.sizes?.large) {
                featuredImage = {
                    src: story.featuredImage.sizes.large.src,
                    infographic: story.featuredImage.infographic,
                };
            }

            if (story.type === "video") {
                type = "video";
            } else if (story.type === "extra_juice") {
                type = "extra juice";
                storyOrExtraJuiceId = story.id;
            } else if (story.type === "infographic") {
                type = "infographic";
                storyOrExtraJuiceId = story.ID;
            } else if (story.type === "article") {
                type = "article";
                storyOrExtraJuiceId = story.ID;
            }

            cards.push({
                id,
                storyId: story.ID,
                storyOrExtraJuiceId,
                type,
                grades: story.grades,
                title: story.title,
                featuredImage,
                content: story.content,
                category,
                statusId: 1,
                video,
                extraJuices: story.extraJuices || [],
                datePublished: story.date,
                onCardClick: () => {
                    onCardClick(id, storyOrExtraJuiceId);
                },
            });
        });

        return cards;
    };

    const getStoryById = (id) => {
        const cards = getStories();

        for (let i = 0; i < cards.length; i += 1) {
            if (cards[i].id === id) {
                return cards[i];
            }
        }

        return null;
    };

    /* ------ */

    const onChangeOrderBy = (value) => {
        setSelectedSortBy(value.value);

        onSearch({
            ...searchParams,
            orderBy: value.value,
        });
    };

    const onChangeType = (newType) => {
        setSelectedType(newType);

        let sCategories = [...categoriesState.selectedCategories];

        if (newType === "extra juice") {
            setCategoriesState((prev) => ({
                ...prev,
                selectedCategories: [],
            }));
            sCategories = [];
        }

        onSearch({
            ...searchParams,
            type: newType,
            categories: sCategories,
        });
    };

    const onChangeGrade = (value) => {
        setSelectedGrades(value.value.split(","));

        setCategoriesState((prev) => ({
            ...prev,
            selectedCategories: [],
        }));

        onSearch({
            ...searchParams,
            grades: value.value.split(","),
            categories: [],
        });
    };

    const onChangeStoriesLayout = (view) => {
        setStories((prev) => ({
            ...prev,
            layoutView: view,
        }));
    };

    /* ------ */

    const getGradeGroup = (selectedGrade) => {
        const gradesGroup = [];

        const allGrades = ["G5", "G6", "G7", "G8", "G9", "G10", "G11", "G12"];

        for (let i = 0; i < allGrades.length; i += 1) {
            if (selectedGrade === allGrades[i]) {
                if (i % 2 === 1) {
                    gradesGroup[0] = allGrades[i - 1];
                    gradesGroup[1] = allGrades[i];
                } else {
                    gradesGroup[0] = allGrades[i];
                    gradesGroup[1] = allGrades[i + 1];
                }
                break;
            }
        }

        return gradesGroup;
    };

    /* ------ */

    const renderSearch = () => {
        // TODO: replace input with form. Use InputCustom component
        return (
            <div className={styles.searcher}>
                <Input
                    placeholder="Search for articles, infographics, videos, and extra juices!"
                    value={searchState.value}
                    onChange={onSearchValueChange}
                    onKeyPress={onSearchByKey}
                    transparent
                />
                <div
                    className={styles.searchButton}
                    role="button"
                    tabIndex="-1"
                    onKeyPress={onSearchByButton}
                    onClick={onSearchByButton}
                >
                    <img
                        src={staticFiles.magnifier}
                        alt="Search"
                        title="Search"
                    />
                </div>
            </div>
        );
    };

    const renderTypeDropdown = () => {
        return (
            <Dropdown
                defaultValueLabel="Type"
                selectedValue={selectedType}
                orangeTheme
                values={[
                    { value: "all", label: "All" },
                    { value: "article", label: "Article" },
                    { value: "infographic", label: "Infographic" },
                    { value: "extra juice", label: "Extra juice" },
                    { value: "video", label: "Video" },
                ]}
                iconContainerClassName={styles.dropdownIcon}
                icon={{
                    src: staticFiles.settingFilter,
                    alt: "Type",
                    title: "Type",
                }}
                onChange={(value) => {
                    onChangeType(value.value);
                }}
            />
        );
    };

    const renderTypesTabs = () => {
        const typeTabs = [];

        allTypes.forEach((type) => {
            const categoryClasses = [styles.singleType];

            if (type === selectedType) {
                categoryClasses.push(styles.singleTypeSelected);
            }

            typeTabs.push(
                <div
                    className={categoryClasses.join(" ")}
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                        onChangeType(type);
                    }}
                    onKeyPress={() => {
                        onChangeType(type);
                    }}
                >
                    {type}
                </div>,
            );
        });

        return (
            <>
                <div className={styles.typeTabs}>
                    {typeTabs}
                </div>
                <div className={styles.typeDropdown}>
                    {renderTypeDropdown()}
                </div>
            </>
        );
    };

    const renderCategoriesPopup = () => {
        if (!categoriesState.isOpenPopup
            || !categoriesState.isLoaded
            || categoriesState.categories.length === 0) {
            return null;
        }

        const categories = categoriesState.categories.map((cat) => ({
            label: cat.name,
            value: cat.id,
        }));

        return (
            <PopupCategories
                categories={categories}
                selectedCategories={categoriesState.selectedCategories}
                onApply={onApplyCategories}
                onClose={onCloseCategoriesPopup}
            />
        );
    };

    const renderCategories = () => {
        if (categoriesState.categories.length === 0) {
            return null;
        }

        let categoriesCount = null;

        if (categoriesState.selectedCategories.length !== 0) {
            categoriesCount = (
                <div className={styles.categoriesCount}>
                    <div className={styles.categoriesCountCircle}>
                        {categoriesState.selectedCategories.length}
                    </div>
                </div>
            );
        }

        return (
            <div
                role="button"
                tabIndex="-1"
                onKeyPress={onOpenCategoriesPopup}
                onClick={onOpenCategoriesPopup}
                className={styles.categories}
            >
                <div className={styles.categoriesIcon}>
                    <img
                        src={staticFiles.categoriesList}
                        alt="Category"
                    />
                    {categoriesCount}
                </div>

                <div className={styles.categoryTitle}>
                    Categories
                </div>

                <img
                    className={styles.caret}
                    src={staticFiles.caret}
                    alt="Open"
                />
            </div>
        );
    };

    const renderCardLayoutSwitcher = () => {
        const cardsSwitcherClasses = [styles.storiesLayoutSwitcher];
        const listSwitcherClasses = [styles.storiesLayoutSwitcher];

        if (stories.layoutView === "cards") {
            cardsSwitcherClasses.push(styles.storiesLayoutSwitcherSelected);
        }

        if (stories.layoutView === "list") {
            listSwitcherClasses.push(styles.storiesLayoutSwitcherSelected);
        }

        return (
            <div className={styles.cardLayoutSwitchers}>
                <div
                    className={cardsSwitcherClasses.join(" ")}
                    onClick={() => {
                        onChangeStoriesLayout("cards");
                    }}
                    onKeyPress={() => {
                        onChangeStoriesLayout("cards");
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        src={staticFiles.cardsLayout}
                        alt="cards layout"
                        title="cards layout"
                    />
                </div>
                <div
                    className={listSwitcherClasses.join(" ")}
                    onClick={() => {
                        onChangeStoriesLayout("list");
                    }}
                    onKeyPress={() => {
                        onChangeStoriesLayout("list");
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        src={staticFiles.listLayout}
                        alt="list layout"
                        title="list layout"
                    />
                </div>
            </div>
        );
    };

    const renderFilters = () => {
        if (!searchState.isSubmitted || (searchState.isSubmitted && searchState.submittedValue === "")) {
            return null;
        }

        let gradeDropdown = null;

        if (User.hasRoleTeacher(store.user) || User.hasRoleGuardian(store.user)) {
            let sGrades = selectedGrades;

            if (sGrades) {
                if (sGrades.length === 1) {
                    sGrades = getGradeGroup(selectedGrades.toString());
                }
                sGrades = sGrades.toString();
            }

            gradeDropdown = (
                <Dropdown
                    defaultValueLabel="Reading level"
                    values={[
                        { value: "G5,G6", label: "level 5-6" },
                        { value: "G7,G8", label: "level 7-8" },
                        { value: "G9,G10", label: "level 9-10" },
                        { value: "G11,G12", label: "level 11-12" },
                    ]}
                    selectedValue={sGrades}
                    orangeTheme
                    iconContainerClassName={styles.dropdownIcon}
                    icon={{
                        src: staticFiles.readingLevel,
                        alt: "Reading level",
                        title: "Reading level",
                    }}
                    onChange={onChangeGrade}
                />
            );
        }

        const categoriesSwitcherClasses = [styles.categoriesAndSwitcher];

        if (categoriesState.categories.length === 0) {
            categoriesSwitcherClasses.push(styles.switcherWithoutCategories);
        }

        return (
            <>
                <div className={styles.filters}>
                    <Dropdown
                        defaultValueLabel="Sort by"
                        selectedValue={selectedSortBy}
                        values={[
                            { value: "newest", label: "Newest" },
                            { value: "oldest", label: "Oldest" },
                        ]}
                        orangeTheme
                        iconContainerClassName={styles.dropdownIcon}
                        icon={{
                            src: staticFiles.settingFilter,
                            alt: "Sort by",
                            title: "Sort by",
                        }}
                        onChange={onChangeOrderBy}
                    />
                    {gradeDropdown}
                </div>
                <div className={styles.categoriesSections}>
                    {renderTypesTabs()}

                    <div className={categoriesSwitcherClasses.join(" ")}>
                        {renderCategories()}

                        {renderCardLayoutSwitcher()}
                    </div>
                </div>
            </>
        );
    };

    const renderStoryPopup = () => {
        if (!storyPopupState.isOpen) {
            return null;
        }

        const story = getStoryById(storyPopupState.id);

        if (!story) {
            return null;
        }

        let extraJuice = null;

        if (storyPopupState.isExtraJuiceView) {
            if (story.extraJuices && story.extraJuices[0]) {
                extraJuice = { ...story.extraJuices[0] };
            }
        }

        const selectedTrackGroupName = storyPopupState.playerState.trackGroupName;

        let audioData = null;

        if (storyPopupState.playerState.isOpen) {
            audioData = (am.state[selectedTrackGroupName]
                || {})[storyPopupState.playerState.trackId];
        }


        return (
            <PopupJuiceStorySearch
                story={story}
                extraJuice={extraJuice}
                isDefaultVideo={!device.isChrome}
                isExtraJuiceView={storyPopupState.isExtraJuiceView}
                onCloseExtraJuice={onCloseExtraJuiceView}
                infographic={storyInfographicState.image}
                isInfographicView={storyInfographicState.isOpen}
                onCloseInfographic={onCloseInfographicView}
                withShareButton={User.hasRoleTeacher(store.user)}
                audio={audioData}
                trackId={storyPopupState.playerState.trackId}
                onAudioPlay={onSingleStoryListenClick}
                onFullscreenImageClick={() => {
                    onFullscreenImageClick(story);
                }}
                onFirstPlay={() => {
                    onSearchStoryPopupFirstPlay(story);
                }}
                onPlay={() => {
                    am.playerPlay(selectedTrackGroupName, storyPopupState.playerState.trackId);
                }}
                onPause={() => {
                    am.playerPause(selectedTrackGroupName, storyPopupState.playerState.trackId);
                }}
                onRewind={() => {
                    am.playerRewind(selectedTrackGroupName, storyPopupState.playerState.trackId);
                }}
                onForward={() => {
                    am.playerForward(selectedTrackGroupName, storyPopupState.playerState.trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(
                        selectedTrackGroupName,
                        storyPopupState.playerState.trackId,
                        rate,
                    );
                }}
                onClosePlayer={() => {
                    am.playerStop(selectedTrackGroupName, storyPopupState.playerState.trackId);
                    setStoryPopup((prev) => ({
                        ...prev,
                        playerState: {
                            isOpen: false,
                            trackId: null,
                            trackGroupName: "",
                        },
                    }));
                }}
                onClose={closeSingleStoryView}
                onWordClick={onWordClick}
                onExtraJuiceWordClick={() => {
                    let hasExtraJuices = false;

                    if (story.extraJuices && story.extraJuices[0]) {
                        hasExtraJuices = true;
                    }

                    onExtraJuiceWordClick(hasExtraJuices);
                }}
            />
        );
    };

    const renderStories = () => {
        if (!searchState.isSubmitted) {
            return null;
        }

        if (stories.error) {
            return (
                <div className={styles.contentMessage}>
                    {stories.error}
                </div>
            );
        }

        if (stories.cards.length === 0) {
            return (
                <div className={styles.contentMessage}>
                    No stories found
                </div>
            );
        }

        const allCards = [];

        const cards = getStories();

        cards.forEach((card) => {
            let trackGroupName = "juiceStories";

            if (card.type === "extra juice") {
                trackGroupName = "extraJuices";
            }

            const audioData = (am.state[trackGroupName] || {})[card.storyOrExtraJuiceId];

            allCards.push(
                <SearchCard
                    key={card.id}
                    cardId={card.id}
                    datePublished={card.datePublished}
                    storyId={card.storyOrExtraJuiceId}
                    isCardsView={stories.layoutView === "cards"}
                    withShareButton={User.hasRoleTeacher(store.user)}
                    type={card.type}
                    featuredImage={card.featuredImage}
                    category={card.category}
                    title={card.title}
                    content={card.content}
                    statusId={card.statusId}
                    audioData={audioData}
                    onCardClick={card.onCardClick}
                    onFirstPlay={() => {
                        am.playerPauseAll();

                        if (am.state[trackGroupName]
                            && am.state[trackGroupName][card.storyOrExtraJuiceId]) {
                            am.playerPlay(trackGroupName, card.storyOrExtraJuiceId);
                        } else if (card.type === "article" || card.type === "infographic") {
                            onStoryPlay(card.storyOrExtraJuiceId);
                        } else if (card.type === "extra juice") {
                            onExtraJuicePlay(card.storyOrExtraJuiceId);
                        }
                    }}
                    onPlay={() => {
                        am.playerPlay(trackGroupName, card.storyOrExtraJuiceId);
                    }}
                    onPause={() => {
                        am.playerPause(trackGroupName, card.storyOrExtraJuiceId);
                    }}
                    onRewind={() => {
                        am.playerRewind(trackGroupName, card.storyOrExtraJuiceId);
                    }}
                    onForward={() => {
                        am.playerForward(trackGroupName, card.storyOrExtraJuiceId);
                    }}
                    onChangeRate={(rate) => {
                        am.playerChangeRate(trackGroupName, card.storyOrExtraJuiceId, rate);
                    }}
                    onWordClick={onWordClick}
                    onClosePlayer={() => {
                        am.playerStop(trackGroupName, card.storyOrExtraJuiceId);
                    }}
                    onClose={() => {
                        am.playerStop(trackGroupName, card.storyOrExtraJuiceId);
                    }}
                />,
            );
        });

        if (stories.layoutView === "list") {
            return (
                <div className={styles.storiesList}>
                    {allCards}
                </div>
            );
        }

        return (
            <Tiles
                isMobile={store.dimensions.width < 750}
            >
                {allCards}
            </Tiles>
        );
    };

    const renderWordPopup = () => {
        if (!wordPopupState.isOpen) {
            return null;
        }

        if (wordPopupState.isLoading) {
            return (
                <PopupLoading
                    popupClasses={styles.fixedPopup}
                />
            );
        }

        const wordLowerCase = wordPopupState.word.toLowerCase();

        let word = store.wordsByName[wordPopupState.word];

        if (!word && store.wordsByName[wordLowerCase]) {
            word = store.wordsByName[wordLowerCase];
        }

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    popupClasses={styles.fixedPopup}
                    onClose={() => {
                        setWordPopup({
                            isOpen: false,
                            isLoading: false,
                            word: "",
                            errors: {},
                        });
                    }}
                />
            );
        }

        const trackGroupName = "words";

        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                popupClasses={styles.fixedPopup}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            am.playerPlayNext(trackGroupName, text);

                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={closeWordPopup}
                closeOnLayoutClick
            />
        );
    };

    const renderPagination = () => {
        if (!pagesState.isLoaded) {
            return null;
        }

        const count = Math.ceil(pagesState.totalResults / pagesState.resultsPerPage);

        if (Number.isNaN(count) || count === 0) {
            return null;
        }

        return (
            <Pagination
                count={count}
                onChange={(page) => {
                    onSearch({
                        ...searchParams,
                        page,
                    });
                }}
                defaultPage={pagesState.selectedPage}
            />
        );
    };

    if (stories.isLoading) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderCategoriesPopup()}
            {renderStoryPopup()}
            {renderWordPopup()}

            <div className={styles.index}>
                {renderSearch()}
                {renderFilters()}
                <div className={styles.stories}>
                    {renderStories()}
                    {renderPagination()}
                </div>
            </div>
        </>
    );
};

export default Search;
