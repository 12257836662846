class Player {
    constructor(files, callbacks) {
        this.onEnded = this.onEnded.bind(this);

        this.currentTrack = 0;
        this.playbackRate = 1;
        this.timeSkip = 15;

        this.player = new Audio();
        this.player.onended = this.onEnded;

        this.files = files || [];
        this.callbacks = callbacks || {
            onEnded: () => {},
        };

        const track = this.getFirstTrack();

        if (track) {
            this.setTrack(track);
        }
    }

    /* --- */

    getFirstTrack() {
        for (let i = 0; i < this.files.length; i += 1) {
            const file = this.files[i];

            if (file.ok) {
                return {
                    index: i,
                    audioFile: file.audioFile,
                };
            }
        }

        return null;
    }

    getNextTrack() {
        for (let i = this.currentTrack + 1; i < this.files.length; i += 1) {
            const file = this.files[i] || {};

            if (file.ok) {
                return {
                    index: i,
                    audioFile: file.audioFile,
                };
            }
        }

        return null;
    }

    getPrevTrack() {
        for (let i = this.currentTrack - 1; i >= 0; i -= 1) {
            const file = this.files[i] || {};

            if (file.ok) {
                return {
                    index: i,
                    audioFile: file.audioFile,
                };
            }
        }

        return null;
    }

    /* --- */

    setTrack(track) {
        this.currentTrack = track.index;

        this.player.src = track.audioFile;
        this.player.load();
    }

    setPrevTrack(track, skipBack) {
        this.currentTrack = track.index;

        this.player.src = track.audioFile;
        this.player.pause();
        this.player.load();

        this.player.playbackRate = this.playbackRate;
        this.player.play();

        this.player.onloadeddata = () => {
            const duration = this.player.duration || 0;

            if (duration - skipBack >= 0) {
                this.player.currentTime = duration - skipBack;
            } else {
                this.player.currentTime = 0;
            }

            this.player.onloadeddata = null;
        };
    }

    setNextTrack(track, skipNext) {
        this.currentTrack = track.index;

        this.player.src = track.audioFile;
        this.player.pause();
        this.player.load();

        this.player.playbackRate = this.playbackRate;
        this.player.play();

        this.player.onloadeddata = () => {
            const duration = this.player.duration || 0;

            if (skipNext <= duration) {
                this.player.currentTime = skipNext;
            } else {
                this.player.currentTime = 0;
            }

            this.player.onloadeddata = null;
        };
    }

    setPlaybackRate(rate) {
        this.playbackRate = rate;

        if (this.player) {
            this.player.playbackRate = rate;
        }
    }

    play() {
        if (this.player.play) {
            this.player.playbackRate = this.playbackRate;
            this.player.play();
        }
    }

    pause() {
        if (this.player.pause) {
            this.player.pause();
        }
    }

    stop() {
        if (this.player.pause) {
            this.player.pause();

            const firstTrack = this.getFirstTrack();

            if (firstTrack) {
                this.setTrack(firstTrack);
            }
        }
    }

    rewind() {
        let cTime = this.player.currentTime || 0;

        if (cTime > this.timeSkip) {
            cTime -= this.timeSkip;
            this.player.currentTime = cTime;
        } else if (this.currentTrack === 0) {
            cTime = 0;
            this.player.currentTime = cTime;
        } else {
            const prevTrack = this.getPrevTrack();

            if (prevTrack) {
                const skipBack = this.timeSkip - cTime;

                this.setPrevTrack(prevTrack, skipBack);
            }
        }
    }

    forward() {
        const duration = this.player.duration || 0;

        let cTime = this.player.currentTime || 0;
        const nextCTime = cTime + this.timeSkip;

        if (nextCTime <= duration) {
            cTime = nextCTime;
            this.player.currentTime = cTime;
        } else if (nextCTime > duration) {
            const nextTrack = this.getNextTrack();

            if (nextTrack) {
                const skipNext = nextCTime - duration;
                this.setNextTrack(nextTrack, skipNext);
            }
        } else {
            cTime = duration;
            this.player.currentTime = cTime;
        }
    }

    /* --- */

    onEnded() {
        const nextTrack = this.getNextTrack();

        if (nextTrack) {
            this.setTrack(nextTrack);
            this.play();

            this.callbacks.onEnded(true, nextTrack);
        } else {
            const firstTrack = this.getFirstTrack();

            if (firstTrack) {
                this.setTrack(firstTrack);

                this.callbacks.onEnded(false, firstTrack);
            }
        }
    }
}

export default Player;
