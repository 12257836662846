// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes irL0gfFfkCvY2nw2G7lt {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.PfDBW9qiI_u5w0zxMMXi {\n    display: inline-block;\n\n    width: 2rem;\n    height: 2rem;\n\n    border: 0.5rem solid #d3d3d3;\n    border-radius: 50%;\n    border-top: 0.51rem solid #3498db;\n\n    animation: irL0gfFfkCvY2nw2G7lt 1.5s linear infinite;\n}\n", "",{"version":3,"sources":["webpack://./base/components/loader-small/styles.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,qBAAqB;;IAErB,WAAW;IACX,YAAY;;IAEZ,4BAA4B;IAC5B,kBAAkB;IAClB,iCAAiC;;IAEjC,oDAAoC;AACxC","sourcesContent":["@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.loader {\n    display: inline-block;\n\n    width: 2rem;\n    height: 2rem;\n\n    border: 0.5rem solid #d3d3d3;\n    border-radius: 50%;\n    border-top: 0.51rem solid #3498db;\n\n    animation: spin 1.5s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "PfDBW9qiI_u5w0zxMMXi",
	"spin": "irL0gfFfkCvY2nw2G7lt"
};
export default ___CSS_LOADER_EXPORT___;
