// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IRWFrC_TBZ3v6piJxr7g {\n    text-align: center;\n    font-weight: 600;\n}\n\n._eaxMOU_mqreqUY6BmDd {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.pFPmVSwvZ9RaHViaF05x {\n    text-align: left;\n    padding: 0 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-grade-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".header {\n    text-align: center;\n    font-weight: 600;\n}\n\n.content {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.form {\n    text-align: left;\n    padding: 0 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "IRWFrC_TBZ3v6piJxr7g",
	"content": "_eaxMOU_mqreqUY6BmDd",
	"form": "pFPmVSwvZ9RaHViaF05x"
};
export default ___CSS_LOADER_EXPORT___;
