import { useRef, useEffect } from "react";

import Scroller from "./scroller.js";
import ScrollerElem from "./scroller-elem.js";


const useScroller = (scrollerRef, options) => {
    const scroller = useRef(null);

    useEffect(() => {
        if (scrollerRef.current) {
            scroller.current = new Scroller(
                scrollerRef.current,
                ScrollerElem,
                options,
            );
        }

        return () => {
            if (scroller.current) {
                scroller.current.destroy();
            }
        };
    }, [scrollerRef]);

    useEffect(() => {
        if (scroller.current) {
            scroller.current.setCallbacks(options);
        }
    }, [options.onLeft, options.onRight]);

    return scroller;
};

export default useScroller;
