import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import LogoRound from "juice-base/components/logo-round/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import PasswordForgotForm from "juice-base/forms/user-password-forgot/index.js";
import MessageWithEmoji from "juice-base/components/message-with-emoji/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const PasswordForgot = () => {
    const history = useHistory();

    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [isResetBy, setIsResetBy] = useState("");

    const onBackToSignIn = () => {
        history.push("/user/sign-in");
    };

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        api.user.resetPassword({
            username: values.username,
        }).then((res) => {
            if (res.ok) {
                setIsMessageVisible(true);
                setIsResetBy(res.resetMethod);
            } else {
                setErrors({
                    username: res.error || "Error!",
                });
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const renderForm = () => {
        if (isMessageVisible) {
            return null;
        }

        const initialValues = {
            username: "",
        };

        return (
            <PasswordForgotForm
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        );
    };

    if (isMessageVisible) {
        let msg = `
            We let your teacher know that you need a new password.
            They're probably great and will help you out as soon as they're able to do so.
            Until you're back in The Juice, check out some of the fresh stories on our news aggregator.
        `;

        if (isResetBy === "restoreByEmail") {
            msg = `
                Huzzah! A fresh reset link is on its way! Check your email.
            `;
        }

        return (
            <div className={styles.passwordForgot}>
                <div className={styles.content}>
                    <MessageWithEmoji
                        header="We're on the case!"
                        message={msg}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.passwordForgot}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        Forgot your password?
                    </div>
                </div>

                {renderForm()}

                <div className={styles.backButton}>
                    <ButtonBig onClick={onBackToSignIn}>
                        Back to sign in
                    </ButtonBig>
                </div>
            </div>
        </div>
    );
};

export default PasswordForgot;
