import React, { useState } from "react";

import User from "juice-base/project/user.js";

import Header from "juice-base/components/header/index.js";
import AsideNav from "juice-base/components/aside-nav/index.js";
import ShortNav from "juice-base/components/short-nav/index.js";

import styles from "./styles.module.css";


const Layout = (props) => {
    const [isVisibleAsideNav, setVisibleAsideNav] = useState(false);

    const renderHeader = () => {
        if (!props.isVisibleMobileHeader) {
            return null;
        }

        const isSubscribeVisible = User.hasRoleTeacher(props.user)
            && User.isTypeFree(props.user);

        return (
            <Header
                name={props.appName}
                user={props.user}
                pathname={props.pathname}
                sectionsByRoles={props.sectionsByRoles}
                onAsideToggle={() => {
                    setVisibleAsideNav(true);
                }}
                isSubscribeVisible={isSubscribeVisible}
                onSubscribe={props.onSubscribe}
                onSignOut={props.onSignOut}
            />
        );
    };

    const renderAsideNav = () => {
        if (!isVisibleAsideNav) {
            return null;
        }

        return (
            <AsideNav
                name={props.appName}
                version={props.version}
                user={props.user}
                sectionsByRoles={props.sectionsByRoles}
                supportLink={props.supportLink}
                onSignOut={props.onSignOut}
                onClose={() => { setVisibleAsideNav(false); }}
            />
        );
    };

    return (
        <>
            {renderAsideNav()}

            <div data-comment="header-desktop" className={styles.layoutHeader}>
                {renderHeader()}
            </div>

            <div data-comment="content" className={styles.layoutContent}>
                {props.children}
            </div>

            <div data-comment="nav-short" className={styles.layoutMobileNav}>
                <ShortNav
                    pathname={props.pathname}
                    user={props.user}
                    sectionsByRoles={props.sectionsByRoles}
                    onSignOut={props.onSignOut}
                />
            </div>
        </>
    );
};

Layout.defaultProps = {
    pathname: "/",
    appName: "",
    version: "",
    sectionsByRoles: {},
    user: {},
    onSubscribe: () => {},
    onSignOut: () => {},
    isVisibleMobileHeader: true,
};

export default Layout;
