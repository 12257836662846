import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";
import User from "juice-base/project/user.js";

import Avatar from "juice-base/components/avatar/index.js";
import CssIcon from "juice-base/components/css-icon/index.js";
import ButtonWithIcon from "juice-base/components/button-with-icon/index.js";

import styles from "./styles.module.css";


const Header = (props) => {
    const renderSections = () => {
        if (!props.user.userId) {
            return null;
        }

        const sections = [];

        User.getNavSections(props.user, props.sectionsByRoles).forEach((sec) => {
            const isSelected = props.pathname.indexOf(sec.to) === 0;

            const iconSrc = isSelected
                ? staticFiles[sec.iconDesktopSelected]
                : staticFiles[sec.iconDesktop];

            const sClassName = classNames({
                [styles.sectionLink]: true,
                [styles.sectionLinkSelected]: isSelected,
            });

            const el = (
                <Link
                    className={sClassName}
                    to={sec.to}
                    key={sec.name}
                    data-comment={`header-${sec.dataComment}`}
                >
                    <img
                        className={styles.sectionIconImg}
                        src={iconSrc}
                        alt={sec.name}
                    />
                    {sec.name}
                </Link>
            );

            sections.push(el);
        });

        const isHomeSelected = props.pathname === "/";

        const homeClassName = classNames({
            [styles.sectionLink]: true,
            [styles.sectionLinkSelected]: isHomeSelected,
        });

        const homeIconSrc = isHomeSelected
            ? staticFiles.iconHomeOrange
            : staticFiles.iconHomeGrey;

        const homeLink = (
            <Link
                to="/"
                className={homeClassName}
                data-comment="header-link-home"
            >
                <img
                    className={styles.sectionIconImg}
                    src={homeIconSrc}
                    alt="Home"
                />
                Home
            </Link>
        );

        return (
            <>
                {homeLink}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        if (!props.user.userId) {
            return (
                <Link to="/user/sign-in" className={styles.signin}>
                    Sign In
                    <CssIcon
                        url={staticFiles.iconLogIn}
                        name="Sign In"
                        className={styles.signInIcon}
                    />
                </Link>
            );
        }

        return (
            <Link
                to="/user/profile"
                className={styles.username}
            >
                <Avatar
                    name={User.getFirstInitial(props.user)}
                />
            </Link>
        );
    };

    let subscribeButton = null;

    if (props.isSubscribeVisible) {
        subscribeButton = (
            <ButtonWithIcon
                iconName="Subscribe"
                iconUrl={staticFiles.iconMedalWhite}
                onClick={props.onSubscribe}
            >
                Subscribe
            </ButtonWithIcon>
        );
    }

    return (
        <header className={styles.header}>
            <CssIcon
                url={staticFiles.iconMenu}
                name="Menu"
                className={styles.menu}
                onClick={props.onAsideToggle}
            />
            <div className={styles.logo}>
                <Link to="/">
                    <img
                        src={staticFiles.juiceHomeLogo}
                        alt={props.name}
                        className={styles.logoImg}
                    />
                </Link>
            </div>
            <div className={styles.sections}>
                {renderSections()}
            </div>
            <div className={styles.userControl}>
                {renderUser()}
                {subscribeButton}
            </div>
        </header>
    );
};

Header.defaultProps = {
    name: "",
    user: {},
    pathname: "/",
    sectionsByRoles: {},
    isSubscribeVisible: false,
    onSubscribe: () => {},
    onAsideToggle: () => {},
    onSignOut: () => {},
};

export default Header;
