import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonToggler = (props) => {
    if (!props.buttons || props.buttons.length === 0) {
        return null;
    }

    const onSelect = (val) => {
        if (val !== props.selected) {
            props.onSelect(val);
        }
    };

    const renderButtons = () => {
        const lastIndex = props.buttons.length - 1;

        return props.buttons.map((btn, i) => {
            const btnStyle = {
                left: `-${0.2 * i}rem`,
            };

            const btnClassName = classNames({
                [styles.button]: true,
                [styles.buttonFirst]: i === 0,
                [styles.buttonLast]: i === lastIndex,
                [styles.buttonSelected]: props.selected === btn.value,
            });

            return (
                <div
                    className={btnClassName}
                    style={btnStyle}
                    onClick={() => { onSelect(btn.value); }}
                    onKeyPress={() => { onSelect(btn.value); }}
                    role="button"
                    tabIndex="-1"
                >
                    {btn.label}
                </div>
            );
        });
    };

    return (
        <div className={styles.buttonToggler}>
            {renderButtons()}
        </div>
    );
};

ButtonToggler.defaultProps = {
    buttons: [],
    selected: 0,
    onSelect: () => {},
};

export default ButtonToggler;
