import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DailyJuice from "juice-base/project/daily-juice.js";

import useWordPopup from "juice-base/hooks/use-word-popup/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import actions from "juice-base/store/actions.js";
import device from "juice-base/lib/device.js";
import storage from "juice-base/lib/storage/index.js";
import { tryFormatDate, getDateFromDate } from "juice-base/lib/date.js";

import staticFiles from "juice-base/static-files.js";

import { withAuth } from "juice-base/components/auth/index.js";

import Tabs from "juice-base/components/tabs/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import DatePickerSingle from "juice-base/components/date-picker-single/index.js";
import ClassroomShareButton from "juice-base/components/google-classroom-share-button/index.js";

import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import PopupImage from "juice-base/components/popup-image/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";

import DailyJuiceSponsor from "juice-base/business/daily-juice-sponsor/index.js";
import SectionAnnouncement from "juice-base/business/section-announcement/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";
import QuizQuestion from "juice-base/business/quiz-question/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const TeacherDailyJuicePage = () => {
    const [audioPlayerPopup, setAudioPlayerPopup] = useState({
        storyId: -1,
    });

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        storyId: -1,
        ejId: -1,
        isVisiblePlayer: false,
    });

    const [imagePopup, setImagePopup] = useState(null);

    const [datePickerState, setDatePicker] = useState({
        date: null,
    });

    const store = useSelector((state) => ({
        session: state.user.session,
        wordsByName: state.vocabulary.wordsByName,
        juices: state.juices,
        juicesDates: state.juices.juicesDates,
        dimensions: state.device.dimensions,
        teacher: state.teacher,
        t2sWords: state.text2speech.words,
        t2sJuiceStories: state.text2speech.juiceStories,
        t2sExtraJuices: state.text2speech.extraJuices,
    }));

    const dispatch = useDispatch();
    const wordPopup = useWordPopup();

    const am = useAudioManager({
        isMac: device.isMac,
    });

    const loadJuicesDates = () => {
        dispatch(actions.juices.clearJuicesDates());

        api.dailyJuices.getJuicesDates({
            session: store.session,
            classId: store.teacher.selectedClassId,
        }).then((res) => {
            let dates = [];

            if (res.ok) {
                dates = res.juices.map((juice) => {
                    return {
                        date: juice.date,
                        juiceId: juice.id,
                    };
                });
            }

            dispatch(actions.juices.setJuicesDates({
                dates,
            }));
        });
    };

    const loadJuiceByDate = (date) => {
        const juiceDate = tryFormatDate(date, getDateFromDate);

        api.dailyJuices.getTeacherJuiceByDate({
            session: store.session,
            classId: store.teacher.selectedClassId,
            date: juiceDate,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setJuice({
                    juice: res.data,
                }));
            }
        });
    };

    const getTeacherClassId = (classes) => {
        let classId = storage.local.loadTeacherSelectedClass();

        let classStillExists = false;

        if (classId) {
            for (let i = 0; i < classes.length; i += 1) {
                if (classes[i].id === parseInt(classId, 10)) {
                    classStillExists = true;
                    break;
                }
            }
        }

        classId = parseInt(classId, 10);

        if (!classStillExists && classes[0]) {
            classId = classes[0].id || -1;
            storage.local.saveTeacherSelectedClass(classId);
        }

        return classId;
    };

    const loadTeacherClasses = () => {
        api.classes.getTeacherClasses({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                const classes = res.classes || [];
                const classId = getTeacherClassId(classes);

                dispatch(actions.teacher.setTeacherSelectedClass({
                    selectedClassId: classId,
                }));

                dispatch(actions.teacher.setClasses({
                    classes,
                }));
            }
        });
    };

    const onOpenImagePopup = (image) => {
        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    const onCloseWordPopup = () => {
        wordPopup.setWord("");
    };

    const onOpenWordPopup = (word) => {
        wordPopup.setLoading(true, word);

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));

                wordPopup.setWord(wordValue);
            } else {
                wordPopup.setError(word, "Cannot load word");
            }
        }).catch(() => {
            wordPopup.setError(word, "Cannot load word");
        });
    };

    const getJuiceIdByDate = (date) => {
        for (let i = 0; i < store.juicesDates.length; i += 1) {
            const selectedDate = tryFormatDate(date, getDateFromDate);
            const juiceDate = tryFormatDate(store.juicesDates[i].date, getDateFromDate);

            if (selectedDate === juiceDate) {
                return store.juicesDates[i].juiceId;
            }
        }

        return null;
    };

    const onPlayJuiceStory = (storyId) => {
        const juiceId = getJuiceIdByDate(datePickerState.date);

        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId,
        }));

        dispatch(actions.t2s.setJuiceStoryLoading({
            storyId,
        }));

        api.t2s.juice({
            juiceId,
            storyId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("juiceStories", storyId);

                dispatch(actions.t2s.setJuiceStory({
                    juiceId,
                    storyId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: -1,
        }));
    };

    const onOpenExtraJuicePopup = (sId, ejId) => {
        if (sId && ejId) {
            setExtraJuicePopup((prev) => ({
                ...prev,
                storyId: sId,
                ejId,
            }));
        }
    };

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        dispatch(actions.t2s.setExtraJuiceLoading({
            extraJuiceId,
        }));
    };

    const onLoadExtraJuiceStory = (extraJuiceId) => {
        // TODO: if already loaded no need to load
        const juiceId = getJuiceIdByDate(datePickerState.date);

        api.t2s.juice({
            juiceId,
            extraJuiceId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("extraJuices", extraJuiceId);

                dispatch(actions.t2s.setExtraJuice({
                    juiceId,
                    extraJuiceId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const getDailyJuice = (date) => {
        const juiceId = getJuiceIdByDate(date);

        return store.juices.juicesById[juiceId] || {};
    };

    const getExtraJuice = (sId, ejId) => {
        const dailyJuice = getDailyJuice(datePickerState.date);

        const stories = (dailyJuice.stories || {}).juice || [];

        return DailyJuice.getExtraJuiceById(stories, ejId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    const onCloseExtraJuicePopup = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            storyId: -1,
            ejId: -1,
        }));
    };

    const onClassChange = (id) => {
        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: id,
        }));

        storage.local.saveTeacherSelectedClass(id);
    };

    const onDateChange = (value) => {
        const newDate = new Date(value);

        setDatePicker((prev) => ({
            ...prev,
            date: newDate,
        }));

        loadJuiceByDate(newDate);
    };

    const getJuiceStories = () => {
        const juiceId = getJuiceIdByDate(datePickerState.date);

        if (juiceId) {
            const juice = store.juices.juicesById[juiceId] || null;

            if (juice?.stories?.juice) {
                return juice?.stories?.juice;
            }
        }

        return [];
    };

    const getJuiceSponsor = () => {
        const dailyJuice = getDailyJuice(datePickerState.date);

        if (!dailyJuice?.classCustomContent?.id) {
            return null;
        }

        return dailyJuice.classCustomContent;
    };

    const getJuiceAccouncement = () => {
        const dailyJuice = getDailyJuice(datePickerState.date);

        if (!dailyJuice?.classCustomContent?.id) {
            return null;
        }

        return dailyJuice.classCustomContent;
    };

    const isContentLoaded = () => {
        if (!store.juices.isJuicesDatesLoaded) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (store.session) {
            loadTeacherClasses();
        }

        return () => {
            events.teacherDailyJuiceClose({
                session: store.session,
            });
        };
    }, [store.session]);

    useEffect(() => {
        am.setFiles({
            juiceStories: store.t2sJuiceStories,
            extraJuices: store.t2sExtraJuices,
            words: store.t2sWords,
        });
    }, [
        store.t2sJuiceStories,
        store.t2sExtraJuices,
        store.t2sWords,
    ]);

    useEffect(() => {
        if (store.teacher.selectedClassId !== -1) {
            setDatePicker({
                date: null,
            });

            loadJuicesDates();

            api.site.getSiteDate().then((res) => {
                if (res.ok) {
                    const siteDate = res.date;

                    setDatePicker({
                        date: siteDate,
                    });

                    loadJuiceByDate(siteDate);
                }
            });
        }
    }, [store.teacher.selectedClassId]);

    const renderTutorial = () => {
        if (!isContentLoaded()) {
            return null;
        }

        return (
            <Tutorial
                name="teacher-daily-juices"
            />
        );
    };

    const renderTabs = () => {
        if (!store.teacher.isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const tabs = store.teacher.classes.map((cls) => {
            return {
                value: cls.id,
                label: cls.title,
            };
        });

        let { selectedClassId } = store.teacher;

        if (selectedClassId === -1) {
            selectedClassId = getTeacherClassId(store.teacher.classes);
        }

        return (
            <Tabs
                tabs={tabs}
                dataComment="teacher-classes"
                selectedTab={selectedClassId}
                onChange={(values) => {
                    onClassChange(values.value);
                }}
            />
        );
    };

    const renderJuiceSelector = () => {
        if (!store.teacher.isClassesLoaded) {
            return null;
        }

        if (!store.juices.isJuicesDatesLoaded) {
            return (
                <RequestLoader />
            );
        }

        let shareButton = null;

        const juiceId = getJuiceIdByDate(datePickerState.date);

        if (juiceId) {
            const juiceTitle = store.juices?.juicesById?.[juiceId]?.title || "";

            shareButton = (
                <div className={styles.shareButton}>
                    <ClassroomShareButton
                        title={`Daily Juice: ${juiceTitle}`}
                        url={`/daily-juice/${juiceId}`}
                        dataComment="classroom-share-button-daily-juice"
                    />
                </div>
            );
        }

        const customInputIcon = (
            <img
                className={styles.customInputIcon}
                src={staticFiles.arrowInCircle}
                alt="Change date"
            />
        );

        const availableDates = store.juicesDates.map((juice) => {
            return juice.date;
        });

        return (
            <div className={styles.dailyJuiceSelector}>
                <DatePickerSingle
                    date={datePickerState.date}
                    customInputIcon={customInputIcon}
                    availableDates={availableDates}
                    dataComment="juice-date-selector"
                    onChange={onDateChange}
                />
                {shareButton}
            </div>
        );
    };

    const renderQuiz = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return null;
        }

        const standards = DailyJuice.getQuizStandards(q);

        return (
            <QuizQuestion
                question={q}
                standards={standards}
                isMobile={store.dimensions.width < 920}
                allowAnswering={false}
                showStandards
                showAnswers
            />
        );
    };

    const getStoryById = (storyId) => {
        const stories = getJuiceStories();

        for (let i = 0; i < stories.length; i += 1) {
            if (stories[i].ID === storyId) {
                return stories[i];
            }
        }

        return null;
    };

    const renderStory = (story, storyIndex) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        const storyId = story.ID;

        const djStory = (
            <DailyJuiceStory
                story={story}
                storyIndex={storyIndex}
                dimensions={store.dimensions}
                isDefaultVideo={!device.isChrome}
                videoCaptionSrc={videoCaptionSrc}
                withShareClassroomButton
                onImageClick={onOpenImagePopup}
                onWordClick={(word) => {
                    onOpenWordPopup(word, storyId, "story");
                }}
                onAudioPlay={() => {
                    onPlayJuiceStory(storyId);
                }}
                onExtraJuiceWordClick={onOpenExtraJuicePopup}
            />
        );

        const quiz = renderQuiz(story);

        let quizSection = null;

        if (quiz) {
            quizSection = (
                <div className={styles.storyQuiz}>
                    {quiz}
                </div>
            );
        }

        return (
            <div className={styles.story}>
                <div className={styles.storyDjStory}>
                    {djStory}
                </div>
                {quizSection}
            </div>
        );
    };

    const renderClassAnnouncement = () => {
        const announcement = getJuiceAccouncement();

        if (!announcement || !announcement?.announcementContent) {
            return null;
        }

        return (
            <div className={styles.sectionAnnouncement}>
                <SectionAnnouncement
                    title={announcement?.announcementTitle || "Class Announcement"}
                    content={announcement?.announcementContent || ""}
                    teacherTheme
                />
            </div>
        );
    };

    const renderSponsor = () => {
        const sponsor = getJuiceSponsor();

        if (!sponsor) {
            return null;
        }

        return (
            <div className={styles.sectionSponsor}>
                <DailyJuiceSponsor
                    sponsor={sponsor}
                    teacherTheme
                />
            </div>
        );
    };

    const renderContent = () => {
        if (!datePickerState.date) {
            return null;
        }

        const juiceStories = [];

        const stories = getJuiceStories();

        stories.forEach((story, index) => {
            juiceStories.push(renderStory(story, index));
        });

        return (
            <div className={styles.juiceStories}>
                {renderSponsor()}
                {renderClassAnnouncement()}
                {juiceStories}
            </div>
        );
    };

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (audioPlayerPopup.storyId !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story.featuredImage && story.featuredImage.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.ID;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = (am.state[trackGroupName] || {})[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onFirstPlay={() => {
                    am.playerPauseAll();

                    if (am.state[trackGroupName]
                        && am.state[trackGroupName][trackId]) {
                        am.playerPlay(trackGroupName, trackId);
                    } else {
                        onPlayJuiceStory(story.ID);
                    }
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderExtraJuicePopup = () => {
        const eJuice = getExtraJuice(extraJuicePopup.storyId, extraJuicePopup.ejId);

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = (am.state[trackGroupName] || {})[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.storyId}`}
                storyId={extraJuicePopup.storyId}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={(word) => {
                    onOpenWordPopup(word, extraJuicePopup.storyId, "extra-juice");
                }}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onFirstPlay={() => {
                    onLoadExtraJuiceStory(eJuice.id);
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderWordPopup = () => {
        if (wordPopup.state.isLoading) {
            return (
                <PopupLoading />
            );
        }

        const wordLowerCase = wordPopup.state.word.toLowerCase();

        let word = store.wordsByName[wordPopup.state.word];

        if (!word && store.wordsByName[wordLowerCase]) {
            word = store.wordsByName[wordLowerCase];
        }

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    onClose={onCloseWordPopup}
                />
            );
        }

        const trackGroupName = "words";
        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderPopups = () => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        if (audioPlayerPopup.storyId !== -1) {
            const story = getStoryById(audioPlayerPopup.storyId);

            ps.push(renderPlayerAudioPopup(story));
        }

        if (extraJuicePopup.storyId !== -1 || extraJuicePopup.ejId !== -1) {
            ps.push(renderExtraJuicePopup());
        }

        if (wordPopup.state.word) {
            ps.push(renderWordPopup());
        }

        return ps;
    };

    return (
        <>
            {renderTutorial()}
            {renderPopups()}
            <div
                data-comment="teacher-daily-juice"
                className={styles.page}
            >
                <div className={styles.pageContent}>
                    <div className={styles.pageContentControls}>
                        {renderTabs()}
                        {renderJuiceSelector()}
                    </div>
                    {renderContent()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["teacher"])(TeacherDailyJuicePage);
