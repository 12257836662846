import React from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmDeleteStudent = (props) => {
    let content = "Delete Student?";

    if (props.students) {
        content = (
            <ul>
                {props.students.map((student) => {
                    return (
                        <li>{student?.fullName}</li>
                    );
                })}
            </ul>
        );
    }

    if (props.isLoading) {
        content = <RequestLoader />;
    }

    if (props.error) {
        content = props.error;
    }

    return (
        <PopupConfirm>
            <PopupConfirmTitle>
                Delete selected students?
            </PopupConfirmTitle>

            <PopupConfirmContent>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.isLoading}
                    onClick={props.onDelete}
                >
                    Delete
                </ButtonFlat>

                <ButtonFlat
                    disabled={props.isLoading}
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmDeleteStudent.defaultProps = {
    students: [],
    error: null,
    isLoading: false,
    onDelete: () => {},
    onClose: () => {},
};

export default PopupConfirmDeleteStudent;
