import React from "react";
import { withAuth } from "juice-base/components/auth/index.js";

import StudentDailyJuiceStory from "juice-app/containers/student-daily-juice-story/index.js";


const DailyJuiceStory = () => {
    return (
        <StudentDailyJuiceStory />
    );
};

export default withAuth([
    "student",
    "guardian",
])(DailyJuiceStory);
