import React from "react";

import styles from "./styles.module.css";


const Footer = (props) => {
    const { copyright } = props;

    let copyrightText = null;

    if (copyright) {
        copyrightText = (
            <div>
                Copyright
                &copy;
                {copyright}
            </div>
        );
    }

    return (
        <footer className={styles.footer}>
            {copyrightText}
        </footer>
    );
};

export default Footer;
