import React from "react";

import staticFiles from "juice-base/static-files.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import styles from "./styles.module.css";


const PopupEmoji = (props) => {
    const { isCorrect } = props;

    let emoji = null;
    let message = null;

    if (isCorrect) {
        emoji = (
            <img
                src={staticFiles.emojisHappy}
                alt="Emoji Happy"
                title="Emoji Happy"
                className={styles.emojiImage}
            />
        );

        message = (
            <div className={styles.emojiMessage}>
                <div className={styles.emojiMessageTitle}>
                    correct!
                </div>
                <div className={styles.emojiMessageText}>
                    <div>Have you been studying?</div>
                    <div>You&apos;re doing awesome!</div>
                </div>
            </div>
        );
    } else {
        emoji = (
            <img
                src={staticFiles.emojisSad}
                alt="Emoji Sad"
                title="Emoji Sad"
                className={styles.emojiImage}
            />
        );

        const messageClasses = [
            styles.emojiMessage,
            styles.emojiMessageIncorrect,
        ];

        message = (
            <div className={messageClasses.join(" ")}>
                <div className={styles.emojiMessageTitle}>
                    incorrect
                </div>
                <div className={styles.emojiMessageText}>
                    <div>Ah...you&apos;ll get the next one!</div>
                </div>
            </div>
        );
    }

    const popupStyles = {
        backgroundImage: `url(${staticFiles.juiceEmojiBg})`,
    };

    const title = (
        <div className={styles.topTitle}>
            Quiz Result
        </div>
    );

    return (
        <PopupWindow
            title={title}
            onClose={props.onClose}
            isSmall
            isCentered
            autoClose
        >
            <div
                className={styles.popupEmoji}
                style={popupStyles}
                data-comment="popup-emoji"
            >
                {emoji}

                <div className={styles.emojiMessageContainer}>
                    {message}
                </div>
            </div>
        </PopupWindow>
    );
};

PopupEmoji.defaultProps = {
    isCorrect: true,
    onClose: () => {},
};

export default PopupEmoji;
