import React, { useRef } from "react";

import { Formik } from "formik";

import ButtonBig from "juice-base/components/button-big/index.js";
import Select from "juice-base/components/forms/select/index.js";
import SelectCreatable from "juice-base/components/forms/select-creatable/index.js";

import styles from "./styles.module.css";


const TeacherSchoolDetailsForm = (props) => {
    const subdivisionRef = useRef(null);
    const schoolDistrictRef = useRef(null);
    const schoolNameRef = useRef(null);

    const isDisabled = (values) => {
        return !values.country
            || !values.subdivision
            || !values.schoolDistrict
            || !values.schoolName;
    };

    const clearSubdivision = () => {
        if (subdivisionRef?.current?.select?.clearValue) {
            subdivisionRef.current.select.clearValue();
        }
    };

    const clearDistrict = () => {
        if (schoolDistrictRef?.current?.select?.select?.clearValue) {
            schoolDistrictRef.current.select.select.clearValue();
        }
    };

    const clearSchoolName = () => {
        if (schoolNameRef?.current?.select?.select?.clearValue) {
            schoolNameRef.current.select.select.clearValue();
        }
    };

    const onCountryChange = (value) => {
        props.onCountryChange(value);
        clearSubdivision();
        clearDistrict();
        clearSchoolName();
    };

    const onSubdivisionsChange = (value) => {
        props.onSubdivisionsChange(value);

        clearDistrict();
        clearSchoolName();
    };

    const onSelectDistrict = (value) => {
        props.onSelectDistrict(value);
        clearSchoolName();
    };

    const onSelectSchoolName = (value) => {
        props.onSelectSchoolName(value);
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleSubmit,
            setFieldValue,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.fields}>
                    <Select
                        name="country"
                        values={props.countries}
                        defaultValueLabel="Country *"
                        isShort
                        onChange={(dValues) => {
                            setFieldValue("country", dValues.value);
                            onCountryChange(dValues.value);
                        }}
                    />

                    <Select
                        selectRef={subdivisionRef}
                        name="subdivision"
                        placeholder={`${props.subdivisionName} *`}
                        defaultValueLabel={`${props.subdivisionName} *`}
                        values={props.subdivisions}
                        isDisabled={!values.country}
                        isSearchable
                        isShort
                        onChange={(dValues) => {
                            const value = dValues?.value || "";

                            setFieldValue("subdivision", value);
                            onSubdivisionsChange(value);
                        }}
                    />

                    <SelectCreatable
                        selectRef={schoolDistrictRef}
                        name="schoolDistrict"
                        placeholder="School District *"
                        values={props.districtsOptions}
                        isDisabled={!values.subdivision}
                        isShort
                        isSearchable
                        onChange={(dValues) => {
                            const value = dValues?.value || dValues?.label;

                            setFieldValue("schoolDistrict", value || "");

                            onSelectDistrict({
                                value: value || "",
                                isNew: dValues?.__isNew__ || false,
                            });
                        }}
                    />

                    <SelectCreatable
                        selectRef={schoolNameRef}
                        name="schoolName"
                        placeholder="School Name *"
                        values={props.schoolNamesOptions}
                        isDisabled={!values.schoolDistrict}
                        isShort
                        isSearchable
                        onChange={(dValues) => {
                            const value = dValues?.value || dValues?.label;

                            setFieldValue("schoolName", value || "");
                            onSelectSchoolName(value || "");
                        }}
                    />
                </div>

                <div className={styles.submitButton}>
                    <ButtonBig
                        disabled={isDisabled(values)}
                        type="submit"
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

TeacherSchoolDetailsForm.defaultProps = {
    initialValues: {},

    districtsOptions: [],
    schoolNamesOptions: [],

    countries: [],
    onCountryChange: () => {},

    subdivisionName: "State",
    subdivisions: [],
    onSubdivisionsChange: () => {},

    onSelectDistrict: () => {},
    onSelectSchoolName: () => {},

    onSubmit: () => {},
};

export default TeacherSchoolDetailsForm;
