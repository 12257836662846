import React from "react";
import { Formik } from "formik";

import Signup from "juice-base/project/signup.js";

import { hasEmptyValue } from "juice-base/lib/object.js";

import Dropdown from "juice-base/components/dropdown/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const UserSignUpTrialRoles = (props) => {
    const roles = Signup.getSignupOptions();

    const isDisabled = (formProps) => {
        return hasEmptyValue(formProps.values);
    };

    const renderForm = (formProps) => {
        const {
            handleSubmit,
            setFieldValue,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Dropdown
                    name="role"
                    values={roles}
                    defaultValueLabel="Choose your role *"
                    onChange={(value) => {
                        setFieldValue("role", value.value); // TODO:
                    }}
                />

                <div className={styles.submitButtonBlock}>
                    <div className={styles.submitButtonBig}>
                        <ButtonBig
                            type="submit"
                            disabled={isDisabled(formProps)}
                        >
                            Next
                        </ButtonBig>
                    </div>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpTrialRoles.defaultProps = {
    initialValues: {},
    onSubmit: () => { },
};

export default UserSignUpTrialRoles;
