// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._R878SAPwv1uujHRz_tF {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border-radius: 50%;\n\n    background-color: var(--grey-light);\n    color: var(--grey-dark);\n    font-size: 1.5rem;\n    text-transform: uppercase;\n    text-decoration: none;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/avatar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,kBAAkB;;IAElB,mCAAmC;IACnC,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;;IAErB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".avatar {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border-radius: 50%;\n\n    background-color: var(--grey-light);\n    color: var(--grey-dark);\n    font-size: 1.5rem;\n    text-transform: uppercase;\n    text-decoration: none;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "_R878SAPwv1uujHRz_tF"
};
export default ___CSS_LOADER_EXPORT___;
