import React from "react";

import staticFiles from "juice-base/static-files.js";
import parseTags from "juice-base/lib/parse-tags.js";

import Text from "juice-base/components/text/index.js";

import styles from "./styles.module.css";


const JuiceContent = (props) => {
    const onContentClick = (evt) => {
        if (evt.target && evt.target.dataset.word) {
            const word = (evt.target.innerText || "").trim();

            if (evt.target.dataset.word === "word") {
                props.onWordClick(word);
            } else if (evt.target.dataset.word === "extraJuiceWord") {
                props.onExtraJuiceWordClick("story");
            }
        }
    };

    const onExtraJuiceClick = () => {
        props.onExtraJuiceWordClick("story-button");
    };

    const renderExtraJuices = () => {
        if (props.extraJuices.length === 0 || props.hideExtraJuicesButtons) {
            return null;
        }

        const extraJuicesWords = [];

        props.extraJuices.forEach((ej) => {
            extraJuicesWords.push(
                <div
                    className={styles.extraJuiceButton}
                    onClick={onExtraJuiceClick}
                    onKeyPress={onExtraJuiceClick}
                    role="button"
                    tabIndex="-1"
                >
                    {`Extra Juice: ${ej.title}`}
                </div>,
            );
        });

        return (
            <div className={styles.extraJuices}>
                {extraJuicesWords}
            </div>
        );
    };

    let newContent = parseTags.parse(props.content).map((line) => {
        if (parseTags.isTag(line)) {
            const wordVal = parseTags.trimTag(line);
            return (`
                <span data-word="word" class="${styles.vocabWord}">${wordVal}</span>
            `);
        }

        return line;
    }).join("");

    newContent = parseTags.parseCurlyBrackets(newContent).map((line) => {
        if (parseTags.isTagCurlyBrackets(line)) {
            const wordVal = parseTags.trimTagCurlyBrackets(line);

            if (props.hideExtraJuicesButtons) {
                return (`
                    <span class="${styles.extraJuiceWordOnDesktop}">${wordVal}</span>
                `);
            }

            return (`
                <span data-word="extraJuiceWord" class="${styles.extraJuiceWord}">
                    <img
                        src="${staticFiles.iconOrange}"
                        alt="extra-juice"
                        title="extra-juice"
                    />${wordVal}</span>
            `);
        }

        return line;
    }).join("");

    newContent = parseTags.parseShortcode(newContent).map((line) => {
        if (!parseTags.isTagShortcode(line)) {
            return line;
        }

        if (parseTags.isTagShortcodeName(line, "caption")) {
            const contentLines = parseTags.parse(line.tagContent, "<", "/>");

            if (contentLines.length === 0) {
                return "";
            }

            let captionText = null;

            if (contentLines[1]) {
                captionText = `
                    <div class="${styles.captionText}">${contentLines[1]}</div>
                `;
            }

            return (`
                <div class="${styles.captionImg}">${contentLines[0]}${captionText}</div>
            `);
        }

        return line.tagContent;
    }).join("");

    const classes = [
        styles.content,
        props.className,
    ];

    return (
        <div className={classes.join(" ")}>
            <Text onClick={onContentClick}>
                {newContent}
            </Text>
            {renderExtraJuices()}
        </div>
    );
};

JuiceContent.defaultProps = {
    className: "",
    content: "",
    extraJuices: [],
    hideExtraJuicesButtons: false,
    onWordClick: () => {},
    onExtraJuiceWordClick: () => {},
};

export default JuiceContent;
