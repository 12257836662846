import React from "react";
import { useHistory } from "react-router-dom";

import { newDateUTC } from "juice-base/lib/date.js";

import SectionStudent from "juice-base/components/section-student/index.js";
import ChartBar from "juice-base/components/chart-bar/index.js";

import styles from "./styles.module.css";


const DailyJuiceQuizResults = (props) => {
    const history = useHistory();

    const results = props.quizResults.map((result) => {
        const groupData = result.data.map((gdata) => {
            return {
                ...gdata,
                date: newDateUTC(gdata.date),
            };
        });

        return {
            name: result.name,
            dates: {
                from: newDateUTC(result.dates.from),
                to: newDateUTC(result.dates.to),
            },
            data: groupData,
        };
    });

    const noDataText = "Looks like we don’t have enough data to show you quiz results yet. Check back again soon!";

    return (
        <SectionStudent
            title="Quiz results"
            dataComment="section-quiz-results"
            showSeeMore
            onSeeMoreClick={() => {
                history.push("/daily-juice");
            }}
        >
            <div className={styles.results}>
                <ChartBar
                    dataGroups={results}
                    noDataText={noDataText}
                    width={props.isMobile ? 270 : 350}
                />
            </div>
        </SectionStudent>
    );
};

DailyJuiceQuizResults.defaultProps = {
    quizResults: [],
    isMobile: false,
};

export default DailyJuiceQuizResults;
