import React from "react";

import { withAuth } from "juice-base/components/auth/index.js";
import Search from "juice-app/containers/search/index.js";


const ShowSearch = () => {
    return (
        <Search />
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(ShowSearch);
