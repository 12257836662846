import React, { useState, useEffect } from "react";

import staticFiles from "juice-base/static-files.js";
import date from "juice-base/lib/date.js";
import classNames from "juice-base/lib/class-names.js";
import array from "juice-base/lib/array.js";

import Standards from "juice-base/project/standards.js";
import Grades from "juice-base/project/grades.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonToggler from "juice-base/components/button-toggler/index.js";
import MenuHorizontal from "juice-base/components/menu-horizontal/index.js";
import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";
import ProgressValue from "juice-base/components/progress-value/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";

import StandardType from "juice-base/business/standard-type/index.js";
import TeacherQuizQuestion from "juice-base/business/teacher-quiz-question/index.js";

import styles from "./styles.module.css";


const StudentQuizPerformance = (props) => {
    const sortByQuiz = "by-quiz";
    const sortByStandard = "by-standard";

    const sortByValues = [
        { value: sortByQuiz, label: props.isMobile ? "Quiz" : "View by quiz" },
        { value: sortByStandard, label: props.isMobile ? "Standard" : "View by standard" },
    ];

    const standardTypes = Standards.getStandardsTypes();

    const standardDates = [
        date.DATES.today,
        date.DATES.twoWeeks,
        date.DATES.thisMonth,
        date.DATES.past3Months,
    ];

    /* --- */

    const [sortByType, setSortByType] = useState(sortByValues[0].value);

    const [quizDate, setQuizDate] = useState(() => {
        const qdate = array.last(props.dailyJuiceDates?.dates);
        return qdate ? qdate.id : null;
    });

    const [standardType, setStandardType] = useState(() => {
        const stdType = array.head(standardTypes);
        return stdType ? stdType.value : "";
    });

    const [selectedStandard, setSelectedStandard] = useState(null);

    /* --- */

    const selectFirstStandardType = (stdType) => {
        const data = props.performance.data || {};
        const stds = Standards.getAllStandardsByType(data.standards, stdType);

        if (stds && stds.length > 0) {
            setSelectedStandard(stds[0].standard);
        } else {
            setSelectedStandard(null);
        }
    };

    /* --- */

    const onSortByQuiz = () => {
        const qDate = array.last(props.dailyJuiceDates?.dates);

        if (qDate) {
            setQuizDate(qDate.id);
            props.onDateChange(qDate.date);
        }
    };

    const onSortByStandard = () => {
        const stdDate = array.head(standardDates);

        if (stdDate) {
            setQuizDate(stdDate.value);
            props.onDateRangeChange(stdDate.value);
        }
    };

    const onSortByValueChange = (val) => {
        setSortByType(val);
        setSelectedStandard(null);

        if (val === sortByQuiz) {
            onSortByQuiz();
        } else if (val === sortByStandard) {
            onSortByStandard();
        }
    };

    /* --- */

    const onQuizDateChange = (value) => {
        const val = parseInt(value, 10);
        const qDate = array.findOneById(props.dailyJuiceDates?.dates, val);

        if (qDate) {
            props.onDateChange(qDate.date);
        }
    };

    const onDateChange = (value) => {
        setQuizDate(value);

        if (sortByType === sortByQuiz) {
            onQuizDateChange(value);
        } else if (sortByType === sortByStandard) {
            props.onDateRangeChange(value);
        }
    };

    const onStandardTypeChange = (value) => {
        setStandardType(value);
        selectFirstStandardType(value);
    };

    /* --- */

    const getSelectedStandardType = () => {
        const { data } = props.performance;
        const standards = data.standards || [];

        return Standards.findStandardType(standards, selectedStandard);
    };

    const isSelectedStandardType = (quiz, standards) => {
        if (!selectedStandard) {
            return false;
        }

        const quizStandards = quiz.standards || [];

        for (let i = 0; i < quizStandards.length; i += 1) {
            const sId = quizStandards[i];

            const stdType = Standards.findStandardTypeByStandard(standards, sId, selectedStandard);

            if (stdType) {
                return true;
            }
        }

        return false;
    };

    /* --- */

    useEffect(() => {
        onSortByQuiz();

        return () => {
            props.onClear();
        };
    }, []);

    useEffect(() => {
        if (!props.performance.isLoading
            && sortByType === sortByStandard
            && !selectedStandard) {
            selectFirstStandardType(standardType);
        }
    }, [props.performance.isLoading]);

    /* --- */

    const renderSortByTypes = () => {
        return (
            <div className={styles.sortByType}>
                <ButtonToggler
                    buttons={sortByValues}
                    selected={sortByType}
                    onSelect={onSortByValueChange}
                />
            </div>
        );
    };

    const renderDates = () => {
        const datesClassName = classNames({
            [styles.dates]: true,
            [styles.datesDesktop]: !props.isMobile,
        });

        if (sortByType === sortByQuiz) {
            const dates = props.dailyJuiceDates?.dates || [];

            const items = dates.map((val) => ({
                value: val.id,
                label: date.tryFormatDate(val.date, date.formatDayShortMonthDate),
            }));

            return (
                <div className={datesClassName}>
                    <MenuHorizontalScrolling
                        items={items}
                        selected={quizDate}
                        onSelect={onDateChange}
                    />
                </div>
            );
        }

        if (sortByType === sortByStandard) {
            return (
                <div className={datesClassName}>
                    <MenuHorizontalScrolling
                        items={standardDates}
                        selected={quizDate}
                        onSelect={onDateChange}
                    />
                </div>
            );
        }

        return null;
    };

    const renderStandards = () => {
        const standardIcon = {
            src: staticFiles.standardsFlamingo,
            alt: "Standards",
        };

        if (props.isMobile) {
            return (
                <div className={styles.standardsTypesDesktop}>
                    <SelectCustom
                        selected={standardType}
                        options={standardTypes}
                        icon={standardIcon}
                        onSelect={onStandardTypeChange}
                    />
                </div>
            );
        }

        return (
            <div className={styles.standardsTypes}>
                <MenuHorizontal
                    items={standardTypes}
                    selected={standardType}
                    onSelect={onStandardTypeChange}
                    isRoseTheme
                />
            </div>
        );
    };

    const renderQuizScores = () => {
        const { data } = props.performance;

        if (Object.keys(data).length === 0) {
            return null;
        }

        const studentGrade = Grades.getGradeGroup([data.studentGradeLevel]);
        const classGrade = Grades.getGradeGroup([data.classGradeLevel]);

        const scoresClassName = classNames({
            [styles.scores]: true,
            [styles.scoresDesktop]: !props.isMobile,
        });

        return (
            <div className={scoresClassName}>
                <ProgressValue
                    title="Student's Quiz Score"
                    subtitle={`Level ${studentGrade}`}
                    value={data.studentQuizScore || 0}
                />

                <ProgressValue
                    title="Class Average"
                    subtitle={`Level ${classGrade}`}
                    value={data.classQuizScore || 0}
                />
            </div>
        );
    };

    const renderByStandardScores = () => {
        const { data } = props.performance;

        if (Object.keys(data).length === 0) {
            return null;
        }

        const stds = Standards.getAllStandardsByType(data.standards, standardType);


        const options = stds.map((std) => {
            let iconName = "";

            if (props.isMobile && std.standard === selectedStandard) {
                iconName = "info";
            }

            return {
                value: std.standard,
                label: std.standard,
                icon: iconName,
            };
        });

        let standardTypeSelector = null;

        if (options.length > 0) {
            const standardTypeIcon = {
                src: staticFiles.standardTypes,
                alt: "Standard Types",
            };

            standardTypeSelector = (
                <SelectCustom
                    selected={selectedStandard}
                    options={options}
                    icon={standardTypeIcon}
                    onSelect={setSelectedStandard}
                    onIconClick={() => {
                        props.onStandardTypeClick(selectedStandard);
                    }}
                />
            );
        }

        const studentGrade = Grades.getGradeGroup([data.studentGradeLevel]);
        const classGrade = Grades.getGradeGroup([data.classGradeLevel]);

        const selectedStandardType = getSelectedStandardType();

        const scoresClassName = classNames({
            [styles.scoresStandards]: true,
            [styles.scoresStandardsDesktop]: !props.isMobile,
        });

        const typeSelectorClassName = classNames({
            [styles.scoreStandardTypeSelector]: true,
            [styles.scoreStandardTypeSelectorDesktop]: !props.isMobile,
        });

        const scoreStandardDetailsClassName = classNames({
            [styles.scoreStandardDetails]: true,
            [styles.scoreStandardDetailsDesktop]: !props.isMobile,
        });

        return (
            <div className={scoresClassName}>
                <div className={typeSelectorClassName}>
                    {standardTypeSelector}
                </div>
                <div className={scoreStandardDetailsClassName}>
                    {selectedStandardType?.details || ""}
                </div>
                <div className={styles.scoreStandardsValues}>
                    <ProgressValue
                        title="Student's Quiz Score"
                        subtitle={`Level ${studentGrade}`}
                        value={data.studentQuizScore || 0}
                    />

                    <ProgressValue
                        title="Class Average"
                        subtitle={`Level ${classGrade}`}
                        value={data.classQuizScore || 0}
                    />
                </div>
            </div>
        );
    };

    const renderScores = () => {
        if (props.performance.isLoading) {
            return null;
        }

        if (sortByType === sortByQuiz) {
            return renderQuizScores();
        }

        if (sortByType === sortByStandard) {
            return renderByStandardScores();
        }

        return null;
    };

    const renderQuizStandard = (quiz, standards) => {
        const quizStandards = quiz.standards || [];
        const quizTypes = [];

        for (let i = 0; i < quizStandards.length; i += 1) {
            const sId = quizStandards[i];

            const stdType = Standards.findStandardTypeByType(standards, sId, standardType);

            if (stdType) {
                const qStd = (
                    <div className={styles.quizStandardType}>
                        <StandardType type={stdType} />
                    </div>
                );

                quizTypes.push(qStd);
            }
        }

        return quizTypes;
    };

    const renderQuiz = (quiz) => {
        return (
            <div className={styles.quiz}>
                <TeacherQuizQuestion quiz={quiz} />
            </div>
        );
    };

    const renderQuizes = () => {
        if (props.performance.isLoading) {
            return null;
        }

        const { data } = props.performance;
        const standards = data.standards || [];

        if (sortByType === sortByQuiz) {
            const qs = [];
            const quizzes = data.quizzes || [];

            for (let i = 0; i < quizzes.length; i += 1) {
                const q = quizzes[i];

                qs.push(renderQuiz(q));
                qs.push(renderQuizStandard(q, standards));
            }

            return (
                <div className={styles.quizzes}>
                    {qs}
                </div>
            );
        }

        if (sortByType === sortByStandard) {
            const qs = [];
            const quizzes = data.quizzes || [];

            for (let i = 0; i < quizzes.length; i += 1) {
                const q = quizzes[i];

                if (isSelectedStandardType(q, standards)) {
                    qs.push(renderQuiz(q));
                }
            }

            return (
                <div className={styles.quizzes}>
                    {qs}
                </div>
            );
        }

        return null;
    };

    const renderContent = () => {
        if (props.performance.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (Object.keys(props.performance.data).length === 0) {
            return (
                <div className={styles.message}>
                    Student has not completed the quiz for this daily juice.
                </div>
            );
        }

        return (
            <>
                {renderScores()}
                {renderQuizes()}
            </>
        );
    };

    if (!props.dailyJuiceDates?.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <div className={styles.studentQuizPerformance}>
            {renderSortByTypes()}
            {renderDates()}
            {renderStandards()}

            {renderContent()}
        </div>
    );
};

StudentQuizPerformance.defaultProps = {
    dailyJuiceDates: [],
    performance: {},
    isMobile: false,

    onDateChange: () => {},
    onDateRangeChange: () => {},
    onStandardTypeClick: () => {},
    onClear: () => {},
};

export default StudentQuizPerformance;
