import {
    SET_JUICES_VIDEOS,
    SET_JUICES_VIDEOS_LOADING,
} from "./types.js";


export const setJuicesVideos = ({ videos, page, hasMorePages }) => ({
    type: SET_JUICES_VIDEOS,
    payload: {
        videos,
        page,
        hasMorePages,
    },
});

export const setJuicesVideosLoading = () => ({
    type: SET_JUICES_VIDEOS_LOADING,
});
