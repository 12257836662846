import React from "react";

import staticFiles from "juice-base/static-files.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Icon from "juice-base/components/icon/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceActivity = (props) => {
    const renderStoryIcon = (story, quiz) => {
        const { featuredVideo } = story;

        if (featuredVideo?.featuredImage?.url) {
            if (featuredVideo.isUserViewed) {
                return (
                    <Icon
                        url={staticFiles.iconPlayGreen}
                        name={DailyJuice.StoryStatesLegend.iconPlayGreen}
                    />
                );
            }

            return (
                <Icon
                    url={staticFiles.iconPlayBlue}
                    name={DailyJuice.StoryStatesLegend.iconPlayBlue}
                />
            );
        }

        if (!story.isUserOpenedStory) {
            return (
                <Icon
                    url={staticFiles.iconNotepadExclamation}
                    name={DailyJuice.StoryStatesLegend.iconNotepadExclamation}
                />
            );
        }

        if (!quiz) {
            return (
                <Icon
                    url={staticFiles.iconNotepadCheck}
                    name={DailyJuice.StoryStates.quizEmpty}
                />
            );
        }

        const isAnswered = DailyJuice.isAnsweredQuiz(quiz);

        if (isAnswered) {
            if (DailyJuice.isValidQuiz(quiz)) {
                return (
                    <Icon
                        url={staticFiles.iconNotepadCheck}
                        name={DailyJuice.StoryStatesLegend.iconNotepadCheck}
                    />
                );
            }

            return (
                <Icon
                    url={staticFiles.iconNotepadX}
                    name={DailyJuice.StoryStatesLegend.iconNotepadX}
                />
            );
        }

        return (
            <Icon
                url={staticFiles.iconNotepadStop}
                name={DailyJuice.StoryStatesLegend.iconNotepadStop}
            />
        );
    };

    const renderStory = (story) => {
        const { featuredImage, featuredVideo } = story;

        const imgStyles = {};

        if (featuredImage?.url) {
            imgStyles.backgroundImage = `url(${featuredImage.url})`;
        } else if (featuredImage?.vocabUrl) {
            imgStyles.backgroundImage = `url(${featuredImage.vocabUrl})`;
        } else if (featuredVideo?.featuredImage?.url) {
            imgStyles.backgroundImage = `url(${featuredVideo.featuredImage.url})`;
        }

        let quiz = null;

        if (story.quizzes && story.quizzes.length > 0) {
            quiz = story.quizzes[0] || null;
        }

        const goToTitle = "Go to story";

        return (
            <div
                className={styles.story}
                onClick={() => {
                    props.onGoToStory(story.ID);
                }}
                onKeyPress={() => {
                    props.onGoToStory(story.ID);
                }}
                tabIndex="-1"
                role="button"
            >
                <div
                    className={styles.storyImage}
                    style={imgStyles}
                />
                <div className={styles.storyTitle}>
                    {story.title}
                </div>

                {renderStoryIcon(story, quiz)}

                <div className={styles.storyGoToLink}>
                    <img
                        src={staticFiles.arrowRightSmall}
                        alt={goToTitle}
                        title={goToTitle}
                    />
                </div>
            </div>
        );
    };

    const renderStories = () => {
        const stories = props.stories.map((story) => renderStory(story));

        return (
            <div className={styles.stories}>
                {stories}
            </div>
        );
    };

    // TODO: button with better styles
    const renderScoreButton = () => {
        return (
            <div
                className={styles.scoreButton}
                onClick={props.onShowReport}
                onKeyPress={props.onShowReport}
                tabIndex="-1"
                role="button"
            >
                View score report
            </div>
        );
    };

    const activityClassName = [
        dailyJuiceCommonStyles.block,
        dailyJuiceCommonStyles.blockWithPadding,
    ].join(" ");

    return (
        <div className={activityClassName}>
            <div className={styles.activityHeader}>
                <div className={dailyJuiceCommonStyles.header}>
                    Activity overview
                </div>
                <div
                    className={styles.infoButton}
                    onClick={props.onInfoClick}
                    onKeyPress={props.onInfoClick}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        src={staticFiles.informationOrange}
                        alt="Information"
                        title="Information"
                    />
                </div>
            </div>

            {renderStories()}

            {renderScoreButton()}
        </div>
    );
};

DailyJuiceActivity.defaultProps = {
    stories: [],
    onGoToStory: () => {},
    onShowReport: () => {},
};

export default DailyJuiceActivity;
