// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cyVS0_IH0yZVXXlqV_tK {\n    width: 100%;\n    height: auto;\n    background-color: #000;\n\n    outline: none;\n}\n\n/* NOTE: can't change this div with class */\n.cyVS0_IH0yZVXXlqV_tK > div {\n    width: 100%;\n    height: 100%;\n}\n\n.cyVS0_IH0yZVXXlqV_tK video {\n    position: initial !important;\n}\n\n.cyVS0_IH0yZVXXlqV_tK button {\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/video-custom/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;;IAEtB,aAAa;AACjB;;AAEA,2CAA2C;AAC3C;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".videoPlayer {\n    width: 100%;\n    height: auto;\n    background-color: #000;\n\n    outline: none;\n}\n\n/* NOTE: can't change this div with class */\n.videoPlayer > div {\n    width: 100%;\n    height: 100%;\n}\n\n.videoPlayer video {\n    position: initial !important;\n}\n\n.videoPlayer button {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoPlayer": "cyVS0_IH0yZVXXlqV_tK"
};
export default ___CSS_LOADER_EXPORT___;
