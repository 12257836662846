import {
    SET_VOCABULARY_WORDS,
    SET_VOCABULARY_WORD,
} from "./types.js";


export const setVocabularyWords = ({ words }) => ({
    type: SET_VOCABULARY_WORDS,
    payload: {
        words,
    },
});

export const setVocabularyWord = ({ word, definitions }) => ({
    type: SET_VOCABULARY_WORD,
    payload: {
        word,
        definitions,
    },
});
