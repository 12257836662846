export default (apiURL, methods) => {
    return {
        getJuices() {
            // NOTE: action=daily_juices_published_get_all
            // TODO: delete request
            const url = `${apiURL}/daily-juices`;

            return methods.get(url);
        },

        getJuicesByPage(params = {}) {
            // NOTE: action=daily_juices_published_get_by_page
            const url = `${apiURL}/daily-juices/by-page`;

            return methods.get3(url, {
                session_id: params.session,
                student_id: params.studentId,
                grades: params.grades,
                page: params.page,
            });
        },

        getJuice(params = {}) {
            // NOTE: action=daily_juices_published_get
            const url = `${apiURL}/daily-juices/${params.juiceId}`;

            return methods.get3(url, {
                session_id: params.session,
                student_id: params.studentId,
                class_slug: params.classSlug,
            });
        },

        getJuiceByDate(params = {}) {
            // NOTE: action=daily_juices_published_get_by_date
            const url = `${apiURL}/daily-juices/by-date/${params.date}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getTeacherJuiceByDate(params = {}) {
            // NOTE: action=daily_juices_published_get_by_date
            const url = `${apiURL}/daily-juices/published`;

            return methods.get3(url, {
                class_id: params.classId,
                session_id: params.session,
                date: params.date,
            });
        },

        getUnpublishedJuice(params = {}) {
            // NOTE: action=daily_juices_published_get_for_preview
            const url = `${apiURL}/daily-juices/unpublished/${params.juiceId}`;

            return methods.get3(url);
        },

        getJuicesVideos(params = {}) {
            // NOTE: action=get_published_featured_videos
            const url = `${apiURL}/daily-juices/videos`;

            return methods.get3(url, {
                page: params.page,
            });
        },

        getJuicesDates(params = {}) {
            // NOTE: action=daily_juices_published_dates
            const url = `${apiURL}/daily-juices/dates`;

            return methods.get3(url, {
                session_id: params.session,
                student_id: params.studentId,
                class_id: params.classId,
            });
        },

        getIncompletedJuices(params = {}) {
            // NOTE: action=get_incompleted_juices
            const url = `${apiURL}/daily-juices/incomplete`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
            });
        },

        getLastJuice(params = {}) {
            // NOTE: action=daily_juices_published_get_last
            const url = `${apiURL}/daily-juices/last`;

            return methods.get3(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },
    };
};
