// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Irqqsv0oZuRLm5N0Ttns {\n    height: 3.5rem;\n    padding: 0.6rem 1rem 0.6rem 1rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    border: 0.1rem solid var(--grey-light);\n    border-radius: 0.5rem;\n    text-align: center;\n    text-decoration: none;\n\n    cursor: pointer;\n    outline: none;\n\n    color: #fff;\n    background-color: var(--orange);\n    text-transform: uppercase;\n\n    font-size: 1.3rem;\n    font-weight: 600;\n\n    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n                border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n}\n\n.Irqqsv0oZuRLm5N0Ttns:hover {\n    background-color: #fdaa4f;\n    border-color: #fc890c;\n}\n\n._xLEsZ_DimW_T1pxz1l6 {\n    margin: 0 1rem 0 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-with-icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gCAAgC;;IAEhC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,sCAAsC;IACtC,qBAAqB;IACrB,kBAAkB;IAClB,qBAAqB;;IAErB,eAAe;IACf,aAAa;;IAEb,WAAW;IACX,+BAA+B;IAC/B,yBAAyB;;IAEzB,iBAAiB;IACjB,gBAAgB;;IAEhB;6DACyD;AAC7D;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".button {\n    height: 3.5rem;\n    padding: 0.6rem 1rem 0.6rem 1rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    border: 0.1rem solid var(--grey-light);\n    border-radius: 0.5rem;\n    text-align: center;\n    text-decoration: none;\n\n    cursor: pointer;\n    outline: none;\n\n    color: #fff;\n    background-color: var(--orange);\n    text-transform: uppercase;\n\n    font-size: 1.3rem;\n    font-weight: 600;\n\n    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n                border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n}\n\n.button:hover {\n    background-color: #fdaa4f;\n    border-color: #fc890c;\n}\n\n.img {\n    margin: 0 1rem 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Irqqsv0oZuRLm5N0Ttns",
	"img": "_xLEsZ_DimW_T1pxz1l6"
};
export default ___CSS_LOADER_EXPORT___;
