// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.oyYQsrWu1fLCz0UlzgXK {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: auto max-content;\n\n    align-items: center;\n}\n\n.HgUAyG82g5blEibGWK1C {\n    display: grid;\n    align-items: center;\n\n    height: 100%;\n    padding: 0 2rem;\n\n    border: solid 0.1rem var(--grey-light);\n    border-left: 0;\n    border-right: 0;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.HgUAyG82g5blEibGWK1C img {\n    width: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/dropdown-standards/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uCAAuC;;IAEvC,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,YAAY;IACZ,eAAe;;IAEf,sCAAsC;IACtC,cAAc;IACd,eAAe;;IAEf,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;AACf","sourcesContent":["\n.standardTypeSelector {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: auto max-content;\n\n    align-items: center;\n}\n\n.unfurlButton {\n    display: grid;\n    align-items: center;\n\n    height: 100%;\n    padding: 0 2rem;\n\n    border: solid 0.1rem var(--grey-light);\n    border-left: 0;\n    border-right: 0;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.unfurlButton img {\n    width: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standardTypeSelector": "oyYQsrWu1fLCz0UlzgXK",
	"unfurlButton": "HgUAyG82g5blEibGWK1C"
};
export default ___CSS_LOADER_EXPORT___;
