import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const LogoRound = () => {
    const title = "The Juice Logo";

    return (
        <img
            src={staticFiles.juiceLogo192}
            alt={title}
            title={title}
            className={styles.logoImg}
        />
    );
};

export default LogoRound;
