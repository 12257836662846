import React, { useState } from "react";

import moment from "moment";

import "react-dates/initialize.js";
import "react-dates/lib/css/_datepicker.css";
import { ICON_AFTER_POSITION } from "react-dates/src/constants.js";

import { SingleDatePicker } from "react-dates";

import classNames from "juice-base/lib/class-names.js";
import { tryFormatDate, getDateFromDate } from "juice-base/lib/date.js";

import "./styles.css";
import styles from "./styles.module.css";


const DatePickerSingle = (props) => {
    const [isFocused, setFocused] = useState(false);

    const onFocusChange = (val) => {
        setFocused(val.focused);
    };

    const isBlocked = (day) => {
        if (props.availableDates === "all") {
            return false;
        }

        const availableDates = [];

        props.availableDates.forEach((date) => {
            const dateAndTime = date.split(" ");

            if (dateAndTime[0]) {
                availableDates.push(dateAndTime[0]);
            }
        });

        const newDay = tryFormatDate(day, getDateFromDate);

        if (availableDates.indexOf(newDay) !== -1) {
            return false;
        }

        return true;
    };

    const renderDayContents = (date) => {
        const day = new Date(date).getDate();

        return (<div>{day}</div>);
    };

    const dayPickerClassName = classNames({
        [styles.datePicker]: true,
        pickerInputLong: props.pickerInputLong,
        pickerInputDropdown: props.isDropdownPicker,
    });

    // TODO: check if id has conflicts
    return (
        <div
            className={dayPickerClassName}
            data-comment={props.dataComment}
        >
            <SingleDatePicker
                id="date-picker-single"
                date={moment(props.date)}
                onDateChange={props.onChange}
                focused={isFocused}
                onFocusChange={onFocusChange}
                numberOfMonths={1}
                displayFormat={props.displayFormat}
                showDefaultInputIcon={props.showDefaultInputIcon}
                inputIconPosition={ICON_AFTER_POSITION}
                customInputIcon={props.customInputIcon}
                renderDayContents={renderDayContents}
                noBorder={props.noBorder}
                isDayBlocked={isBlocked}
                isOutsideRange={() => false}
                enableOutsideDays
                disabled={props.disabled}
            />
        </div>
    );
};

DatePickerSingle.defaultProps = {
    date: null,
    availableDates: "all",
    displayFormat: "MMMM DD, YYYY",
    customInputIcon: null,
    noBorder: true,
    disabled: false,
    showDefaultInputIcon: true,
    pickerInputLong: true,
    isDropdownPicker: false,
    dataComment: "date-picker-single",
    onChange: () => {},
};

export default DatePickerSingle;
