import React from "react";
import { Link } from "react-router-dom";
import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const LinkBlockWithImage = (props) => {
    const {
        title,
        date,
        showImage,
        imgUrl,
        juiceUrl,
    } = props;

    const renderImage = () => {
        if (!showImage || !imgUrl) {
            return null;
        }

        return (
            <img
                src={imgUrl}
                alt={title}
                title={title}
            />
        );
    };

    return (
        <div
            className={[
                styles.link,
                props.linkClassName,
            ].join(" ")}
        >
            <div className={styles.blockImg}>
                {renderImage()}
            </div>
            <Link
                to={juiceUrl}
                className={styles.block}
            >
                <div className={styles.dot} />
                <div className={styles.blockTitle}>
                    <div className={styles.date}>
                        {date}
                    </div>
                    <div className={styles.juiceTitle}>
                        {title}
                    </div>
                </div>
                <div className={styles.arrowRight}>
                    <img
                        src={staticFiles.arrowInCircle}
                        alt="Open"
                        title="Open"
                    />
                </div>
            </Link>
        </div>
    );
};

LinkBlockWithImage.defaultProps = {
    title: "",
    date: "",
    showImage: true,
    imgUrl: null,
    juiceUrl: null,
    linkClassName: "",
};

export default LinkBlockWithImage;
