// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oOlXqBpdGoD95gbFq1K_ {\n    position: relative;\n    background: white;\n\n    border-radius: 0.5rem;\n}\n\n._1rwsYHVEZMAjLLe7mjK {\n    display: grid;\n\n    border-radius: 0.4rem;\n\n    overflow: hidden;\n}\n\n._1rwsYHVEZMAjLLe7mjK div {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: start;\n\n    padding: 1.5rem 2rem;\n    border: solid 0.1rem #ededed;\n}\n\n.Ndl0j8Avrz4QrxWW_gr6 {\n    width: 2rem;\n    height: 2rem;\n}\n\n.wktzxQiMQDops8jMP8Yz {\n    position: absolute;\n    right: 1rem;\n    top: 1rem;\n}", "",{"version":3,"sources":["webpack://./base/business/popup-daily-juice-states-legend/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;;IAEjB,qBAAqB;AACzB;;AAEA;IACI,aAAa;;IAEb,qBAAqB;;IAErB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,sBAAsB;;IAEtB,oBAAoB;IACpB,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb","sourcesContent":[".popup {\n    position: relative;\n    background: white;\n\n    border-radius: 0.5rem;\n}\n\n.legend {\n    display: grid;\n\n    border-radius: 0.4rem;\n\n    overflow: hidden;\n}\n\n.legend div {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: start;\n\n    padding: 1.5rem 2rem;\n    border: solid 0.1rem #ededed;\n}\n\n.icon {\n    width: 2rem;\n    height: 2rem;\n}\n\n.close {\n    position: absolute;\n    right: 1rem;\n    top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "oOlXqBpdGoD95gbFq1K_",
	"legend": "_1rwsYHVEZMAjLLe7mjK",
	"icon": "Ndl0j8Avrz4QrxWW_gr6",
	"close": "wktzxQiMQDops8jMP8Yz"
};
export default ___CSS_LOADER_EXPORT___;
