import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as events from "juice-base/events.js";

import storage from "juice-base/lib/storage/index.js";
import actions from "juice-base/store/actions.js";

import PopupAccountSettings from "juice-base/components/popup-account-settings/index.js";
import PopupUserLogout from "juice-base/components/popup-user-logout/index.js";
import UserMainFooter from "juice-base/components/user-main-footer/index.js";

import settings from "juice-app/settings";
import api from "juice-app/api.js";


const UserFooter = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const store = useSelector((state) => ({
        session: state.user.session,
        user: state.user.user,
        location: state.navigation.location,
    }));

    const [isVisibleAccountSettings, setVisibleAccountSettings] = useState(false);
    const [isVisibleLogout, setVisibleLogout] = useState(false);

    const onLogout = () => {
        api.user.signOut({
            session: store.session,
        });

        events.userSignOut({
            session: store.session,
            location: store.location.pathname,
        });

        storage.local.clearSession();

        dispatch(actions.user.clearUser());

        dispatch(actions.juices.clearJuices());
        dispatch(actions.juices.clearJuicesDates());

        dispatch(actions.studentTodo.clearIncompletedJuices());

        history.push("/user/sign-in");
    };

    const onOpenLogout = () => {
        setVisibleLogout(true);
    };

    const onCloseLogout = () => {
        setVisibleLogout(false);
    };

    const onOpenAccountSettings = () => {
        setVisibleAccountSettings(true);
    };

    const onCloseAccountSettings = () => {
        setVisibleAccountSettings(false);
    };

    const renderPopup = () => {
        if (isVisibleAccountSettings) {
            const accountSettings = [
                {
                    title: "Username",
                    value: store.user.userName,
                },
                {
                    title: "Email",
                    value: store.user.email,
                },
            ];

            return (
                <PopupAccountSettings
                    onClose={onCloseAccountSettings}
                    accountSettings={accountSettings}
                />
            );
        }

        if (isVisibleLogout) {
            return (
                <PopupUserLogout
                    onClose={onCloseLogout}
                    onLogout={onLogout}
                />
            );
        }

        return null;
    };

    return (
        <>
            {renderPopup()}
            <UserMainFooter
                onLogout={onOpenLogout}
                onSettingsClick={onOpenAccountSettings}
                privacyPolicyLink={settings.privacyPolicyLink}
                supportLink={settings.supportLink}
                copyright={settings.copyright}
            />
        </>
    );
};

export default UserFooter;
