import React, { useRef } from "react";

import PopupCommon from "juice-base/components/popup-common/index.js";

import ImageWithZoom from "juice-base/components/image-with-zoom/index.js";

import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";
import styles from "./styles.module.css";


const PopupImage = (props) => {
    const backgroundLayout = useRef(null);

    const { image } = props;

    if (!image) {
        return null;
    }

    const closePopup = (evt) => {
        if (backgroundLayout
            && backgroundLayout.current === evt.target) {
            props.onClose();
        }
    };

    let close = null;

    if (props.showClose) {
        // TODO: normal component
        /* eslint-disable */
        close = (
            <div
                className={styles.close}
                onClick={props.onClose}
                onKeyPress={props.onClose}
                role="button"
                tabIndex="-1"
            />
        );
        /* eslint-enable */
    }

    const imageScrollerClasses = [
        juiceScrollbarStyles.scrollbar,
        styles.imageScroller,
    ];

    const content = (
        <div
            ref={backgroundLayout}
            className={styles.imagePopup}
            onClick={closePopup}
            onKeyPress={closePopup}
            role="button"
            tabIndex="-1"
        >
            <div className={styles.imageWrapper}>
                {close}
                <div className={imageScrollerClasses.join(" ")}>
                    <ImageWithZoom
                        src={image.url}
                        title={image.title}
                        className={styles.img}
                        toolsClassName={styles.zoomButtons}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
            popupClasses={props.popupClasses}
        >
            {content}
        </PopupCommon>
    );
};

PopupImage.defaultProps = {
    image: null,
    showClose: true,
    popupClasses: "",
    onClose: () => {},
};

export default PopupImage;
