import {
    SET_TEMPLATE_LOADING_BY_SLUG,
    SET_TEMPLATE_BY_SLUG,
} from "./types.js";


const initialState = {
    templatesBySlug: {},
};

export default (state, action) => {
    switch (action.type) {
        case SET_TEMPLATE_LOADING_BY_SLUG: {
            const { slug } = action.payload;
            const template = state.templatesBySlug[slug] || {};

            return {
                ...state,
                templatesBySlug: {
                    ...state.templatesBySlug,
                    [slug]: {
                        isLoading: true,
                        data: {},
                        ...template,
                    },
                },
            };
        }

        case SET_TEMPLATE_BY_SLUG: {
            const { slug, template } = action.payload;

            return {
                ...state,
                templatesBySlug: {
                    ...state.templatesBySlug,
                    [slug]: {
                        isLoading: false,
                        data: template,
                    },
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
