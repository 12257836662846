import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";


const Image = (props) => {
    if (!props.src) {
        return null;
    }

    const [isLoadingError, setIsLoadingError] = useState(false);

    if (isLoadingError) {
        const errorTitle = "Ops! We can't load the image";
        return (
            <img
                src={staticFiles.imageNotFound}
                alt={errorTitle}
                title={errorTitle}
            />
        );
    }

    return (
        <img
            src={props.src}
            alt={props.title}
            title={props.title}
            className={props.className}
            onError={() => { setIsLoadingError(true); }}
        />
    );
};

Image.defaultProps = {
    src: "",
    title: "",
    className: "",
};

export default Image;
