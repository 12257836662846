// NOTE: this is old api, no new functions
import { post } from "./lib/net.js";


const apiURL = API_URL; // eslint-disable-line no-undef

/* --- */

export const addEvent = ({
    session,
    locationId,
    eventId,
    objectId = 0,
    subObjectId = 0,
    subSubObjectId = 0,
}) => {
    const url = `${apiURL}/events`;

    return post(url, {
        session_id: session,
        location_id: locationId,
        event_id: eventId,
        object_id: objectId,
        sub_object_id: subObjectId,
        sub_sub_object_id: subSubObjectId,
    });
};

export default {};
