import { useState } from "react";


const useTeacherPopupWelcome = () => {
    const [state, setState] = useState({
        isOpen: false,
        skipTutorial: false,
    });

    const open = () => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
        }));
    };

    const close = () => {
        setState((prev) => ({
            ...prev,
            isOpen: false,
        }));
    };

    const skipTutorial = () => {
        setState((prev) => ({
            ...prev,
            skipTutorial: true,
        }));
    };

    return {
        state,
        open,
        close,
        skipTutorial,
    };
};

export default useTeacherPopupWelcome;
