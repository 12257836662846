// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M6UelChfzmgYgQVaOr9x {\n    display: grid;\n    grid-auto-flow: column;\n    position: absolute;\n    width: 100%;\n    background: white;\n    box-shadow: 0rem 0.4rem 0.6rem 0.2rem #c4c4c46e;\n}\n\n.ZK6LEkwfxMU89l_lXGSn {\n    display: grid;\n    align-items: center;\n\n    min-height: 20rem;\n\n    margin: 7rem 2rem 1rem;\n}\n\n._HtFALLsRzqoBmh0sYNj {\n    display: grid;\n    grid-gap: 3rem;\n}\n\n.EQ9QNy3LI9Vw_zjvWi0P {\n    display: grid;\n    grid-gap: 1rem;\n}\n\n.EQ9QNy3LI9Vw_zjvWi0P div:first-child {\n    text-transform: uppercase;\n}\n\n.EQ9QNy3LI9Vw_zjvWi0P div:last-child {\n    color: var(--grey);\n}", "",{"version":3,"sources":["webpack://./base/business/popup-learning-standards/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,+CAA+C;AACnD;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,iBAAiB;;IAEjB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".tabs {\n    display: grid;\n    grid-auto-flow: column;\n    position: absolute;\n    width: 100%;\n    background: white;\n    box-shadow: 0rem 0.4rem 0.6rem 0.2rem #c4c4c46e;\n}\n\n.content {\n    display: grid;\n    align-items: center;\n\n    min-height: 20rem;\n\n    margin: 7rem 2rem 1rem;\n}\n\n.standards {\n    display: grid;\n    grid-gap: 3rem;\n}\n\n.contentStandard {\n    display: grid;\n    grid-gap: 1rem;\n}\n\n.contentStandard div:first-child {\n    text-transform: uppercase;\n}\n\n.contentStandard div:last-child {\n    color: var(--grey);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "M6UelChfzmgYgQVaOr9x",
	"content": "ZK6LEkwfxMU89l_lXGSn",
	"standards": "_HtFALLsRzqoBmh0sYNj",
	"contentStandard": "EQ9QNy3LI9Vw_zjvWi0P"
};
export default ___CSS_LOADER_EXPORT___;
