import React from "react";
import { useSelector } from "react-redux";

import User from "juice-base/project/user.js";
import { withAuth } from "juice-base/components/auth/index.js";

import TeacherIndex from "juice-app/containers/teacher-index/index.js";

import StudentIndexRegular from "juice-app/containers/student-index-regular/index.js";
import StudentIndexTrial from "juice-app/containers/student-index-trial/index.js";


const Index = () => {
    const store = useSelector((state) => ({
        user: state.user.user,
    }));

    if (User.hasRoleTeacher(store.user)) {
        return (
            <TeacherIndex />
        );
    }

    if (User.hasRoleStudent(store.user) || User.hasRoleGuardian(store.user)) {
        if (User.isTypeTrial(store.user)) {
            return (
                <StudentIndexTrial />
            );
        }

        return (
            <StudentIndexRegular />
        );
    }

    return null;
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(Index);
