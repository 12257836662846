import React from "react";

import staticFiles from "juice-base/static-files.js";

import Video from "juice-base/components/video/index.js";
import FeaturedImage from "juice-base/components/featured-image/index.js";
import JuiceContent from "juice-base/components/juice-content/index.js";
import ButtonWithIcon from "juice-base/components/button-with-icon/index.js";
import ClassroomShareButton from "juice-base/components/google-classroom-share-button/index.js";
import EventsWrapper from "juice-base/components/events-wrapper/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStory = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    let hasImage = false;

    if (story.featuredImage && story.featuredImage.url) {
        hasImage = true;
    }

    let hasVideo = false;

    if (story.featuredVideo && story.featuredVideo.url) {
        hasVideo = true;
    }

    const onListenStory = () => {
        props.onAudioPlay();
    };

    const renderFeaturedVideo = () => {
        const { featuredVideo } = story;

        let posterUrl = "";

        if (featuredVideo.featuredImage && featuredVideo.featuredImage.url) {
            posterUrl = featuredVideo.featuredImage.url;
        }

        return (
            <EventsWrapper className={styles.featuredVideoContainer}>
                <Video
                    videoId={featuredVideo.id}
                    src={featuredVideo.url}
                    type={featuredVideo.mimeType}
                    captionSrc={props.videoCaptionSrc}
                    poster={posterUrl}
                    onPlayStart={props.onVideoPlayStart}
                    onPlayEnd={props.onVideoPlayEnd}
                    onPause={props.onVideoPlayPause}
                    canPlay={props.isVisible}
                    isDefault={props.isDefaultVideo}
                />
            </EventsWrapper>
        );
    };

    const renderFeaturedContent = () => {
        if (hasImage) {
            const { featuredImage } = story;

            return (
                <FeaturedImage
                    featuredImage={featuredImage}
                    dimensions={props.dimensions}
                    onFullscreenImage={(img) => {
                        props.onImageClick(img);
                    }}
                />
            );
        }

        if (hasVideo) {
            return renderFeaturedVideo();
        }

        return null;
    };

    const renderListenButton = () => {
        if (hasVideo) {
            return null;
        }

        let shareButton = null;

        if (props.withShareClassroomButton) {
            shareButton = (
                <ClassroomShareButton
                    title={props?.story?.title || ""}
                    url={`/daily-juice/${props?.story?.ID || ""}`}
                />
            );
        }

        return (
            <div className={styles.playerAudioToggleButton}>
                <ButtonWithIcon
                    iconName="Headphone"
                    iconUrl={staticFiles.iconPlayerHeadphoneWhite}
                    onClick={onListenStory}
                >
                    Listen
                </ButtonWithIcon>
                {shareButton}
            </div>
        );
    };

    const categoryClassName = [
        dailyJuiceCommonStyles.header,
        styles.category,
    ].join(" ");

    const headlineClassName = [
        dailyJuiceCommonStyles.headline,
        styles.headline,
    ].join(" ");

    return (
        <div className={styles.juiceStory}>
            {renderFeaturedContent()}

            <div className={categoryClassName}>
                {`${props.storyIndex + 1} | ${story.categoryName}`}
            </div>

            <div className={headlineClassName}>
                {story.title}
            </div>

            {renderListenButton()}

            <JuiceContent
                content={story.content}
                onWordClick={(word) => {
                    props.onWordClick(word);
                }}
                onExtraJuiceWordClick={(openedBy) => {
                    const sId = story.ID || story.id;
                    const ejs = story.extraJuices || [];
                    const ejId = ejs.length > 0 ? ejs[0].id : null;

                    props.onExtraJuiceWordClick(sId, ejId, openedBy);
                }}
                className={styles.content}
                extraJuices={story.extraJuices || []}
            />
        </div>
    );
};

DailyJuiceStory.defaultProps = {
    story: null,
    storyIndex: 0,
    dimensions: {},
    isDefaultVideo: true,
    withShareClassroomButton: false,
    videoCaptionSrc: null,
    onImageClick: () => {},
    onWordClick: () => {},
    onAudioPlay: () => {},
    onVideoPlayStart: () => {},
    onVideoPlayPause: () => {},
    onVideoPlayEnd: () => {},
    onExtraJuiceWordClick: () => {},
};

export default DailyJuiceStory;
