import React from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import Icon from "juice-base/components/icon/index.js";
import Image from "juice-base/components/image/index.js";

import styles from "./styles.module.css";


const FeaturedImage = (props) => {
    const {
        featuredImage,
        dimensions,
    } = props;

    const defaultImage = {
        id: featuredImage.id,
        title: featuredImage.title,
        url: featuredImage.url,
    };

    const image = {
        title: featuredImage.title,
        url: featuredImage.url,
    };

    const mediumLargeImagesWidth = 400;

    if (featuredImage?.sizes?.large?.src) {
        image.url = featuredImage.sizes.large.src;
    }

    if (dimensions.width !== 0 && dimensions.width <= mediumLargeImagesWidth) {
        if (featuredImage?.sizes?.medium_large?.src) { // eslint-disable-line camelcase
            image.url = featuredImage.sizes.medium_large.src;
        }
    }

    const onClick = () => {
        if (featuredImage.infographic) {
            props.onFullscreenImage(defaultImage);
        }
    };

    const renderIcon = () => {
        if (!featuredImage.infographic) {
            return null;
        }

        return (
            <Icon
                url={staticFiles.iconFullscreenOrange}
                name="fullscreen"
                className={styles.featuredImgFullscreen}
                onClick={(evt) => {
                    evt.stopPropagation();
                    onClick();
                }}
            />
        );
    };

    const containerClassName = classNames({
        [styles.featuredImgContainer]: true,
        [styles.featuredImgContainerClickable]: featuredImage.infographic,
        [props.className]: props.className,
    });

    return (
        <div
            className={containerClassName}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="-1"
            data-comment="featured-image"
        >
            <Image
                src={image.url}
                title={image.title}
                className={styles.featuredImg}
            />
            {renderIcon()}
        </div>
    );
};

FeaturedImage.defaultProps = {
    featuredImage: {},
    dimensions: {},
    className: "",
    onFullscreenImage: () => {},
};

export default FeaturedImage;
