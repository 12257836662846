import React, { useState } from "react";

import { DATES } from "juice-base/lib/date.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ChartPie from "juice-base/components/chart-pie/index.js";
import Skeleton from "juice-base/components/skeleton/index.js";
import SkeletonHeader from "juice-base/components/skeleton-header/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";
import SectionRange from "juice-base/components/section-range/index.js";
import SkeletonTabsSelector from "juice-base/components/skeleton-tabs-selector/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
    SectionTeacherMessage,
} from "juice-base/components/section-teacher/index.js";

import styles from "./styles.module.css";


const TeacherClassActivity = (props) => {
    const defaultPage = "completion-rate";

    const [selectedPage, setSelectedPage] = useState(defaultPage);

    const [selectedDateRange, setSelectedDateRange] = useState("today");

    const onStatsPageChange = (values) => {
        setSelectedPage(values.value);
    };

    const onDateRangeChange = (value) => {
        props.onRangeChange(value);

        setSelectedDateRange(value);
    };

    const renderPageContentSkeleton = () => {
        const skeletonPie = (
            <div className={styles.skeletonPie}>
                <Skeleton variant="circle" height={20} width={20} />
                <div className={styles.skeletonPieData}>
                    <Skeleton variant="text" fullWidth />
                    <Skeleton variant="text" fullWidth />
                    <Skeleton variant="text" fullWidth />
                </div>
            </div>
        );

        if (props.isMobile) {
            return skeletonPie;
        }

        const rangeSection = (
            <div className={styles.skeletonRange}>
                <Skeleton variant="text" fullWidth />
                <Skeleton variant="rect" fullWidth />
                <Skeleton variant="text" fullWidth />
            </div>
        );

        return (
            <div className={styles.skeletonContent}>
                {skeletonPie}
                <div className={styles.skeletonRanges}>
                    {rangeSection}
                    {rangeSection}
                </div>
            </div>
        );
    };

    const renderTimeRange = () => {
        if (props.isSkeleton) {
            return <SkeletonTabsSelector />;
        }

        const dates = [
            DATES.today,
            DATES.yesterday,
            DATES.thisWeek,
            DATES.lastWeek,
            DATES.thisMonth,
            DATES.past3Months,
        ];

        return (
            <div className={styles.dateRange}>
                <MenuHorizontalScrolling
                    items={dates}
                    selected={selectedDateRange}
                    onSelect={onDateRangeChange}
                />
            </div>
        );
    };

    const renderCompletionRatePage = () => {
        return (
            <div>
                <ChartPie
                    title="Completion Rate"
                    chartContentClasses={styles.chartContent}
                    data={[
                        {
                            label: "Complete",
                            value: props.chartPie.complete,
                        },
                        {
                            label: "In Progress",
                            value: props.chartPie.inProgress,
                        },
                        {
                            label: "Unopened",
                            value: props.chartPie.unopened,
                        },
                    ]}
                />
            </div>
        );
    };

    const renderScorePage = () => {
        return (
            <div className={styles.scorePage}>
                <div className={styles.scoresRangeSections}>
                    <SectionRange
                        label="Average Score"
                        min={0}
                        max={props.stats.average}
                        borderTop={props.isMobile}
                        noMinHandle
                    />
                    <SectionRange
                        label="Score Range"
                        min={props.stats.range.min}
                        max={props.stats.range.max}
                        borderTop={false}
                    />
                </div>
                <div className={styles.scorePageControls}>
                    <ButtonBig
                        type="button"
                        onClick={props.onViewAllScores}
                    >
                        View all scores
                    </ButtonBig>
                </div>
            </div>
        );
    };

    const renderSectionContent = () => {
        let pageContent = null;

        if (props.isSkeleton) {
            pageContent = renderPageContentSkeleton();
        } else if (props.isLoading) {
            pageContent = <RequestLoader />;
        } else if (!props.stats || !props.chartPie) {
            let range = props.dropdownRange;

            if (props.dropdownRange === "this-week") {
                range = "this week";
            }

            if (props.dropdownRange === "last-week") {
                range = "last week";
            }

            if (props.dropdownRange === "this-month") {
                range = "this month";
            }

            pageContent = (
                <SectionTeacherMessage>
                    {`The Juice was off ${range}, and so there is no activity data to see.
                    Change the timeframe to see your class activity stats.`}
                </SectionTeacherMessage>
            );
        } else if (props.isMobile) {
            if (selectedPage === "completion-rate") {
                pageContent = renderCompletionRatePage();
            } else if (selectedPage === "score") {
                pageContent = renderScorePage();
            }
        } else {
            pageContent = (
                <div className={styles.sectionContent}>
                    {renderCompletionRatePage()}
                    {renderScorePage()}
                </div>
            );
        }

        return pageContent;
    };

    const renderContent = () => {
        return (
            <div className={styles.page}>
                {renderTimeRange()}
                {renderSectionContent()}
            </div>
        );
    };

    let blockHeader = (
        <div className={styles.headerNameTitle}>
            Class Activity
        </div>
    );

    let rightControls = [
        <SectionTeacherExplanation
            pageWidth={props.pageWidth}
            alignLeft={props.pageWidth < 920}
        >
            Student activity and progress in today&apos;s edition of the Daily Juice.
        </SectionTeacherExplanation>,
    ];

    const bottomControls = [];

    if (props.isMobile && !props.isSkeleton) {
        bottomControls.push(
            <Tabs
                tabs={[
                    {
                        label: "Completion rate",
                        value: "completion-rate",
                    },
                    {
                        label: "Score",
                        value: "score",
                    },
                ]}
                selectedTab={selectedPage}
                blueTheme
                onlyTabs
                onChange={onStatsPageChange}
            />,
        );
    }

    if (props.isSkeleton) {
        blockHeader = <SkeletonHeader />;

        rightControls = (
            <Skeleton
                variant="rect"
                height={2.5}
            />
        );
    }

    return (
        <SectionTeacher dataComment="daily-juice-stats">
            <SectionTeacherHeader
                label={blockHeader}
                rightControls={rightControls}
                bottomControls={bottomControls}
            />
            {renderContent()}
        </SectionTeacher>
    );
};

TeacherClassActivity.defaultProps = {
    pageWidth: 0,
    stats: {},
    chartPie: {},
    dropdownRange: null,
    error: null,
    isLoading: false,
    isSkeleton: true,
    isMobile: true,
    onRangeChange: () => {},
    onViewAllScores: () => {},
};

export default TeacherClassActivity;
