// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xrwwj2h7I_5BM0Ld3W_m {\n    display: grid;\n    justify-self: center;\n\n    max-width: 50rem;\n    width: 100%;\n}\n\n._5JCnvde5Ma0yDnS_SDc {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 2rem 1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}\n\n.urvWEva57XMIQuZroXHA {\n    font-weight: 600;\n}\n\n.svxcjfsSzfM6Ylw1Plpt {\n    display: grid;\n}\n\n.GpP5JkskZYH2dl9t011h {\n    padding: 2rem 0;\n\n    font-weight: 600;\n    font-size: 2.1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}", "",{"version":3,"sources":["webpack://./base/business/teacher-share-class-code/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;;IAEpB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,8BAA8B;IAC9B,mBAAmB;;IAEnB,kBAAkB;;IAElB,6CAA6C;AACjD;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;;IAEf,gBAAgB;IAChB,iBAAiB;;IAEjB,6CAA6C;AACjD","sourcesContent":[".shareClassCodePage {\n    display: grid;\n    justify-self: center;\n\n    max-width: 50rem;\n    width: 100%;\n}\n\n.shareClassCodePageBlock {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 2rem 1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}\n\n.shareClassCodePageBlockLabel {\n    font-weight: 600;\n}\n\n.shareClassCodePageBlocks {\n    display: grid;\n}\n\n.shareClassCodePageTitle {\n    padding: 2rem 0;\n\n    font-weight: 600;\n    font-size: 2.1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareClassCodePage": "Xrwwj2h7I_5BM0Ld3W_m",
	"shareClassCodePageBlock": "_5JCnvde5Ma0yDnS_SDc",
	"shareClassCodePageBlockLabel": "urvWEva57XMIQuZroXHA",
	"shareClassCodePageBlocks": "svxcjfsSzfM6Ylw1Plpt",
	"shareClassCodePageTitle": "GpP5JkskZYH2dl9t011h"
};
export default ___CSS_LOADER_EXPORT___;
