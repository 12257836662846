import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const HorizontalScrollingMenu = (props) => {
    const [isGrabbing, setGrabbing] = useState(false);

    const onMouseDown = () => {
        setGrabbing(true);
    };

    const onMouseLeave = () => {
        setGrabbing(false);
    };

    const onMouseUp = () => {
        setGrabbing(false);
    };

    const arrowClassName = classNames({
        [styles.arrow]: true,
        [styles.arrowHidden]: props.hideArrows,
    });

    const arrowRight = (
        <img
            className={styles.arrowRight}
            src={staticFiles.arrowRight}
            alt="Scroll Left"
        />
    );

    const arrowLeft = (
        <img
            className={styles.arrowLeft}
            src={staticFiles.arrowRight}
            alt="Scroll Right"
        />
    );

    const menuClassName = classNames({
        [props.menuClassName]: props.menuClassName,
        [styles.cursorGrabbing]: isGrabbing,
    });

    const innerWrapperClassName = classNames({
        [styles.innerWrapperClass]: true,
        [styles.elementsWithCursorPointer]: !isGrabbing,
    });

    return (
        <div
            className={menuClassName}
            data-comment={props.dataComment}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            role="button"
            tabIndex="-1"
        >
            <ScrollMenu
                data={props.menu}
                innerWrapperClass={innerWrapperClassName}
                itemClass={props.itemClass}
                arrowClass={arrowClassName}
                menuStyle={props.menuStyle}
                arrowRight={arrowRight}
                arrowLeft={arrowLeft}
                arrowDisabledClass={styles.arrowDisabled}
                inertiaScrollingSlowdown={1}
                onLastItemVisible={props.onLastItemVisible}
                onFirstItemVisible={props.onFirstItemVisible}
                selected={props.selected}
                onSelect={props.onSelect}
                alignCenter={false}
                wheel={false}
                scrollToSelected={props.scrollToSelected}
                hideSingleArrow
                disableTabindex
                inertiaScrolling
            />
        </div>
    );
};

HorizontalScrollingMenu.defaultProps = {
    menu: [],
    menuClassName: "",
    itemClass: "",
    menuStyle: {},
    dataComment: "",
    selected: 0,
    hideArrows: false,
    scrollToSelected: true,
    onSelect: () => {},
    onLastItemVisible: () => {},
    onFirstItemVisible: () => {},
};

export default HorizontalScrollingMenu;
