import React from "react";

import SectionStudent from "../section-student/index.js";

import styles from "./styles.module.css";


const SectionSponsor = (props) => {
    const renderImage = () => {
        const {
            djHomeImage,
            djHomeUrl,
        } = props.sponsor;

        let img = null;

        if (djHomeImage) {
            const imageTitle = "Sponsor image";

            img = (
                <img
                    className={styles.sponsorImg}
                    src={djHomeImage}
                    alt={imageTitle}
                    title={imageTitle}
                />
            );
        }

        if (djHomeUrl) {
            return (
                <a
                    href={djHomeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.sponsorLink}
                    onClick={props.onSponsorClick}
                >
                    {img}
                </a>
            );
        }

        return img;
    };

    return (
        <SectionStudent
            title=""
            dataComment="section-sponsor"
            showSeeMore={false}
        >
            {renderImage()}
        </SectionStudent>
    );
};

SectionSponsor.defaultProps = {
    sponsor: null,
    onSponsorClick: () => {},
};

export default SectionSponsor;
