// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yx9HH3xUwA9DbzDsNJCE {\n    min-width: 25rem;\n}\n\n.kKjC1MK6wHgT38SfCFBL {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n    margin: 0 0 1rem 0;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.5rem;\n\n    background-color: var(--text-primary-bg-color);\n    color: var(--text-primary-color);\n}\n\n.l7IMZ6C6cHAv9uwb3_K9 {\n    margin: 0 0 0.5rem 0;\n}\n\n.laY1ERA7KH7usHKi3sKO {\n    display: grid;\n    padding: 2rem 0;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/quiz-question/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;IAEnB,oBAAoB;IACpB,kBAAkB;;IAElB,gDAAgD;IAChD,qBAAqB;;IAErB,8CAA8C;IAC9C,gCAAgC;AACpC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".form {\n    min-width: 25rem;\n}\n\n.field {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n    margin: 0 0 1rem 0;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.5rem;\n\n    background-color: var(--text-primary-bg-color);\n    color: var(--text-primary-color);\n}\n\n.answerError {\n    margin: 0 0 0.5rem 0;\n}\n\n.buttons {\n    display: grid;\n    padding: 2rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Yx9HH3xUwA9DbzDsNJCE",
	"field": "kKjC1MK6wHgT38SfCFBL",
	"answerError": "l7IMZ6C6cHAv9uwb3_K9",
	"buttons": "laY1ERA7KH7usHKi3sKO"
};
export default ___CSS_LOADER_EXPORT___;
