import React from "react";

import staticFiles from "juice-base/static-files.js";
import Text from "juice-base/components/text/index.js";

import styles from "./styles.module.css";


const QuizQuestionAnswers = (props) => {
    const { answers } = props;

    const renderAnswers = () => {
        let hasAnswer = false;

        for (let i = 0; i < answers.length; i += 1) {
            if (answers[i].isCorrect !== "") {
                hasAnswer = true;
                break;
            }
        }

        return answers.map((a) => {
            const aClasses = [styles.answer];
            let icon = null;

            if (a.isCorrect) {
                aClasses.push(styles.answerCorrect);
                icon = (
                    <img
                        src={staticFiles.iconCheck}
                        alt="Correct answer"
                        title="Correct answer"
                        className={styles.answerIcon}
                    />
                );
            } else if (a.isUserChoice && !a.isCorrect) {
                aClasses.push(styles.answerIncorrect);
                icon = (
                    <img
                        src={staticFiles.iconCancel}
                        alt="Incorrect answer"
                        title="Incorrect answer"
                        className={styles.answerIcon}
                    />
                );
            } else if (hasAnswer) {
                aClasses.push(styles.answerOther);
                icon = (<span />);
            }

            return (
                <div className={aClasses.join(" ")}>
                    {icon}
                    <Text>
                        {a.answer}
                    </Text>
                </div>
            );
        });
    };

    return (
        <div className={styles.answers}>
            {renderAnswers()}
        </div>
    );
};

QuizQuestionAnswers.defaultProps = {
    answers: [],
    quizId: -1,
};

export default QuizQuestionAnswers;
