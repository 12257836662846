import React, { useState } from "react";

import Grades from "juice-base/project/grades.js";
import Students from "juice-base/project/students.js";

import UploaderCSV from "juice-base/components/forms/uploader-csv/index.js";
import WizardMultiStep from "juice-base/components/wizard-multi-step/index.js";

import TeacherAddStudentsRows from "juice-base/business/teacher-add-students-rows/index.js";
import PopupConfirmClassStudentsLimit from "juice-base/business/popup-confirm-class-students-limit/index.js";

import styles from "./styles.module.css";


const TeacherImportStudents = (props) => {
    const [importStudentsState, setImportStudentsState] = useState({
        step: 0,
        importedRows: [],
    });

    const [
        isVisibleClassStudentsLimitPopup,
        setIsVisibleClassStudentsLimitPopup,
    ] = useState(false);


    const onUploadDifferentFile = () => {
        setImportStudentsState({
            step: 0,
            importedRows: [],
        });
    };

    const onImportBackToEdit = () => {
        setImportStudentsState((prev) => ({
            ...prev,
            step: 1,
        }));
    };

    const onProccedToConfirmation = (rows) => {
        setImportStudentsState((prev) => ({
            ...prev,
            importedRows: rows,
            step: 2,
        }));
    };

    const onImportAddStudents = (rows) => {
        props.onAddStudentsToClass(rows);

        setImportStudentsState({
            step: 0,
            importedRows: [],
        });
    };

    const onImportComplete = (params) => {
        const rows = params.results.map((student, index) => {
            // NOTE: first row is header row
            if (index === 0) {
                return null;
            }

            const studentData = {
                name: student?.data?.[0] || "",
                lastname: student?.data?.[1] || "",
                email: student?.data?.[2] || "",
                grade: Grades.getValidGrade(student?.data?.[3] || props.defaultGrade),
            };

            if (!studentData.name && !studentData.lastname && !studentData.email) {
                return null;
            }

            const nameError = Students.validateName(studentData.name);
            const lastNameError = Students.validateLastName(studentData.lastname);
            const emailError = studentData.email ? Students.validateEmail(studentData.email) : null;

            return {
                nameValue: studentData.name,
                nameError,

                lastNameValue: studentData.lastname,
                lastNameError,

                emailValue: studentData.email,
                emailError,

                gradeValue: studentData.grade,
            };
        }).filter((row) => row !== null);

        if (!props.noRowsLimit && rows.length > props.rowsLimit) {
            setIsVisibleClassStudentsLimitPopup(true);
        }

        setImportStudentsState((prev) => ({
            ...prev,
            step: 1,
            importedRows: rows,
        }));
    };

    let importTemplate = null;

    if (props.importStudentsTemplateUrl) {
        importTemplate = (
            <div className={styles.importTemplate}>
                <div>Not sure what to upload?</div>
                <div>Only .csv files are accepted</div>
                <a
                    href={props.importStudentsTemplateUrl}
                >
                    Download and edit this template
                </a>
            </div>
        );
    }

    const uploadFileContent = (
        <div>
            <UploaderCSV
                onUploadComplete={onImportComplete}
            />
            {importTemplate}
        </div>
    );

    const reviewDataContent = (
        <TeacherAddStudentsRows
            imported
            editable
            supportLink={props.supportLink}
            rows={importStudentsState.importedRows}
            rowsLimit={props.rowsLimit}
            noRowsLimit={props.noRowsLimit}
            defaultGrade={props.defaultGrade}
            grades={props.grades}
            onRemoveStudent={props.onRemoveStudent}
            onAddStudentsToClass={props.onAddStudentsToClass}
            onUploadDifferentFile={onUploadDifferentFile}
            onProccedToConfirmation={onProccedToConfirmation}
        />
    );

    let addStudentsContent = null;

    if (importStudentsState.step === 2) {
        addStudentsContent = (
            <TeacherAddStudentsRows
                imported
                preview
                supportLink={props.supportLink}
                defaultGrade={props.defaultGrade}
                grades={props.grades}
                rowsLimit={props.rowsLimit}
                noRowsLimit={props.noRowsLimit}
                rows={importStudentsState.importedRows}
                onRemoveStudent={props.onRemoveStudent}
                onAddStudentsToClass={props.onAddStudentsToClass}
                onImportBackToEdit={onImportBackToEdit}
                onImportAddStudents={onImportAddStudents}
            />
        );
    }

    const wizardSteps = [
        { name: "Upload File", content: uploadFileContent },
        { name: "Review Data", content: reviewDataContent },
        { name: "Add Students", content: addStudentsContent },
    ];

    const onCloseClassStudentsLimitPopup = () => {
        setIsVisibleClassStudentsLimitPopup(false);
    };

    const renderClassStudentsLimitPopup = () => {
        if (!isVisibleClassStudentsLimitPopup) {
            return null;
        }

        return (
            <PopupConfirmClassStudentsLimit
                isDescription
                rowsLimit={props.rowsLimit}
                rowsAdded={importStudentsState.importedRows.length}
                onClose={onCloseClassStudentsLimitPopup}
            />
        );
    };

    return (
        <>
            {renderClassStudentsLimitPopup()}
            <WizardMultiStep
                steps={wizardSteps}
                step={importStudentsState.step}
                onStepChange={(step) => {
                    setImportStudentsState((prev) => ({
                        ...prev,
                        step,
                    }));
                }}
            />
        </>
    );
};

TeacherImportStudents.defaultProps = {
    importStudentsTemplateUrl: "",
    supportLink: "",

    rowsLimit: 3,
    noRowsLimit: false,
    defaultGrade: "G7-G8",
    grades: [],
    onAddStudentsToClass: () => {},
    onRemoveStudent: () => {},
};

export default TeacherImportStudents;
