import React from "react";

import staticFiles from "juice-base/static-files.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStats = (props) => {
    const onGoToSkipped = () => {
        props.onScrollTo();
    };

    const renderSponsor = () => {
        const { sponsors } = props;

        if (!sponsors.djLastPageImage) {
            return null;
        }

        const sponsorImage = (
            <img
                src={sponsors.djLastPageImage}
                alt="Sponsor"
                title="Sponsor"
            />
        );

        if (!sponsors.djLastPageUrl) {
            return sponsorImage;
        }

        return (
            <a
                href={sponsors.djLastPageUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={props.onSponsorClick}
                onKeyPress={props.onSponsorClick}
            >
                {sponsorImage}
            </a>
        );
    };

    const renderFinished = () => {
        return (
            <>
                <div className={styles.statsMessage}>
                    <img
                        src={staticFiles.emojisHappy}
                        alt="Happy"
                        title="Happy"
                        className={styles.statsMessageLogo}
                    />

                    <div className={styles.statsMessageText}>
                        <div>All done!</div>
                        <div>
                            {`You got ${props.stats.correct} out of ${props.stats.total} questions right today.`}
                        </div>
                    </div>
                </div>
                <div className={styles.sponsorImage}>
                    {renderSponsor()}
                </div>
            </>
        );
    };

    const renderIsNotFinished = () => {
        const skippedButtonClasses = [styles.goToSkippedButtonText];

        if (props.isWindows) {
            skippedButtonClasses.push(styles.goToSkippedButtonTextWindowsHack);
        }

        return (
            <div className={styles.statsMessage}>
                <div className={styles.statsUnfinishedIssueText}>
                    {`${props.stats.total} questions in this issue`}
                </div>

                <div className={styles.statsValues}>
                    <div className={styles.statsBlock}>
                        <div className={styles.statsValueMain}>
                            Answered
                        </div>
                        <div className={styles.statValue}>
                            {props.stats.answered}
                        </div>
                    </div>
                    <div className={styles.statsSubBlock}>
                        <div className={styles.statsValueSub}>
                            Correct
                        </div>
                        <div className={styles.statValue}>
                            {props.stats.correct}
                        </div>
                    </div>
                    <div className={styles.statsBlock}>
                        <div className={styles.statsValueSub}>
                            Incorrect
                        </div>
                        <div className={styles.statValue}>
                            {props.stats.answered - props.stats.correct || 0}
                        </div>
                    </div>
                    <div className={[styles.statsBlock, styles.statsBlockLast].join(" ")}>
                        <div className={styles.statsValueMain}>
                            Skipped
                        </div>
                        <div className={styles.statValue}>
                            {props.stats.total - props.stats.answered || 0}
                        </div>
                    </div>
                </div>

                <div
                    className={styles.goToSkippedButton}
                    onClick={onGoToSkipped}
                    onKeyPress={onGoToSkipped}
                    role="button"
                    tabIndex="-1"
                >
                    <div className={skippedButtonClasses.join(" ")}>
                        Go to first skipped question
                    </div>
                </div>
            </div>
        );
    };

    const renderStatsMessage = () => {
        if (props.stats.answered === props.stats.total) {
            return renderFinished();
        }

        return renderIsNotFinished();
    };

    const statsClassName = [
        dailyJuiceCommonStyles.block,
        dailyJuiceCommonStyles.blockWithPadding,
    ].join(" ");

    return (
        <div className={statsClassName}>
            <div className={dailyJuiceCommonStyles.header}>
                Score overview
            </div>

            {renderStatsMessage()}
        </div>
    );
};

DailyJuiceStats.defaultProps = {
    stats: {},
    sponsors: {},
    isWindows: false,
    onScrollTo: () => {},
    onSponsorClick: () => {},
};

export default DailyJuiceStats;
