import React from "react";

import Close from "../close/index.js";

import styles from "./styles.module.css";


const PopupScroller = (props) => {
    const onClose = () => {
        props.onClose();
    };

    let close = null;

    if (props.showClose) {
        close = (
            <Close
                className={styles.popupClose}
                onClose={onClose}
            />
        );
    }

    return (
        <div
            data-comment="popup-scroller"
            className={[
                styles.popup,
                props.popupClasses,
            ].join(" ")}
        >
            {close}
            {props.children}
        </div>
    );
};

PopupScroller.defaultProps = {
    popupClasses: "",
    showClose: true,
    onClose: () => {},
    children: null,
};

export default PopupScroller;
