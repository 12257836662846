// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XTN85MS5tUn4AgXeGHP_ {\n    display: grid;\n    grid-auto-flow: column;\n    grid-auto-columns: 1fr;\n    grid-column-gap: 2rem;\n\n    align-items: start;\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/form-row/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;;IAErB,kBAAkB;AACtB","sourcesContent":[".row {\n    display: grid;\n    grid-auto-flow: column;\n    grid-auto-columns: 1fr;\n    grid-column-gap: 2rem;\n\n    align-items: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "XTN85MS5tUn4AgXeGHP_"
};
export default ___CSS_LOADER_EXPORT___;
