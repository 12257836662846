import React, { useEffect } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import useDimensions from "juice-base/hooks/use-dimensions/index.js";

import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";
import urls from "juice-base/lib/urls.js";

import ErrorBoundary from "juice-base/components/error-boundary/index.js";
import ErrorBoundaryWithValue from "juice-base/components/error-boundary-with-value/index.js";
import Layout from "juice-base/components/layout/index.js";

import UserPopupConfirmExpiration from "juice-app/containers/user-popup-confirm-expiration/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";
import rootRoutes from "juice-app/routes/root.js";


const RootLayout = () => {
    const loc = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const dimensions = useDimensions();

    const {
        version,
        session,
        user,
        location,
    } = useSelector((state) => ({
        version: state.app.version,
        session: state.user.session,
        user: state.user.user,
        location: state.navigation.location,
    }));

    const getIsVisibleMobileHeader = () => {
        if (dimensions.width >= 901) {
            return true;
        }

        for (let i = 0; i < settings.mobileHeaderHiddenPaths.length; i += 1) {
            const path = settings.mobileHeaderHiddenPaths[i];
            const match = useRouteMatch(path);

            if (match) {
                return false;
            }
        }

        return true;
    };

    const loadUserOptions = (userSession) => {
        api.user.getOptions({
            session: userSession,
        }).then((res) => {
            const options = res.ok ? res.data || [] : [];

            dispatch(actions.user.setUserOptions({
                options: User.optionsArrToObj(options),
            }));
        });
    };

    const loadUser = () => {
        let userSession = storage.session.loadSession();

        if (!userSession) {
            userSession = storage.local.loadSession();
        }

        dispatch(actions.user.setUserSession(userSession));

        if (userSession) {
            api.user.checkSession({
                session: userSession,
            }).then((res) => {
                if (res.ok) {
                    dispatch(actions.user.setUser(res.user));
                    loadUserOptions(userSession);
                } else {
                    storage.local.clearSession();
                    history.push("/user/sign-in");
                }

                dispatch(actions.user.setUserLoading(false));
            });
        } else {
            dispatch(actions.user.setUserLoading(false));
        }
    };

    const onError = (error) => {
        if (error) {
            const userSession = storage.session.loadSession()
                  || storage.local.loadSession();

            api.error.sendError({
                session: userSession,
                error: {
                    ...error,
                    url: location.pathname,
                },
            });
        }
    };

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        if (window.zE) {
            window.zE("webWidget", "hide");
        }
    }, [user, loc]);

    useEffect(() => {
        dispatch(actions.device.setDimensions(dimensions));
    }, [dimensions.height, dimensions.width]);

    useEffect(() => {
        dispatch(actions.navigation.setLocation(loc));
    }, [loc]);

    const onSubscribe = () => {
        const { domain, routeSignUp } = settings.landingSite;
        const url = `${domain}${routeSignUp}`;
        urls.openUrl(url);
    };

    const onSignOut = () => {
        events.user.signOut({
            session,
            location: location.pathname,
        });

        api.user.signOut({ session });
        storage.local.clearSession();

        dispatch(actions.user.clearUser());

        dispatch(actions.juices.clearJuices());
        dispatch(actions.juices.clearJuicesDates());

        dispatch(actions.studentTodo.clearIncompletedJuices());

        history.push("/user/sign-in");
    };

    return (
        <ErrorBoundary onError={onError}>
            <UserPopupConfirmExpiration
                isStudent={User.hasRoleStudent(user)}
                isTeacher={User.hasRoleTeacher(user)}
                onSignOut={onSignOut}
            />
            <Layout
                pathname={loc.pathname}
                appName={settings.appName}
                copyright={settings.copyright}
                version={version}
                sectionsByRoles={settings.sectionsByRoles}
                user={user}
                supportLink={settings.supportLink}
                onSubscribe={onSubscribe}
                onSignOut={onSignOut}
                isVisibleMobileHeader={getIsVisibleMobileHeader()}
            >
                <ErrorBoundaryWithValue
                    changeValue={loc.pathname}
                    onError={onError}
                >
                    {rootRoutes}
                </ErrorBoundaryWithValue>
            </Layout>
        </ErrorBoundary>
    );
};

export default RootLayout;
