import React from "react";

import styles from "./styles.module.css";


const MessagePassword = (props) => {
    if (props.passwordMinLength === -1) {
        return null;
    }

    const message = `
        Your password should be at least ${props.passwordMinLength} characters long,
        use upper and lower case letters, numbers, and at least one symbol.
    `;

    return (
        <div className={styles.message}>
            {message}
        </div>
    );
};

MessagePassword.defaultProps = {
    passwordMinLength: -1,
};

export default MessagePassword;
