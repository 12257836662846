import React, { useState } from "react";

import Standards from "juice-base/project/standards.js";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";

import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";
import SectionRange from "juice-base/components/section-range/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Accordion from "juice-base/components/accordion/index.js";

import Skeleton from "juice-base/components/skeleton/index.js";
import SkeletonTabsSelector from "juice-base/components/skeleton-tabs-selector/index.js";
import SkeletonHeader from "juice-base/components/skeleton-header/index.js";

import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
    SectionTeacherMessage,
} from "juice-base/components/section-teacher/index.js";

import DropdownStandards from "juice-base/business/dropdown-standards/index.js";

import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";
import styles from "./styles.module.css";


const TeacherLearningStandards = (props) => {
    const standardTypes = Standards.getStandardsTypes();

    const [selectedStandardType, setSelectedStandardType] = useState(standardTypes[0].value);

    const [extendedSectionsIndexes, setExtendedSectionsIndexes] = useState([]);

    const [selectedDateRange, setSelectedDateRange] = useState("today");

    const getStandardsByType = (type) => {
        const standards = props.standards.map((s) => {
            return {
                anchorStandardGroup: s.anchorStandardGroup,
                standards: s.standards.filter((standard) => standard.type === type),
            };
        });

        return standards;
    };

    const onDateRangeChange = (value) => {
        setExtendedSectionsIndexes([]);

        props.onRangeChange(value);

        setSelectedDateRange(value);
    };

    const onChangeStandardType = (values) => {
        setSelectedStandardType(values.value);

        setExtendedSectionsIndexes([]);
    };

    const onUnfurlStandards = () => {
        const newIndexes = [];

        if (extendedSectionsIndexes.length === 0) {
            const standards = getStandardsByType(selectedStandardType);

            for (let i = 0; i < standards.length; i += 1) {
                newIndexes.push(i);
            }
        }

        setExtendedSectionsIndexes(newIndexes);
    };

    const onAccordionClick = (idx) => {
        let newExtendedSectionsIndexes = [...extendedSectionsIndexes];

        if (newExtendedSectionsIndexes.indexOf(idx) === -1) {
            newExtendedSectionsIndexes.push(idx);
        } else {
            newExtendedSectionsIndexes = newExtendedSectionsIndexes
                .filter((val) => val !== idx);
        }

        setExtendedSectionsIndexes(newExtendedSectionsIndexes);
    };

    const renderSkeletonContent = () => {
        return (
            <div className={styles.skeletonContent}>
                <Skeleton variant="rect" height={3} fullWidth />
                <Skeleton variant="rect" height={3} fullWidth />
                <Skeleton variant="rect" height={3} fullWidth />
            </div>
        );
    };

    const renderTimeRange = () => {
        if (props.isSkeleton) {
            return <SkeletonTabsSelector />;
        }

        const dates = [
            date.DATES.today,
            date.DATES.oneWeek,
            date.DATES.twoWeeks,
            date.DATES.oneMonth,
            date.DATES.threeMonths,
        ];

        return (
            <div className={styles.dateRange}>
                <MenuHorizontalScrolling
                    items={dates}
                    selected={selectedDateRange}
                    onSelect={onDateRangeChange}
                />
            </div>
        );
    };

    const renderStandardTypeDropdown = () => {
        const standards = getStandardsByType(selectedStandardType);

        if (standards.length === 0) {
            return null;
        }

        // TODO: replace with select-custom
        return (
            <DropdownStandards
                values={standardTypes}
                selected={selectedStandardType}
                isExtended={extendedSectionsIndexes.length === standards.length}
                onUnfurlStandards={onUnfurlStandards}
                onChange={onChangeStandardType}
            />
        );
    };

    const renderContent = () => {
        if (props.isSkeleton) {
            return renderSkeletonContent();
        }

        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        const standards = getStandardsByType(selectedStandardType);

        let content = standards.map((standard, idx) => {
            let expanded = false;

            if (extendedSectionsIndexes.indexOf(idx) !== -1) {
                expanded = true;
            }

            const accordionClassName = classNames({
                [styles.accordionBorderTop]: idx !== 0,
            });

            const stds = standard.standards.map((s) => {
                return (
                    <SectionRange
                        label={s.standard}
                        min={0}
                        max={s.percent}
                        noHandlers
                        noBottomData
                        noPadding
                        noBorder
                    />
                );
            });

            return (
                <div className={accordionClassName}>
                    <Accordion
                        label={standard.anchorStandardGroup}
                        expanded={expanded}
                        onClick={() => {
                            onAccordionClick(idx);
                        }}
                    >
                        <div className={styles.accordionStandards}>
                            {stds}
                        </div>
                    </Accordion>
                </div>
            );
        });

        let resultsButton = (
            <ButtonBig
                type="button"
                onClick={props.onViewResults}
            >
                View results by student
            </ButtonBig>
        );

        if (standards.length === 0) {
            content = (
                <SectionTeacherMessage>
                    Nothing to see here either. Change the timeframe to see learning standards data.
                </SectionTeacherMessage>
            );

            resultsButton = null;
        }

        const controlsClassName = classNames({
            [styles.controls]: resultsButton,
        });

        const sectionClassName = [
            juiceScrollbarStyles.scrollbar,
            styles.contentSectionContent,
        ].join(" ");

        return (
            <div className={styles.contentSection}>
                {renderStandardTypeDropdown()}

                <div className={sectionClassName}>
                    {content}
                </div>

                <div className={controlsClassName}>
                    {resultsButton}
                </div>
            </div>
        );
    };

    let headerLabel = "Learning Standards";

    let explanationMark = (
        <SectionTeacherExplanation
            pageWidth={props.pageWidth}
            alignLeft
        >
            Get an overview of class performance by learning standard.
            Percentages capture the number of correct answers
            relative to the number of attempted answers.
        </SectionTeacherExplanation>
    );

    if (props.isSkeleton) {
        headerLabel = (
            <SkeletonHeader />
        );

        explanationMark = (
            <Skeleton variant="circle" height={2} width={2} />
        );
    }

    return (
        <SectionTeacher>
            <SectionTeacherHeader
                label={headerLabel}
                rightControls={[explanationMark]}
            />
            <div className={styles.content}>
                {renderTimeRange()}
                {renderContent()}
            </div>
        </SectionTeacher>
    );
};

TeacherLearningStandards.defaultProps = {
    pageWidth: 0,
    standards: [],
    isSkeleton: true,
    isLoading: false,
    onViewResults: () => {},
    onRangeChange: () => {},
};

export default TeacherLearningStandards;
