import React from "react";

import Chip from "juice-base/components/chip/index.js";

import styles from "./styles.module.css";


const MenuHorizontal = (props) => {
    const menuItems = props.items.map((menuItem) => {
        return (
            <Chip
                key={menuItem.value}
                label={menuItem.label}
                selected={menuItem.value === props.selected}
                onClick={() => {
                    props.onSelect(menuItem.value);
                }}
                isRoseTheme={props.isRoseTheme}
            />
        );
    });

    return (
        <div
            className={styles.menuHorizontal}
            data-comment={props.dataComment}
        >
            {menuItems}
        </div>
    );
};

MenuHorizontal.defaultProps = {
    items: [],
    selected: null,
    isRoseTheme: false,
    dataComment: "menu-horizontal",
    onSelect: () => {},
};

export default MenuHorizontal;
