import * as types from "./types.js";


export const setJuices = (payload) => ({
    type: types.SET_JUICES,
    payload,
});

export const clearJuices = () => ({
    type: types.CLEAR_JUICES,
});

export const setJuice = (payload) => ({
    type: types.SET_JUICE,
    payload,
});

export const clearJuice = (payload) => ({
    type: types.CLEAR_JUICE,
    payload,
});

export const setJuiceQuestion = (payload) => ({
    type: types.SET_JUICE_QUESTION,
    payload,
});

export const setJuiceQuestionAnswer = (payload) => ({
    type: types.SET_JUICE_QUESTION_ANSWER,
    payload,
});

export const setLastVisitedJuice = (payload) => ({
    type: types.SET_LAST_VISITED_JUICE,
    payload,
});

export const setJuicesLoading = (payload) => ({
    type: types.SET_JUICES_LOADING,
    payload,
});

export const setJuicesPage = (payload) => ({
    type: types.SET_JUICES_PAGE,
    payload,
});

export const setStoryViewed = (payload) => ({
    type: types.SET_JUICE_STORY_VIEWED,
    payload,
});

export const setStoryVideoViewed = (payload) => ({
    type: types.SET_JUICE_STORY_VIDEO_VIEWED,
    payload,
});

export const setJuicesDates = (payload) => ({
    type: types.SET_JUICES_DATES,
    payload,
});

export const clearJuicesDates = () => ({
    type: types.CLEAR_JUICES_DATES,
});

export const setJuicesDatesLoaded = (payload) => ({
    type: types.SET_JUICES_DATES_LOADED,
    payload,
});

export const setStudentJuicesById = ({
    studentId,
    juices,
    hasMore,
    page,
}) => ({
    type: types.SET_STUDENT_JUICES_BY_ID,
    payload: {
        studentId,
        juices,
        hasMore,
        page,
    },
});

export const setStudentJuicesByIdLoading = ({ studentId }) => ({
    type: types.SET_STUDENT_JUICES_BY_ID_LOADING,
    payload: {
        studentId,
    },
});

export const clearStudentJuicesById = ({ studentId }) => ({
    type: types.CLEAR_STUDENT_JUICES_BY,
    payload: {
        studentId,
    },
});
