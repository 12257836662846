import React from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Image from "juice-base/components/image/index.js";
import Video from "juice-base/components/video/index.js";
import JuiceContentFirstLines from "juice-base/components/juice-content-first-lines/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStoryCard = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    let hasFeaturedImage = false;
    let hasVocabFeaturedImage = false;

    if (story.featuredImage?.url) {
        hasFeaturedImage = true;
    }

    if (story.featuredImage?.vocabUrl) {
        hasVocabFeaturedImage = true;
    }

    const storyVideo = {
        hasVideo: false,
        isUserViewedVideo: false,
    };

    if (story.featuredVideo?.url) {
        storyVideo.hasVideo = true;
        storyVideo.isUserViewedVideo = story.featuredVideo.isUserViewed;
    }

    const onListenStory = () => {
        props.onAudioPlay();
    };

    const renderFeaturedVideo = () => {
        const { featuredVideo } = story;

        let posterUrl = "";

        if (featuredVideo?.featuredImage?.url) {
            posterUrl = featuredVideo.featuredImage.url;
        }

        return (
            <div className={styles.featuredVideoContainer}>
                <Video
                    src={featuredVideo.url}
                    captionSrc={props.videoCaptionUrl}
                    videoId={featuredVideo.id}
                    poster={posterUrl}
                    type={featuredVideo.mimeType}
                    onPlayStart={props.onVideoPlayStart}
                    onPlayEnd={props.onVideoPlayEnd}
                    onPause={props.onVideoPlayPause}
                    isDefault={props.isDefaultVideo}
                />
            </div>
        );
    };

    const renderFeaturedContent = () => {
        if (hasFeaturedImage || hasVocabFeaturedImage) {
            let image = { ...story.featuredImage };

            if (hasVocabFeaturedImage) {
                image = {
                    url: story.featuredImage.vocabUrl,
                    title: "Vocabulary",
                    infographic: false,
                };
            }

            const imageClassName = classNames({
                [styles.featuredImage]: true,
                [styles.featuredInfographicImage]: image.infographic,
            });

            return (
                <div
                    className={styles.featuredImgContainer}
                    onClick={props.onReadMore}
                    onKeyPress={props.onReadMore}
                    role="button"
                    tabIndex="-1"
                >
                    <Image
                        src={image.url}
                        title={image.title}
                        className={imageClassName}
                    />
                </div>
            );
        }

        if (storyVideo.hasVideo) {
            return renderFeaturedVideo();
        }

        return null;
    };

    const renderHeadline = () => {
        const headlineClassName = [
            dailyJuiceCommonStyles.headline,
            styles.headline,
        ].join(" ");

        return (
            <div
                className={headlineClassName}
                onClick={props.onReadMore}
                onKeyPress={props.onReadMore}
                role="button"
                tabIndex="-1"
            >
                {story.title}
            </div>
        );
    };

    const renderQuizVideoStatus = () => {
        const quizClassName = classNames({
            [styles.quizStatus]: true,
            [styles.quizStatusVideo]: storyVideo.hasVideo,
            [styles.quizStatusVideoCorrect]: storyVideo.hasVideo && storyVideo.isUserViewedVideo,
        });

        let statusIcon = (
            <>
                <img
                    className={styles.quizStatusIcon}
                    src={staticFiles.iconPlayBlue}
                    alt={DailyJuice.StoryStates.videoNew}
                    title={DailyJuice.StoryStates.videoNew}
                />
                {DailyJuice.StoryStates.videoNew}
            </>
        );

        if (storyVideo.isUserViewedVideo) {
            statusIcon = (
                <>
                    <img
                        className={styles.quizStatusIcon}
                        src={staticFiles.iconPlayGreen}
                        alt={DailyJuice.StoryStates.videoWatched}
                        title={DailyJuice.StoryStates.videoWatched}
                    />
                    {DailyJuice.StoryStates.videoWatched}
                </>
            );
        }

        return (
            <div className={quizClassName}>
                {statusIcon}
            </div>
        );
    };

    const renderQuizStatus = () => {
        if (storyVideo.hasVideo) {
            return renderQuizVideoStatus();
        }

        if (!story.isUserOpenedStory) {
            const newStoryClassName = [
                styles.quizStatus,
                styles.quizStatusCorrect,
            ].join(" ");

            return (
                <div className={newStoryClassName}>
                    <img
                        className={styles.quizStatusIcon}
                        src={staticFiles.iconNotepadExclamation}
                        alt={DailyJuice.StoryStates.storyNew}
                        title={DailyJuice.StoryStates.storyNew}
                    />
                    {DailyJuice.StoryStates.storyNew}
                </div>
            );
        }

        const quizzes = props.story.quizzes || [];

        if (quizzes.length === 0) {
            return (
                <div className={styles.quizStatus}>
                    <img
                        className={styles.quizStatusIcon}
                        src={staticFiles.iconNotepadCheck}
                        alt={DailyJuice.StoryStates.storyEmpty}
                        title={DailyJuice.StoryStates.storyEmpty}
                    />
                    {DailyJuice.StoryStates.quizEmpty}
                </div>
            );
        }

        const quiz = quizzes[0];
        const isAnswered = DailyJuice.isAnsweredQuiz(quiz);
        const isValid = isAnswered ? DailyJuice.isValidQuiz(quiz) : false;

        const quizClassName = classNames({
            [styles.quizStatus]: true,
            [styles.quizStatusIncorrect]: !isAnswered,
        });

        if (isAnswered) {
            if (isValid) {
                return (
                    <div className={quizClassName}>
                        <img
                            className={styles.quizStatusIcon}
                            src={staticFiles.iconNotepadCheck}
                            alt={DailyJuice.StoryStates.quizCorrect}
                            title={DailyJuice.StoryStates.quizCorrect}
                        />
                        {DailyJuice.StoryStates.quizCorrect}
                    </div>
                );
            }

            return (
                <div className={quizClassName}>
                    <img
                        className={styles.quizStatusIcon}
                        src={staticFiles.iconNotepadX}
                        alt={DailyJuice.StoryStates.quizIncorrect}
                        title={DailyJuice.StoryStates.quizIncorrect}
                    />
                    {DailyJuice.StoryStates.quizIncorrect}
                </div>
            );
        }

        return (
            <div className={quizClassName}>
                <img
                    className={styles.quizStatusIcon}
                    src={staticFiles.iconNotepadStop}
                    alt={DailyJuice.StoryStates.quizSkipped}
                    title={DailyJuice.StoryStates.quizSkipped}
                />
                {DailyJuice.StoryStates.quizSkipped}
            </div>
        );
    };

    const renderListenButton = () => {
        if (storyVideo.hasVideo) {
            return null;
        }

        return (
            <div
                className={styles.listenButton}
                role="button"
                tabIndex="-1"
                onKeyPress={onListenStory}
                onClick={onListenStory}
            >
                <img
                    src={staticFiles.iconPlayerHeadphoneOrange}
                    alt="Headphone"
                    title="Headphone"
                />
            </div>
        );
    };

    const renderStoryControl = () => {
        return (
            <div className={styles.control}>
                <div
                    className={styles.quizStatusSection}
                    onKeyPress={props.onReadMore}
                    onClick={props.onReadMore}
                    tabIndex="-1"
                    role="button"
                >
                    {renderQuizStatus()}
                </div>
                {renderListenButton()}
            </div>
        );
    };

    const categoryClassName = [
        dailyJuiceCommonStyles.header,
        styles.category,
    ].join(" ");

    return (
        <div
            className={dailyJuiceCommonStyles.block}
            data-comment="daily-juice-story-card"
        >
            {renderFeaturedContent()}

            <div className={styles.cardContent}>
                <div className={categoryClassName}>
                    {`${props.storyIndex + 1} | ${story.categoryName}`}
                </div>

                {renderHeadline()}

                <JuiceContentFirstLines
                    content={story.content}
                    className={styles.content}
                    onClick={props.onReadMore}
                />

                {renderStoryControl()}
            </div>
        </div>
    );
};

DailyJuiceStoryCard.defaultProps = {
    story: null,
    storyIndex: 0,
    videoCaptionUrl: "",
    isDefaultVideo: true,
    onAudioPlay: () => {},
    onVideoPlayEnd: () => {},
    onVideoPlayPause: () => {},
    onVideoPlayStart: () => {},
    onReadMore: () => {},
};

export default DailyJuiceStoryCard;
