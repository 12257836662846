import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment"; // TODO: delete

import Students from "juice-base/project/students.js";
import Grades from "juice-base/project/grades.js";
import Classes from "juice-base/project/classes.js";

import useAddStudentPopup from "juice-base/hooks/use-add-student-popup/index.js";
import useStudentInfoPopup from "juice-base/hooks/use-student-info-popup/index.js";
import useProgressPopupAddStudents from "juice-base/hooks/use-progress-popup-add-students/index.js";
import useClassCodePopup from "juice-base/hooks/use-class-code-popup/index.js";
import useSnackbar from "juice-base/hooks/use-snackbar/index.js";

import classNames from "juice-base/lib/class-names.js";
import copyToClipboard from "juice-base/lib/clipboard.js";
import { getDateFromDate, getDatesByRange } from "juice-base/lib/date.js";
import storage from "juice-base/lib/storage/index.js";
import actions from "juice-base/store/actions.js";

import { withAuth } from "juice-base/components/auth/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import LoaderSmall from "juice-base/components/loader-small/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import TableStudents from "juice-base/components/table-students/index.js";
import AlertBox from "juice-base/components/alert-box/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";

import PopupResetPassword from "juice-base/components/popup-reset-password/index.js";
import PopupNewPassword from "juice-base/components/popup-new-password/index.js";

import PopupAddStudentsProgress from "juice-base/business/popup-add-students-progress/index.js";
import PopupConfirmGenerateClassCode from "juice-base/business/popup-confirm-generate-class-code/index.js";
import PopupConfirmDeleteStudent from "juice-base/business/popup-confirm-delete-student/index.js";
import PopupConfirmError from "juice-base/business/popup-confirm-error/index.js";
import PopupFullScreenAddStudent from "juice-base/business/popup-full-screen-add-student/index.js";
import PopupConfirmClassStudentsLimit from "juice-base/business/popup-confirm-class-students-limit/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import TeacherPopupStudentInfo from "juice-app/containers/teacher-popup-student-info/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import * as events from "juice-base/events.js";
import api from "juice-app/api.js";

import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    dimensions: state.device.dimensions,
    juices: state.juices,
    teacher: state.teacher,
});

const Class = () => {
    const defaultDateRange = "today";

    const [dateRange, setDateRange] = useState({
        selectedRange: defaultDateRange,
        startDate: null,
        endDate: null,
    });

    const studentInfoPopup = useStudentInfoPopup();
    const addStudentPopup = useAddStudentPopup();
    const progressPopupAddStudents = useProgressPopupAddStudents();

    /* ---------- */

    const classCodePopup = useClassCodePopup();
    const classCodeLinkPopup = useClassCodePopup();

    const snackbar = useSnackbar();

    /* ---------- */

    const [dismissStudentState, setDismissStudentState] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        id: null,
    });

    const [acceptStudentState, setAcceptStudentState] = useState({
        isLoading: false,
        id: null,
    });

    /* ---------- */

    const [resetStudentPasswordPopup, setResetStudentPasswordPopup] = useState({
        isOpen: false,
        isLoading: false,
        message: null,
    });

    const [deleteSelectedUsersPopup, setDeleteSelectedUsersPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        students: [],
    });

    const [deleteStudentPopup, setDeleteStudentPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        id: -1,
    });

    const [studentsState, setStudents] = useState({
        isLoaded: false,
        isOverview: false,
        message: null,
        students: [],
    });

    const [newPasswordPopupState, setNewPasswordPopupState] = useState({
        isOpen: false,
        isLoading: false,
        isSubmitted: true,
        message: null,
        id: null,
    });

    const [selectedSortByValue, setSelectedSortByValue] = useState(() => {
        const sValues = Students.getSortValues();

        if (sValues && sValues.length > 0) {
            return sValues[0].value;
        }

        return "";
    });

    const [
        lastTouchedStudentIdWithPendingStatus,
        setLastTouchedStudentIdWithPendingStatus,
    ] = useState(null);

    const [
        lastTouchedStudentIdWithResetPasswordRequest,
        setLastTouchedStudentIdWithResetPasswordRequest,
    ] = useState(null);

    const [
        isVisibleClassStudentsLimitPopup,
        setIsVisibleClassStudentsLimitPopup,
    ] = useState(false);

    let sortedStudents = studentsState.students.filter((student) => {
        return Students.isStatusActive(student);
    });

    sortedStudents = Students.sortBy(selectedSortByValue, sortedStudents, studentsState.isOverview);

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    let hostname = "";

    if (window?.location?.hostname) {
        hostname = `https://${window.location.hostname}`;
    }

    const signUpUrl = `${hostname}${settings.signUpByCodePath}`;

    /* --- */

    const getTeacherClassId = (classes) => {
        let classId = storage.local.loadTeacherSelectedClass();

        let classStillExists = false;

        if (classId) {
            for (let i = 0; i < classes.length; i += 1) {
                if (classes[i].id === parseInt(classId, 10)) {
                    classStillExists = true;
                    break;
                }
            }
        }

        classId = parseInt(classId, 10);

        if (!classStillExists && classes[0]) {
            classId = classes[0].id || -1;
            storage.local.saveTeacherSelectedClass(classId);
        }

        return classId;
    };

    const getClassGradeById = (classId) => {
        const defaultGrade = Classes.getClassGradeById(
            store.teacher.classes,
            classId,
        );

        return defaultGrade || "G7";
    };

    const getStudentWithPendingStatusById = (id) => {
        let student = null;

        for (let i = 0; i < store.teacher.studentsWithPendingStatus.length; i += 1) {
            if (store.teacher.studentsWithPendingStatus[i].id === id) {
                student = store.teacher.studentsWithPendingStatus[i];
                break;
            }
        }

        return student;
    };

    /* --- */

    const loadImportStudentsTemplate = () => {
        api.students.getImportStudentsTemplate({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                addStudentPopup.setTemplate(res.templateLink);
            }
        });
    };

    const loadStudentDailyJuices = (studentId, clearJuices = true) => {
        if (clearJuices) {
            dispatch(actions.juices.clearStudentJuicesById({
                studentId,
            }));
        }

        dispatch(actions.juices.setStudentJuicesByIdLoading({
            studentId,
        }));

        let page = 0;

        if (!clearJuices && store.juices?.studentJuicesById[studentId]?.page) {
            page = store.juices.studentJuicesById[studentId].page;
        }

        api.students.getStudentDailyJuicesResultsByPage({
            session: store.session,
            studentId,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setStudentJuicesById({
                    studentId,
                    juices: res.juices,
                    hasMore: res.hasMore,
                    page: page + 1,
                }));
            }
        });
    };

    const loadStudentInfo = (studentId) => {
        studentInfoPopup.open(studentId);

        api.students.getStudentInfo({
            session: store.session,
            studentId,
        }).then((res) => {
            if (res.ok) {
                // TODO: hmmm?
                dispatch(actions.teacherStudents.setStudentById({
                    student: res.student || {},
                }));
            }

            studentInfoPopup.setStudent(res.student);
        });
    };

    /* --- */

    const onGenerateClassCode = () => {
        classCodePopup.open();

        const classCode = Classes.getClassCodeById(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        if (classCode) {
            classCodePopup.setCode(classCode);
        } else {
            api.signup.generateClassCode({
                session: store.session,
                classId: store.teacher.selectedClassId,
            }).then((res) => {
                if (!res.ok) {
                    classCodePopup.setError(res.error);
                    return;
                }

                classCodePopup.setCode(res.code);

                dispatch(actions.teacher.setClassCode({
                    classId: store.teacher.selectedClassId,
                    classCode: res.code,
                }));
            });
        }
    };

    const onDirectLinkClick = () => {
        classCodeLinkPopup.open();

        const classCode = Classes.getClassCodeById(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        if (classCode) {
            classCodeLinkPopup.setCode(classCode);
        } else {
            api.signup.generateClassCode({
                session: store.session,
                classId: store.teacher.selectedClassId,
            }).then((res) => {
                if (!res.ok) {
                    classCodeLinkPopup.setError(res.error);
                    return;
                }

                classCodeLinkPopup.setCode(res.code);

                dispatch(actions.teacher.setClassCode({
                    classId: store.teacher.selectedClassId,
                    classCode: res.code,
                }));
            });
        }
    };

    const onAddStudentsToClass = (idx, students, addNewOnDuplicate = false) => {
        if (!students[idx]) {
            progressPopupAddStudents.setInProgress(false);
            return;
        }

        const newStudents = [...students];

        newStudents[idx] = {
            ...newStudents[idx],
            status: "loading",
        };

        progressPopupAddStudents.setStudents({
            index: idx,
            students: newStudents,
        });

        const student = newStudents[idx];

        const selectedGrade = student.gradeValue.split("-")[1];

        api.classes.addStudentV2({
            session: store.session,
            classId: store.teacher.selectedClassId,
            firstname: student.nameValue,
            lastname: student.lastNameValue,
            email: student.emailValue,
            grade: selectedGrade,
            addNewOnDuplicate,
        }).then((res) => {
            if (res.ok) {
                newStudents[idx] = {
                    ...newStudents[idx],
                    status: "success",
                };

                progressPopupAddStudents.setStudentsLoadedSuccessfully({
                    students: newStudents,
                });

                onAddStudentsToClass(idx + 1, newStudents);
            } else {
                let errorCode = -1;

                if (res.error.indexOf("Fullname duplicate") !== -1) {
                    errorCode = 0;
                }

                if (res.error.indexOf("Email duplicate") !== -1) {
                    errorCode = 1;
                }

                if (errorCode === -1) {
                    newStudents[idx] = {
                        ...newStudents[idx],
                        status: "failed",
                    };
                }

                progressPopupAddStudents.setStudentsFailedLoaded({
                    students: newStudents,
                    errorCode,
                });

                if (errorCode === -1) {
                    onAddStudentsToClass(idx + 1, newStudents);
                }
            }
        });
    };

    const loadClassStudents = (id, selectedRange, customDates = null) => {
        setDateRange((prev) => ({
            ...prev,
            selectedRange,
            startDate: customDates ? customDates.dateFrom : null,
            endDate: customDates ? customDates.dateTo : null,
        }));

        setStudents((prev) => ({
            ...prev,
            isLoaded: false,
            isOverview: false,
            message: null,
            students: [],
        }));

        if (customDates) {
            const dateFrom = customDates.dateFrom.format("YYYY-MM-DD");
            const dateTo = customDates.dateTo.format("YYYY-MM-DD");

            api.classes.getClassStudentsById({
                session: store.session,
                classId: id,
                dateFrom,
                dateTo,
            }).then((classRes) => {
                const newStudents = classRes.ok ? classRes.data.students : [];
                const isOverview = classRes.ok ? classRes.data.isOverview : false;

                setStudents((prev) => ({
                    ...prev,
                    isLoaded: true,
                    isOverview,
                    message: classRes.ok ? null : classRes.error,
                    students: newStudents,
                }));
            });
        } else {
            api.site.getSiteDate().then((res) => {
                const siteDate = res.ok ? res.date : null;

                const dates = getDatesByRange(siteDate, selectedRange);

                let { dateFrom, dateTo } = dates;

                if (dateFrom !== "all") {
                    dateFrom = getDateFromDate(dateFrom);
                }

                if (dateTo !== "") {
                    dateTo = getDateFromDate(dateTo);
                }

                if (dateFrom !== "all" && dateTo !== "") {
                    setDateRange((prev) => ({
                        ...prev,
                        startDate: moment(dateFrom), // TODO: date.js
                        endDate: moment(dateTo),
                    }));
                }

                api.classes.getClassStudentsById({
                    session: store.session,
                    classId: id,
                    dateFrom,
                    dateTo,
                }).then((classRes) => {
                    const newStudents = classRes.ok ? classRes.data.students : [];
                    const isOverview = classRes.ok ? classRes.data.isOverview : false;

                    setStudents({
                        isLoaded: true,
                        isOverview,
                        message: classRes.ok ? null : classRes.error,
                        students: newStudents,
                    });
                });
            });
        }
    };

    const onCloseAddStudentProgressPopup = () => {
        const range = store.teacher.statsDate ? store.teacher.statsDate : defaultDateRange;
        loadClassStudents(store.teacher.selectedClassId, range);

        progressPopupAddStudents.close();
    };

    const onCloseClassStudentsLimitPopup = () => {
        setIsVisibleClassStudentsLimitPopup(false);
    };

    const onContactSupport = () => {
        window.open(settings.supportLink, "_blank");
    };

    const onAddDuplicate = () => {
        onAddStudentsToClass(
            progressPopupAddStudents.state.currentLoading.index,
            progressPopupAddStudents.state.students,
            true,
        );
    };

    const onSkipDuplicate = () => {
        const newStudents = [...progressPopupAddStudents.state.students];

        newStudents[progressPopupAddStudents.state.currentLoading.index] = {
            ...newStudents[progressPopupAddStudents.state.currentLoading.index],
            status: "skipped",
        };

        progressPopupAddStudents.setStudentsSkippedStatus({
            students: newStudents,
        });

        onAddStudentsToClass(
            progressPopupAddStudents.state.currentLoading.index + 1,
            newStudents,
        );
    };

    const onAddStudentsToClassButtonClick = (students) => {
        progressPopupAddStudents.open();

        onAddStudentsToClass(0, students);
    };

    const onShowAddStudentPopup = () => {
        addStudentPopup.open();

        loadImportStudentsTemplate();
    };

    const onOpenStudentPopup = (userId) => {
        events.classOpenStudentInfo({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        loadStudentInfo(userId);

        loadStudentDailyJuices(userId);
    };

    const onCloseAddStudentPopup = () => {
        addStudentPopup.close();
        progressPopupAddStudents.close();
    };

    const onCloseResetStudentPopup = () => {
        setResetStudentPasswordPopup({
            isOpen: false,
            isLoading: true,
            message: null,
        });
    };

    const onCloseNewPasswordPopup = () => {
        setNewPasswordPopupState({
            isOpen: false,
            isSubmitted: false,
            isLoading: false,
            message: null,
            id: null,
        });
    };

    const onChangeStudentPassword = (newPassword) => {
        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSubmitted: true,
            isLoading: true,
        }));

        api.students.setPassword({
            session: store.session,
            studentId: newPasswordPopupState.id,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                setLastTouchedStudentIdWithResetPasswordRequest(newPasswordPopupState.id);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                        studentId: newPasswordPopupState.id,
                    }));
                }, 200);
            }

            setNewPasswordPopupState((prev) => ({
                ...prev,
                isLoading: false,
                message: res.ok ? "Password successfully updated!" : res.error,
            }));
        });
    };

    const onDismissUserWithForgottenPassowrd = (studentId) => {
        setDismissStudentState((prev) => ({
            ...prev,
            isLoading: true,
            id: studentId,
        }));

        api.classes.dismissStudentWithForgottenPassword({
            session: store.session,
            studentId,
        }).then((res) => {
            let error = null;

            if (res.ok) {
                setLastTouchedStudentIdWithResetPasswordRequest(studentId);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                        studentId,
                    }));
                }, 200);
            } else {
                error = res.error;
            }

            let isOpen = false;
            let id = null;

            if (error) {
                isOpen = true;
                id = studentId;
            }

            setDismissStudentState((prev) => ({
                ...prev,
                isOpen,
                isLoading: false,
                error,
                id,
            }));
        });
    };

    const onAlertDismissUserWithForgottenPassowrd = (student) => {
        snackbar.add({
            isVisibleUndoButton: true,
            id: student.id,
            message: `${student?.fullName}'s password request has been dismissed.`,
            autoCloseInSeconds: 3,
            callback: () => {
                onDismissUserWithForgottenPassowrd(student.id);
            },
        });
    };

    const onCloseDeleteSelectedUsersPopup = () => {
        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
            students: [],
        }));
    };

    const onCloseDeletingPopup = () => {
        setDeleteStudentPopup({
            isOpen: false,
            isLoading: false,
            error: null,
            id: -1,
        });
    };

    const onCloseStudentPopup = () => {
        studentInfoPopup.close();
    };

    const onRemoveStudent = (studentId) => {
        setDeleteStudentPopup({
            isOpen: true,
            isLoading: false,
            error: null,
            id: studentId,
        });
    };

    /* --- */

    const onSelectStudent = (id) => {
        loadStudentInfo(id);

        loadStudentDailyJuices(id);
    };

    const onAcceptStudentToClass = (studentId) => {
        setAcceptStudentState({
            isLoading: true,
            id: studentId,
        });

        api.classes.acceptStudentToClass({
            session: store.session,
            studentId,
        }).then((res) => {
            let message = "Student has been added to the class.";

            if (res.ok) {
                const studentData = getStudentWithPendingStatusById(studentId);

                if (studentData?.fullName) {
                    message = `${studentData.fullName} has been added to the class`;
                }

                setLastTouchedStudentIdWithPendingStatus(studentId);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithPendingStatusById({
                        studentId,
                    }));
                }, 200);

                const range = store.teacher.statsDate
                    ? store.teacher.statsDate
                    : defaultDateRange;

                loadClassStudents(store.teacher.selectedClassId, range);
            } else {
                message = res.error || "Error!";
            }

            setAcceptStudentState({
                isLoading: false,
                id: null,
            });

            snackbar.add({
                id: studentId,
                message,
            });
        });
    };

    const onRejectStudentToClass = (studentId) => {
        api.classes.rejectStudentToClass({
            session: store.session,
            studentId,
            classId: store.teacher.selectedClassId,
        }).then((res) => {
            if (res.ok) {
                setLastTouchedStudentIdWithPendingStatus(studentId);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithPendingStatusById({
                        studentId,
                    }));
                }, 200);
            } else {
                snackbar.add({
                    id: studentId,
                    message: res.error,
                });
            }
        });
    };

    const removeSelectedUsers = () => {
        const { students } = deleteSelectedUsersPopup;

        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        for (let i = 0; i < students.length; i += 1) {
            api.classes.removeStudentById({
                session: store.session,
                studentId: students[i].id,
            }).then((res) => {
                if (i === students.length - 1) {
                    if (res.ok) {
                        loadClassStudents(store.teacher.selectedClassId, defaultDateRange);

                        onCloseDeleteSelectedUsersPopup();
                    } else {
                        setDeleteSelectedUsersPopup((prev) => ({
                            ...prev,
                            isOpen: true,
                            isLoading: false,
                            error: res.error,
                        }));
                    }
                }
            });
        }
    };

    const onClassChange = (classId) => {
        events.classChangeClass({
            session: store.session,
            classId,
        });

        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        storage.local.saveTeacherSelectedClass(classId);

        if (store.teacher.statsDate) {
            loadClassStudents(classId, store.teacher.statsDate);
        } else {
            loadClassStudents(classId, defaultDateRange);
        }
    };

    const onChangeDateRange = (range) => {
        dispatch(actions.teacher.setTeacherStatsDate({
            statsDate: range,
        }));

        loadClassStudents(store.teacher.selectedClassId, range);
    };

    const onSelectCustomRange = (values) => {
        const range = {
            dateFrom: values.startDate,
            dateTo: values.endDate,
        };

        loadClassStudents(store.teacher.selectedClassId, "custom", range);
    };

    const removeStudent = () => {
        setDeleteStudentPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        api.classes.removeStudentById({
            session: store.session,
            studentId: deleteStudentPopup.id,
        }).then((res) => {
            events.classDeleteStudent({
                session: store.session,
                classId: store.teacher.selectedClassId,
            });

            if (res.ok) {
                onCloseDeletingPopup();
                onCloseStudentPopup();

                loadClassStudents(
                    store.teacher.selectedClassId,
                    defaultDateRange,
                );
            } else {
                setDeleteStudentPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.error,
                }));
            }
        });
    };

    const onAlertResetPassword = (student) => {
        loadStudentInfo(student.id);

        loadStudentDailyJuices(student.id);

        setNewPasswordPopupState({
            isOpen: true,
            isLoading: false,
            isSubmitted: false,
            message: null,
            id: student.id,
        });
    };

    const onAlertAcceptStudent = (student) => {
        const classLimit = Classes.getClassLimit(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        if (sortedStudents.length >= classLimit) {
            setIsVisibleClassStudentsLimitPopup(true);
        } else {
            onAcceptStudentToClass(student.id);
        }
    };

    const onAlertRejectStudent = (student) => {
        const message = `${student?.fullName} will be rejected from the class.`;

        snackbar.add({
            isVisibleUndoButton: true,
            id: student.id,
            message,
            callback: () => {
                onRejectStudentToClass(student.id);
            },
        });
    };

    const onOpenNewPasswordPopup = (studentId) => {
        setNewPasswordPopupState({
            isLoading: false,
            isSubmitted: false,
            message: null,
            isOpen: true,
            id: studentId,
        });
    };

    const onCopyToClipboard = (toCopy, snackbarMessage) => {
        copyToClipboard(toCopy);

        snackbar.add({
            autoCloseInSeconds: 3,
            message: snackbarMessage,
        });
    };

    const onCloseSnackbar = (params = {}) => {
        snackbar.close(params.index);

        if (params.withUndo) {
            return;
        }

        if (params.snackbarCallback) {
            params.snackbarCallback();
        }
    };

    const onResetStudentPassword = (student) => {
        if (student.hasEmail) {
            setResetStudentPasswordPopup({
                isLoading: true,
                isOpen: true,
                message: null,
            });

            api.classes.resetStudentPasswordById({
                session: store.session,
                studentId: student.id,
            }).then((res) => {
                events.classResetPassword({
                    session: store.session,
                    classId: store.teacher.selectedClassId,
                });

                setResetStudentPasswordPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    message: res.ok ? "Email successfully sent!" : res.error,
                }));
            });
        } else {
            onOpenNewPasswordPopup(student.id);
        }
    };

    const loadTeacherClasses = () => {
        api.classes.getTeacherClasses({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                const classes = res.classes || [];
                const classId = getTeacherClassId(classes);

                dispatch(actions.teacher.setTeacherSelectedClass({
                    selectedClassId: classId,
                }));

                dispatch(actions.teacher.setClasses({
                    classes,
                }));

                if (store.teacher.statsDate) {
                    loadClassStudents(classId, store.teacher.statsDate);
                } else {
                    loadClassStudents(classId, defaultDateRange);
                }
            }
        });
    };

    const loadStudentsForgotttenPassword = () => {
        api.classes.getStudentsWithForgottenPasswords({
            session: store.session,
            classId: store.teacher.selectedClassId,
        }).then((res) => {
            dispatch(actions.teacher.setTeacherStudentWithForgottenPasswords({
                students: res.ok ? res.students : [],
            }));
        });
    };

    const loadClassStudentWithPendingStatus = (
        newPage,
        prevStudents = store.teacher.studentsWithPendingStatus,
    ) => {
        dispatch(actions.teacher.setTeacherStudentsWithPendingStatusLoading());

        api.classes.getStudentsWithPendingStatus({
            session: store.session,
            classId: store.teacher.selectedClassId,
            page: newPage,
        }).then((res) => {
            if (res.ok) {
                const newStudents = [
                    ...prevStudents,
                    ...res.students,
                ];

                dispatch(actions.teacher.setTeacherStudentsWithPendingStatus({
                    students: newStudents,
                    hasMore: res.hasMore,
                    page: newPage,
                }));
            }
        });
    };

    useEffect(() => {
        if (store.session) {
            loadTeacherClasses();
        }
    }, [store.session]);

    useEffect(() => {
        if (store.teacher.selectedClassId !== -1) {
            loadStudentsForgotttenPassword();

            dispatch(actions.teacher.clearTeacherStudentsWithPendingStatus());

            loadClassStudentWithPendingStatus(0, []);
        }
    }, [store.teacher.selectedClassId]);

    /* --- */

    const renderTutorial = () => {
        if (!studentsState.isLoaded
            || studentInfoPopup.state.isOpen) {
            return null;
        }

        return (
            <Tutorial name="teacher-class" />
        );
    };

    const renderStudentInfoPopup = () => {
        if (!studentInfoPopup.state.isOpen) {
            return null;
        }

        let isStudentForgotPassword = false;
        let studentData = {};

        const accountId = studentInfoPopup.state?.student?.account?.ID;

        if (accountId) {
            const { studentsWithForgottenPasswords } = store.teacher;

            for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
                if (studentsWithForgottenPasswords[i].id === accountId) {
                    isStudentForgotPassword = true;
                    break;
                }
            }

            studentData = {
                ...studentInfoPopup.state.student,
                isForgotPassword: isStudentForgotPassword,
            };
        }

        let dailyJuices = {};
        let isDailyJuicesLoading = false;

        if (store.juices?.studentJuicesById[studentInfoPopup.state.studentId]) {
            dailyJuices = store.juices.studentJuicesById[studentInfoPopup.state.studentId];

            if (store.juices.studentJuicesById[studentInfoPopup.state.studentId].isLoading) {
                isDailyJuicesLoading = true;
            }
        }

        return (
            <TeacherPopupStudentInfo
                session={store.session}
                classId={store.teacher.selectedClassId}
                studentId={studentInfoPopup.state.studentId}
                student={studentData}
                isStudentLoaded={studentInfoPopup.state.isLoaded}
                students={sortedStudents}
                dailyJuices={dailyJuices}
                isDailyJuicesLoading={isDailyJuicesLoading}
                isMobile={store.dimensions.width < 900}
                isCards={store.dimensions.width < 900}
                hideArrows={store.dimensions.width < 500}
                onSelectStudent={onSelectStudent}
                onLoadMoreDailyJuices={() => {
                    loadStudentDailyJuices(studentInfoPopup.state.studentId, false);
                }}
                onEditPassword={() => {
                    onOpenNewPasswordPopup(studentInfoPopup.state.studentId);
                }}
                onRemoveStudent={() => {
                    onRemoveStudent(studentInfoPopup.state.studentId);
                }}
                onUpdate={() => {
                    loadStudentInfo(studentInfoPopup.state.studentId);

                    const range = {
                        dateFrom: dateRange.startDate,
                        dateTo: dateRange.endDate,
                    };

                    loadClassStudents(
                        store.teacher.selectedClassId,
                        dateRange.selectedRange,
                        range,
                    );
                }}
                onClose={onCloseStudentPopup}
            />
        );
    };

    /* --- */

    const renderAddStudentWindow = () => {
        if (!addStudentPopup.state.isOpen) {
            return null;
        }

        const defaultGrade = Grades.getValidGrade(
            getClassGradeById(store.teacher.selectedClassId),
        );

        const classLimit = Classes.getClassLimit(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        return (
            <PopupFullScreenAddStudent
                grades={settings.grades}
                defaultGrade={defaultGrade}
                isMobile={store.dimensions.width < 800}
                classStudentsCount={sortedStudents.length}
                classLimit={classLimit}
                supportLink={settings.supportLink}
                importStudentsTemplateUrl={addStudentPopup.state.templateLink}
                onGenerateClassCode={onGenerateClassCode}
                onDirectLinkClick={onDirectLinkClick}
                onAddStudentsToClass={onAddStudentsToClassButtonClick}
                onClose={onCloseAddStudentPopup}
            />
        );
    };

    const renderAddStudentsProgressPopup = () => {
        if (!progressPopupAddStudents.state.isOpen) {
            return null;
        }

        return (
            <PopupAddStudentsProgress
                data={progressPopupAddStudents.state.students}
                inProgress={progressPopupAddStudents.state.inProgress}
                totalLoaded={progressPopupAddStudents.state.totalLoaded}
                currentStudentErrorCode={progressPopupAddStudents.state.currentLoading.errorCode}
                currentLoadingStudentIndex={progressPopupAddStudents.state.currentLoading.index}
                onAddDuplicate={onAddDuplicate}
                onSkipDuplicate={onSkipDuplicate}
                onClose={onCloseAddStudentProgressPopup}
            />
        );
    };

    const renderClassCodePopup = () => {
        if (!classCodePopup.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmGenerateClassCode
                code={classCodePopup.state.code}
                signUpUrl={signUpUrl}
                error={classCodePopup.state.error}
                isLoading={classCodePopup.state.isLoading}
                disabled={classCodePopup.state.isLoading || classCodePopup.state.error}
                onSubmit={() => {
                    onCopyToClipboard(classCodePopup.state.code, "Class code copied to clipboard.");
                }}
                onClose={() => {
                    classCodePopup.close();
                }}
            />
        );
    };

    const renderClassCodeDirectLinkPopup = () => {
        if (!classCodeLinkPopup.state.isOpen) {
            return null;
        }

        const registrationLink = `${signUpUrl}/${classCodeLinkPopup.state.code}`;

        return (
            <PopupConfirmGenerateClassCode
                code={registrationLink}
                signUpUrl={signUpUrl}
                error={classCodePopup.state.error}
                isLoading={classCodeLinkPopup.state.isLoading || classCodeLinkPopup.state.error}
                isLink
                disabled={classCodeLinkPopup.state.isLoading}
                onSubmit={() => {
                    onCopyToClipboard(registrationLink, "Direct link copied to clipboard.");
                }}
                onClose={() => {
                    classCodeLinkPopup.close();
                }}
            />
        );
    };

    /* --- */

    const renderResetStudentPopup = () => {
        if (!resetStudentPasswordPopup.isOpen) {
            return null;
        }

        return (
            <PopupResetPassword
                onClose={onCloseResetStudentPopup}
                isLoading={resetStudentPasswordPopup.isLoading}
                message={resetStudentPasswordPopup.message}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopupState.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={newPasswordPopupState.message}
                isSubmitted={newPasswordPopupState.isSubmitted}
                isLoading={newPasswordPopupState.isLoading}
                onSave={onChangeStudentPassword}
                onClose={onCloseNewPasswordPopup}
            />
        );
    };

    const renderDismissStudentErrorPopup = () => {
        if (!dismissStudentState.isOpen) {
            return null;
        }

        return (
            <PopupConfirmError
                error={dismissStudentState.error}
                onClose={() => {
                    setDismissStudentState({
                        isOpen: false,
                        isLoading: false,
                        error: null,
                        id: null,
                    });
                }}
            />
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            return (
                <Snackbar
                    isVisibleUndoButton={bar.isVisibleUndoButton}
                    autoCloseInSeconds={bar.autoCloseInSeconds}
                    message={bar.message}
                    onClose={(params) => {
                        onCloseSnackbar({
                            ...params,
                            index,
                            callback: () => {
                                if (bar.callback) {
                                    bar.callback();
                                }
                            },
                        });
                    }}
                />
            );
        });
    };

    const renderDeletePopup = () => {
        if (!deleteStudentPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                isLoading={deleteStudentPopup.isLoading}
                error={deleteStudentPopup.error}
                onDelete={removeStudent}
                onClose={onCloseDeletingPopup}
            />
        );
    };

    const renderDeleteSelectedUsersPopup = () => {
        if (!deleteSelectedUsersPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                students={deleteSelectedUsersPopup.students}
                isLoading={deleteSelectedUsersPopup.isLoading}
                error={deleteSelectedUsersPopup.error}
                onDelete={removeSelectedUsers}
                onClose={onCloseDeleteSelectedUsersPopup}
            />
        );
    };

    const renderClassStudentsLimitPopup = () => {
        if (!isVisibleClassStudentsLimitPopup) {
            return null;
        }

        return (
            <PopupConfirmClassStudentsLimit
                hideScrollbar
                onContactSupport={onContactSupport}
                onClose={onCloseClassStudentsLimitPopup}
            />
        );
    };

    /* --- */

    const renderTabs = () => {
        const tabs = store.teacher.classes.map((cls) => {
            return {
                value: cls.id,
                label: cls.title,
            };
        });

        let { selectedClassId } = store.teacher;

        if (selectedClassId === -1) {
            selectedClassId = getTeacherClassId(store.teacher.classes);
        }

        return (
            <Tabs
                tabs={tabs}
                dataComment="teacher-classes"
                selectedTab={selectedClassId}
                onChange={(values) => {
                    onClassChange(values.value);
                }}
            />
        );
    };

    const renderAlerts = () => {
        if (!store.teacher.isStudentsWithForgottenPasswordsLoaded
            || !store.teacher.isStudentsWithPendingStatusLoaded) {
            return null;
        }

        const alerts = [];

        store.teacher.studentsWithForgottenPasswords.forEach((student) => {
            let isVisibleBox = true;

            if (student.id === lastTouchedStudentIdWithResetPasswordRequest) {
                isVisibleBox = false;
            }

            const isVisibleSnackbarsExists = snackbar.isVisibleSnackbarsExists();

            const buttonClassName = classNames({
                [styles.alertControlButton]: true,
                [styles.alertControlButtonDisabled]: isVisibleSnackbarsExists,
            });

            let controls = [
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isVisibleSnackbarsExists) {
                            onAlertResetPassword(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isVisibleSnackbarsExists) {
                            onAlertResetPassword(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Reset password
                </div>,
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isVisibleSnackbarsExists) {
                            onAlertDismissUserWithForgottenPassowrd(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isVisibleSnackbarsExists) {
                            onAlertDismissUserWithForgottenPassowrd(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Dismiss
                </div>,
            ];

            if (isVisibleSnackbarsExists && (student.id === dismissStudentState.id)) {
                controls = [<LoaderSmall />];
            }

            alerts.push(
                <AlertBox
                    isVisibleBox={isVisibleBox}
                    theme="danger"
                    rightControls={controls}
                >
                    {`A student in this class, ${student.fullName}(${student.userName}), has forgotten their password.`}
                </AlertBox>,
            );
        });

        store.teacher.studentsWithPendingStatus.forEach((student) => {
            let isVisibleBox = true;

            if (student.id === lastTouchedStudentIdWithPendingStatus) {
                isVisibleBox = false;
            }

            const isVisibleSnackbarsExists = snackbar.isVisibleSnackbarsExists();

            const buttonClassName = classNames({
                [styles.alertControlButton]: true,
                [styles.alertControlButtonDisabled]: isVisibleSnackbarsExists
                    || acceptStudentState.isLoading,
            });

            let controls = [
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isVisibleSnackbarsExists && !acceptStudentState.isLoading) {
                            onAlertAcceptStudent(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isVisibleSnackbarsExists && !acceptStudentState.isLoading) {
                            onAlertAcceptStudent(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Accept
                </div>,
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isVisibleSnackbarsExists && !acceptStudentState.isLoading) {
                            onAlertRejectStudent(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isVisibleSnackbarsExists && !acceptStudentState.isLoading) {
                            onAlertRejectStudent(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Reject
                </div>,
            ];

            if (acceptStudentState.isLoading && student.id === acceptStudentState.id) {
                controls = [<LoaderSmall />];
            }

            alerts.push(
                <AlertBox
                    isVisibleBox={isVisibleBox}
                    theme="danger"
                    rightControls={controls}
                >
                    {`${student.fullName} has requested to join your class on The Juice.`}
                </AlertBox>,
            );
        });

        let loadMoreStudentsWithPendingStatusButton = null;

        if (store.teacher.studentsWithPendingStatusHasMorePages) {
            loadMoreStudentsWithPendingStatusButton = (
                <div
                    className={styles.loadMoreButton}
                    onClick={() => {
                        loadClassStudentWithPendingStatus(
                            store.teacher.studentsWithPendingStatusPage + 1,
                        );
                    }}
                    onKeyPress={() => {
                        loadClassStudentWithPendingStatus(
                            store.teacher.studentsWithPendingStatusPage + 1,
                        );
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Load more students who requested to join to your class
                </div>
            );
        }

        if (store.teacher.isStudentsWithPendingStatusLoading
            && store.teacher.isStudentsWithPendingStatusLoaded) {
            loadMoreStudentsWithPendingStatusButton = (
                <RequestLoader />
            );
        }

        if (alerts.length === 0) {
            return null;
        }

        return (
            <div className={styles.alerts}>
                {alerts}
                {loadMoreStudentsWithPendingStatusButton}
            </div>
        );
    };

    const renderTable = () => {
        return (
            <TableStudents
                dateRange={dateRange}
                calendarDates={settings.calendarDates}
                onChangeDateRange={onChangeDateRange}
                onSelectCustomRange={onSelectCustomRange}
                onChangeSortValue={(value) => {
                    setSelectedSortByValue(value);
                }}
                sortValue={selectedSortByValue}
                data={sortedStudents}
                error={studentsState.message}
                onOpenStudentPopup={onOpenStudentPopup}
                onAddStudent={onShowAddStudentPopup}
                onRemoveStudent={onRemoveStudent}
                onRemoveStudents={(s) => {
                    setDeleteSelectedUsersPopup((prev) => ({
                        ...prev,
                        isOpen: true,
                        students: s,
                    }));
                }}
                onResetStudentPassword={onResetStudentPassword}
                isCards={store.dimensions.width < 1200}
                isOverview={studentsState.isOverview}
                isLoading={!studentsState.isLoaded}
            />
        );
    };

    if (!store.teacher.isClassesLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderTutorial()}

            {renderStudentInfoPopup()}

            {renderAddStudentWindow()}
            {renderAddStudentsProgressPopup()}
            {renderClassCodePopup()}
            {renderClassCodeDirectLinkPopup()}

            {renderResetStudentPopup()}
            {renderNewPasswordPopup()}
            {renderDismissStudentErrorPopup()}

            {renderSnackbars()}

            {renderDeletePopup()}
            {renderDeleteSelectedUsersPopup()}

            {renderClassStudentsLimitPopup()}

            <div className={styles.indexWrapper}>
                <div className={styles.index}>
                    {renderTabs()}
                    {renderAlerts()}
                    {renderTable()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["teacher"])(Class);
