import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Classes from "juice-base/project/classes.js";
import Grades from "juice-base/project/grades.js";

import useAddStudentPopup from "juice-base/hooks/use-add-student-popup/index.js";
import useStudentInfoPopup from "juice-base/hooks/use-student-info-popup/index.js";
import useClassCodePopup from "juice-base/hooks/use-class-code-popup/index.js";
import useProgressPopupAddStudents from "juice-base/hooks/use-progress-popup-add-students/index.js";
import useTeacherPopupWelcome from "juice-base/hooks/use-teacher-popup-welcome/index.js";
import useSnackbar from "juice-base/hooks/use-snackbar/index.js";

import storage from "juice-base/lib/storage/index.js";
import { getDatesByRange, getDateFromDate } from "juice-base/lib/date.js";
import copyToClipboard from "juice-base/lib/clipboard.js";

import { withAuth } from "juice-base/components/auth/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import AlertBox from "juice-base/components/alert-box/index.js";
import ButtonDefault from "juice-base/components/button-default/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";

import PopupAdsPreview from "juice-base/components/popup-ads-preview/index.js";
import PopupNewPassword from "juice-base/components/popup-new-password/index.js";

import TeacherQuickLinks from "juice-base/business/teacher-quick-links/index.js";
import TeacherLearningStandards from "juice-base/business/teacher-learning-standards/index.js";
import TeacherClassAnnouncement from "juice-base/business/teacher-class-announcement/index.js";
import TeacherClassActivity from "juice-base/business/teacher-class-activity/index.js";
import TeacherStudentsActivity from "juice-base/business/teacher-students-activity/index.js";

import PopupAnnouncement from "juice-base/business/popup-announcement/index.js";
import PopupSponsorContent from "juice-base/business/popup-sponsor-content/index.js";
import PopupAddStudentsProgress from "juice-base/business/popup-add-students-progress/index.js";
import PopupConfirmGenerateClassCode from "juice-base/business/popup-confirm-generate-class-code/index.js";
import PopupConfirmDeleteStudent from "juice-base/business/popup-confirm-delete-student/index.js";
import PopupFullScreenAddStudent from "juice-base/business/popup-full-screen-add-student/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import TeacherPopupWelcome from "juice-app/containers/teacher-popup-welcome/index.js";
import TeacherPopupStudentInfo from "juice-app/containers/teacher-popup-student-info/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import actions from "juice-base/store/actions.js";

import settings from "juice-app/settings.js";
import * as events from "juice-base/events.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getSponsorPopupState = () => ({
    selectedClass: -1,

    isLoaded: false,
    isImageLoading: false,
    isSaving: false,

    sponsor: {},
    errors: [],
});

const getAnnouncementPopupState = () => ({
    selectedClass: -1,

    isLoaded: false,
    isSaving: false,

    announcement: {},
    errors: [],
});

const TeacherHomePage = () => {
    const defaultRange = "today";
    const allClassesTitle = "All";

    const [isVisibleAnnouncementPopup, setIsVisibleAnnouncementPopup] = useState(false);
    const [isVisibleSponsorPopup, setIsVisibleSponsorPopup] = useState(false);

    const [adsPreviewPopup, setAdsPreviewPopup] = useState({
        isOpen: false,
        img: "",
        text: "",
    });

    const [removeStudentPopup, setRemoveStudentPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        studentId: -1,
    });

    const [selectedClassAnnouncement, setSelectedClassAnnouncement] = useState({
        isLoaded: false,
        content: "",
        title: "",
    });

    const [studentsActivity, setStudentsActivity] = useState({
        isLoaded: false,
        range: defaultRange,
        students: [],
    });

    const [juiceStats, setJuiceStats] = useState({
        isLoading: true,
        stats: null,
        error: null,
    });

    const [sponsorPopup, setSponsorPopup] = useState(() => getSponsorPopupState());

    const [announcementPopup, setAnnoucementPopup] = useState(() => getAnnouncementPopupState());

    const [newPasswordPopupState, setNewPasswordPopupState] = useState({
        isOpen: false,
        isSubmitted: false,
        isLoading: false,
        message: null,
        id: null,
    });

    const [learningStandardsState, setLearningStandardsState] = useState({
        isLoading: true,
        range: defaultRange,
        standards: [],
        error: null,
    });

    const [dropdownRange, setDropDownRange] = useState(defaultRange);

    const studentInfoPopup = useStudentInfoPopup();
    const classCodePopup = useClassCodePopup();
    const classCodeLinkPopup = useClassCodePopup();
    const addStudentPopup = useAddStudentPopup();
    const progressPopupAddStudents = useProgressPopupAddStudents();
    const teacherPopupWelcome = useTeacherPopupWelcome();
    const snackbar = useSnackbar();

    const dispatch = useDispatch();
    const history = useHistory();

    const store = useSelector((state) => ({
        session: state.user.session,
        user: state.user,
        dimensions: state.device.dimensions,
        juices: state.juices,
        teacher: state.teacher,
        tutorialsBySlug: state.tutorials.tutorialsBySlug,
    }));

    let pageWidth = 0;

    if (store.dimensions?.width) {
        pageWidth = store.dimensions.width;
    }

    const signUpUrl = `${window?.location?.hostname}${settings.signUpByCodePath}`;

    const getClassByClassId = (id) => {
        for (let i = 0; i < store.teacher.classes.length; i += 1) {
            if (store.teacher.classes[i].id === id) {
                return store.teacher.classes[i];
            }
        }

        return null;
    };

    const getClassGradeById = (classId) => {
        const defaultGrade = Classes.getClassGradeById(
            store.teacher.classes,
            classId,
        );

        return defaultGrade || "G7";
    };

    const onCopyToClipboard = (toCopy, snackbarMessage) => {
        copyToClipboard(toCopy);

        snackbar.add({
            autoCloseInSeconds: 3,
            message: snackbarMessage,
        });
    };

    const onCloseSnackbar = (params = {}) => {
        snackbar.close(params.index);

        if (params.withUndo) {
            return;
        }


        if (params.snackbarCallback) {
            params.snackbarCallback();
        }
    };

    const isClassHasStudents = (id) => {
        const sClass = getClassByClassId(id);

        if (sClass?.hasStudents) {
            return false;
        }

        return true;
    };

    const showSkeletonForSelectedClass = isClassHasStudents(store.teacher.selectedClassId);

    const loadImportStudentsTemplate = () => {
        api.students.getImportStudentsTemplate({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                addStudentPopup.setTemplate(res.templateLink);
            }
        });
    };

    const onAddStudentsToClass = (idx, students, addNewOnDuplicate = false) => {
        if (!students[idx]) {
            progressPopupAddStudents.setInProgress(false);
            return;
        }

        const newStudents = [...students];

        newStudents[idx] = {
            ...newStudents[idx],
            status: "loading",
        };

        progressPopupAddStudents.setStudents({
            index: idx,
            students: newStudents,
        });

        const student = newStudents[idx];

        const selectedGrade = student.gradeValue.split("-")[1];

        api.classes.addStudentV2({
            session: store.session,
            classId: store.teacher.selectedClassId,
            firstname: student.nameValue,
            lastname: student.lastNameValue,
            email: student.emailValue,
            grade: selectedGrade,
            addNewOnDuplicate,
        }).then((res) => {
            if (res.ok) {
                newStudents[idx] = {
                    ...newStudents[idx],
                    status: "success",
                };

                progressPopupAddStudents.setStudentsLoadedSuccessfully({
                    students: newStudents,
                });

                onAddStudentsToClass(idx + 1, newStudents);
            } else {
                let errorCode = -1;

                if (res.error.indexOf("Fullname duplicate") !== -1) {
                    errorCode = 0;
                }

                if (res.error.indexOf("Email duplicate") !== -1) {
                    errorCode = 1;
                }

                if (errorCode === -1) {
                    newStudents[idx] = {
                        ...newStudents[idx],
                        status: "failed",
                    };
                }

                progressPopupAddStudents.setStudentsFailedLoaded({
                    students: newStudents,
                    errorCode,
                });

                if (errorCode === -1) {
                    onAddStudentsToClass(idx + 1, newStudents);
                }
            }
        });
    };

    const loadLearningStandartsByClassId = (classId, range = learningStandardsState.range) => {
        setLearningStandardsState({
            isLoading: true,
            range,
            standards: [],
            error: null,
        });

        api.site.getSiteDate().then((resDate) => {
            if (resDate.ok) {
                const ranges = getDatesByRange(resDate.date, range);

                if (ranges.dateFrom !== "all") {
                    ranges.dateFrom = getDateFromDate(ranges.dateFrom);
                }

                if (ranges.dateTo !== "") {
                    ranges.dateTo = getDateFromDate(ranges.dateTo);
                }

                api.classes.getLearningStandardsByClassId({
                    session: store.session,
                    classId,
                    dateFrom: ranges.dateFrom,
                    dateTo: ranges.dateTo,
                }).then((res) => {
                    if (res.ok) {
                        setLearningStandardsState((prev) => ({
                            ...prev,
                            isLoading: false,
                            standards: res.data,
                            error: null,
                        }));
                    } else {
                        setLearningStandardsState((prev) => ({
                            ...prev,
                            error: res.error,
                        }));
                    }
                });
            }
        });
    };

    const loadClassSelectedAnnouncementByClassId = (classId) => {
        setSelectedClassAnnouncement({
            isLoaded: false,
            content: "",
            title: "",
        });

        api.classes.getClassAnnouncement({ classId: classId || -1 }).then((res) => {
            let content = "";
            let title = "";

            if (res.ok) {
                content = res.data.announcementContent;
                title = res.data.announcementTitle;
            }

            setSelectedClassAnnouncement({
                isLoaded: true,
                title,
                content,
            });
        });
    };

    const loadJuiceStats = (classId, rangeValue = null) => {
        setJuiceStats({
            isLoading: true,
            stats: null,
            error: null,
        });

        let selectedRange = dropdownRange;

        if (rangeValue) {
            selectedRange = rangeValue;
            setDropDownRange(selectedRange);
        }

        api.site.getSiteDate().then((dateRes) => {
            const siteDate = dateRes.ok ? dateRes.date : null;
            if (siteDate) {
                const range = getDatesByRange(siteDate, selectedRange);

                if (range.dateFrom !== "all") {
                    range.dateFrom = getDateFromDate(range.dateFrom);
                }

                if (range.dateTo !== "") {
                    range.dateTo = getDateFromDate(range.dateTo);
                }

                api.classes.getJuiceStats({
                    session: store.session,
                    classId,
                    dateFrom: range.dateFrom,
                    dateTo: range.dateTo,
                }).then((res) => {
                    let stats = null;

                    if (res.ok && Object.keys(res.stats).length !== 0) {
                        stats = res.stats;
                    }

                    setJuiceStats({
                        isLoading: false,
                        stats,
                        error: res.ok ? null : res.error,
                    });
                });
            } else {
                setJuiceStats({
                    isLoading: false,
                    stats: null,
                    error: dateRes.ok ? null : dateRes.error,
                });
            }
        });
    };

    const loadStudentActivity = (classId, range = studentsActivity.range) => {
        setStudentsActivity({
            isLoaded: false,
            range,
            students: [],
        });

        api.site.getSiteDate().then((resDate) => {
            const ranges = getDatesByRange(resDate.date, range);

            ranges.dateFrom = getDateFromDate(ranges.dateFrom);
            ranges.dateTo = getDateFromDate(ranges.dateTo);

            api.classes.getStudentsActivity({
                session: store.session,
                classId,
                dateFrom: ranges.dateFrom,
                dateTo: ranges.dateTo,
            }).then((res) => {
                setStudentsActivity((prev) => ({
                    ...prev,
                    isLoaded: true,
                    students: res.ok ? res.studentActivity : [],
                }));
            });
        });
    };

    const loadStudentsForgotttenPassword = () => {
        api.classes.getStudentsWithForgottenPasswords({
            session: store.session,
            classId: store.teacher.selectedClassId,
        }).then((res) => {
            dispatch(actions.teacher.setTeacherStudentWithForgottenPasswords({
                students: res.ok ? res.students : [],
            }));
        });
    };

    const loadTeacherClasses = () => {
        dispatch(actions.teacher.setTeacherStatsDate({
            statsDate: null,
        }));

        api.classes.getTeacherClasses({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                const classes = res.classes || [];

                let classId = storage.local.loadTeacherSelectedClass();

                let classStillExists = false;

                if (classId) {
                    for (let i = 0; i < classes.length; i += 1) {
                        if (classes[i].id === parseInt(classId, 10)) {
                            classStillExists = true;
                            break;
                        }
                    }
                }

                classId = parseInt(classId, 10);

                if (!classStillExists && classes[0]) {
                    classId = classes[0].id || -1;
                    storage.local.saveTeacherSelectedClass(classId);
                }

                dispatch(actions.teacher.setTeacherSelectedClass({
                    selectedClassId: classId,
                }));

                dispatch(actions.teacher.setClasses({
                    classes,
                }));

                if (classes.length === 0) {
                    teacherPopupWelcome.open();
                }

                loadLearningStandartsByClassId(classId);
                loadClassSelectedAnnouncementByClassId(classId);
                loadStudentActivity(classId);
                loadJuiceStats(classId);
            }
        });
    };

    const loadClassStudents = (classId) => {
        dispatch(actions.teacher.setLoadingClassStudentsById({
            classId,
        }));

        api.classes.getClassShortInfoStudentsById({
            session: store.session,
            classId,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.teacher.setClassStudentsById({
                    classId,
                    students: res.students,
                }));
            }
        });
    };

    useEffect(() => {
        if (store.session) {
            loadTeacherClasses();
        }
    }, [store.session]);

    useEffect(() => {
        setAnnoucementPopup((prev) => ({
            ...prev,
            selectedClass: store.teacher.selectedClassId,
        }));

        setSponsorPopup((prev) => ({
            ...prev,
            selectedClass: store.teacher.selectedClassId,
        }));

        if (store.teacher.selectedClassId !== -1) {
            loadStudentsForgotttenPassword();
            loadClassStudents(store.teacher.selectedClassId);
        }
    }, [store.teacher.selectedClassId]);

    const loadStudentDailyJuices = (studentId, clearJuices = true) => {
        if (clearJuices) {
            dispatch(actions.juices.clearStudentJuicesById({
                studentId,
            }));
        }

        dispatch(actions.juices.setStudentJuicesByIdLoading({
            studentId,
        }));

        let page = 0;

        if (!clearJuices && store.juices?.studentJuicesById[studentId]?.page) {
            page = store.juices.studentJuicesById[studentId].page;
        }

        api.students.getStudentDailyJuicesResultsByPage({
            session: store.session,
            studentId,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setStudentJuicesById({
                    studentId,
                    juices: res.juices,
                    hasMore: res.hasMore,
                    page: page + 1,
                }));
            }
        });
    };

    const loadStudentInfo = (studentId) => {
        studentInfoPopup.open(studentId);

        api.students.getStudentInfo({
            session: store.session,
            studentId,
        }).then((res) => {
            studentInfoPopup.setStudent(res.student);
        });
    };

    const getClassStudentsByClassId = (classId) => {
        return store.teacher.classesStudentsById[classId]?.students || [];
    };

    const onLearningStandardsViewResults = () => {
        const students = getClassStudentsByClassId(store.teacher.selectedClassId);

        if (students.length > 0) {
            loadStudentInfo(students[0].id);
            loadStudentDailyJuices(students[0].id);
        }
    };

    const onChangeStudentActivityDataRange = (date) => {
        loadStudentActivity(store.teacher.selectedClassId, date);
    };

    const onOpenStudentPopup = (userId) => {
        loadStudentInfo(userId);
        loadStudentDailyJuices(userId);
    };

    const onCloseAddStudentPopup = () => {
        addStudentPopup.close();
        progressPopupAddStudents.close();
    };

    const onStudentInfoSelectStudent = (id) => {
        loadStudentInfo(id);
        loadStudentDailyJuices(id);
    };

    const onCloseStudentPopup = () => {
        studentInfoPopup.close();
    };

    const onOpenNewPasswordPopup = (studentId) => {
        setNewPasswordPopupState({
            isLoading: false,
            isSubmitted: false,
            message: null,
            isOpen: true,
            id: studentId,
        });
    };

    const onRemoveStudent = (id) => {
        setRemoveStudentPopup({
            isOpen: true,
            isLoading: false,
            error: null,
            studentId: id,
        });
    };

    const onUploadSponsorImage = (evt) => {
        events.teacherHomeUploadAnImage({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        if (!evt) {
            setSponsorPopup((prev) => {
                const allImages = (prev.sponsor.images || []).map(() => ({
                    id: "",
                    url: "",
                }));

                return {
                    ...prev,
                    isImageLoading: false,
                    sponsor: {
                        ...prev.sponsor,
                        imageId: 0,
                        imageUrl: "",
                        images: allImages,
                    },
                };
            });
        } else if (evt.target.files.length > 0) {
            const file = evt.target.files[0];

            setSponsorPopup((prev) => ({
                ...prev,
                isImageLoading: true,
            }));

            api.classes.uploadSponsorMedia({
                session: store.session,
                file,
            }).then((res) => {
                if (res.ok) {
                    setSponsorPopup((prev) => ({
                        ...prev,
                        isImageLoading: false,
                        sponsor: {
                            ...prev.sponsor,
                            imageId: res.data.id,
                            imageUrl: res.data.url,
                        },
                    }));
                }
            });
        }
    };

    const loadSponsorsByClassId = (classId) => {
        setSponsorPopup((prev) => ({
            ...prev,
            isLoaded: false,
            sponsor: {},
            selectedClass: classId,
        }));

        if (classId !== allClassesTitle) {
            api.classes.getClassSponsors({ classId: classId || -1 }).then((res) => {
                let content = "";
                let imageId = "";
                let imageUrl = "";

                if (res.ok) {
                    content = res.data.sponsorContent;
                    imageId = res.data.sponsorImageId;
                    imageUrl = res.data.sponsorImageUrl;
                }

                setSponsorPopup((prev) => ({
                    ...prev,
                    isLoaded: true,
                    sponsor: {
                        ...prev.sponsor,
                        content,
                        imageId,
                        imageUrl,

                        contents: [],
                        images: [],
                    },
                }));
            });
        } else {
            api.classes.getAllSponsorsByTeacher({
                session: store.session,
            }).then((res) => {
                const contents = [];
                const images = [];

                if (res.ok) {
                    res.data.forEach((oneSponsor) => {
                        contents.push(oneSponsor.sponsorContent);
                        images.push({
                            id: oneSponsor.sponsorImageId,
                            url: oneSponsor.sponsorImageUrl,
                        });
                    });
                }

                setSponsorPopup((prev) => ({
                    ...prev,
                    isLoaded: true,
                    sponsor: {
                        ...prev.sponsor,

                        content: "",
                        imageId: "",
                        imageUrl: "",

                        contents,
                        images,
                    },
                }));
            });
        }
    };

    const loadAnnouncementByClassId = (classId) => {
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            isLoaded: false,
            selectedClass: classId,
        }));

        if (classId !== allClassesTitle) {
            api.classes.getClassAnnouncement({ classId: classId || -1 }).then((res) => {
                const content = res.ok ? res.data.announcementContent : "";
                const title = res.ok ? res.data.announcementTitle : "";

                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isLoaded: true,
                    announcement: {
                        content,
                        title,
                        contents: [],
                    },
                }));
            });
        } else {
            api.classes.getAllClassesAnnouncementsByTeacher({
                session: store.session,
            }).then((res) => {
                const allClassesContent = [];

                if (res.ok) {
                    res.data.forEach((oneClass) => {
                        allClassesContent.push(oneClass.announcementContent);
                    });
                }

                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isLoaded: true,
                    announcement: {
                        ...prevState.announcement,
                        contents: allClassesContent,
                    },
                }));
            });
        }
    };

    const onOpenSponsorPopup = () => {
        const classId = store.teacher.selectedClassId;
        loadSponsorsByClassId(classId);
        setIsVisibleSponsorPopup(true);
    };

    const onStatsRangeChange = (value) => {
        dispatch(actions.teacher.setTeacherStatsDate({
            statsDate: value,
        }));

        loadJuiceStats(store.teacher.selectedClassId, value);
    };

    const onViewAllScores = () => {
        events.teacherHomeViewAllScores({
            session: store.session,
        });

        history.push("/class");
    };

    const onOpenAnnouncementPopup = () => {
        const classId = store.teacher.selectedClassId;
        loadAnnouncementByClassId(classId);
        setIsVisibleAnnouncementPopup(true);
    };

    const onCloseSponsorPopup = () => {
        setSponsorPopup(getSponsorPopupState());
        setSponsorPopup((prevState) => ({
            ...prevState,
            selectedClass: store.teacher.selectedClassId,
        }));
        setIsVisibleSponsorPopup(false);
    };

    const onCloseAnnouncementPopup = () => {
        setAnnoucementPopup(getAnnouncementPopupState());
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            selectedClass: store.teacher.selectedClassId,
        }));
        loadClassSelectedAnnouncementByClassId(store.teacher.selectedClassId);
        setIsVisibleAnnouncementPopup(false);
    };

    const saveAnnouncementForAllClasses = (values) => {
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            isSaving: true,
            errors: [],
        }));

        api.classes.updateAllTeacherClassesAnnouncements({
            session: store.session,
            content: values.content,
        }).then((res) => {
            if (res.ok) {
                onCloseAnnouncementPopup();
            } else {
                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isSaving: false,
                    errors: [res.error],
                }));
            }
        });
    };

    const saveAndCloseAnnouncementPopup = (values) => {
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            isSaving: true,
            errors: [],
        }));

        events.teacherHomeEditClassAnnouncement({
            session: store.session,
            classId: announcementPopup.selectedClass,
        });

        api.classes.updateClassAnnouncement({
            session: store.session,
            id: announcementPopup.selectedClass,
            content: values.content,
        }).then((res) => {
            if (res.ok) {
                onCloseAnnouncementPopup();
            } else {
                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isSaving: false,
                    errors: [res.error],
                }));
            }
        });
    };

    const saveAndCloseSponsorPopup = (values) => {
        setSponsorPopup((prev) => ({
            ...prev,
            isSaving: true,
            errors: [],
        }));

        api.classes.updateClassSponsors({
            session: store.session,
            classId: sponsorPopup.selectedClass,
            sponsorContent: values.content,
            sponsorImageId: values.imageId,
        }).then((res) => {
            if (res.ok) {
                onCloseSponsorPopup();
            } else {
                setSponsorPopup((prev) => ({
                    ...prev,
                    isSaving: false,
                    errors: res.error ? [res.error] : [],
                }));
            }
        });
    };

    const saveSponsorContentForAllTeacherClasses = (values) => {
        setSponsorPopup((prevState) => ({
            ...prevState,
            isSaving: true,
            errors: [],
        }));

        api.classes.updateAllTeacherClassesSponsor({
            session: store.session,
            sponsorImageId: values.imageId,
            sponsorContent: values.content,
        }).then((res) => {
            if (res.ok) {
                onCloseSponsorPopup();
            } else {
                setSponsorPopup((prev) => ({
                    ...prev,
                    isSaving: false,
                    errors: res.error ? [res.error] : [],
                }));
            }
        });
    };

    const onPreviewAds = (img, text) => {
        setAdsPreviewPopup({
            isOpen: true,
            img: img.imageUrl,
            text,
        });
    };

    const onCloseNewPasswordPopup = () => {
        setNewPasswordPopupState({
            isOpen: false,
            isSubmitted: false,
            isLoading: false,
            message: null,
            id: null,
        });
    };

    const onChangeStudentPassword = (newPassword) => {
        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSubmitted: true,
            isLoading: true,
        }));

        api.students.setPassword({
            session: store.session,
            studentId: newPasswordPopupState.id,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                    studentId: newPasswordPopupState.id,
                }));
            }

            setNewPasswordPopupState((prev) => ({
                ...prev,
                isLoading: false,
                message: res.ok ? "Password successfully updated!" : res.error,
            }));
        });
    };

    const onCloseDeletingPopup = () => {
        setRemoveStudentPopup({
            isOpen: false,
            isLoading: false,
            error: null,
            studentId: -1,
        });
    };

    const removeStudent = () => {
        setRemoveStudentPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        api.classes.removeStudentById({
            session: store.session,
            studentId: removeStudentPopup.studentId,
        }).then((res) => {
            if (res.ok) {
                onCloseDeletingPopup();
                onCloseStudentPopup();
                loadStudentActivity(store.teacher.selectedClassId);
            } else {
                setRemoveStudentPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.error,
                }));
            }
        });
    };

    const onLoadClassById = (id) => {
        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: id,
        }));
        storage.local.saveTeacherSelectedClass(id);

        events.teacherHomeChangeClass({
            session: store.session,
            classId: id,
        });

        loadLearningStandartsByClassId(id);
        loadClassSelectedAnnouncementByClassId(id);
        loadStudentActivity(id);
        loadJuiceStats(id, dropdownRange);
    };

    const onCloseAddStudentProgressPopup = () => {
        loadTeacherClasses();

        progressPopupAddStudents.close();
    };

    const onShowAddStudentPopup = () => {
        addStudentPopup.open();

        loadImportStudentsTemplate();
    };

    const onAddStudentsToClassButtonClick = (students) => {
        progressPopupAddStudents.open();

        onAddStudentsToClass(0, students);
    };

    const onGenerateClassCode = () => {
        classCodePopup.open();

        const classCode = Classes.getClassCodeById(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        if (classCode) {
            classCodePopup.setCode(classCode);
        } else {
            api.signup.generateClassCode({
                session: store.session,
                classId: store.teacher.selectedClassId,
            }).then((res) => {
                if (!res.ok) {
                    classCodePopup.setError(res.error);
                    return;
                }

                classCodePopup.setCode(res.code);

                dispatch(actions.teacher.setClassCode({
                    classId: store.teacher.selectedClassId,
                    classCode: res.code,
                }));
            });
        }
    };

    const onDirectLinkClick = () => {
        classCodeLinkPopup.open();

        const classCode = Classes.getClassCodeById(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        if (classCode) {
            classCodeLinkPopup.setCode(classCode);
        } else {
            api.signup.generateClassCode({
                session: store.session,
                classId: store.teacher.selectedClassId,
            }).then((res) => {
                if (!res.ok) {
                    classCodeLinkPopup.setError(res.error);
                    return;
                }

                classCodeLinkPopup.setCode(res.code);

                dispatch(actions.teacher.setClassCode({
                    classId: store.teacher.selectedClassId,
                    classCode: res.code,
                }));
            });
        }
    };

    const onAddDuplicate = () => {
        onAddStudentsToClass(
            progressPopupAddStudents.state.currentLoading.index,
            progressPopupAddStudents.state.students,
            true,
        );
    };

    const onSkipDuplicate = () => {
        const newStudents = [...progressPopupAddStudents.state.students];

        newStudents[progressPopupAddStudents.state.currentLoading.index] = {
            ...newStudents[progressPopupAddStudents.state.currentLoading.index],
            status: "skipped",
        };

        progressPopupAddStudents.setStudentsSkippedStatus({
            students: newStudents,
        });

        onAddStudentsToClass(
            progressPopupAddStudents.state.currentLoading.index + 1,
            newStudents,
        );
    };

    const isContentLoaded = () => {
        if (!selectedClassAnnouncement.isLoaded
            || juiceStats.isLoading
            || !studentsActivity.isLoaded
            || isVisibleSponsorPopup
            || (announcementPopup && announcementPopup.isLoaded)) {
            return false;
        }

        return true;
    };

    const renderTutorial = () => {
        if (!isContentLoaded()) {
            return null;
        }

        return (
            <Tutorial
                disabled={teacherPopupWelcome.state.isOpen}
                autoSkip={teacherPopupWelcome.state.skipTutorial}
                name="teacher-index"
            />
        );
    };

    const renderAnnouncementPopup = () => {
        if (!isVisibleAnnouncementPopup || !announcementPopup.announcement) {
            return null;
        }

        const classes = [...store.teacher.classes.map((cl) => ({ id: cl.id, title: cl.title }))];

        if (classes.length > 1) {
            classes.push({
                title: allClassesTitle,
                id: allClassesTitle,
            });
        }

        const isSelectedAllClasses = announcementPopup.selectedClass === allClassesTitle;

        const announcement = {
            isLoaded: announcementPopup.isLoaded,

            contents: announcementPopup.announcement.contents || [],
            content: announcementPopup.announcement.content || "",
        };

        return (
            <PopupAnnouncement
                classes={classes || []}
                selectedClass={announcementPopup.selectedClass}
                dimensions={store.dimensions}
                announcement={announcement}
                errors={announcementPopup.errors}
                isSaving={announcementPopup.isSaving}
                isSelectedAllClasses={isSelectedAllClasses}
                onPreviewAds={onPreviewAds}
                onClose={onCloseAnnouncementPopup}
                onSaveAndClose={saveAndCloseAnnouncementPopup}
                onSaveForAllClasses={saveAnnouncementForAllClasses}
                onClassChange={loadAnnouncementByClassId}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopupState.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={newPasswordPopupState.message}
                isSubmitted={newPasswordPopupState.isSubmitted}
                isLoading={newPasswordPopupState.isLoading}
                onClose={onCloseNewPasswordPopup}
                onSave={onChangeStudentPassword}
            />
        );
    };

    const renderAdsPreviewPopup = () => {
        if (!adsPreviewPopup.isOpen) {
            return null;
        }

        return (
            <PopupAdsPreview
                data={{
                    sponsorImage: adsPreviewPopup.img,
                    announcementContent: adsPreviewPopup.text,
                }}
                onClose={() => {
                    setAdsPreviewPopup({
                        isOpen: false,
                        img: "",
                        text: "",
                    });
                }}
            />
        );
    };

    const renderRemoveStudentPopup = () => {
        if (!removeStudentPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                isLoading={removeStudentPopup.isLoading}
                error={removeStudentPopup.error}
                onDelete={removeStudent}
                onClose={onCloseDeletingPopup}
            />
        );
    };

    const renderStudentInfoPopup = () => {
        if (!studentInfoPopup.state.isOpen) {
            return null;
        }

        let isStudentForgotPassword = false;
        let studentData = {};

        const accountId = studentInfoPopup.state?.student?.account?.ID;

        if (accountId) {
            const { studentsWithForgottenPasswords } = store.teacher;

            for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
                if (studentsWithForgottenPasswords[i].id === accountId) {
                    isStudentForgotPassword = true;
                    break;
                }
            }

            studentData = {
                ...studentInfoPopup.state.student,
                isForgotPassword: isStudentForgotPassword,
            };
        }

        let dailyJuices = {};
        let isDailyJuicesLoading = false;

        if (store.juices?.studentJuicesById[studentInfoPopup.state.studentId]) {
            dailyJuices = store.juices.studentJuicesById[studentInfoPopup.state.studentId];

            if (store.juices.studentJuicesById[studentInfoPopup.state.studentId].isLoading) {
                isDailyJuicesLoading = true;
            }
        }

        const students = getClassStudentsByClassId(store.teacher.selectedClassId);

        return (
            <TeacherPopupStudentInfo
                session={store.session}
                classId={store.teacher.selectedClassId}
                studentId={studentInfoPopup.state.studentId}
                student={studentData}
                isStudentLoaded={studentInfoPopup.state.isLoaded}
                students={students}
                dailyJuices={dailyJuices}
                isDailyJuicesLoading={isDailyJuicesLoading}
                defaultSelectedMenu={2}
                isMobile={store.dimensions.width < 900}
                isCards={store.dimensions.width < 900}
                hideArrows={store.dimensions.width < 500}
                onLoadMoreDailyJuices={() => {
                    loadStudentDailyJuices(studentInfoPopup.state.studentId, false);
                }}
                onEditPassword={() => {
                    onOpenNewPasswordPopup(studentInfoPopup.state.studentId);
                }}
                onRemoveStudent={() => {
                    onRemoveStudent(studentInfoPopup.state.studentId);
                }}
                onUpdate={() => {
                    loadStudentInfo(studentInfoPopup.state.studentId);
                    loadStudentActivity(store.teacher.selectedClassId);
                }}
                onSelectStudent={onStudentInfoSelectStudent}
                onClose={onCloseStudentPopup}
            />
        );
    };

    const renderSponsorPopup = () => {
        if (!isVisibleSponsorPopup || !sponsorPopup.sponsor) {
            return null;
        }

        const classes = [...store.teacher.classes.map((cl) => ({ id: cl.id, title: cl.title }))];

        if (classes.length > 1) {
            classes.push({
                title: allClassesTitle,
                id: allClassesTitle,
            });
        }

        const sponsor = {
            isLoaded: sponsorPopup.isLoaded,
            isImageLoading: sponsorPopup.isImageLoading,

            content: sponsorPopup.sponsor.content || "",
            imageId: sponsorPopup.sponsor.imageId || "",
            imageUrl: sponsorPopup.sponsor.imageUrl || "",

            contents: sponsorPopup.sponsor.contents || [],
            images: sponsorPopup.sponsor.images || [],
        };

        const isSelectedAllClasses = sponsorPopup.selectedClass === allClassesTitle;

        return (
            <PopupSponsorContent
                classes={classes}
                selectedClass={sponsorPopup.selectedClass}
                isSelectedAllClasses={isSelectedAllClasses}
                sponsor={sponsor}
                dimensions={store.dimensions}
                errors={sponsorPopup.errors || []}
                isSaving={sponsorPopup.isSaving}
                onPreviewAds={onPreviewAds}
                onClose={onCloseSponsorPopup}
                onClassChange={loadSponsorsByClassId}
                onSave={saveAndCloseSponsorPopup}
                onSaveAll={saveSponsorContentForAllTeacherClasses}
                onUploadSponsorImage={onUploadSponsorImage}
            />
        );
    };

    const renderTabs = () => {
        const tabs = store.teacher.classes.map((sClass) => {
            return {
                value: sClass.id,
                label: sClass.title,
            };
        });

        return (
            <Tabs
                tabs={tabs}
                selectedTab={store.teacher.selectedClassId}
                onChange={(values) => {
                    onLoadClassById(values.value);
                }}
            />
        );
    };

    const renderAddStudentAlertBox = () => {
        if (!showSkeletonForSelectedClass) {
            return null;
        }

        const controls = [
            <ButtonDefault
                isPrimary
                onClick={onShowAddStudentPopup}
            >
                Add student
            </ButtonDefault>,
        ];

        return (
            <AlertBox
                theme="add-student"
                rightControls={controls}
            >
                You haven&apos;t added any students yet. Add a student to get started!
            </AlertBox>
        );
    };

    const renderAlerts = () => {
        return (
            <div className={styles.alerts}>
                {renderAddStudentAlertBox()}
            </div>
        );
    };

    const renderAddStudentWindow = () => {
        if (!addStudentPopup.state.isOpen) {
            return null;
        }

        const defaultGrade = Grades.getValidGrade(
            getClassGradeById(store.teacher.selectedClassId),
        );

        const classLimit = Classes.getClassLimit(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        const studentsCount = Classes.getStudentsCount(
            store.teacher.classes,
            store.teacher.selectedClassId,
        );

        return (
            <PopupFullScreenAddStudent
                grades={settings.grades}
                defaultGrade={defaultGrade}
                isMobile={store.dimensions.width < 800}
                classStudentsCount={studentsCount}
                classLimit={classLimit}
                supportLink={settings.supportLink}
                importStudentsTemplateUrl={addStudentPopup.state.templateLink}
                onGenerateClassCode={onGenerateClassCode}
                onDirectLinkClick={onDirectLinkClick}
                onAddStudentsToClass={onAddStudentsToClassButtonClick}
                onClose={onCloseAddStudentPopup}
            />
        );
    };

    const renderAddStudentsProgressPopup = () => {
        if (!progressPopupAddStudents.state.isOpen) {
            return null;
        }

        return (
            <PopupAddStudentsProgress
                data={progressPopupAddStudents.state.students}
                inProgress={progressPopupAddStudents.state.inProgress}
                totalLoaded={progressPopupAddStudents.state.totalLoaded}
                currentStudentErrorCode={progressPopupAddStudents.state.currentLoading.errorCode}
                currentLoadingStudentIndex={progressPopupAddStudents.state.currentLoading.index}
                onAddDuplicate={onAddDuplicate}
                onSkipDuplicate={onSkipDuplicate}
                onClose={onCloseAddStudentProgressPopup}
            />
        );
    };

    const renderClassCodePopup = () => {
        if (!classCodePopup.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmGenerateClassCode
                code={classCodePopup.state.code}
                signUpUrl={signUpUrl}
                error={classCodePopup.state.error}
                isLoading={classCodePopup.state.isLoading}
                disabled={classCodePopup.state.isLoading || classCodePopup.state.error}
                onSubmit={() => {
                    onCopyToClipboard(classCodePopup.state.code, "Class code copied to clipboard.");
                }}
                onClose={() => {
                    classCodePopup.close();
                }}
            />
        );
    };

    const renderClassCodeDirectLinkPopup = () => {
        if (!classCodeLinkPopup.state.isOpen) {
            return null;
        }

        const registrationLink = `${signUpUrl}/${classCodeLinkPopup.state.code}`;

        return (
            <PopupConfirmGenerateClassCode
                code={registrationLink}
                signUpUrl={signUpUrl}
                error={classCodePopup.state.error}
                isLoading={classCodeLinkPopup.state.isLoading || classCodeLinkPopup.state.error}
                isLink
                disabled={classCodeLinkPopup.state.isLoading}
                onSubmit={() => {
                    onCopyToClipboard(registrationLink, "Direct link copied to clipboard.");
                }}
                onClose={() => {
                    classCodeLinkPopup.close();
                }}
            />
        );
    };

    const renderTeacherWelcomePopup = () => {
        if (!teacherPopupWelcome.state.isOpen) {
            return null;
        }

        return (
            <TeacherPopupWelcome
                onTakeTour={loadTeacherClasses}
                onExploreOnMyOwn={() => {
                    teacherPopupWelcome.skipTutorial();
                    teacherPopupWelcome.close();
                    loadTeacherClasses();
                }}
            />
        );
    };

    const renderRow1 = () => {
        let stats = null;
        let chartPie = null;

        if (juiceStats.stats) {
            stats = {
                average: juiceStats.stats.averageScore,
                range: {
                    min: juiceStats.stats.minScore,
                    max: juiceStats.stats.maxScore,
                },
            };

            chartPie = {
                complete: juiceStats.stats.complete,
                inProgress: juiceStats.stats.inProgress,
                unopened: juiceStats.stats.unopened,
            };
        }

        const rowClasses = [
            styles.widgetRow,
            styles.widgetColumn2,
        ];

        return (
            <div className={rowClasses.join(" ")}>
                <TeacherClassActivity
                    pageWidth={pageWidth}
                    isSkeleton={showSkeletonForSelectedClass}
                    stats={stats}
                    chartPie={chartPie}
                    error={juiceStats.error}
                    isLoading={juiceStats.isLoading}
                    isMobile={pageWidth < 920}
                    dropdownRange={dropdownRange}
                    onRangeChange={onStatsRangeChange}
                    onViewAllScores={onViewAllScores}
                />

                <TeacherLearningStandards
                    pageWidth={pageWidth}
                    isSkeleton={showSkeletonForSelectedClass}
                    isLoading={learningStandardsState.isLoading}
                    standards={learningStandardsState.standards}
                    onViewResults={onLearningStandardsViewResults}
                    onRangeChange={(value) => {
                        loadLearningStandartsByClassId(store.teacher.selectedClassId, value);
                    }}
                />
            </div>
        );
    };

    const renderRow2 = () => {
        const quickLinks = [
            {
                name: "Upload an image",
                dataComment: "daily-juice-upload-image",
                callback: () => {
                    onOpenSponsorPopup();
                },
            },
        ];

        const rowClasses = [
            styles.widgetRow,
            styles.widgetColumn3,
        ].join(" ");

        return (
            <div className={styles.homePage}>
                <div className={rowClasses}>
                    <TeacherStudentsActivity
                        pageWidth={pageWidth}
                        isSkeleton={showSkeletonForSelectedClass}
                        students={studentsActivity.students}
                        isLoaded={studentsActivity.isLoaded}
                        onChangeDataRange={onChangeStudentActivityDataRange}
                        onStudentClick={onOpenStudentPopup}
                    />

                    <TeacherClassAnnouncement
                        pageWidth={pageWidth}
                        isSkeleton={showSkeletonForSelectedClass}
                        isLoaded={selectedClassAnnouncement.isLoaded}
                        content={selectedClassAnnouncement.content}
                        onEdit={onOpenAnnouncementPopup}
                    />

                    <TeacherQuickLinks
                        links={quickLinks}
                        pageWidth={pageWidth}
                        isSkeleton={showSkeletonForSelectedClass}
                    />
                </div>
            </div>
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            return (
                <Snackbar
                    isVisibleUndoButton={bar.isVisibleUndoButton}
                    autoCloseInSeconds={bar.autoCloseInSeconds}
                    message={bar.message}
                    onClose={(params) => {
                        onCloseSnackbar({
                            ...params,
                            index,
                            callback: () => {
                                if (bar.callback) {
                                    bar.callback();
                                }
                            },
                        });
                    }}
                />
            );
        });
    };

    const renderRows = () => {
        return [
            renderRow1(),
            renderRow2(),
        ];
    };

    if (!store.teacher.isClassesLoaded) {
        return <RequestLoader />;
    }

    return (
        <>
            {renderTutorial()}

            {renderStudentInfoPopup()}
            {renderTeacherWelcomePopup()}

            {renderAddStudentWindow()}
            {renderAddStudentsProgressPopup()}
            {renderClassCodePopup()}
            {renderClassCodeDirectLinkPopup()}

            {renderAnnouncementPopup()}

            {renderNewPasswordPopup()}

            {renderSponsorPopup()}
            {renderRemoveStudentPopup()}
            {renderAdsPreviewPopup()}

            {renderSnackbars()}

            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    {renderTabs()}
                    {renderAlerts()}
                    {renderRows()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["teacher"])(TeacherHomePage);
