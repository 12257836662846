// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DUuSaqpRh87pOKt5HG7Y {\n    position: relative;\n\n    width: 100%;\n    height: auto;\n\n    outline: none;\n}\n\n._APaDy8o9fpDzv_ell55 {\n    cursor: pointer;\n    outline: none;\n}\n\n.jNAca_T62pXvZiM9KvO_ {\n    position: relative;\n}\n\n._3uDEoI74F3L5B7hXcxP {\n    display: block;\n\n    margin: 0 auto;\n\n    width: auto;\n    max-width: 100%;\n\n    height: auto;\n    max-height: 35rem;\n}\n\n.ekdPWmooFIBTRhWFjZd1 {\n    position: absolute;\n    bottom: 0.7rem;\n    right: 0.8rem;\n}\n\n@media only screen and (min-width: 601px) {\n    ._3uDEoI74F3L5B7hXcxP {\n        border-radius: 0.8rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/featured-image/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;;IAEZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;;IAEd,cAAc;;IAEd,WAAW;IACX,eAAe;;IAEf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".featuredImgContainer {\n    position: relative;\n\n    width: 100%;\n    height: auto;\n\n    outline: none;\n}\n\n.featuredImgContainerClickable {\n    cursor: pointer;\n    outline: none;\n}\n\n.featuredImgWrapper {\n    position: relative;\n}\n\n.featuredImg {\n    display: block;\n\n    margin: 0 auto;\n\n    width: auto;\n    max-width: 100%;\n\n    height: auto;\n    max-height: 35rem;\n}\n\n.featuredImgFullscreen {\n    position: absolute;\n    bottom: 0.7rem;\n    right: 0.8rem;\n}\n\n@media only screen and (min-width: 601px) {\n    .featuredImg {\n        border-radius: 0.8rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featuredImgContainer": "DUuSaqpRh87pOKt5HG7Y",
	"featuredImgContainerClickable": "_APaDy8o9fpDzv_ell55",
	"featuredImgWrapper": "jNAca_T62pXvZiM9KvO_",
	"featuredImg": "_3uDEoI74F3L5B7hXcxP",
	"featuredImgFullscreen": "ekdPWmooFIBTRhWFjZd1"
};
export default ___CSS_LOADER_EXPORT___;
