import array from "juice-base/lib/array.js";


const getStandardsTypes = () => {
    return [
        {
            value: "juice-standard",
            label: "Juice Standard",
        },
        {
            value: "common-core",
            label: "Common Core",
        },
    ];
};

/* --- */

const getStandardTypeByStandard = (std, stdTypeName) => {
    const stdTypes = std.standardTypes || [];

    for (let i = 0; i < stdTypes.length; i += 1) {
        if (stdTypes[i].standard === stdTypeName) {
            return stdTypes[i];
        }
    }

    return false;
};

const getStandardTypeByType = (std, type) => {
    const stdTypes = std.standardTypes || [];

    for (let i = 0; i < stdTypes.length; i += 1) {
        if (stdTypes[i].type === type) {
            return stdTypes[i];
        }
    }

    return false;
};

/* --- */

const getStandardById = (standards, standardId) => {
    return array.findOneById(standards, standardId);
};

const getStandardsByType = (standards, type) => {
    return standards.filter((s) => s.type === type);
};

const getAllStandardsByType = (standards, type) => {
    const ret = [];

    if (standards) {
        for (let i = 0; i < standards.length; i += 1) {
            const stdTypes = standards[i].standardTypes || [];

            for (let j = 0; j < stdTypes.length; j += 1) {
                if (stdTypes[j].type === type) {
                    ret.push(stdTypes[j]);
                }
            }
        }
    }

    return ret;
};

/* --- */

const findStandardTypeByType = (standards, standardId, standardType) => {
    const std = getStandardById(standards, standardId);

    if (std) {
        return getStandardTypeByType(std, standardType);
    }

    return null;
};

const findStandardTypeByStandard = (standards, standardId, standardName) => {
    const std = getStandardById(standards, standardId);

    if (std) {
        return getStandardTypeByStandard(std, standardName);
    }

    return null;
};

const findStandardType = (standards, stdTypeName) => {
    if (standards && standards.length > 0) {
        for (let i = 0; i < standards.length; i += 1) {
            const std = standards[i];

            const stdType = getStandardTypeByStandard(std, stdTypeName);

            if (stdType) {
                return stdType;
            }
        }
    }

    return null;
};

export default {
    getStandardsTypes,

    getStandardTypeByStandard,
    getStandardTypeByType,

    getStandardById,
    getStandardsByType,
    getAllStandardsByType,

    findStandardTypeByType,
    findStandardTypeByStandard,
    findStandardType,
};
