const debug = (messageType, message, params = {}) => {
    if (IS_DEV) { // eslint-disable-line no-undef
        if (Object.keys(params).length > 0) {
            console.log(`[${messageType}]: ${message}`, params); // eslint-disable-line no-console
        } else {
            console.log(`[${messageType}]: ${message}`); // eslint-disable-line no-console
        }
    }
};


export const debugEvent = (message, params = {}) => {
    debug("event", message, params);
};

export default {};
