import * as types from "./types.js";


export const setStudentById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_BY_ID,
    payload,
});


export const setStudentQuizPerformanceLoadingById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_LOADING_BY_ID,
    payload,
});

export const setStudentQuizPerformanceById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_BY_ID,
    payload,
});

export const clearStudentQuizPerformances = () => ({
    type: types.CLEAR_TEACHER_STUDENT_QUIZ_PERFORMANCES,
});
