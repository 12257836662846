import React, { useState, useEffect } from "react";

import TextEditor from "juice-base/components/forms/text-editor/index.js";
import ButtonDefault from "juice-base/components/button-default/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import Tabs from "juice-base/components/tabs/index.js";

import styles from "./styles.module.css";


const PopupAnnouncement = (props) => {
    const isAllContentsSame = () => {
        const firstClassContent = props.announcement.contents[0] || "";

        return props.announcement.contents
            .every((content) => content === firstClassContent);
    };

    const getAllClassesAnnouncementText = () => {
        let text = "";

        if (isAllContentsSame()) {
            text = props.announcement.contents[0] || "";
        }

        return text;
    };

    const getText = () => {
        let text = "";

        if (props.isSelectedAllClasses) {
            text = getAllClassesAnnouncementText();
        } else {
            text = props.announcement.content;
        }

        return text;
    };

    const [text, setText] = useState(() => {
        const textValue = getText();
        return {
            text: textValue,
            size: textValue.length,
        };
    });

    const isContentChanged = () => {
        if (!props.isSelectedAllClasses && props.announcement.content !== text.text) {
            return true;
        }

        if (props.isSelectedAllClasses && getAllClassesAnnouncementText() !== text.text) {
            return true;
        }

        return false;
    };

    const onSaveText = () => {
        const values = {
            content: text.text,
        };

        if (props.isSelectedAllClasses) {
            props.onSaveForAllClasses(values);
        } else {
            props.onSaveAndClose(values);
        }
    };

    useEffect(() => {
        const textValue = getText();

        setText((prev) => ({
            ...prev,
            text: textValue,
            size: textValue.length,
        }));
    }, [
        props.announcement.content,
        props.announcement.contents,
    ]);

    const renderMessages = () => {
        if (props.errors.length > 0) {
            let ret = "";

            props.errors.forEach((error) => {
                ret = `${ret} ${error}`;
            });

            return (
                <p className={styles.errorMessage}>
                    {ret}
                </p>
            );
        }

        return null;
    };

    const renderTextEditor = () => {
        const defaultText = getText();

        let rewriteMessage = null;

        if (props.isSelectedAllClasses) {
            if (!isAllContentsSame()) {
                rewriteMessage = (
                    <p className={styles.rewriteMessage}>
                        Some announcements have already been written.
                        Saving this tab will overwrite all announcements.
                    </p>
                );
            }
        }

        return (
            <div>
                <TextEditor
                    label=""
                    name="announcementText"
                    defaultValue={defaultText}
                    onChange={(textValue, size) => {
                        setText((prev) => ({
                            ...prev,
                            text: textValue,
                            size,
                        }));
                    }}
                />
                {rewriteMessage}
            </div>
        );
    };

    const renderControl = () => {
        if (!props.announcement.isLoaded) {
            return null;
        }

        const charactersStyles = {};

        const isTextLimitReached = text.size > props.maxTextCharacters;

        if (isTextLimitReached) {
            charactersStyles.color = "red";
        }

        return (
            <div className={styles.announcementControls}>
                <div>
                    <div
                        style={charactersStyles}
                    >
                        {`${text.size}/${props.maxTextCharacters} characters`}
                    </div>
                </div>
                <div>
                    {renderMessages()}
                </div>
                <div className={styles.announcementButtons}>
                    <ButtonDefault
                        isPrimary
                        onClick={onSaveText}
                        disabled={isTextLimitReached}
                        withLoader={props.isSaving}
                    >
                        Save
                    </ButtonDefault>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (!props.announcement.isLoaded) {
            return (
                <div className={styles.contentLoader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <>
                {renderTextEditor()}
                {renderControl()}
            </>
        );
    };

    const renderTabs = () => {
        const tabs = props.classes.map((sClass) => {
            return {
                value: sClass.id,
                label: sClass.title,
            };
        });

        return (
            <Tabs
                tabs={tabs}
                selectedTab={props.selectedClass}
                onChange={(values) => {
                    props.onClassChange(values.value);
                }}
            />
        );
    };

    const renderPopupContent = () => {
        return (
            <div className={styles.announcementContent}>
                <div className={styles.aboutAnnouncementList}>
                    <div>
                        About the announcement:
                    </div>
                    <ul>
                        <li>
                            An announcement will display every day until
                            you edit or delete it.
                        </li>
                        <li>
                            Your announcement will be displayed on the second screen
                            of the Daily Juice.
                            <span
                                className={styles.seeHere}
                                onKeyPress={() => {
                                    props.onPreviewAds("", text.text);
                                }}
                                onClick={() => {
                                    props.onPreviewAds("", text.text);
                                }}
                                role="button"
                                tabIndex="-1"
                            >
                                See example.
                            </span>
                        </li>
                        <li>
                            Each class can have their own separate announcement,
                            or you can send one announcement to all your classes at the same time.
                        </li>
                    </ul>
                </div>

                {renderTabs()}

                {renderContent()}
            </div>
        );
    };

    return (
        <PopupWindow
            onClose={props.onClose}
            showConfirmOnClose={isContentChanged()}
            title="Class Announcement"
        >
            {renderPopupContent()}
        </PopupWindow>
    );
};

PopupAnnouncement.defaultProps = {
    onClose: () => {},
    onClassChange: () => {},
    onSaveForAllClasses: () => {},

    onSaveAndClose: () => {},

    onPreviewAds: () => {},

    popupClasses: "",

    dimensions: {},
    classes: [],
    maxTextCharacters: 300,

    isSelectedAllClasses: false,

    isSaving: false,
    selectedClass: -1,
    announcement: {},
    errors: {},
};

export default PopupAnnouncement;
