
export const getSignupOptions = () => {
    return [
        { value: "teacher", label: "Teacher" },
        { value: "homeschooler", label: "Homeschooler" },
        { value: "administrator", label: "Administrator" },
        { value: "community-group-leader", label: "Community Group Leader" },
    ];
};

export default {
    getSignupOptions,
};
