import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Vocabulary from "juice-base/project/vocabulary.js";

import device from "juice-base/lib/device.js";
import { withAuth } from "juice-base/components/auth/index.js";
import actions from "juice-base/store/actions.js";
import useWordPopup from "juice-base/hooks/use-word-popup/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ExtraJuice from "juice-base/business/extra-juice/index.js";

import PopupText from "juice-base/components/popup-text/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    extraJuicesById: state.extraJuices.extraJuicesById,
    wordsByName: state.vocabulary.wordsByName,
    t2sExtraJuices: state.text2speech.extraJuices,
    t2sWords: state.text2speech.words,
});

const ShowExtraJuice = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const store = useSelector(storeSelector);

    const [audioPlayerPopup, setAudioPlayerPopup] = useState(-1);
    const wordPopup = useWordPopup();

    const am = useAudioManager({
        isMac: device.isMac,
    });

    const goBack = () => {
        history.push("/");
    };

    /* --- */

    const onOpenWordPopup = (word) => {
        wordPopup.setLoading(true, word);

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));

                wordPopup.setWord(wordValue);
            } else {
                wordPopup.setError(word, "Cannot load word");
            }
        }).catch(() => {
            wordPopup.setError(word, "Cannot load word");
        });
    };

    const onCloseWordPopup = () => {
        wordPopup.setWord("");
    };

    /* --- */

    const onPlayExtraJuice = (ejId) => {
        setAudioPlayerPopup(ejId);

        dispatch(actions.t2s.setExtraJuiceLoading({
            extraJuiceId: ejId,
        }));

        api.t2s.juice({
            juiceId: null,
            extraJuiceId: ejId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("extraJuices", ejId);

                dispatch(actions.t2s.setExtraJuice({
                    juiceId: null,
                    extraJuiceId: ejId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup(-1);
    };

    /* --- */

    useEffect(() => {
        api.extraJuices.getExtraJuice({
            session: store.session,
            extraJuiceId: params.extraJuiceId,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.extraJuices.setExtraJuice({
                    extraJuice: res.extra_juice,
                }));
            } else {
                goBack();
            }
        });
    }, []);

    useEffect(() => {
        am.setFiles({
            juiceStories: store.t2sJuiceStories,
            extraJuices: store.t2sExtraJuices,
            words: store.t2sWords,
        });
    }, [
        store.t2sJuiceStories,
        store.t2sExtraJuices,
        store.t2sWords,
    ]);

    /* --- */

    const renderWordPopup = () => {
        if (wordPopup.state.isLoading) {
            return (
                <PopupLoading />
            );
        }

        const word = Vocabulary.getWord(store.wordsByName, wordPopup.state.word);

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    onClose={onCloseWordPopup}
                />
            );
        }

        const trackGroupName = "words";
        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderPlayerAudioPopup = (eJuice) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (audioPlayerPopup !== -1) {
            title = eJuice.title;
            categoryName = eJuice.categoryName;

            if (eJuice.image) {
                img = eJuice.image;
            }

            trackGroupName = "extraJuices";
            trackId = eJuice.id;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = (am.state[trackGroupName] || {})[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-extra-juice-${eJuice.id}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onFirstPlay={() => {
                    am.playerPauseAll();

                    if (am.state[trackGroupName]
                        && am.state[trackGroupName][trackId]) {
                        am.playerPlay(trackGroupName, trackId);
                    } else {
                        onPlayExtraJuice(eJuice.id);
                    }
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderPopups = (eJuice) => {
        const ps = [];

        if (wordPopup.state.word) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup !== -1) {
            ps.push(renderPlayerAudioPopup(eJuice));
        }

        return ps;
    };

    if (!store.extraJuicesById[params.extraJuiceId]) {
        return (
            <RequestLoader />
        );
    }

    const extraJuice = store.extraJuicesById[params.extraJuiceId];

    return (
        <>
            {renderPopups(extraJuice)}
            <div className={styles.extraJuice}>
                <ExtraJuice
                    extraJuice={extraJuice}
                    onWordClick={onOpenWordPopup}
                    onAudioPlay={() => {
                        onPlayExtraJuice(extraJuice.id);
                    }}
                />
            </div>
        </>
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(ShowExtraJuice);
