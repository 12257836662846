import React from "react";

import classNames from "juice-base/lib/class-names.js";
import Error from "juice-base/components/forms/error/index.js";

import styles from "./styles.module.css";


const InputCustom = (props) => {
    const {
        type,
        autoComplete,
        name,
        label,
        value,
        error,
        maxLength,
        onKeyPress,
        onChange,
        onBlur,
    } = props;

    let inputError = null;

    if (error) {
        inputError = (
            <Error>
                {error}
            </Error>
        );
    }

    const inputClassName = classNames({
        [styles.input]: true,
        [styles.inputError]: error,
    });

    return (
        <div className={styles.field}>
            <input
                id={name}
                name={name}
                type={type}
                autoComplete={autoComplete}
                value={value}
                onKeyPress={onKeyPress}
                onChange={onChange}
                onBlur={onBlur}
                maxLength={maxLength}
                className={inputClassName}
            />

            <label htmlFor={name} className={styles.fieldLabel}>
                {label}
            </label>

            {inputError}
        </div>
    );
};

InputCustom.defaultProps = {
    type: "text",
    autoComplete: "",
    name: "",
    label: "",
    value: "",
    error: "",
    maxLength: 255,
    iconSrc: null,
    transparent: false,
    onKeyPress: () => { },
    onChange: () => { },
    onBlur: () => { },
};

export default InputCustom;
