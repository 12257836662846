// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y1_Aw2_9AlVrLs7kEvP1 {\n    display: grid;\n    grid-row-gap: 3rem;\n    justify-content: center;\n}\n\n.sWnYkCCFinS_fefYQmtV {}\n\n.Mdh_vN_k_wsdOgiHRoQm {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.2rem solid #cccccc;\n    border-radius: 0.5rem;\n\n    background-color: #fff;\n    color: #000000;\n}\n\n.QXf5MdnmrnzbKMNP6R75 {\n    width: 4rem;\n    height: 4rem;\n}\n\n.T7IKUVxcnl_bXOJ_FSVt {\n    grid-template-columns: 5rem auto;\n}\n\n._C1dSX_1Px0LiSszDxNE {\n    grid-template-columns: 5rem auto;\n}\n\n._dImLL7ediwW_x7weQNd {\n    grid-template-columns: 5rem auto;\n}\n", "",{"version":3,"sources":["webpack://./base/business/quiz-question-answers/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA,uBAAU;;AAEV;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;IAEnB,kBAAkB;IAClB,oBAAoB;;IAEpB,4BAA4B;IAC5B,qBAAqB;;IAErB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".answerEmoji {\n    display: grid;\n    grid-row-gap: 3rem;\n    justify-content: center;\n}\n\n.answers {}\n\n.answer {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.2rem solid #cccccc;\n    border-radius: 0.5rem;\n\n    background-color: #fff;\n    color: #000000;\n}\n\n.answerIcon {\n    width: 4rem;\n    height: 4rem;\n}\n\n.answerCorrect {\n    grid-template-columns: 5rem auto;\n}\n\n.answerIncorrect {\n    grid-template-columns: 5rem auto;\n}\n\n.answerOther {\n    grid-template-columns: 5rem auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"answerEmoji": "y1_Aw2_9AlVrLs7kEvP1",
	"answers": "sWnYkCCFinS_fefYQmtV",
	"answer": "Mdh_vN_k_wsdOgiHRoQm",
	"answerIcon": "QXf5MdnmrnzbKMNP6R75",
	"answerCorrect": "T7IKUVxcnl_bXOJ_FSVt",
	"answerIncorrect": "_C1dSX_1Px0LiSszDxNE",
	"answerOther": "_dImLL7ediwW_x7weQNd"
};
export default ___CSS_LOADER_EXPORT___;
