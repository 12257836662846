// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DNGc09Y1kYhMosrPucCI {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.BZvpoHMDjqbNZLKhkla6 {\n    display: grid;\n}\n\n/* --- */\n\n@media only screen and (min-width: 521px) {\n    .BZvpoHMDjqbNZLKhkla6 {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .DNGc09Y1kYhMosrPucCI {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/user/password-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA,QAAQ;;AAER;IACI;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".passwordChange {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    display: grid;\n}\n\n/* --- */\n\n@media only screen and (min-width: 521px) {\n    .content {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .passwordChange {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordChange": "DNGc09Y1kYhMosrPucCI",
	"content": "BZvpoHMDjqbNZLKhkla6"
};
export default ___CSS_LOADER_EXPORT___;
