// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s_FIP8a91bnwZGDJODNt {\n    display: grid;\n    justify-self: center;\n\n    padding: 5rem 2.5rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-full-screen-add-student/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;;IAEpB,oBAAoB;AACxB","sourcesContent":[".pageContent {\n    display: grid;\n    justify-self: center;\n\n    padding: 5rem 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "s_FIP8a91bnwZGDJODNt"
};
export default ___CSS_LOADER_EXPORT___;
