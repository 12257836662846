import {
    SET_LOCATION,
} from "./types.js";


const initialState = {
    location: {},
    prevLocation: {},
};

export default (state, action) => {
    switch (action.type) {
        case SET_LOCATION: {
            return {
                ...state,
                location: action.payload.location,
                prevLocation: {
                    ...state.location,
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
