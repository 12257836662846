import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";

import Icon from "juice-base/components/icon/index.js";

import linkStyles from "juice-base/components/link/styles.module.css";
import styles from "./styles.module.css";


const NotFound = () => {
    return (
        <div className={styles.notFound}>
            <div className={styles.message}>
                <div>
                    The page you were looking for doesn&apos;t exists.
                </div>

                <Icon
                    url={staticFiles.iconHome}
                    name="Home"
                />

                <div>
                    <Link to="/" className={linkStyles.link}>
                        Go to Home Page
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
