import React from "react";

import styles from "./styles.module.css";


const Button = (props) => {
    const {
        type,
        disabled,
        theme,
    } = props;

    const classes = [styles.button, props.buttonClasses];

    if (theme === "yellow") {
        classes.push(styles.buttonYellow);
    } else if (theme === "blue") {
        classes.push(styles.buttonBlue);
    } else if (theme === "orange") {
        classes.push(styles.buttonOrange);
    } else if (theme === "purple") {
        classes.push(styles.buttonPurple);
    }

    return (
        <button
            type={type} // eslint-disable-line react/button-has-type
            disabled={disabled}
            className={classes.join(" ")}
            onClick={props.onClick}
            onKeyPress={props.onClick}
        >
            {props.children}
        </button>
    );
};

Button.defaultProps = {
    type: "submit",
    disabled: false,
    theme: "",
    buttonClasses: "",
    onClick: () => {},
};

export default Button;
