// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.a7h9MvQoTph0ghWWtZAG {\n    display: grid;\n    grid-auto-flow: column;\n    align-items: center;\n\n    grid-gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/skeleton-header/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,cAAc;AAClB","sourcesContent":["\n.skeletonHeader {\n    display: grid;\n    grid-auto-flow: column;\n    align-items: center;\n\n    grid-gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeletonHeader": "a7h9MvQoTph0ghWWtZAG"
};
export default ___CSS_LOADER_EXPORT___;
