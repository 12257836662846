// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zm0fb9oNqPAUPmvRT_XC {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    font-size: 2.5rem;\n}", "",{"version":3,"sources":["webpack://./base/components/error-boundary/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".error {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    font-size: 2.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "Zm0fb9oNqPAUPmvRT_XC"
};
export default ___CSS_LOADER_EXPORT___;
