import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const MessageWithEmoji = (props) => {
    return (
        <div className={styles.messageWithEmoji}>
            <img
                src={staticFiles.emojisHappy}
                alt="Emoji Happy"
                className={styles.emojiImg}
            />
            <div className={styles.header}>
                {props.header}
            </div>
            <div className={styles.message}>
                {props.message}
            </div>
        </div>
    );
};

MessageWithEmoji.defaultProps = {
    header: "",
    message: "",
};

export default MessageWithEmoji;
