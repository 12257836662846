// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._bnJX57Yw1RMXEBTPmK5 {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n}\n\n._bnJX57Yw1RMXEBTPmK5 div:first-child {\n    margin-left: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/menu-horizontal/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".menuHorizontal {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n}\n\n.menuHorizontal div:first-child {\n    margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuHorizontal": "_bnJX57Yw1RMXEBTPmK5"
};
export default ___CSS_LOADER_EXPORT___;
