import React, { useState } from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import RadioCustom from "juice-base/components/forms/radio-custom/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import styles from "./styles.module.css";


const PopupGradeChange = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.defaultGrade);

    const renderContent = () => {
        let content = null;

        if (props.isSaving) {
            content = (
                <RequestLoader />
            );
        } else if (props.error) {
            content = props.error;
        } else {
            const radios = props.grades.map((grade) => {
                const isChecked = grade === selectedValue;

                const inputId = `grade-level-${grade}`;

                return (
                    <RadioCustom
                        id={inputId}
                        name="answer"
                        label={grade}
                        value={grade}
                        checked={isChecked}
                        onChange={() => {
                            setSelectedValue(grade);
                        }}
                    />
                );
            });

            content = (
                <div className={styles.form}>
                    {radios}
                </div>
            );
        }

        return (
            <div className={styles.content}>
                <div className={styles.header}>
                    Edit Reading Level
                </div>
                {content}
            </div>
        );
    };

    return (
        <PopupConfirm hideScrollbar={false}>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.isSaving || props.error}
                    onClick={() => {
                        props.onSave(selectedValue);
                    }}
                >
                    Save
                </ButtonFlat>
                <ButtonFlat
                    disabled={props.isSaving}
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupGradeChange.defaultProps = {
    defaultGrade: "",
    isSaving: false,
    error: null,
    grades: [],
    onSave: () => {},
    onClose: () => {},
};

export default PopupGradeChange;
