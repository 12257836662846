// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h_LN587Hl55V8OJTh6__ {}\n\n.tkDtgTjTWVv_unJfv9RB {}\n", "",{"version":3,"sources":["webpack://./base/business/popup-daily-juice-stats/styles.module.css"],"names":[],"mappings":"AAAA,uBAAQ;;AAER,uBAAc","sourcesContent":[".popup {}\n\n.popupWindow {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "h_LN587Hl55V8OJTh6__",
	"popupWindow": "tkDtgTjTWVv_unJfv9RB"
};
export default ___CSS_LOADER_EXPORT___;
