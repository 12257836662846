// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d2x74HjvhjGcEi463iE5 {}\n\n.Jj4Y1UcSIfND2Gkerq64 {\n    margin: 0 0 1rem 0;\n\n    display: grid;\n    grid-template-areas:\n        \"title    value\";\n    grid-row-gap: 0.5rem;\n    align-items: center;\n}\n\n.a0J2Xp1_h_Rarn8ovVH4 {\n    grid-template-areas:\n        \"title    value\"\n        \"subtitle value\";\n}\n\n.xylkvPWp1ako16ph0WM7 {\n    grid-area: title;\n\n    color: #000;\n    font-size: 1.6rem;\n    font-weight: 600;\n}\n\n.Bs_1fkJErEJT_L7hTKb2 {\n    grid-area: subtitle;\n\n    color: var(--grey-dark);\n    font-size: 1.4rem;\n}\n\n.YAj_VLq6LZHFfMOhWUmE {\n    grid-area: value;\n\n    color: rgb(var(--cerulean));\n    font-weight: 600;\n    text-align: right;\n}\n", "",{"version":3,"sources":["webpack://./base/components/progress-value/styles.module.css"],"names":[],"mappings":"AAAA,uBAAgB;;AAEhB;IACI,kBAAkB;;IAElB,aAAa;IACb;wBACoB;IACpB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI;;wBAEoB;AACxB;;AAEA;IACI,gBAAgB;;IAEhB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;;IAEnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;;IAEhB,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".progressValue {}\n\n.info {\n    margin: 0 0 1rem 0;\n\n    display: grid;\n    grid-template-areas:\n        \"title    value\";\n    grid-row-gap: 0.5rem;\n    align-items: center;\n}\n\n.infoWithSubtitle {\n    grid-template-areas:\n        \"title    value\"\n        \"subtitle value\";\n}\n\n.title {\n    grid-area: title;\n\n    color: #000;\n    font-size: 1.6rem;\n    font-weight: 600;\n}\n\n.subtitle {\n    grid-area: subtitle;\n\n    color: var(--grey-dark);\n    font-size: 1.4rem;\n}\n\n.value {\n    grid-area: value;\n\n    color: rgb(var(--cerulean));\n    font-weight: 600;\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressValue": "d2x74HjvhjGcEi463iE5",
	"info": "Jj4Y1UcSIfND2Gkerq64",
	"infoWithSubtitle": "a0J2Xp1_h_Rarn8ovVH4",
	"title": "xylkvPWp1ako16ph0WM7",
	"subtitle": "Bs_1fkJErEJT_L7hTKb2",
	"value": "YAj_VLq6LZHFfMOhWUmE"
};
export default ___CSS_LOADER_EXPORT___;
