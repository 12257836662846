import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grades from "juice-base/project/grades.js";
import Standards from "juice-base/project/standards.js";
import DailyJuices from "juice-base/project/daily-juices.js";

import date from "juice-base/lib/date.js";
import useValuePopup from "juice-base/hooks/use-value-popup/index.js";
import actions from "juice-base/store/actions.js";

import PopupNameChange from "juice-base/components/popup-name-change/index.js";
import PopupEmailChange from "juice-base/components/popup-email-change/index.js";
import PopupGradeChange from "juice-base/components/popup-grade-change/index.js";

import PopupConfirmStandardType from "juice-base/business/popup-confirm-standard-type/index.js";
import PopupStudentInfo from "juice-base/business/popup-student-info/index.js";

import api from "juice-app/api.js";
import * as events from "juice-base/events.js";


const storeSelector = (state) => ({
    quizPerformancesByStudentId: state.teacherStudents.quizPerformancesByStudentId,
});

const TeacherPopupStudentInfo = (props) => {
    const namePopup = useValuePopup();
    const emailPopup = useValuePopup();
    const gradePopup = useValuePopup();

    const [juiceDates, setJuiceDates] = useState({
        isLoaded: false,
        dates: [],
    });

    const [quizPerformanceRange, setQuizPerformanceRange] = useState("");

    const [standardTypePopup, setStandardTypePopup] = useState({
        isVisibile: false,
        standardType: "",
    });

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* --- */

    const getQuizPerformanceData = () => {
        const studentQuizPerfs = store.quizPerformancesByStudentId[props.studentId] || {};

        const data = studentQuizPerfs[quizPerformanceRange] || {
            isLoading: false,
            data: {},
        };

        return data;
    };

    const setQuizPerformanceLoading = (range) => {
        const studentQuizPerfs = store.quizPerformancesByStudentId[props.studentId] || {};

        const data = studentQuizPerfs[range];

        if (!data) {
            dispatch(actions.teacherStudents.setStudentQuizPerformanceLoadingById({
                studentId: props.studentId,
                range,
            }));
        }
    };

    /* --- */

    const onOpenStandardTypePopup = (standardType) => {
        setStandardTypePopup((prev) => ({
            ...prev,
            isVisible: true,
            standardType,
        }));
    };

    const onCloseStandardTypePopup = () => {
        setStandardTypePopup((prev) => ({
            ...prev,
            isVisible: false,
            standardType: "",
        }));
    };

    const onClosePopups = () => {
        namePopup.close();
        emailPopup.close();
        gradePopup.close();
    };

    /* --- */

    const onQuizPerformanceClear = () => {
        setQuizPerformanceRange("");
    };

    const onQuizPerformanceChange = async (values) => {
        setQuizPerformanceLoading(values.range);

        const perfResp = await api.students.getStudentQuizPerformance({
            session: props.session,
            studentId: props.studentId,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        let perfData = {};

        if (perfResp.ok) {
            perfData = perfResp?.quizPerformance || {};
        }

        dispatch(actions.teacherStudents.setStudentQuizPerformanceById({
            studentId: props.studentId,
            range: values.range,
            data: perfData,
        }));
    };

    const onQuizPerformanceDateChange = (value) => {
        const dateFrom = date.stringToDayDate(value);
        const range = `${dateFrom}-${dateFrom}`;

        setQuizPerformanceRange(range);

        onQuizPerformanceChange({
            range,
            dateFrom,
            dateTo: dateFrom,
        });
    };

    const onQuizPerformanceDateRangeChange = async (value) => {
        setQuizPerformanceRange(value);

        const resDate = await api.site.getSiteDate();
        // TODO: change logic when API is ready
        const siteDate = resDate.ok ? resDate.date : null;

        if (!siteDate) {
            setQuizPerformanceRange("");
            return;
        }

        const range = date.getDatesByRange(siteDate, value);

        onQuizPerformanceChange({
            range: value,
            dateFrom: date.getDateFromDate(range.dateFrom),
            dateTo: date.getDateFromDate(range.dateTo),
        });
    };

    /* --- */

    const loadJuicesDates = async () => {
        setJuiceDates((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        const resDates = await api.dailyJuices.getJuicesDates({
            session: props.session,
            studentId: props.studentId,
            classId: props.classId,
        });

        if (resDates.ok) {
            const dates = DailyJuices.sortJuiceDatesASC(resDates.juices);

            setJuiceDates((prev) => ({
                ...prev,
                isLoaded: true,
                dates,
            }));
        }
    };

    const onUpdateStudentInfo = (params) => {
        if (params.newFullname) {
            namePopup.setIsSaving();
        } else if (params.newEmail) {
            emailPopup.setIsSaving();
        } else if (params.newGrade) {
            gradePopup.setIsSaving();
        }

        api.students.updateStudentDataV2({
            session: props.session,
            studentId: props.studentId,

            fullname: params.newFullname || "",
            email: params.newEmail || "",
            grade: params.newGrade || "",
        }).then((res) => {
            events.classEditStudent({
                session: props.session,
                classId: props.classId,
            });

            if (res.ok) {
                onClosePopups();
                props.onUpdate();
                return;
            }

            const error = res.error || "Error!";

            if (params.newFullname) {
                namePopup.setError(error);
            } else if (params.newEmail) {
                emailPopup.setError(error);
            } else if (params.newGrade) {
                gradePopup.setError(error);
            }
        });
    };

    /* --- */

    useEffect(() => {
        return () => {
            dispatch(actions.teacherStudents.clearStudentQuizPerformances());
        };
    }, []);

    useEffect(() => {
        loadJuicesDates();
    }, [props.studentId]);

    /* --- */

    const renderNameChangePopup = () => {
        if (!namePopup.state.isOpen) {
            return null;
        }

        const defaultValue = props.student?.account?.fullName || "";

        return (
            <PopupNameChange
                defaultValue={defaultValue}
                isSaving={namePopup.state.isSaving}
                error={namePopup.state.error}
                onSave={(newFullname) => {
                    onUpdateStudentInfo({
                        newFullname,
                    });
                }}
                onClose={namePopup.close}
            />
        );
    };

    const renderEmailChangePopup = () => {
        if (!emailPopup.state.isOpen) {
            return null;
        }

        const defaultValue = props.student?.account?.email || "";

        return (
            <PopupEmailChange
                defaultValue={defaultValue}
                isSaving={emailPopup.state.isSaving}
                error={emailPopup.state.error}
                onSave={(newEmail) => {
                    onUpdateStudentInfo({
                        newEmail,
                    });
                }}
                onClose={emailPopup.close}
            />
        );
    };

    const renderGradeChangePopup = () => {
        if (!gradePopup.state.isOpen) {
            return null;
        }

        const studentGrade = props.student?.overview?.studentGrade;
        let defaultGrade = null;

        if (studentGrade) {
            defaultGrade = Grades.getGradeRange(studentGrade);
        }

        return (
            <PopupGradeChange
                defaultGrade={defaultGrade}
                grades={Grades.getGradesRanges()}
                isSaving={gradePopup.state.isSaving}
                error={gradePopup.state.error}
                onSave={(newGrades) => {
                    const sGrades = newGrades.split("-");

                    if (sGrades.length > 1) {
                        onUpdateStudentInfo({
                            newGrade: `g${sGrades[1]}`,
                        });
                    }
                }}
                onClose={gradePopup.close}
            />
        );
    };

    const renderStandardTypePopup = () => {
        if (!standardTypePopup.isVisible) {
            return null;
        }

        const quizData = getQuizPerformanceData();
        const standards = quizData?.data?.standards || [];
        const stdType = Standards.findStandardType(standards, standardTypePopup.standardType);

        return (
            <PopupConfirmStandardType
                standardType={stdType}
                onClose={onCloseStandardTypePopup}
            />
        );
    };

    const studentProps = {
        isLoaded: props.isStudentLoaded,
        id: props.studentId,
        data: props.student,

        onEditName: namePopup.open,
        onEditEmail: emailPopup.open,
        onEditGrade: gradePopup.open,
        onEditPassword: props.onEditPassword,

        onDeleteAccount: props.onRemoveStudent,
    };

    const quizPerformanceProps = {
        data: getQuizPerformanceData(),
        onDateChange: onQuizPerformanceDateChange,
        onDateRangeChange: onQuizPerformanceDateRangeChange,
        onStandardTypeClick: onOpenStandardTypePopup,
        onClear: onQuizPerformanceClear,
    };

    return (
        <>
            {renderNameChangePopup()}
            {renderGradeChangePopup()}
            {renderEmailChangePopup()}

            {renderStandardTypePopup()}

            <PopupStudentInfo
                student={studentProps}
                quizPerformance={quizPerformanceProps}
                studentsList={props.students}
                isDailyJuicesLoading={props.isDailyJuicesLoading}
                dailyJuices={props.dailyJuices}
                defaultSelectedMenu={props.defaultSelectedMenu}
                dailyJuiceDates={juiceDates}
                isMobile={props.isMobile}
                isCards={props.isMobile}
                hideArrows={props.hideArrows}
                onSelectStudent={props.onSelectStudent}
                onLoadMoreJuices={props.onLoadMoreDailyJuices}
                onClose={props.onClose}
            />
        </>
    );
};

TeacherPopupStudentInfo.defaultProps = {
    classId: -1,
    studentId: -1,
    defaultSelectedMenu: 0,
    student: null,
    isStudentLoaded: false,
    students: [],
    dailyJuices: [],
    isDailyJuicesLoading: false,
    isMobile: false,
    hideArrows: false,

    onSelectStudent: () => {},
    onLoadMoreDailyJuices: () => {},
    onEditPassword: () => {},
    onRemoveStudent: () => {},
    onUpdate: () => {},
    onClose: () => {},
};

export default TeacherPopupStudentInfo;
