import site from "./site.js";

import user from "./user.js";
import signup from "./signup.js";

import dailyJuices from "./daily-juices.js";
import extraJuices from "./extra-juices.js";
import juiceStories from "./juice-stories.js";
import quizzes from "./quizzes.js";
import vocabulary from "./vocabulary.js";
import videos from "./videos.js";

import classes from "./classes.js";
import students from "./students.js";

import search from "./search.js";

import tutorials from "./tutorials.js";
import templates from "./templates.js";

import t2s from "./t2s.js";

import geo from "./geo.js";

import events from "./events.js";

import error from "./error.js";


const createAPI = (url, methods) => {
    return {
        site: site(url, methods),

        user: user(url, methods),
        signup: signup(url, methods),

        dailyJuices: dailyJuices(url, methods),
        extraJuices: extraJuices(url, methods),
        juiceStories: juiceStories(url, methods),
        quizzes: quizzes(url, methods),
        vocabulary: vocabulary(url, methods),
        videos: videos(url, methods),

        search: search(url, methods),

        classes: classes(url, methods),
        students: students(url, methods),

        templates: templates(url, methods),
        tutorials: tutorials(url, methods),

        t2s: t2s(url, methods),

        geo: geo(url, methods),

        events: events(url, methods),

        error: error(url, methods),
    };
};

export default createAPI;
