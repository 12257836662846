// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JR9lDyz_04gUPmyPo859 {\n    display: grid;\n}\n\n.MbXEKh34ygqZWG373laB {\n    overflow: hidden;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/menu-horizontal-scrolling/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".menu {\n    display: grid;\n}\n\n.menuScrolling {\n    overflow: hidden;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "JR9lDyz_04gUPmyPo859",
	"menuScrolling": "MbXEKh34ygqZWG373laB"
};
export default ___CSS_LOADER_EXPORT___;
