import React from "react";

import styles from "./styles.module.css";


const Section = (props) => {
    const {
        name,
        children,
    } = props;

    let header = null;

    if (name) {
        header = (
            <div className={styles.header}>
                {name}
            </div>
        );
    }

    return (
        <section className={styles.section}>
            {header}
            {children}
        </section>
    );
};

Section.defaultProps = {
    name: "",
    children: null,
};

export default Section;
