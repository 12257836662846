// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.gGYpo3n56TFbeRoGg2zt {\n    margin: 2rem 1rem;\n}\n\n.IhcUgQHxflCZjiSEldO9 {\n    margin: 1.3rem 1.1rem;\n    padding: 1.3rem 0rem 1.3rem 1.4rem;\n\n    color: #2d9cdb;\n    font-weight: 600;\n    font-size: 1.4rem;\n\n    border-radius: 0.6rem;\n\n    background-color: #f1f1f1;\n    transition: background-color 300ms linear;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.IhcUgQHxflCZjiSEldO9:hover {\n    background-color: #dbdbdb;\n}\n\n", "",{"version":3,"sources":["webpack://./base/business/teacher-quick-links/styles.module.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,kCAAkC;;IAElC,cAAc;IACd,gBAAgB;IAChB,iBAAiB;;IAEjB,qBAAqB;;IAErB,yBAAyB;IACzB,yCAAyC;;IAEzC,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.quickLinks {\n    margin: 2rem 1rem;\n}\n\n.quickLink {\n    margin: 1.3rem 1.1rem;\n    padding: 1.3rem 0rem 1.3rem 1.4rem;\n\n    color: #2d9cdb;\n    font-weight: 600;\n    font-size: 1.4rem;\n\n    border-radius: 0.6rem;\n\n    background-color: #f1f1f1;\n    transition: background-color 300ms linear;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.quickLink:hover {\n    background-color: #dbdbdb;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickLinks": "gGYpo3n56TFbeRoGg2zt",
	"quickLink": "IhcUgQHxflCZjiSEldO9"
};
export default ___CSS_LOADER_EXPORT___;
