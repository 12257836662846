// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B6r9FVozumFcFLbPycyq {\n    z-index: 201;\n}\n\n.Fczec5nogMZK5Df8_gDf {\n    height: 100%;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    padding: 20% 1rem 1rem 1rem;\n    padding-top: 5rem;\n\n    outline: none;\n}\n\n.u2kNWJo3R1IFI1RuRULI {\n    overflow-y: auto;\n    max-height: 80vh;\n}\n\n.MrD_3s8BKqa_vwCrOtlL {\n    position: relative;\n}\n\n.VQhrtCfnBguZvCxsq5xj {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n\n.VQhrtCfnBguZvCxsq5xj:before,\n.VQhrtCfnBguZvCxsq5xj:after {\n    background-color: black;\n}\n\n@media only screen and (max-height: 550px) {\n    .Fczec5nogMZK5Df8_gDf {}\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-word-card/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,2BAA2B;IAC3B,iBAAiB;;IAEjB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,uBAAgB;AACpB","sourcesContent":[".overlappedPopup {\n    z-index: 201;\n}\n\n.wordCardPopup {\n    height: 100%;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    padding: 20% 1rem 1rem 1rem;\n    padding-top: 5rem;\n\n    outline: none;\n}\n\n.wordCardScroller {\n    overflow-y: auto;\n    max-height: 80vh;\n}\n\n.word {\n    position: relative;\n}\n\n.close {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n\n.close:before,\n.close:after {\n    background-color: black;\n}\n\n@media only screen and (max-height: 550px) {\n    .wordCardPopup {}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlappedPopup": "B6r9FVozumFcFLbPycyq",
	"wordCardPopup": "Fczec5nogMZK5Df8_gDf",
	"wordCardScroller": "u2kNWJo3R1IFI1RuRULI",
	"word": "MrD_3s8BKqa_vwCrOtlL",
	"close": "VQhrtCfnBguZvCxsq5xj"
};
export default ___CSS_LOADER_EXPORT___;
