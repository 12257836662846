import React from "react";

import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";
import styles from "./styles.module.css";


const SwiperPopupPages = (props) => {
    const renderPages = () => {
        const pageClassName = [
            juiceScrollbarStyles.scrollbar,
            styles.page,
        ].join(" ");

        return props.children.map((page) => {
            return (
                <div
                    className={pageClassName}
                    style={{
                        width: props.popupWidth,
                    }}
                >
                    {page}
                </div>
            );
        });
    };

    const offsetX = props.page * props.popupWidth;

    const pagesStyles = {
        transform: `translate3d(-${offsetX}px, 0, 0)`,
    };

    return (
        <div
            className={styles.swipper}
            data-comment="swiper-popup-pages"
        >
            <div
                className={styles.pages}
                style={pagesStyles}
            >
                {renderPages()}
            </div>
        </div>
    );
};

SwiperPopupPages.defaultProps = {
    page: 0,
    popupWidth: 0,
    children: null,
};

export default SwiperPopupPages;
