import React, { useState } from "react";

import Standards from "juice-base/project/standards.js";

import PopupWindow from "juice-base/components/popup-window/index.js";

import Tabs from "juice-base/components/tabs/index.js";

import styles from "./styles.module.css";


const PopupLearningStandards = (props) => {
    const [selectedStandardType, setSelectedStandardType] = useState("juice-standard");

    const onTabChange = (values) => {
        setSelectedStandardType(values.value);
    };

    const renderPopupTitle = () => {
        return (
            <div>Learning standards</div>
        );
    };

    const renderTabs = () => {
        const tabs = Standards.getStandardsTypes();

        return (
            <div className={styles.tabs}>
                <Tabs
                    tabs={tabs}
                    selectedTab={selectedStandardType}
                    onChange={onTabChange}
                />
            </div>
        );
    };

    const renderContent = () => {
        const standards = Standards.getStandardsByType(props.standards, selectedStandardType);

        const selectedStandards = standards.map((s) => {
            return (
                <div className={styles.contentStandard}>
                    <div>{s?.standard || ""}</div>
                    <div>{s?.details || ""}</div>
                </div>
            );
        });

        return (
            <div className={styles.standards}>
                {selectedStandards}
            </div>
        );
    };

    return (
        <PopupWindow
            title={renderPopupTitle()}
            bottomControls={[]}
            onClose={props.onClose}
        >
            {renderTabs()}
            <div className={styles.content}>
                {renderContent()}
            </div>
        </PopupWindow>
    );
};

PopupLearningStandards.defaultProps = {
    onClose: () => {},
    standards: [],
};

export default PopupLearningStandards;
