class ScrollerElem {
    constructor(scrollerElem, callbacks) {
        this.scrollerElem = scrollerElem;
        this.callbacks = callbacks;

        /* --- */

        this.invalidElements = [
            "video",
        ];

        /* --- */

        this.evtTouchLastTime = new Date().getTime();
        this.isTouchFast = false;

        /* --- */

        this.onKeyDown = this.onKeyDown.bind(this);

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.onResize = this.onResize.bind(this);
        this.onFullscreen = this.onFullscreen.bind(this);
    }

    isEvtValidElement(evt) {
        const elem = evt.target;

        if (elem?.tagName && typeof elem.tagName === "string") {
            const tagName = elem.tagName.toLowerCase();
            return this.invalidElements.indexOf(tagName) === -1;
        }

        return true;
    }

    addEventListeners() {
        const useCapture = false;

        this.scrollerElem.addEventListener("touchstart", this.onTouchStart, useCapture);
        this.scrollerElem.addEventListener("touchmove", this.onTouchMove, useCapture);
        this.scrollerElem.addEventListener("touchend", this.onTouchEnd, useCapture);
        this.scrollerElem.addEventListener("touchcancel", this.onTouchEnd, useCapture);

        window.addEventListener("keydown", this.onKeyDown, useCapture);

        window.addEventListener("resize", this.onResize, useCapture);
        window.addEventListener("fullscreenchange", this.onFullscreen, useCapture);
    }

    removeEventListeners() {
        this.scrollerElem.removeEventListener("touchstart", this.onTouchStart);
        this.scrollerElem.removeEventListener("touchmove", this.onTouchMove);
        this.scrollerElem.removeEventListener("touchend", this.onTouchEnd);
        this.scrollerElem.removeEventListener("touchcancel", this.onTouchEnd);

        window.removeEventListener("keydown", this.onKeyDown);

        window.removeEventListener("resize", this.onResize);
        window.addEventListener("fullscreenchange", this.onFullscreen);
    }

    /* --- */

    getMainElem() {
        return this.scrollerElem;
    }

    getMainElems() {
        const scrElem = this.getMainElem();

        if (!scrElem) {
            return [];
        }

        return scrElem.children || [];
    }

    getNextIndex(index) {
        const nextIndex = index + 1;

        const elems = this.getMainElems();

        if (elems[nextIndex]) {
            return nextIndex;
        }

        return index;
    }

    getPrevIndex(index) {
        const prevIndex = index - 1;

        const elems = this.getMainElems();

        if (elems[prevIndex]) {
            return prevIndex;
        }

        return index;
    }

    getOffsetByIndex(index) {
        const elems = this.getMainElems();
        const elem = elems[index];

        if (elem) {
            return {
                isFirst: index === 0,
                isLast: index === elems.length - 1,
                offset: elem.offsetLeft || 0,
            };
        }

        return {
            isFirst: true,
            isLast: index === elems.length - 1,
            offset: 0,
        };
    }

    /* --- */

    scrollXFast(offset) {
        const pageContentElem = this.getMainElem();

        if (pageContentElem) {
            const transition = "all 0ms linear 0s";

            pageContentElem.style.transition = transition;
            pageContentElem.style.transform = `translate3d(${-Math.round(offset)}px, 0, 0)`;
        }
    }

    scrollX(offset, options = {}) {
        const pageContentElem = this.getMainElem();

        let withTransition = true;

        if (options.withTransition !== undefined) {
            withTransition = options.withTransition;
        }

        if (pageContentElem) {
            const duration = this.isTouchFast ? "100ms" : "300ms";
            const transition = withTransition ? `all ${duration} linear 0s` : "none";

            pageContentElem.style.transition = transition;
            pageContentElem.style.transform = `translate3d(${-Math.round(offset)}px, 0, 0)`;
        }

        this.isTouchFast = false;
    }

    /* --- */

    onKeyDown(evt) {
        this.callbacks.evtKeyDown(evt);
    }

    /* --- */

    onTouchStart(evt) {
        if (!this.isEvtValidElement(evt)) {
            return;
        }

        if (evt.touches.length > 1) {
            return;
        }

        const evtTouch = evt.changedTouches[0];

        if (evtTouch) {
            this.evtTouchLastTime = new Date().getTime();
            this.isTouchFast = false;

            this.callbacks.evtStart({
                x: evtTouch.clientX,
                y: evtTouch.clientY,
            });
        }
    }

    onTouchMove(evt) {
        if (evt.touches.length > 1) {
            return;
        }

        const evtTouch = evt.changedTouches[0];

        if (evtTouch) {
            this.callbacks.evtMove({
                evt,
                x: evtTouch.clientX,
                y: evtTouch.clientY,
            });
        }
    }

    onTouchEnd(evt) {
        if (evt.touches.length > 1) {
            return;
        }

        const evtTouch = evt.changedTouches[0];

        if (evtTouch) {
            const nowTime = new Date().getTime();
            const timeDiff = nowTime - this.evtTouchLastTime;

            const isFast = timeDiff < 120;

            this.evtTouchLastTime = new Date().getTime();
            this.isTouchFast = isFast;

            this.callbacks.evtEnd({
                x: evtTouch.clientX,
                y: evtTouch.clientY,
            });
        }
    }

    /* --- */

    onResize() {
        this.callbacks.evtResize();
    }

    onFullscreen() {
        this.callbacks.evtFullscreen();
    }
}

export default ScrollerElem;
