import React, { useEffect, useRef, useState } from "react";

import RequestLoader from "../../request-loader/index.js";

import styles from "./styles.module.css";


const UploaderImage = (props) => {
    const [isVisibleField, setVisibleField] = useState(true);

    const [isDragOverField, setDragOverField] = useState(false);

    const [imageFileError, setImageFileError] = useState(null);

    const dropAreaRef = useRef(null);

    useEffect(() => {
        if (props.imageUrl) {
            setVisibleField(false);
        } else {
            setVisibleField(true);
        }
    }, [props.imageUrl]);

    const onRemoveImage = (evt) => {
        evt.preventDefault();
        props.onFileChange(null);
    };

    const onFileChange = (evt) => {
        if (props.imageRequiredHeight && props.imageRequiredHeight) {
            if (evt.target.files.length > 0) {
                const file = evt.target.files[0];
                const img = new Image();
                img.src = window.URL.createObjectURL(file);

                img.onload = () => {
                    let sizesError = null;

                    if (img.width !== props.imageRequiredWidth
                        && img.height !== props.imageRequiredHeight
                    ) {
                        sizesError = `The width and height of the image should be: ${props.imageRequiredWidth} x ${props.imageRequiredHeight}`;
                    } else if (img.width !== props.imageRequiredWidth) {
                        sizesError = `The width of the image should be: ${props.imageRequiredWidth}px`;
                    } else if (img.height !== props.imageRequiredHeight) {
                        sizesError = `The height of the image should be: ${props.imageRequiredHeight}px`;
                    }

                    if (sizesError) {
                        setDragOverField(false);
                        setImageFileError(sizesError);
                    } else {
                        setImageFileError(null);
                        props.onFileChange(evt);
                    }

                    // NOTE: This is HACK to upload the same file multiple times in a row
                    if (dropAreaRef.current && dropAreaRef.current.value) {
                        dropAreaRef.current.value = null;
                    }
                };
            }
        } else {
            props.onFileChange(evt);
        }
    };

    const renderField = () => {
        if (props.isLoading) {
            if (isDragOverField) {
                setDragOverField(false);
            }

            return (
                <div className={styles.formFieldLoader}>
                    <RequestLoader />
                </div>
            );
        }

        let browseText = "Drag an image here or upload one from your computer.";

        let removeButton = null;

        let adImg = null;

        if (props.imageUrl) {
            browseText = "Drag image here to load, or click browse to select file!";

            const adImageClasses = [styles.fieldImage];

            if (isVisibleField || isDragOverField) {
                adImageClasses.push(styles.lowOpacityElement);
            }

            adImg = (
                <div
                    className={adImageClasses.join(" ")}
                >
                    <img
                        className={styles.image}
                        src={props.imageUrl}
                        alt="Uploaded"
                        title="Uploaded"
                    />
                </div>
            );

            removeButton = (
                <>
                    <div className={styles.browseButtonOR}>
                        or
                    </div>

                    <div
                        className={[
                            styles.button,
                            styles.buttonRemove,
                        ].join(" ")}
                        onClick={onRemoveImage}
                        onKeyPress={onRemoveImage}
                        tabIndex="-1"
                        role="button"
                    >
                        Remove
                    </div>
                </>
            );
        }

        const formFieldClasses = [styles.formField];
        const browserTextClasses = [styles.browseText];
        const buttonsClasses = [styles.buttons];

        if (adImg && (!isVisibleField || isDragOverField)) {
            buttonsClasses.push(styles.hiddenElement);
            browserTextClasses.push(styles.hiddenElement);
            formFieldClasses.push(styles.formSolidBorder);
        } else if (!adImg && isDragOverField) {
            browserTextClasses.push(styles.hiddenElement);
            buttonsClasses.push(styles.hiddenElement);
        }

        let dragImageHereText = null;

        if (isDragOverField) {
            formFieldClasses.push(styles.formFieldOnDragOver);

            dragImageHereText = (
                <div className={styles.dragImageHereText}>
                    Drop the image here
                </div>
            );
        }

        return (
            <div
                className={formFieldClasses.join(" ")}
                onFocus={() => {
                    setVisibleField(true);
                }}
                onMouseOver={() => {
                    setVisibleField(true);
                }}
                onMouseLeave={() => {
                    setVisibleField(false);
                }}
                onDragOver={() => {
                    setDragOverField(true);
                }}
                onDragLeave={() => {
                    setDragOverField(false);
                }}
            >
                <label
                    htmlFor="drop-area"
                    className={styles.label}
                >
                    {adImg}

                    <input
                        ref={dropAreaRef}
                        id="drop-area"
                        name="drop-area"
                        type="file"
                        accept="image/*"
                        className={styles.input}
                        onChange={onFileChange}
                    />

                    <div className={browserTextClasses.join(" ")}>
                        {browseText}
                    </div>

                    <div className={buttonsClasses.join(" ")}>
                        <div
                            className={[
                                styles.button,
                                styles.buttonBrowse,
                            ].join(" ")}
                        >
                            Upload
                        </div>
                        {removeButton}
                    </div>

                    {dragImageHereText}
                </label>
            </div>
        );
    };

    return (
        <>
            <div className={styles.uploader}>
                {renderField()}
            </div>
            <div className={styles.error}>
                {imageFileError}
            </div>
        </>
    );
};


UploaderImage.defaultProps = {
    imageUrl: "",
    isLoading: false,
    imageRequiredWidth: null,
    imageRequiredHeight: null,
    onFileChange: () => {},
};

export default UploaderImage;
