import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmUnsavedProgress = (props) => {
    return (
        <PopupConfirm
            hideScrollbar={props.hideScrollbar}
        >
            <PopupConfirmContent>
                Are you sure you want to leave with unsaved progress?
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onLeave}>
                    Leave
                </ButtonFlat>
                <ButtonFlat onClick={props.onClose}>
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmUnsavedProgress.defaultProps = {
    hideScrollbar: false,
    onLeave: () => {},
    onClose: () => {},
};

export default PopupConfirmUnsavedProgress;
