import React from "react";

import styles from "./styles.module.css";


const Close = (props) => {
    const { className } = props;

    const classes = [styles.close];

    if (className) {
        classes.push(className);
    }

    /* eslint-disable */
    const close = (
        <div
            className={classes.join(" ")}
            onClick={props.onClose}
            onKeyPress={props.onClose}
            role="button"
            tabIndex="-1"
        />
    );
    /* eslint-enable */

    return close;
};

Close.defaultProps = {
    className: "",
    onClose: () => {},
};

export default Close;
