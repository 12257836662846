// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".to68fLkuRJd7wk_4aYpz {\n\n}\n\n.bR1sdhPRWE23NmVjKcmD {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 0.6rem;\n}\n\n.bR1sdhPRWE23NmVjKcmD button {\n    margin-left: 0.4rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    background: #fffc00;\n    border: solid 0.1rem #c4c4c4;\n    outline: none;\n    width: 3.6rem;\n    height: 3.6rem;\n    border-radius: 0.8rem;\n}", "",{"version":3,"sources":["webpack://./base/components/image-with-zoom/styles.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,aAAa;IACb,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".imageWithZoom {\n\n}\n\n.tools {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 0.6rem;\n}\n\n.tools button {\n    margin-left: 0.4rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    background: #fffc00;\n    border: solid 0.1rem #c4c4c4;\n    outline: none;\n    width: 3.6rem;\n    height: 3.6rem;\n    border-radius: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWithZoom": "to68fLkuRJd7wk_4aYpz",
	"tools": "bR1sdhPRWE23NmVjKcmD"
};
export default ___CSS_LOADER_EXPORT___;
