import * as types from "./types.js";


const initialState = {
    storiesById: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_JUICE_STORY: {
            const { storiesById } = state;
            const { story } = action.payload;

            return {
                ...state,
                storiesById: {
                    ...storiesById,
                    [story.id]: story,
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
