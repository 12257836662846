// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gBtpmo54TwvP6RThHxId {\n    display: grid;\n    grid-template-columns: 3rem auto;\n    align-items: center;\n\n    color: var(--pumpkin);\n    font-family: \"Soleil-Semibold\";\n    font-size: 1.7rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/message-danger/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gCAAgC;IAChC,mBAAmB;;IAEnB,qBAAqB;IACrB,8BAA8B;IAC9B,iBAAiB;AACrB","sourcesContent":[".message {\n    display: grid;\n    grid-template-columns: 3rem auto;\n    align-items: center;\n\n    color: var(--pumpkin);\n    font-family: \"Soleil-Semibold\";\n    font-size: 1.7rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "gBtpmo54TwvP6RThHxId"
};
export default ___CSS_LOADER_EXPORT___;
