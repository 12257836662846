// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PYLircu_o8DL5TuZBcI5 {\n    padding: 1rem;\n\n    display: grid;\n    grid-gap: 2rem;\n\n    overflow: hidden;\n}\n\n.lnX_4C_cVeO_6Su9O83m {\n    padding: 2rem 0;\n}\n\n.ULxThkJQPVMbcuMMgTYN {\n    font-weight: 600;\n}\n\n.kjM9RsK7zsdU7lQessHQ {\n    display: grid;\n    justify-content: center;\n}\n\n.kjM9RsK7zsdU7lQessHQ img {\n    width: 20rem;\n}\n\n._18zM_dVDRMwsU3B6MVD {\n    text-align: center;\n    font-weight: 600;\n\n    font-size: 1.8rem;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/teacher-popup-welcome/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,aAAa;IACb,cAAc;;IAEd,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;;IAEhB,iBAAiB;AACrB","sourcesContent":[".wizardPage {\n    padding: 1rem;\n\n    display: grid;\n    grid-gap: 2rem;\n\n    overflow: hidden;\n}\n\n.content {\n    padding: 2rem 0;\n}\n\n.schoolDetailsLabel {\n    font-weight: 600;\n}\n\n.confirmEmoji {\n    display: grid;\n    justify-content: center;\n}\n\n.confirmEmoji img {\n    width: 20rem;\n}\n\n.confirmText {\n    text-align: center;\n    font-weight: 600;\n\n    font-size: 1.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wizardPage": "PYLircu_o8DL5TuZBcI5",
	"content": "lnX_4C_cVeO_6Su9O83m",
	"schoolDetailsLabel": "ULxThkJQPVMbcuMMgTYN",
	"confirmEmoji": "kjM9RsK7zsdU7lQessHQ",
	"confirmText": "_18zM_dVDRMwsU3B6MVD"
};
export default ___CSS_LOADER_EXPORT___;
