import React from "react";

import PopupCommon from "juice-base/components/popup-common/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import styles from "./styles.module.css";


const PopupLoading = (props) => {
    const classes = [
        styles.overlappedPopup,
    ];

    if (props.popupClasses) {
        classes.push(props.popupClasses);
    }

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
            popupClasses={classes.join(" ")}
        >
            <div className={styles.popupLoading}>
                <RequestLoader />
            </div>
        </PopupCommon>
    );
};

PopupLoading.defaultProps = {
    popupClasses: "", // TODO: delete
    onClose: () => {},
};

export default PopupLoading;
