// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B4oSOPx8mSs8OHIdBv4z {\n    color: var(--orange);\n\n    font-family: 'Grifter-Bold';\n    font-weight: 600;\n    font-size: 1.2rem;\n\n    line-height: 1.3rem;\n    letter-spacing: 0.025rem;\n\n    text-transform: uppercase;\n}\n\n/* -------- */\n\n.t7w974Go9mcSMTMQ9lFW {\n    font-family: 'Soleil-Regular';\n    line-height: 1.8rem;\n}\n\n.t7w974Go9mcSMTMQ9lFW p {\n    margin: 1.2rem 0;\n}\n\n.t7w974Go9mcSMTMQ9lFW a {\n    display: inline-block;\n\n    color: #00e;\n    text-decoration: none;\n    font-weight: 600;\n    border-bottom: 0.2rem solid #00e;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.t7w974Go9mcSMTMQ9lFW a:visited {\n    color: #551a8b;\n    border-color: #551a8b;\n}\n", "",{"version":3,"sources":["webpack://./base/components/class-announcement/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;;IAEpB,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;;IAEjB,mBAAmB;IACnB,wBAAwB;;IAExB,yBAAyB;AAC7B;;AAEA,aAAa;;AAEb;IACI,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;;IAErB,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,gCAAgC;;IAEhC,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".title {\n    color: var(--orange);\n\n    font-family: 'Grifter-Bold';\n    font-weight: 600;\n    font-size: 1.2rem;\n\n    line-height: 1.3rem;\n    letter-spacing: 0.025rem;\n\n    text-transform: uppercase;\n}\n\n/* -------- */\n\n.content {\n    font-family: 'Soleil-Regular';\n    line-height: 1.8rem;\n}\n\n.content p {\n    margin: 1.2rem 0;\n}\n\n.content a {\n    display: inline-block;\n\n    color: #00e;\n    text-decoration: none;\n    font-weight: 600;\n    border-bottom: 0.2rem solid #00e;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.content a:visited {\n    color: #551a8b;\n    border-color: #551a8b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "B4oSOPx8mSs8OHIdBv4z",
	"content": "t7w974Go9mcSMTMQ9lFW"
};
export default ___CSS_LOADER_EXPORT___;
