import React from "react";

import parseTags from "juice-base/lib/parse-tags.js";
import Text from "juice-base/components/text/index.js";

import styles from "./styles.module.css";


const JuiceContentFirstLines = (props) => {
    const onClick = (evt) => {
        if (evt.target.tagName === "A") {
            if (evt.target.href) {
                evt.preventDefault();
                window.open(evt.target.href, "_blank");
            }
            return;
        }

        props.onClick(evt);
    };

    const renderContent = () => {
        let newContent = parseTags.parse(props.content).map((line) => {
            if (parseTags.isTag(line)) {
                return parseTags.trimTag(line);
            }

            return line;
        }).join("");

        newContent = parseTags.parseCurlyBrackets(newContent).map((line) => {
            if (parseTags.isTagCurlyBrackets(line)) {
                return parseTags.trimTagCurlyBrackets(line);
            }

            return line;
        }).join("");

        newContent = parseTags.parseShortcode(newContent).map((line) => {
            if (!parseTags.isTagShortcode(line)) {
                return line;
            }

            if (parseTags.isTagShortcodeName(line, "caption")) {
                const contentLines = parseTags.parse(line.tagContent, "<", "/>");

                if (contentLines.length === 0) {
                    return "";
                }

                let captionText = null;

                if (contentLines[1]) {
                    [captionText] = contentLines;
                }

                return `${contentLines[0]} ${captionText}`;
            }

            return line.tagContent;
        }).join("");

        return newContent;
    };

    const classes = [styles.content];

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div className={classes.join(" ")}>
            <Text onClick={onClick}>
                {renderContent()}
            </Text>
        </div>
    );
};

JuiceContentFirstLines.defaultProps = {
    content: "",
    className: "",
    onClick: () => {},
};

export default JuiceContentFirstLines;
