// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ENVsI4AwHBjPY9gMB7YP {\n    width: 100%;\n    height: 100%;\n\n    overflow: hidden;\n}\n\n.HRdWg5EgAxeX6Svz3EYV {\n    display: grid;\n    grid-auto-flow: column;\n\n    transition: all 1000ms ease-in-out 0s\n}\n\n._gGo166LVmN6KmM10JlW {\n    width: 100vw;\n\n    /* NOTE: with additional nav shadow indent */\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem);\n}\n\n.kAaE0oFTvcdjbktT2KHt {\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem - 7rem);\n}\n\n@media only screen and (min-width: 1025px) {\n    ._gGo166LVmN6KmM10JlW {\n        height: calc(100vh - var(--main-header-height) - var(--daily-juice-nav-height-desktop));\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/swiper-daily-juice-stories/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB;AACJ;;AAEA;IACI,YAAY;;IAEZ,4CAA4C;IAC5C,iGAAiG;AACrG;;AAEA;IACI,wGAAwG;AAC5G;;AAEA;IACI;QACI,uFAAuF;IAC3F;AACJ","sourcesContent":[".swiper {\n    width: 100%;\n    height: 100%;\n\n    overflow: hidden;\n}\n\n.pages {\n    display: grid;\n    grid-auto-flow: column;\n\n    transition: all 1000ms ease-in-out 0s\n}\n\n.page {\n    width: 100vw;\n\n    /* NOTE: with additional nav shadow indent */\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem);\n}\n\n.pageForSafari {\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem - 7rem);\n}\n\n@media only screen and (min-width: 1025px) {\n    .page {\n        height: calc(100vh - var(--main-header-height) - var(--daily-juice-nav-height-desktop));\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": "ENVsI4AwHBjPY9gMB7YP",
	"pages": "HRdWg5EgAxeX6Svz3EYV",
	"page": "_gGo166LVmN6KmM10JlW",
	"pageForSafari": "kAaE0oFTvcdjbktT2KHt"
};
export default ___CSS_LOADER_EXPORT___;
