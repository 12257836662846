// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tcJ5i63C_osBwVBNw5At {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.DraZCsOndyjcRE6vt1uS {\n    display: grid;\n}\n\n.GTeqiDp293OvrRcWQrmw {\n    margin: 0 auto 4rem auto;\n    text-align: center;\n}\n\n.Rgv4tjkrybD0a1ldVTVv {\n    margin: 1rem 0 0 0;\n\n    font-weight: 600;\n    font-size: 2.3rem;\n    letter-spacing: 0.05rem;\n}\n\n._Z6lHyZuXgpnzEIMF0wN {\n    margin: 5rem 0 0 0;\n    display: grid;\n}\n\n@media only screen and (min-width: 521px) {\n    .DraZCsOndyjcRE6vt1uS {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .tcJ5i63C_osBwVBNw5At {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/user/password-forgot/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;;IAElB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".passwordForgot {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    display: grid;\n}\n\n.contentHeader {\n    margin: 0 auto 4rem auto;\n    text-align: center;\n}\n\n.formName {\n    margin: 1rem 0 0 0;\n\n    font-weight: 600;\n    font-size: 2.3rem;\n    letter-spacing: 0.05rem;\n}\n\n.backButton {\n    margin: 5rem 0 0 0;\n    display: grid;\n}\n\n@media only screen and (min-width: 521px) {\n    .content {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .passwordForgot {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordForgot": "tcJ5i63C_osBwVBNw5At",
	"content": "DraZCsOndyjcRE6vt1uS",
	"contentHeader": "GTeqiDp293OvrRcWQrmw",
	"formName": "Rgv4tjkrybD0a1ldVTVv",
	"backButton": "_Z6lHyZuXgpnzEIMF0wN"
};
export default ___CSS_LOADER_EXPORT___;
