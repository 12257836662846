import React from "react";

import styles from "./styles.module.css";


const PopupConfirmButtons = (props) => {
    return (
        <div className={styles.buttons}>
            {props.children}
        </div>
    );
};

PopupConfirmButtons.defaultProps = {
    children: null,
};

export default PopupConfirmButtons;
