import {
    SET_JUICES_VIDEOS,
    SET_JUICES_VIDEOS_LOADING,
} from "./types.js";


const initialState = {
    isVideosLoaded: false,
    isVideosLoading: false,
    hasMorePages: false,
    page: 0,
    videos: [],
};

export default (state, action) => {
    switch (action.type) {
        case SET_JUICES_VIDEOS: {
            return {
                ...state,
                isVideosLoaded: true,
                isVideosLoading: false,
                videos: action.payload.videos,
                page: action.payload.page,
                hasMorePages: action.payload.hasMorePages,
            };
        }

        case SET_JUICES_VIDEOS_LOADING: {
            return {
                ...state,
                isVideosLoading: true,
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
