import React, { useEffect, useState } from "react";

import staticFiles from "juice-base/static-files.js";
import * as Students from "juice-base/project/students.js";

import { DATES } from "juice-base/lib/date.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonMenu from "juice-base/components/button-menu/index.js";
import IconStatus from "juice-base/components/icon-status/index.js";
import Collapse from "juice-base/components/collapse-section/index.js";
import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";

import PopupDateRangeCustom from "juice-base/components/popup-date-range-custom/index.js";

import Checkbox from "juice-base/components/forms/checkbox/index.js";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const TableStudents = (props) => {
    const [tableSelectedUsers, setTableSelectedUsers] = useState([]);

    const [cardsExpended, setCardsExpended] = useState(false);

    const [isVisibleCustomRangePopup, setVisibleCustomRangePopup] = useState(false);

    const [isAllRowsCheckboxSelected, setIsAllRowsCheckboxSelected] = useState(false);

    const isSortedByName = props.sortValue.indexOf("name") !== -1;
    const isSortedByScore = props.sortValue.indexOf("score") !== -1;

    /* --- */

    useEffect(() => {
        setIsAllRowsCheckboxSelected(false);
        setTableSelectedUsers([]);
    }, [props.data]);

    const onChangeSortValueByNameColumn = () => {
        // TODO: use Student file
        let value = "name: a to z";

        if (props.sortValue === "name: a to z") {
            value = "name: z to a";
        }

        props.onChangeSortValue(value);
    };

    const onChangeSortValueByQuizScoreColumn = () => {
        // TODO: use Student file
        let value = "score: high to low";

        if (props.sortValue === "score: high to low") {
            value = "score: low to high";
        }

        props.onChangeSortValue(value);
    };

    const onExtendOrCollapseCards = () => {
        setCardsExpended((prev) => !prev);
    };

    const onChangeAllRowsCheckox = () => {
        if (isAllRowsCheckboxSelected) {
            setTableSelectedUsers([]);
        } else {
            setTableSelectedUsers(props.data);
        }

        setIsAllRowsCheckboxSelected((prev) => !prev);
    };

    const getColumnsTitles = () => {
        const columnNameArrowClassName = classNames({
            [styles.columnNameArrow]: true,
            [styles.columnNameArrowUp]: props.sortValue === "name: z to a",
        });

        const columnQuizScoreArrowClassName = classNames({
            [styles.columnQuizScoreArrow]: true,
            [styles.columnQuizScoreArrowUp]: props.sortValue === "score: high to low",
        });

        let name = "Name";
        let quizScore = props.isOverview ? "Average quiz Score" : "Quiz Score";

        if (!props.isCards) {
            name = (
                <div
                    className={styles.columnName}
                    data-comment="table-students-sort-by-name"
                    onClick={onChangeSortValueByNameColumn}
                    onKeyPress={onChangeSortValueByNameColumn}
                    role="button"
                    tabIndex="-1"
                >
                    <div>
                        Name
                    </div>
                    <div className={columnNameArrowClassName}>
                        <img
                            src={staticFiles.boldArrow}
                            alt="Sory by name"
                            title="Sory by name"
                        />
                    </div>
                </div>
            );

            quizScore = (
                <div
                    className={styles.columnQuizScore}
                    data-comment="table-students-sort-by-score"
                    onClick={onChangeSortValueByQuizScoreColumn}
                    onKeyPress={onChangeSortValueByQuizScoreColumn}
                    role="button"
                    tabIndex="-1"
                >
                    <div>
                        {props.isOverview ? "Average quiz Score" : "Quiz Score"}
                    </div>
                    <div className={columnQuizScoreArrowClassName}>
                        <img
                            src={staticFiles.boldArrow}
                            alt="Sory by quiz status"
                            title="Sory by quiz status"
                        />
                    </div>
                </div>
            );
        }

        const allRowsCheckbox = (
            <Checkbox
                key="allRowsCheckbox"
                name="allRowsCheckbox"
                checked={isAllRowsCheckboxSelected}
                dataComment="table-students-select-all"
                onChange={onChangeAllRowsCheckox}
            />
        );

        let titles = [
            allRowsCheckbox,
            name,
            "Username",
            "Quiz Status",
            quizScore,
            "Extra Juice",
            "Video",
            "",
        ];

        if (props.isOverview) {
            titles = [
                allRowsCheckbox,
                name,
                "Username",
                "Completion status",
                quizScore,
                "",
            ];
        }

        return titles;
    };

    const getTemplateColumns = () => {
        let columns = "5% 18% 20% 16% 13% 13% 10% 5%";

        if (props.isOverview) {
            columns = "5% 25% 25% 20% 20% 5%";
        }

        return columns;
    };

    const columnsTitles = getColumnsTitles();
    const templateColumns = getTemplateColumns();

    /* --- */

    const isSelectedUserInTable = (studentId) => {
        for (let i = 0; i < tableSelectedUsers.length; i += 1) {
            if (tableSelectedUsers[i].id === studentId) {
                return true;
            }
        }

        return false;
    };

    const onAddStudent = () => {
        if (tableSelectedUsers.length === 0) {
            props.onAddStudent();
        }
    };

    const onRemoveSelectedUsers = () => {
        if (tableSelectedUsers.length > 0) {
            props.onRemoveStudents(tableSelectedUsers);
        }
    };

    const onSelectStudentInTable = (student) => {
        const isSelectedUser = isSelectedUserInTable(student.id);

        let newStudents = [];

        if (isSelectedUser) {
            newStudents = tableSelectedUsers.filter((s) => s.id !== student.id);
        } else {
            newStudents = tableSelectedUsers.concat(student);
        }

        setIsAllRowsCheckboxSelected(newStudents.length === props.data.length);
        setTableSelectedUsers(newStudents);
    };

    const getRowStyles = () => {
        const rowStyle = {};

        if (templateColumns) {
            rowStyle.gridTemplateColumns = templateColumns;
        } else {
            for (let i = 0; i < columnsTitles.length; i += 1) {
                if (rowStyle.gridTemplateColumns) {
                    rowStyle.gridTemplateColumns = `${rowStyle.gridTemplateColumns} 1fr`;
                } else {
                    rowStyle.gridTemplateColumns = "1fr";
                }
            }
        }

        return rowStyle;
    };

    const onSelectCustomRange = (values) => {
        setVisibleCustomRangePopup(false);
        props.onSelectCustomRange(values);
    };

    /* --- */

    const renderTableSortToggler = () => {
        const sortTogglerClassName = classNames({
            [styles.sortToggler]: true,
            [styles.sortTogglerMobile]: props.isCards,
        });

        const sortByNameClassName = classNames({
            [styles.sortTogglerByName]: true,
            [styles.sortTogglerSelected]: isSortedByName,
            [styles.sortTogglerByScoreSelected]: isSortedByScore,
        });

        const sortByScoreClassName = classNames({
            [styles.sortTogglerByScore]: true,
            [styles.sortTogglerSelected]: isSortedByScore,
        });

        return (
            <div
                className={sortTogglerClassName}
                data-comment="table-students-sort-toggler"
            >
                <div
                    className={sortByNameClassName}
                    tabIndex="-1"
                    role="button"
                    onKeyPress={() => {
                        // TODO: use Student.js
                        props.onChangeSortValue("name: a to z");
                    }}
                    onClick={() => {
                        // TODO: use Student.js
                        props.onChangeSortValue("name: a to z");
                    }}
                >
                    Name
                </div>
                <div
                    className={sortByScoreClassName}
                    tabIndex="-1"
                    role="button"
                    onKeyPress={() => {
                        // TODO: use Student.js
                        props.onChangeSortValue("score: high to low");
                    }}
                    onClick={() => {
                        // TODO: use Student.js
                        props.onChangeSortValue("score: high to low");
                    }}
                >
                    Score
                </div>
            </div>
        );
    };

    const renderTableControlButton = (params) => {
        const buttonClassName = classNames({
            [styles.tableControlButton]: true,
            [params.imgClassName]: params.imgClassName,
            [params.buttonClassName]: params.buttonClassName,
        });

        return (
            <div
                className={buttonClassName}
                onClick={params.onClick}
                onKeyPress={params.onClick}
                role="button"
                tabIndex="-1"
                data-comment={params.dataComment}
            >
                <img
                    src={params.img.src}
                    alt={params.img.alt}
                    title={params.img.alt}
                />
            </div>
        );
    };

    const renderMenuButton = (params) => {
        return (
            <div
                className={params.className}
                onClick={params.onClick}
                onKeyPress={params.onClick}
                role="button"
                tabIndex="-1"
            >
                {params.label}
            </div>
        );
    };

    const renderMobileButtonMenu = () => {
        let addButton = (renderMenuButton({
            label: "Add Student",
            className: styles.menuButton,
            onClick: () => {
                onAddStudent();
            },
        }));

        // TODO: logic
        if (props.hideAddStudentButton) {
            addButton = null;
        }

        const menuButtons = (
            <div className={styles.menuButtons}>
                {addButton}
                {renderMenuButton({
                    label: "Delete Students",
                    className: styles.menuButton,
                    onClick: () => {
                        onRemoveSelectedUsers();
                    },
                })}
            </div>
        );

        return (
            <div className={styles.menuButtonsMobile}>
                <ButtonMenu
                    menuButtons={[menuButtons]}
                    closeMenuOnClick
                />
            </div>
        );
    };

    const renderTableControlsButtons = () => {
        let controls = [];

        if (props.isCards) {
            controls = [
                renderTableControlButton({
                    buttonClassName: styles.sortByNameButton,
                    dataComment: "table-students-sort-by-name",
                    img: {
                        src: staticFiles.arrowsSort,
                        alt: "Sort Table",
                    },
                    onClick: () => {
                        if (isSortedByName) {
                            onChangeSortValueByNameColumn();
                        } else if (isSortedByScore) {
                            onChangeSortValueByQuizScoreColumn();
                        }
                    },
                }),
                <div className={styles.controlSeparator} />,
                renderTableControlButton({
                    buttonClassName: styles.expandOrCollapseButton,
                    img: {
                        src: staticFiles.listLayoutGrey,
                        alt: "Expand or collapse the list",
                    },
                    onClick: () => {
                        onExtendOrCollapseCards();
                    },
                }),
                <div className={styles.controlSeparator} />,
                renderMobileButtonMenu(),
            ];
        } else {
            let addButton = renderTableControlButton({
                buttonClassName: styles.addStudentButton,
                dataComment: "table-students-add-student",
                img: {
                    src: staticFiles.iconAddUser,
                    alt: "Add Student",
                },
                onClick: () => {
                    onAddStudent();
                },
            });

            // TODO: logic issue -> create and then delete?
            if (props.hideAddStudentButton) {
                addButton = null;
            }

            controls = [
                addButton,
                <div className={styles.controlSeparator} />,
                renderTableControlButton({
                    buttonClassName: styles.deleteStudentsButton,
                    dataComment: "table-students-remove-students",
                    img: {
                        src: staticFiles.iconTrashV2,
                        alt: "Remove Students",
                    },
                    onClick: () => {
                        onRemoveSelectedUsers();
                    },
                }),
            ];
        }

        return (
            <div className={styles.tableControlsButtons}>
                {controls}
            </div>
        );
    };

    const renderTableRanges = () => {
        const dates = [
            DATES.today,
            DATES.yesterday,
            DATES.thisWeek,
            DATES.lastWeek,
            DATES.thisMonth,
            DATES.past3Months,
            {
                value: "custom",
                label: "Custom",
            },
        ];

        return (
            <div
                data-comment="table-students-date-range"
                className={styles.dateRange}
            >
                <MenuHorizontalScrolling
                    items={dates}
                    selected={props.dateRange.selectedRange}
                    onSelect={(value) => {
                        if (value === "custom") {
                            setVisibleCustomRangePopup(true);
                        } else {
                            props.onChangeDateRange(value);
                        }
                    }}
                />
            </div>
        );
    };

    const renderTableControls = () => {
        if (props.isLoading) {
            return null;
        }

        if (props.isCards) {
            return (
                <div className={styles.mobileControls}>
                    <div className={styles.mobileControlMenuRanges}>
                        {renderTableRanges()}
                    </div>
                    <div className={styles.mobileControlsTogglerWithButtons}>
                        {renderTableSortToggler()}
                        <div className={styles.controlSeparator} />
                        {renderTableControlsButtons()}
                        <div className={styles.controlSeparator} />
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.desktopControls}>
                {renderTableRanges()}
                <div className={styles.controlSeparator} />
                {renderTableSortToggler()}
                <div className={styles.controlSeparator} />
                {renderTableControlsButtons()}
            </div>
        );
    };

    /* --- */

    const renderColumnTitle = () => {
        return columnsTitles.map((title) => (
            <div>{title}</div>
        ));
    };

    /* --- */

    const renderButtonMenu = (student) => {
        const removeStudentClasses = [
            styles.menuButton,
            styles.removeButton,
        ];

        let studentHasEmail = false;

        if (student.email) {
            studentHasEmail = true;
        }

        const menuButtons = (
            <div className={styles.menuButtons}>
                {renderMenuButton({
                    label: "Reset Password",
                    className: styles.menuButton,
                    onClick: () => {
                        props.onResetStudentPassword({
                            id: student.id,
                            hasEmail: studentHasEmail,
                        });
                    },
                })}
                <div className={styles.separator} />
                {renderMenuButton({
                    label: "Remove Student...",
                    className: removeStudentClasses.join(" "),
                    onClick: () => {
                        props.onRemoveStudent(student.id);
                    },
                })}
            </div>
        );

        return (
            <ButtonMenu
                menuButtons={[menuButtons]}
            />
        );
    };

    const renderSelectStudentControls = (student) => {
        const isSelectedUser = isSelectedUserInTable(student.id);

        const checkbox = (
            <Checkbox
                key={`studentCheckbox-${student.id}`}
                name={`studentCheckbox-${student.id}`}
                checked={isSelectedUser}
                onChange={() => {
                    onSelectStudentInTable(student);
                }}
            />
        );

        if (props.isCards) {
            return (
                <div className={styles.studentCardMobileControls}>
                    {checkbox}
                    <div
                        className={styles.profileIcon}
                        role="button"
                        tabIndex="-1"
                        onClick={() => {
                            props.onOpenStudentPopup(student.id);
                        }}
                        onKeyPress={() => {
                            props.onOpenStudentPopup(student.id);
                        }}
                    >
                        <img
                            src={staticFiles.iconProfile}
                            alt="Open profile"
                            title="Open profile"
                        />
                    </div>
                </div>
            );
        }

        return checkbox;
    };

    const renderStudentName = (student) => {
        if (props.isCards) {
            return (
                <div className={styles.studentFullName}>
                    {student.fullName}
                </div>
            );
        }

        return (
            <div
                onClick={() => {
                    props.onOpenStudentPopup(student.id);
                }}
                onKeyPress={() => {
                    props.onOpenStudentPopup(student.id);
                }}
                className={styles.studentClickableData}
                role="button"
                tabIndex="-1"
                data-comment="student-table-student-name"
            >
                {student.fullName}
            </div>
        );
    };

    const renderStudentUsername = (student) => {
        return (
            <div>{student.userName}</div>
        );
    };

    const renderTableRows = () => {
        const rows = [];

        props.data.forEach((student) => {
            const menuButton = renderButtonMenu(student);
            const selectStudentControls = renderSelectStudentControls(student);
            const selectedStudentName = renderStudentName(student);
            const selectedStudentUsername = renderStudentUsername(student);

            if (!props.isOverview) {
                const quizStatus = (
                    <IconStatus
                        statusId={student.juiceStatusId}
                    />
                );

                const extraJuiceIcons = (student.extraJuices || []).map((ej) => (
                    <IconStatus
                        statusId={ej.statusId}
                    />
                ));

                const lifeHackIcons = (student.lifeHacks || []).map((hack) => (
                    <IconStatus
                        statusId={hack.statusId}
                    />
                ));

                const quizScore = student.juiceStatusId === -1 ? "-" : `${student.quizScore}%`;

                rows.push([
                    selectStudentControls,
                    selectedStudentName,
                    selectedStudentUsername,
                    quizStatus,
                    quizScore,
                    extraJuiceIcons,
                    lifeHackIcons,
                    menuButton,
                ]);
            } else {
                const completionStatus = (
                    <IconStatus
                        statusId={student.completionStatusId}
                    />
                );

                rows.push([
                    selectStudentControls,
                    selectedStudentName,
                    selectedStudentUsername,
                    completionStatus,
                    `${student.averageQuizScore}%`,
                    menuButton,
                ]);
            }
        });

        return rows;
    };

    const renderEmptyTable = () => {
        return (
            <div className={styles.emptyTableContainer}>
                <div className={styles.emptyTableLabel}>
                    Hmm. Looks like there&apos;s no information to display, yet.
                    Add some students to get started.
                </div>
                <div
                    className={styles.emptyTableButton}
                    onClick={onAddStudent}
                    onKeyPress={onAddStudent}
                    tabIndex="-1"
                    role="button"
                >
                    Add students
                </div>
            </div>
        );
    };

    const renderTableContent = () => {
        if (props.isLoading) {
            return (
                <div className={styles.errorRow}>
                    <RequestLoader />
                </div>
            );
        }

        if (props.error) {
            return (
                <div className={styles.errorRow}>
                    {props.error}
                </div>
            );
        }

        const tableRows = renderTableRows();

        if (tableRows.length === 0) {
            return renderEmptyTable();
        }

        const rowStyles = getRowStyles();

        const rows = [];

        tableRows.forEach((row, index) => {
            const rowClasses = [styles.oneRow, styles.row];

            if (props.disabledRowsIds.indexOf(index) !== -1) {
                rowClasses.push(styles.disabledRow);
            }

            const oneRow = row.map((rowItem) => (
                <div className={styles.rowItem}>
                    {rowItem}
                </div>
            ));

            const rowElem = (
                <div
                    style={rowStyles}
                    className={rowClasses.join(" ")}
                >
                    {oneRow}
                </div>
            );

            rows.push(rowElem);
        });

        return rows;
    };

    const renderCards = () => {
        if (props.isLoading) {
            return (
                <div className={styles.errorRow}>
                    <RequestLoader />
                </div>
            );
        }

        if (props.error) {
            return (
                <div className={styles.errorRow}>
                    {props.error}
                </div>
            );
        }

        const tableRows = renderTableRows();

        if (tableRows.length === 0) {
            return renderEmptyTable();
        }

        const collapseExtendButton = (
            <img
                src={staticFiles.caret}
                alt="Extend info"
                className={styles.caretDown}
            />
        );

        const collapseCollapseButton = (
            <img
                className={styles.caretUp}
                alt="collapse info"
                src={staticFiles.caret}
            />
        );

        const cards = [];

        tableRows.forEach((row) => {
            const cardRows = [];
            let userFullName = "";
            let userControls = null;

            columnsTitles.forEach((title, index) => {
                const rowClassName = classNames({
                    [styles.rowIcons]: title === "Extra Juice" || title === "Video",
                });

                if (index === 1) {
                    userFullName = row[index];
                    userControls = { ...row[0] };
                }

                if (title && index !== 0) {
                    cardRows.push(
                        <div className={styles.cardRow}>
                            <div className={styles.cardTitle}>
                                {title}
                            </div>
                            <div className={rowClassName}>
                                {row[index]}
                            </div>
                        </div>,
                    );
                }
            });

            const cardTitle = (
                <div className={styles.mobileCardCollapsed}>
                    <div className={styles.studentFullName}>
                        {userFullName}
                    </div>
                    <div>{userControls}</div>
                </div>
            );

            cards.push(
                <Collapse
                    isExtended={cardsExpended}
                    title={cardTitle}
                    collapseClassName={styles.cardCollapse}
                    extendedContentClassName={styles.cardExtended}
                    extendWithButton
                    extendButton={collapseExtendButton}
                    collapseButton={collapseCollapseButton}
                >
                    <div className={styles.card}>
                        {cardRows}
                    </div>
                </Collapse>,
            );
        });

        return cards;
    };

    const renderRowsOrCards = () => {
        if (props.isCards) {
            return renderCards();
        }

        const columnClassName = [
            styles.oneRow,
            styles.tableColumns,
        ].join(" ");

        const rowStyles = getRowStyles();

        return (
            <div className={styles.tableRows}>
                <div
                    style={rowStyles}
                    className={columnClassName}
                >
                    {renderColumnTitle()}
                </div>
                {renderTableContent()}
            </div>
        );
    };

    const renderCustomRangePopup = () => {
        if (!isVisibleCustomRangePopup) {
            return null;
        }

        return (
            <PopupDateRangeCustom
                calendarDates={props.calendarDates}
                dateRange={props.dateRange}
                onApply={onSelectCustomRange}
                onClose={() => {
                    setVisibleCustomRangePopup(false);
                }}
            />
        );
    };

    const settingsClassName = classNames({
        [styles.settings]: true,
        [styles.cardsSettings]: props.isCards,
    });

    const titleClassName = classNames({
        [styles.title]: true,
        [styles.hiddenTableTitle]: props.isCards,
    });

    return (
        <>
            {renderCustomRangePopup()}

            <div className={styles.table}>
                <div className={settingsClassName}>
                    <div className={titleClassName}>
                        {props.title}
                    </div>
                    <div className={styles.controls}>
                        {renderTableControls()}
                    </div>
                </div>
                {renderRowsOrCards()}
            </div>
        </>
    );
};

TableStudents.defaultProps = {
    title: "My Students",
    sortValue: "",
    sortValues: Students.getSortValues(),

    dateRange: {},
    calendarDates: {},

    data: [],
    disabledRowsIds: [],
    error: null,

    onChangeDateRange: () => {},
    onSelectCustomRange: () => {},

    onChangeSortValue: () => {},

    onOpenStudentPopup: () => {},

    onAddStudent: () => {},
    onRemoveStudent: () => {},
    onRemoveStudents: () => {},
    onResetStudentPassword: () => {},

    hideAddStudentButton: false,
    isCards: false,
    isLoading: false,
    isOverview: false,
};

export default TableStudents;
