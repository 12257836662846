import React from "react";

import styles from "./styles.module.css";


const SectionTeacherMessage = (props) => {
    return (
        <div className={styles.sectionMessage}>
            {props.children}
        </div>
    );
};

SectionTeacherMessage.defaultProps = {
    children: null,
};

export default SectionTeacherMessage;
