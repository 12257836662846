import React, { useEffect, useState, useRef } from "react";

import useDimensions from "juice-base/hooks/use-dimensions/index.js";

import classNames from "juice-base/lib/class-names.js";

import Dropdown from "juice-base/components/dropdown/index.js";

import styles from "./styles.module.css";


const Tabs = (props) => {
    const tabsElements = useRef([]);

    const [selectedTab, setSelectedTab] = useState(null);
    const [isDropdownView, setDropdownView] = useState(false);

    const dimensions = useDimensions();

    useEffect(() => {
        if (selectedTab !== props.selectedTab) {
            setSelectedTab(props.selectedTab);
        }
    }, [props.selectedTab]);

    const isEllipsisActive = (e) => {
        return e.offsetWidth < e.scrollWidth;
    };

    useEffect(() => {
        if (props.onlyTabs) {
            return;
        }

        let isTextOverflowed = false;

        for (let i = 0; i < tabsElements.current.length; i += 1) {
            if (tabsElements.current[i]) {
                if (isEllipsisActive(tabsElements.current[i])) {
                    isTextOverflowed = true;
                    break;
                }
            }
        }

        setDropdownView(isTextOverflowed);
    }, [dimensions]);

    const onChange = (values) => {
        setSelectedTab(values.value);
        props.onChange(values);
    };

    const renderTabs = () => {
        const tabs = props.tabs.map((tab, index) => {
            const isSelected = selectedTab === tab.value;

            const tabClassName = classNames({
                [styles.tab]: true,
                [styles.tabSelected]: isSelected,
                [styles.tabBlueSelected]: props.blueTheme && isSelected,
            });

            return (
                <div
                    key={tab.value}
                    className={tabClassName}
                    onClick={() => {
                        onChange(tab);
                    }}
                    onKeyPress={() => {
                        onChange(tab);
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    <div
                        className={styles.tabTitle}
                        ref={(currElem) => {
                            tabsElements.current[index] = currElem;
                        }}
                    >
                        {tab.label}
                    </div>
                </div>
            );
        });

        const tabsClassName = classNames({
            [styles.tabs]: true,
            [styles.hiddenTabs]: isDropdownView,
        });

        const tabsCommonClassName = classNames({
            [styles.elementHidden]: isDropdownView,
        });

        return (
            <div className={tabsCommonClassName}>
                <div className={tabsClassName}>
                    {tabs}
                </div>
            </div>
        );
    };

    const renderDropdown = () => {
        if (!isDropdownView || props.onlyTabs) {
            return null;
        }

        return (
            <Dropdown
                selectedValue={selectedTab}
                values={props.tabs}
                onChange={onChange}
            />
        );
    };

    return (
        <div className={styles.tabsCommon} data-comment={props.dataComment}>
            {renderTabs()}
            {renderDropdown()}
        </div>
    );
};

Tabs.defaultProps = {
    dataComment: "",
    tabs: [],
    selectedTab: 0,

    blueTheme: false,
    onlyTabs: false,
    onChange: () => {},
};

export default Tabs;
