import * as types from "./types.js";


export const setUserLoading = (isUserLoading) => ({
    type: types.SET_USER_LOADING,
    payload: {
        isUserLoading,
    },
});

export const setUserSession = (session) => ({
    type: types.SET_USER_SESSION,
    payload: {
        session,
    },
});

export const setUser = (user) => ({
    type: types.SET_USER,
    payload: {
        user,
    },
});

export const clearUser = () => ({
    type: types.CLEAR_USER,
});

export const setUserOptions = (payload) => ({
    type: types.SET_USER_OPTIONS,
    payload,
});

export const setUserOption = (payload) => ({
    type: types.SET_USER_OPTION,
    payload,
});
