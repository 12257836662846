import React, { useRef, useEffect, useState } from "react";

import useDimensions from "juice-base/hooks/use-dimensions/index.js";

import { convertRemToPixels } from "juice-base/lib/dimensions.js";

import scroll from "juice-base/lib/scroll.js";
import classNames from "juice-base/lib/class-names.js";

import Close from "juice-base/components/close/index.js";
import WithScroll from "juice-base/components/with-scroll/index.js";
import PopupConfirmUnsavedProgress from "juice-base/components/popup-confirm-unsaved-progress/index.js";

import styles from "./styles.module.css";


const PopupWindow = (props) => {
    const popupLayout = useRef(null);

    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleConfirmClose, setIsVisibleConfirmClose] = useState(false);

    const dimensions = useDimensions();

    const onAutoClose = () => {
        setIsVisible(false);

        window.setTimeout(() => {
            props.onClose();
        }, 1000);
    };

    const onClose = (showConfirm) => {
        if (!isVisible) {
            return;
        }

        if (showConfirm) {
            setIsVisibleConfirmClose(true);
            return;
        }

        setIsVisible(false);

        window.setTimeout(() => {
            props.onClose();
        }, 1000);
    };

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        if (props.autoClose) {
            const seconds = 3;
            window.setTimeout(() => {
                onAutoClose();
            }, 1000 * seconds);
        }

        scroll.hideBodyScroll();

        return () => {
            scroll.unhideBodyScroll();
        };
    }, []);

    const onLayoutClick = (evt) => {
        if (!props.hideClose && popupLayout && popupLayout.current === evt.target) {
            scroll.unhideBodyScroll();
            onClose(props.showConfirmOnClose);
        }
    };

    const getPopupContentStyle = () => {
        const style = {};

        const popupHeightMobile = dimensions.height;
        const popupHeightDesktop = dimensions.height - convertRemToPixels(5);
        const popupTitleHeight = convertRemToPixels(7);
        const playerAudioHeight = convertRemToPixels(8);
        const playerAudioExtendedHeight = convertRemToPixels(21);

        let popupHeight = popupHeightDesktop;

        if (dimensions.width < 1025) {
            popupHeight = popupHeightMobile;
        }

        style.maxHeight = popupHeight - popupTitleHeight;

        if (props.player && !props.playerExpanded) {
            style.maxHeight = popupHeight - popupTitleHeight - playerAudioHeight;
        }

        if (props.player && props.playerExpanded) {
            style.maxHeight = popupHeight - popupTitleHeight - playerAudioExtendedHeight;
        }

        return style;
    };

    const hasMultipleControl = props.rightControl && props.rightControl.length > 1;

    const titleClassName = classNames({
        [styles.topTitleBar]: true,
        [styles.topTitleBarOrange]: props.orangeBar,
        [styles.topTitleBarWithControl2]: hasMultipleControl,
    });

    const renderBar = () => {
        let close = null;

        if (!props.hideClose) {
            close = (
                <Close
                    onClose={() => {
                        onClose(props.showConfirmOnClose);
                    }}
                />
            );
        }

        return (
            <div className={titleClassName}>
                <div className={styles.leftControl}>
                    {props.leftControl}
                </div>

                {props.title}

                <div className={styles.rightControl}>
                    {props.rightControl}
                    {close}
                </div>
            </div>
        );
    };

    const renderConfirmClose = () => {
        if (!isVisibleConfirmClose) {
            return null;
        }

        return (
            <PopupConfirmUnsavedProgress
                onLeave={() => {
                    setIsVisibleConfirmClose(false);
                    onClose(false);
                }}
                onClose={() => {
                    setIsVisibleConfirmClose(false);
                }}
            />
        );
    };

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupSmall]: props.isSmall,
        [props.popupClassName]: props.popupClassName,
        [styles.popupVisible]: isVisible,
        [styles.popupCentered]: isVisible && props.isCentered,
    });

    const windowClassName = classNames({
        [styles.popupWindow]: true,
        [styles.popupWindowRoundedCorners]: props.isCentered,
    });

    const popupContentClassName = classNames({
        [styles.popupContent]: true,
        [styles.popupContentWithPlayer]: props.player && !props.playerExpanded,
        [styles.popupContentWithPlayerExpanded]: props.player && props.playerExpanded,
    });

    const popupContentStyle = getPopupContentStyle();

    return (
        <>
            <div
                ref={popupLayout}
                className={styles.popupLayout}
                onClick={onLayoutClick}
                onKeyPress={onLayoutClick}
                role="button"
                tabIndex="-1"
                data-comment="popup-window"
            >
                <div className={popupClassName}>
                    <div className={windowClassName}>
                        {renderBar()}

                        <WithScroll style={popupContentStyle} className={popupContentClassName}>
                            {props.children}
                        </WithScroll>

                        {props.player}
                    </div>
                </div>
            </div>
            {renderConfirmClose()}
        </>
    );
};

PopupWindow.defaultProps = {
    title: null,
    leftControl: [],
    rightControl: [],
    player: null,
    orangeBar: false,
    isCentered: false,
    isSmall: false,
    popupClassName: "",
    autoClose: false,
    hideClose: false,
    showConfirmOnClose: false,
    children: null,
    onClose: () => {},
};

export default PopupWindow;
