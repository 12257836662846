import React from "react";
import AsyncSelect from "react-select/async"; // eslint-disable-line import/extensions


const styles = {
    dropdownIndicator: () => ({
        display: "none",
    }),

    indicatorSeparator: () => ({
        display: "none",
    }),

    placeholder: () => ({
        fontSize: "1.6rem",
        color: "#000",
        marginLeft: "0.2rem",
    }),

    control: (provided, state) => ({
        cursor: "pointer",
        display: "flex",
        backgroundColor: "#fff",
        border: "0.1rem solid #C6C6C6",
        opacity: state.selectProps.controlStyles.opacity,
        borderRadius: 4,
    }),

    option: (provided) => ({
        ...provided,
        cursor: "pointer",
    }),
};

const Searcher = (props) => {
    const onChange = (val) => {
        props.onChange(val || null);
    };

    const controlStyles = {
        opacity: props.isDisabled ? 0.5 : 1,
    };

    return (
        <AsyncSelect
            name={props.name}
            ref={props.searcherRef}
            cacheOptions
            defaultOptions={props.defaultOptions}
            placeholder={props.placeholder}
            loadOptions={props.onLoad}
            onChange={onChange}
            isClearable={props.isClearable}
            controlStyles={controlStyles}
            isDisabled={props.isDisabled}
            styles={styles}
        />
    );
};

Searcher.defaultProps = {
    name: "",
    placeholder: "Search",
    defaultOptions: [],
    searcherRef: null,
    isClearable: true,
    isDisabled: false,
    onLoad: () => {
        return new Promise((resolve) => { // eslint-disable-line compat/compat
            resolve([]);
        });
    },
    onChange: () => {},
};

export default Searcher;
