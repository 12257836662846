import React from "react";

import styles from "./styles.module.css";


const CssIcon = (props) => {
    if (!props.url || !props.name) {
        return null;
    }

    const iconClassName = [
        styles.container,
        props.className,
    ].join(" ");

    return (
        <div
            onClick={props.onClick}
            onKeyPress={props.onClick}
            className={iconClassName}
            tabIndex="-1"
            role="button"
        >
            <img
                src={props.url}
                title={props.name}
                alt={props.name}
                className={props.className}
            />
        </div>
    );
};

CssIcon.defaultProps = {
    url: "",
    name: "",
    className: "",
    onClick: () => {},
};

export default CssIcon;
