import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const TeacherQuizQuestion = (props) => {
    if (!props.quiz) {
        return null;
    }

    const renderStoryImage = () => {
        if (props.quiz?.story?.thumbnail) {
            return (
                <img
                    className={styles.headerImg}
                    src={props.quiz?.story?.thumbnail}
                    alt="Story Title"
                />
            );
        }

        return null;
    };

    const renderCorrectIcon = () => {
        return (
            <img
                className={styles.answerIconImg}
                src={staticFiles.iconCheck}
                alt="Correct answer"
            />
        );
    };

    const renderIncorrectIcon = () => {
        return (
            <img
                className={styles.answerIconImg}
                src={staticFiles.iconCancel}
                alt="Incorrect answer"
            />
        );
    };

    const renderAnswers = () => {
        const answers = props.quiz?.answers || [];

        return answers.map((ans) => {
            let icon = null;

            if (ans.is_user_choice && ans.is_correct_answer) {
                icon = renderCorrectIcon();
            } else if (ans.is_user_choice && !ans.is_correct_answer) {
                icon = renderIncorrectIcon();
            } else if (!ans.is_user_choice && ans.is_correct_answer) {
                icon = renderCorrectIcon();
            }

            return (
                <div className={styles.answer}>
                    <div className={styles.answerIcon}>
                        {icon}
                    </div>
                    <div className={styles.answerText}>
                        {ans.answer}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.quizQuestion}>
            <div className={styles.header}>
                <div className={styles.headerImage}>
                    {renderStoryImage()}
                </div>
                <div className={styles.headerTitle}>
                    Question
                </div>
                <div className={styles.headerQuestion}>
                    {props.quiz.question}
                </div>
                <div className={styles.headerIcon} />
            </div>

            <div className={styles.answers}>
                {renderAnswers()}
            </div>
        </div>
    );
};

TeacherQuizQuestion.defaultProps = {
    quiz: null,
};

export default TeacherQuizQuestion;
