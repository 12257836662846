import React from "react";
import { useSelector } from "react-redux";

import AccountSettings from "juice-base/components/account-settings/index.js";
import { withAuth } from "juice-base/components/auth/index.js";

import styles from "./styles.module.css";


const Profile = () => {
    const {
        user,
    } = useSelector((state) => ({
        user: state.user.user,
    }));

    return (
        <div className={styles.profile}>
            <AccountSettings
                settings={[
                    {
                        title: "Username",
                        value: user.userName,
                    },
                    {
                        title: "Email",
                        value: user.email,
                    },
                ]}
            />
        </div>
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(Profile);
