// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m4KOXho7NXe2bC3q_n6Q {}\n\n.uRYg4QUu5P6js4a7tf_g {\n    display: grid;\n    grid-gap: 2.5rem;\n}\n\n.Q7_HNQKC2H_Hoabp8ynq {\n    color: rgb(var(--cerulean));\n    font-size: 1.4rem;\n    font-weight: 600;\n    text-decoration: none;\n}\n\n.pUXgAeweiuB4_WeaFBTH {\n    margin-top: 2.5rem;\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .uRYg4QUu5P6js4a7tf_g {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}", "",{"version":3,"sources":["webpack://./base/forms/user-sign-in/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;IACvB;AACJ","sourcesContent":[".form {}\n\n.formLinksSection {\n    display: grid;\n    grid-gap: 2.5rem;\n}\n\n.accountLinkPassword {\n    color: rgb(var(--cerulean));\n    font-size: 1.4rem;\n    font-weight: 600;\n    text-decoration: none;\n}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .formLinksSection {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "m4KOXho7NXe2bC3q_n6Q",
	"formLinksSection": "uRYg4QUu5P6js4a7tf_g",
	"accountLinkPassword": "Q7_HNQKC2H_Hoabp8ynq",
	"submitButtonBlock": "pUXgAeweiuB4_WeaFBTH"
};
export default ___CSS_LOADER_EXPORT___;
