import React, { useEffect, useState } from "react";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";
import TextLine from "juice-base/components/text-line/index.js";
import PlayerLoader from "juice-base/components/player-loader/index.js";

import Button from "./button.js";
import styles from "./styles.module.css";


const PlayerAudio = (props) => {
    const buttonDataComment = "player-audio-btn";

    const [audioState, setAudioState] = useState({
        selectedRate: props.audio.playbackRate || 1,
    });

    useEffect(() => {
        props.onFirstPlay();

        return () => {
            props.onClose();
        };
    }, []);

    const getMiniClassName = () => {
        return classNames({
            [styles.playerWithImageAndInfoAndControl]: props.image,
            [styles.playerWithInfoAndControl]: !props.image,
        });
    };

    const getFullClassName = () => {
        return classNames({
            [styles.playerWithImageAndInfo]: props.image,
            [styles.playerWithInfo]: !props.image,
        });
    };

    const onToggleExpand = (evt) => {
        if (props.error) {
            return;
        }

        if (evt.target.dataset.comment !== buttonDataComment) {
            props.onExpand();
        }
    };

    const onChangeRate = (selectedRate) => {
        return () => {
            setAudioState((prev) => ({
                ...prev,
                selectedRate,
            }));

            props.onChangeRate(selectedRate);
        };
    };

    /* --- */

    const renderBtnRewind = () => {
        const { audio } = props;

        if (audio.isPlaying) {
            return (
                <Button
                    dataComment={buttonDataComment}
                    iconSrc={staticFiles.iconPlayerReplay}
                    iconName="Rewind"
                    onClick={props.onRewind}
                    medium
                />
            );
        }

        return (
            <Button
                dataComment={buttonDataComment}
                iconSrc={staticFiles.iconPlayerReplayDisabled}
                iconName="Rewind disabled"
                medium
            />
        );
    };

    const renderBtnForward = () => {
        const { audio } = props;

        if (audio.isPlaying) {
            return (
                <Button
                    dataComment={buttonDataComment}
                    iconSrc={staticFiles.iconPlayerReplay}
                    iconName="Forward"
                    onClick={props.onForward}
                    rotateRight
                    medium
                />
            );
        }

        return (
            <Button
                dataComment={buttonDataComment}
                iconSrc={staticFiles.iconPlayerReplayDisabled}
                iconName="Forward disabled"
                rotateRight
                medium
            />
        );
    };

    const renderBtnPlay = () => {
        const { audio } = props;

        if (audio.isLoading) {
            return (
                <PlayerLoader />
            );
        }

        if (audio.isPlaying) {
            return (
                <Button
                    dataComment={buttonDataComment}
                    iconSrc={staticFiles.iconPlayerPause}
                    iconName="Pause"
                    onClick={props.onPause}
                    medium={props.isExpanded}
                />
            );
        }

        return (
            <Button
                dataComment={buttonDataComment}
                iconSrc={staticFiles.iconPlayerPlay}
                iconName="Play"
                onClick={props.onPlay}
                medium={props.isExpanded}
            />
        );
    };

    const renderBtnClose = () => {
        return (
            <Button
                dataComment={buttonDataComment}
                iconSrc={staticFiles.iconPlayerClose}
                iconName="Close"
                onClick={props.onClose}
            />
        );
    };

    const renderMinimize = () => {
        const mClassName = classNames({
            [styles.minimize]: true,
            [styles.minimizeVisible]: props.isExpanded,
        });

        // TODO: delete minimize icon
        return (
            <div
                className={mClassName}
                onClick={onToggleExpand}
                onKeyPress={onToggleExpand}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.minimizeBar} />
            </div>
        );
    };

    const renderImage = () => {
        if (!props.image) {
            return null;
        }

        const imgStyle = {
            backgroundImage: `url(${props.image})`,
        };

        const imgClassName = classNames({
            [styles.coverImage]: true,
            [styles.coverImageExpanded]: props.isExpanded,
        });

        return (
            <div
                className={imgClassName}
                style={imgStyle}
            />
        );
    };

    const renderInfo = () => {
        const infoClassName = classNames({
            [styles.info]: true,
            [styles.infoExpanded]: props.isExpanded,
        });

        let statusOrCategory = null;

        if (props.isExpanded) {
            statusOrCategory = (
                <TextLine className={styles.category}>
                    {props.error ? "Error" : props.category}
                </TextLine>
            );
        } else {
            statusOrCategory = (
                <TextLine className={styles.playStatus}>
                    {props.error ? "Error" : "Now Playing"}
                </TextLine>
            );
        }

        return (
            <div className={infoClassName}>
                {statusOrCategory}
                <TextLine className={styles.title}>
                    {props.title || "Unknown title"}
                </TextLine>
            </div>
        );
    };

    const renderControl = () => {
        const controlClassName = classNames({
            [styles.control]: true,
            [styles.controlExpanded]: props.isExpanded,
        });

        if (props.error) {
            return (
                <div className={controlClassName} />
            );
        }

        if (props.isExpanded) {
            return (
                <div className={controlClassName}>
                    {renderBtnRewind()}
                    {renderBtnPlay()}
                    {renderBtnForward()}
                </div>
            );
        }

        return (
            <div className={controlClassName}>
                {renderBtnPlay()}
                {renderBtnClose()}
            </div>
        );
    };

    const renderPlayRates = () => {
        if (props.error) {
            return null;
        }

        const rates = props.rates.map((rate) => {
            const rateClassName = classNames({
                [styles.controlRate]: true,
                [styles.controlRateSelected]: rate.value === audioState.selectedRate,
            });

            return (
                <div
                    className={rateClassName}
                    onClick={onChangeRate(rate.value)}
                    onKeyPress={onChangeRate(rate.value)}
                    role="button"
                    tabIndex="-1"
                >
                    {rate.text}
                </div>
            );
        });

        return (
            <div className={styles.controlRates}>
                {rates}
            </div>
        );
    };

    const playerClassName = classNames({
        [styles.playerAudio]: true,
        [styles.playerAudioExpanded]: props.isExpanded,
        [styles.playerAudioExpandedWithImage]: props.isExpanded && props.image,
        [styles.playerAudioWhite]: props.whitePlayer,
        [styles.playerAudioRounded]: props.roundedPlayer,
    });

    if (props.isExpanded) {
        return (
            <div
                data-comment="player-audio"
                className={playerClassName}
            >
                {renderMinimize()}
                <div className={getFullClassName()}>
                    {renderImage()}
                    {renderInfo()}
                </div>
                {renderControl()}
                {renderPlayRates()}
            </div>
        );
    }

    return (
        <div
            data-comment="player-audio"
            className={playerClassName}
            onClick={onToggleExpand}
            onKeyPress={onToggleExpand}
            role="button"
            tabIndex="-1"
        >
            {renderMinimize()}
            <div className={getMiniClassName()}>
                {renderImage()}
                {renderInfo()}
                {renderControl()}
            </div>
        </div>
    );
};

PlayerAudio.defaultProps = {
    image: "",
    title: "",
    category: "",
    audio: {},
    error: "",
    isExpanded: false,
    whitePlayer: false,
    roundedPlayer: false,
    rates: [
        { value: 0.5, text: "0.5x" },
        { value: 0.75, text: "0.75x" },
        { value: 1, text: "1.0x" },
        { value: 1.5, text: "1.5x" },
        { value: 1.75, text: "1.75x" },
    ],
    onFirstPlay: () => {},
    onPlay: () => {},
    onPause: () => {},
    onRewind: () => {},
    onForward: () => {},
    onChangeRate: () => {},
    onExpand: () => {},
    onClose: () => {},
};

export default PlayerAudio;
