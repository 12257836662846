// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p3_OzIWii941IJORLgya {\n    font-size: 1.4rem;\n    color: var(--error-color);\n\n    padding: 0.5rem 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/error/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;;IAEzB,oBAAoB;AACxB","sourcesContent":[".error {\n    font-size: 1.4rem;\n    color: var(--error-color);\n\n    padding: 0.5rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "p3_OzIWii941IJORLgya"
};
export default ___CSS_LOADER_EXPORT___;
