import React from "react";

import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const SwipeUp = (props) => {
    const swipeClasses = [
        styles.swipeUp,
    ];

    if (props.small) {
        swipeClasses.push(styles.swipeUpSmall);
    }

    let swipeLogoImg = staticFiles.doubleArrowBlack;

    if (props.theme === "white") {
        swipeLogoImg = staticFiles.doubleArrow;
        swipeClasses.push(styles.swipeUpWhite);
    }

    return (
        <div className={swipeClasses.join(" ")}>
            <img
                src={swipeLogoImg}
                title="Swipe Up"
                alt="Swipe Up"
                className={styles.swipeUpLogo}
            />
            <div className={styles.swipeUpText}>
                Swipe Up
            </div>
        </div>
    );
};

SwipeUp.defaultProps = {
    theme: "",
    small: false,
};

export default SwipeUp;
