export const capitalize = (str) => {
    if (typeof str !== "string") {
        return str;
    }

    if (str.length === 0) {
        return str;
    }

    const head = str[0].toUpperCase();
    const tail = str.slice(1);

    return `${head}${tail}`;
};

export default {};
