// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gFcW_4JW6v6Rv3Y4BrZ6 {\n    display: grid;\n    justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-quiz-results/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".results {\n    display: grid;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"results": "gFcW_4JW6v6Rv3Y4BrZ6"
};
export default ___CSS_LOADER_EXPORT___;
