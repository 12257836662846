import React from "react";

import staticFiles from "juice-base/static-files.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import styles from "./styles.module.css";


const PopupConfirmGenerateClassCode = (props) => {
    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return props.error;
        }

        let contentTitle = null;
        let contentBody = "Your Class Link is";

        if (!props.isLink) {
            contentTitle = (
                <div className={styles.contentTitle}>
                    {`Tell your students to visit ${props.signUpUrl} and enter the class code to join to your class!`}
                </div>
            );

            contentBody = "Your Class Code is";
        }

        return (
            <div className={styles.classCode}>
                {contentTitle}
                <div>
                    {contentBody}
                </div>
                <div className={styles.classCodeLink}>
                    {props.code}
                </div>
            </div>
        );
    };

    const copyButtonText = props.isLink
        ? "Copy Class Link to Clipboard"
        : "Copy Class Code to Clipboard";

    return (
        <PopupConfirm
            onClose={props.onClose}
            fullWidth={props.isLink}
            closeOnLayoutClick
        >
            <PopupConfirmIcon
                src={staticFiles.iconKeyBlue}
                alt="Key"
            />

            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.disabled}
                    onClick={props.onSubmit}
                >
                    {copyButtonText}
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmGenerateClassCode.defaultProps = {
    code: "",
    signUpUrl: "",
    error: "",
    isLoading: false,
    isLink: false,
    disabled: false,
    onSubmit: () => {},
    onClose: () => {},
};

export default PopupConfirmGenerateClassCode;
