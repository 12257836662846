// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g92PFnvvmi7_Ljw5nZua {\n    padding: 0 2rem 2rem 2rem;\n\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .g92PFnvvmi7_Ljw5nZua {\n        grid-template-columns: 45.9rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .g92PFnvvmi7_Ljw5nZua {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/user/profile/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;;IAEzB,aAAa;AACjB;;AAEA;IACI;QACI,8BAA8B;QAC9B,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".profile {\n    padding: 0 2rem 2rem 2rem;\n\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .profile {\n        grid-template-columns: 45.9rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .profile {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": "g92PFnvvmi7_Ljw5nZua"
};
export default ___CSS_LOADER_EXPORT___;
