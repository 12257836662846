import React from "react";

import staticFiles from "juice-base/static-files.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import SectionStudent from "juice-base/components/section-student/index.js";
import ButtonWithMessage from "juice-base/components/button-with-message/index.js";

import styles from "./styles.module.css";


const DailyJuiceExplore = (props) => {
    const {
        extraJuices,
        videos,
    } = props;

    const renderRow = (params) => {
        let image = (<div />);

        if (params.imageSrc) {
            image = (
                <img
                    className={styles.rowFeatured}
                    src={params.imageSrc}
                    alt="Featured"
                    title="Featured"
                />
            );
        } else if (params.imageDefault) {
            image = (
                <img
                    className={styles.rowFeatured}
                    src={params.imageDefault}
                    alt="Featured"
                    title="Featured"
                />
            );
        }

        let icon = (<div />);

        if (params.iconSrc) {
            icon = (
                <img
                    className={styles.rowIcon}
                    src={params.iconSrc}
                    alt="icon"
                />
            );
        }

        return (
            <div
                className={styles.row}
                role="button"
                tabIndex="-1"
                onClick={params.onClick}
                onKeyPress={params.onClick}
            >
                {image}
                <div className={styles.rowTitleWithIcon}>
                    <div className={styles.rowTitle}>
                        {params.title}
                    </div>
                    {icon}
                </div>
            </div>
        );
    };

    const renderButtonWithMessage = (message) => {
        return (
            <ButtonWithMessage
                message={message}
                messageClassName={styles.buttonWithMessageMessageBlock}
                triangleClassName={styles.buttonWithMessageTriangle}
                pageWidth={props.pageWidth}
            >
                <img
                    className={styles.informationIcon}
                    src={staticFiles.informationOrange}
                    alt="Information"
                />
            </ButtonWithMessage>
        );
    };

    const renderExtraJuices = () => {
        const extraJuicesList = [];

        if (!props.isExtraJuicesLoaded) {
            extraJuicesList.push(
                <div className={styles.sectionLoader}>
                    <RequestLoader />
                </div>,
            );
        } else {
            for (let i = 0; i < props.columnRowsLength; i += 1) {
                if (extraJuices[i]) {
                    extraJuicesList.push(renderRow({
                        imageSrc: extraJuices[i].image,
                        imageDefault: staticFiles.iconOrange,
                        title: extraJuices[i].title,
                        iconSrc: staticFiles.notebookOrange,
                        onClick: () => {
                            props.onExtraJuiceClick(extraJuices[i].id);
                        },
                    }));
                }
            }
        }

        return (
            <div className={[styles.column, styles.columnExtraJuices].join(" ")}>
                <div className={styles.columnTitle}>
                    <div className={styles.boldText}>
                        Extra Juices
                    </div>
                    {renderButtonWithMessage("Have fun doing deep dives into big ideas so that you can be the smartest person in the room when it comes to the news.")}
                </div>
                {extraJuicesList}
            </div>
        );
    };

    const renderVideos = () => {
        const videosList = [];

        if (!props.isVideosLoaded) {
            videosList.push(
                <div className={styles.sectionLoader}>
                    <RequestLoader />
                </div>,
            );
        } else {
            for (let i = 0; i < props.columnRowsLength; i += 1) {
                if (videos[i]) {
                    let featured = null;

                    if (videos[i]?.featuredImage?.sizes?.thumbnail?.src) {
                        featured = videos[i].featuredImage.sizes.thumbnail.src;
                    }

                    videosList.push(renderRow({
                        imageSrc: featured,
                        title: videos[i].newsTitle,
                        iconSrc: staticFiles.iconPlayOrange,
                        onClick: () => {
                            props.onVideoClick(videos[i].videoID);
                        },
                    }));
                }
            }
        }

        return (
            <div className={[styles.column, styles.columnVideos].join(" ")}>
                <div className={styles.columnTitle}>
                    <div className={styles.boldText}>
                        Videos
                    </div>
                    {renderButtonWithMessage("Watch videos on cool STEAM topics, learn about exciting careers, and get stellar YA book reviews.")}
                </div>

                {videosList}
            </div>
        );
    };

    return (
        <SectionStudent
            sectionClassName={styles.section}
            title="Explore"
            dataComment="section-explore"
            showSeeMore={false}
        >
            <div className={styles.columns}>
                {renderExtraJuices()}
                {renderVideos()}
            </div>
            <div
                className={styles.viewMoreButton}
                role="button"
                tabIndex="-1"
                onClick={props.onViewMore}
                onKeyPress={props.onViewMore}
            >
                View more
            </div>
        </SectionStudent>
    );
};

DailyJuiceExplore.defaultProps = {
    pageWidth: 0,

    columnRowsLength: 3,
    extraJuices: [],
    videos: [],

    isExtraJuicesLoaded: false,
    isVideosLoaded: false,

    onVideoClick: () => {},
    onExtraJuiceClick: () => {},
    onViewMore: () => {},
};

export default DailyJuiceExplore;
