// TODO: replace, delete

import GTag from "./lib/gtag.js";
import { addEvent } from "./api.js";

const locationIds = {
    dashboard: 0,
    dailyJuice: 1,
    vocabulary: 2,
    myAccount: 3,
    login: 4,
    logout: 5,
    signup: 6,
    recoverPassword: 7,
    class: 9,
    search: 10,
    home: 11,
    changePassword: 12,
    student: 13,
};

const eventIds = {
    juiceOpen: 0,
    juiceClose: 1,
    juiceStoryOpen: 2,
    juiceStoryClose: 3,
    juiceStoryReadStory: 4,
    juiceStoryExtraJuiceOpen: 5,
    juiceStoryExtraJuiceClose: 6,
    juiceStoryExtraJuiceVocabOpen: 7,
    juiceStoryVideoPlay: 8,
    juiceStoryVideoPause: 9,
    juiceStoryVocabOpen: 10,
    juiceStoryVocabClose: 11,
    studentHomeVideoOpen: 12,
    studentHomeVideoPlay: 13,
    studentHomeVideoPause: 14,
    juiceStoryImageOpen: 15,
    juiceStoryImageClose: 16,
    juiceStoryQuizQuestionOpen: 17,
    juiceStoryQuizQuestionClose: 18,
    juiceStoryQuizQuiestionSubmit: 19,
    juiceSponsorHome: 20,
    juiceSponsorFirst: 21,
    juiceSponsorLast: 22,
    juiceStoryWordSpeechPause: 23,
    juiceStoryWordSpeechPlay: 24,
    juiceStoryVideoEnd: 25,
    userLogin: 26,
    userLogout: 27,
    extraJuiceOpenFromWordInStory: 28,
    extraJuiceOpenFromButtonInStory: 29,
    extraJuiceOpenFromExplore: 30,
    extraJuiceReadMore: 31,
    juiceStoryDefinitionPlay: 32,
    dailyJuiceStoryListen: 33,
    vocabOpenFromExtraJuice: 34,
    juiceStoryQuizQuiestionAnswerClick: 35,
    classAddStudent: 36,
    classDeleteStudent: 37,
    classEditStudent: 38,
    classResetPassword: 39,
    classChangeClass: 40,
    classOpenStudentInfo: 41,
    teacherDailyJuiceClose: 41,
    teacherDailyJuiceOpen: 42,
    teacherDailyJuiceExtraJuiceOpen: 43,
    teacherDailyJuiceExtrajuiceVocabOpen: 44,
    onSearch: 45,
    searchStoryClick: 46,
    searchFilterClick: 47,
    teacherHomeChangeClass: 48,
    teacherHomeEditClassAnnouncement: 49,
    teacherHomeUploadAnImage: 50,
    teacherDailyJuiceVideoStart: 51,
    teacherDailyJuiceVideoPause: 52,
    teacherDailyJuiceDefinitionPlay: 53,
    teacherDailyJuicePronouncePlay: 54,
    teacherHomeViewAllScores: 55,
    userChangePassword: 56,
    guardianHomeSelectStudent: 57,
    guardianStudentEditStudent: 58,
    guardianStudentSelectStudent: 59,
    dailyJuiceOnQuizAnswerClick: 60,
};

const parsePathname = (pathname) => {
    let newPath = "";

    if (pathname) {
        newPath = pathname.split("/").filter((path) => path !== "");
    }

    const loc = newPath[0] || "";

    if (loc === "") {
        return locationIds.home;
    }

    if (loc === "daily-juice") {
        return locationIds.dailyJuice;
    }

    if (loc === "class") {
        return locationIds.class;
    }

    if (loc === "search") {
        return locationIds.dailyJuice;
    }

    if (loc === "user" && newPath[1] === "profile") {
        return locationIds.myAccount;
    }

    return undefined;
};

export const dailyJuicesHomePageSponsor = (params) => {
    GTag.dailyJuicesOnClickHomePageSponsor(params.grades, params.classSlug);

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceSponsorHome,
    });
};

export const dailyJuiceFirstPageSponsor = (params) => {
    GTag.dailyJuiceOnClickFirstPageSponsor(
        params.juiceId,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceSponsorFirst,
        objectId: params.juiceId,
    });
};

export const dailyJuiceLastPageSponsor = (params) => {
    GTag.dailyJuiceOnClickLastPageSponsor(
        params.juiceId,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceSponsorLast,
        objectId: params.juiceId,
    });
};

export const dailyJuiceOpen = (params) => {
    GTag.dailyJuiceOpen(
        params.juiceId,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceOpen,
        objectId: params.juiceId,
    });
};

export const dailyJuiceOnSubmitQuiz = (params) => {
    GTag.dailyJuiceOnSubmitQuiz(
        params.juiceId,
        params.quizId,
        params.isCorrect,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryQuizQuiestionSubmit,
        objectId: params.juiceId,
        subObjectId: params.quizId,
        subSubObjectId: params.questionId,
    });
};

export const dailyJuiceOnQuizAnswerClick = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.dailyJuiceOnQuizAnswerClick,
        objectId: params.juiceId,
        subObjectId: params.quizId,
        subSubObjectId: params.answerId,
    });
};

export const dailyJuiceOnPauseWordToSpeech = (params) => {
    GTag.dailyJuiceOnPauseWordToSpeech(
        params.juiceId,
        params.text,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryWordSpeechPause,
        objectId: params.juiceId,
        subObjectId: params.wordId,
    });
};

export const dailyJuiceOnPlayWordToSpeech = (params) => {
    GTag.dailyJuiceOnPlayWordToSpeech(
        params.juiceId,
        params.text,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryWordSpeechPlay,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.wordId,
    });
};

export const dailyJuiceOnClickVocabularyWord = (params) => {
    GTag.dailyJuiceOnClickVocabularyWord(
        params.juiceId,
        params.word,
        params.grades,
        params.classSlug,
    );

    let eventId = eventIds.juiceStoryVocabOpen;
    let objectId = params.juiceId;
    let subObjectId = params.storyId;
    let subSubObjectId = params.wordId;

    if (params.openedFrom === "extra-juice") {
        eventId = eventIds.vocabOpenFromExtraJuice;
        objectId = params.storyId;
        subObjectId = params.wordId;
        subSubObjectId = null;
    }

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId,
        objectId,
        subObjectId,
        subSubObjectId,
    });
};

export const dailyJuiceStoryOpen = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryOpen,
        objectId: params.juiceId,
        subObjectId: params.storyId,
    });
};

export const dailyJuiceOnClickReadStory = (params) => {
    GTag.dailyJuiceOnClickReadStory(
        params.juiceId,
        params.postId,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryReadStory,
        objectId: params.juiceId,
        subObjectId: params.postId,
    });
};

export const dailyJuiceFeaturedImage = (params) => {
    GTag.dailyJuiceFeaturedImage(
        params.juiceId,
        params.storyId,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryImageOpen,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.imageId,
    });
};

export const dailyJuiceVideoEnded = (params) => {
    GTag.dailyJuiceVideoEnded(
        params.juiceId,
        params.storyId,
        params.videoSrc,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryVideoEnd,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.videoId,
    });
};

export const dailyJuiceVideoPause = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryVideoPause,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.videoId,
    });
};

export const dailyJuiceVideoPlay = (params) => {
    GTag.dailyJuiceVideoPlay(
        params.juiceId,
        params.storyId,
        params.videoSrc,
        params.grades,
        params.classSlug,
    );

    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryVideoPlay,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.videoId,
    });
};

export const userSignIn = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.login,
        eventId: eventIds.userLogin,
    });
};

export const userSignOut = (params) => {
    const locationId = parsePathname(params.location);

    addEvent({
        session: params.session,
        locationId,
        eventId: eventIds.userLogout,
    });
};

export const dailyJuiceClose = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceClose,
        objectId: params.juiceId,
    });
};

export const dailyJuiceStoryListen = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.dailyJuiceStoryListen,
        objectId: params.juiceId,
        subObjectId: params.storyId,
    });
};

export const extraJuiceOpen = (params) => {
    let locationId = locationIds.dailyJuice;
    let eventId = eventIds.extraJuiceOpenFromWordInStory;

    if (params.openedBy === "story-button") {
        eventId = eventIds.extraJuiceOpenFromButtonInStory;
    }

    if (params.openedBy === "explore") {
        eventId = eventIds.extraJuiceOpenFromExplore;
        locationId = locationIds.home;
    }

    addEvent({
        session: params.session,
        locationId,
        eventId,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.extraJuiceId,
    });
};

export const extraJuiceReadMore = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.extraJuiceReadMore,
    });
};

export const dailyJuiceOnDefinitionPlay = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryDefinitionPlay,
        objectId: params.juiceId,
        subObjectId: params.storyId,
        subSubObjectId: params.wordId,
    });
};

export const dailyJuiceOnOpenQuiz = (params) => {
    // TODO
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.juiceStoryQuizQuestionOpen,
    });
};

export const studentHomeVideoOpen = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.studentHomeVideoOpen,
        objectId: params.videoId,
    });
};

export const studentHomeVideoPlay = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.studentHomeVideoPlay,
        objectId: params.videoId,
    });
};

export const studentHomeVideoPause = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.studentHomeVideoPause,
        objectId: params.videoId,
    });
};

/* -------------- */

export const classAddStudent = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.class,
        eventId: eventIds.classAddStudent,
        objectId: params.classId,
    });
};

export const classDeleteStudent = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.class,
        eventId: eventIds.classDeleteStudent,
        objectId: params.classId,
    });
};

export const classEditStudent = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.class,
        eventId: eventIds.classEditStudent,
        objectId: params.classId,
    });
};

export const classResetPassword = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.class,
        eventId: eventIds.classResetPassword,
        objectId: params.classId,
    });
};

export const classChangeClass = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.class,
        eventId: eventIds.classChangeClass,
        objectId: params.classId,
    });
};

export const classOpenStudentInfo = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.class,
        eventId: eventIds.classOpenStudentInfo,
        objectId: params.classId,
    });
};

/* -------------- */

export const teacherDailyJuiceOpen = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceOpen,
        objectId: params.juiceId,
    });
};

export const teacherDailyJuiceClose = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceClose,
    });
};

export const teacherDailyJuiceExtraJuiceOpen = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceExtraJuiceOpen,
        objectId: params.juiceId,
        subObjectId: params.extraJuiceId,
    });
};

export const teacherDailyJuiceExtrajuiceVocabOpen = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceExtrajuiceVocabOpen,
        objectId: params.extraJuiceId,
        subObjectId: params.wordId,
    });
};

export const teacherDailyJuiceVideoStart = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceVideoStart,
        objectId: params.storyId,
        subObjectId: params.videoId,
    });
};

export const teacherDailyJuiceVideoPause = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceVideoPause,
        objectId: params.storyId,
        subObjectId: params.videoId,
    });
};

// TODO:
export const teacherDailyJuiceDefinitionPlay = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuiceDefinitionPlay,
        objectId: params.wordId,
    });
};

export const teacherDailyJuicePronouncePlay = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.dailyJuice,
        eventId: eventIds.teacherDailyJuicePronouncePlay,
        objectId: params.wordId,
    });
};

/* -------------- */

export const onSearch = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.search,
        eventId: eventIds.onSearch,
    });

    GTag.onSearch({
        userId: params.userId,
        grades: params.grades,
        word: params.word,
    });
};

export const searchStoryClick = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.search,
        eventId: eventIds.searchStoryClick,
        objectId: params.storyId,
    });

    GTag.searchStoryClick({
        userId: params.userId,
        grades: params.grades,
        storyId: params.storyId,
    });
};

export const searchFilterClick = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.search,
        eventId: eventIds.searchFilterClick,
    });

    GTag.searchFilterClick({
        userId: params.userId,
        grades: params.grades,
    });
};

/* -------------- */

export const teacherHomeChangeClass = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.teacherHomeChangeClass,
        objectId: params.classId,
    });
};

export const teacherHomeEditClassAnnouncement = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.teacherHomeEditClassAnnouncement,
        objectId: params.classId,
    });
};

export const teacherHomeUploadAnImage = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.teacherHomeUploadAnImage,
        objectId: params.classId,
    });
};

export const teacherHomeViewAllScores = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.teacherHomeViewAllScores,
    });
};

/* -------------- */

export const userChangePassword = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.changePassword,
        eventId: eventIds.userChangePassword,
    });
};

/* -------------- */

export const guardianHomeSelectStudent = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.home,
        eventId: eventIds.guardianHomeSelectStudent,
        objectId: params.studentId,
    });
};

export const guardianStudentEditStudent = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.student,
        eventId: eventIds.guardianStudentEditStudent,
        objectId: params.studentId,
    });
};

export const guardianStudentSelectStudent = (params) => {
    addEvent({
        session: params.session,
        locationId: locationIds.student,
        eventId: eventIds.guardianStudentSelectStudent,
        objectId: params.studentId,
    });
};

/* -------------- */
