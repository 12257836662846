export const validate = (value, validators = []) => {
    for (let i = 0; i < validators.length; i += 1) {
        const validator = validators[i];

        if (validator) {
            const msg = validator(value);
            if (msg) {
                return msg;
            }
        }
    }

    return undefined;
};

export const required = (message) => {
    return (value) => {
        return value ? undefined : message;
    };
};

export const email = (message) => {
    return (value) => {
        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            return message;
        }
        return undefined;
    };
};

export const min = (message, minValue) => {
    return (value) => {
        if (value.length && value.length < minValue) {
            return message;
        }
        return undefined;
    };
};

export const isSymbol = (chr) => {
    const symbols = " !\"#$%&'()*+,-./\\:;<=>?@[]^_`{|}~";
    return symbols.indexOf(chr) !== -1;
};

export const hasUpperCase = (message) => {
    return (value) => {
        if (typeof value !== "string") {
            return message;
        }

        let hasUpper = false;

        for (let i = 0; i < value.length; i += 1) {
            const chr = value.charAt(i);

            if (!isSymbol(chr)
                && Number.isNaN(chr * 1)
                && chr === chr.toUpperCase()) {
                hasUpper = true;
                break;
            }
        }

        return hasUpper ? undefined : message;
    };
};

export const hasLowerCase = (message) => {
    return (value) => {
        if (typeof value !== "string") {
            return message;
        }

        let hasLower = false;

        for (let i = 0; i < value.length; i += 1) {
            const chr = value.charAt(i);

            if (Number.isNaN(chr * 1) && chr === chr.toLowerCase()) {
                hasLower = true;
            }
        }

        return hasLower ? undefined : message;
    };
};

export const hasSymbol = (message) => {
    return (value) => {
        if (typeof value !== "string") {
            return message;
        }

        let isValid = false;

        for (let i = 0; i < value.length; i += 1) {
            const chr = value.charAt(i);

            if (isSymbol(chr)) {
                isValid = true;
                break;
            }
        }

        return isValid ? undefined : message;
    };
};
