import React, { useState } from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import UserPasswordResetForm from "juice-base/forms/user-password-reset/index.js";

import styles from "./styles.module.css";


const PopupNewPassword = (props) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [newPassword, setNewPassword] = useState("");

    const onFormChange = (formProps) => {
        setIsDisabled(formProps.isDisabled);
        setNewPassword(formProps.values.password);
    };

    const onSave = () => {
        props.onSave(newPassword);
    };

    const renderContent = () => {
        let content = null;

        if (props.isLoading) {
            content = (
                <RequestLoader />
            );
        } else if (props.isSubmitted) {
            content = (
                <div className={styles.message}>
                    {props.message}
                </div>
            );
        } else {
            content = (
                <UserPasswordResetForm
                    passwordMinLength={props.passwordMinLength}
                    onChange={onFormChange}
                    onSubmit={onSave}
                />
            );
        }

        return (
            <div className={styles.content}>
                {content}
            </div>
        );
    };

    let buttonSave = null;

    if (!props.isSubmitted) {
        buttonSave = (
            <ButtonFlat
                onClick={onSave}
                disabled={isDisabled}
            >
                Save
            </ButtonFlat>
        );
    }

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                {buttonSave}

                <ButtonFlat
                    onClick={props.onClose}
                    disabled={props.isLoading}
                >
                    {props.isSubmitted ? "Close" : "Cancel"}
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupNewPassword.defaultProps = {
    message: null,
    passwordMinLength: 8,
    isSubmitted: false,
    isLoading: false,
    onSave: () => {},
    onClose: () => {},
};

export default PopupNewPassword;
