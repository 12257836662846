import React, { useState, useRef } from "react";

import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const ButtonMenu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const backgroundLayout = useRef(null);

    const onLayoutClick = (evt) => {
        if (backgroundLayout) {
            if (backgroundLayout.current === evt.target) {
                setMenuOpen(false);
            }
        }
    };

    const renderLayout = () => {
        if (!menuOpen) {
            return null;
        }

        return (
            // eslint-disable-next-line
            <div
                ref={backgroundLayout}
                className={styles.layout}
                onClick={onLayoutClick}
                onKeyPress={onLayoutClick}
                role="button"
                tabIndex="-1"
            />
        );
    };

    const onMenuClick = () => {
        if (!props.isDisabled) {
            setMenuOpen(!menuOpen);
        }
    };

    const onMenuClicked = () => {
        if (props.closeMenuOnClick) {
            setMenuOpen(false);
        }
    };

    const renderMenu = () => {
        if (!menuOpen) {
            return null;
        }

        return (
            <div
                onClick={onMenuClicked}
                onKeyPress={onMenuClicked}
                role="button"
                tabIndex="-1"
                className={[
                    styles.menuButtons,
                    props.menuButtonsClassName,
                ].join(" ")}
            >
                {props.menuButtons}
            </div>
        );
    };

    const menuClasses = [styles.menuButton];

    if (props.isDisabled) {
        menuClasses.push(styles.desabledMenuButton);
    }

    return (
        <div
            className={styles.menu}
            data-comment={props.dataComment}
        >
            {renderLayout()}
            {renderMenu()}
            <div
                className={menuClasses.join(" ")}
                onClick={onMenuClick}
                onKeyPress={onMenuClick}
                role="button"
                tabIndex="-1"
            >
                <img
                    src={staticFiles.threeDotsMenu}
                    alt="Menu"
                    title="Menu"
                />
            </div>
        </div>
    );
};

ButtonMenu.defaultProps = {
    menuButtons: [],
    menuButtonsClassName: "",
    dataComment: "",
    isDisabled: false,
    closeMenuOnClick: false,
};

export default ButtonMenu;
