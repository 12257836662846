import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmTitle,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

const PopupConfirmStudentDuplicate = (props) => {
    let duplicateData = null;

    let addDuplicateButton = null;

    if (props.errorCode === 0) {
        duplicateData = `${props.user.nameValue} ${props.user.lastNameValue}`;

        addDuplicateButton = (
            <ButtonFlat onClick={props.onAddDuplicate}>
                Add Duplicate
            </ButtonFlat>
        );
    } else if (props.errorCode === 1) {
        duplicateData = props.user.emailValue;
    }

    return (
        <PopupConfirm>
            <PopupConfirmTitle>
                {`Found duplicate user: ${duplicateData}`}
            </PopupConfirmTitle>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onSkipDuplicate}>
                    Skip User
                </ButtonFlat>
                {addDuplicateButton}
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmStudentDuplicate.defaultProps = {
    user: {},
    errorCode: 0,
    onSkipDuplicate: () => {},
    onAddDuplicate: () => {},
};

export default PopupConfirmStudentDuplicate;
