// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fatPLr6N1_LGKbv3lY8z {\n    padding: 0 1rem;\n\n    color: var(--grey);\n    font-size: 1.4rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/message-password/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;IAEf,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".message {\n    padding: 0 1rem;\n\n    color: var(--grey);\n    font-size: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "fatPLr6N1_LGKbv3lY8z"
};
export default ___CSS_LOADER_EXPORT___;
