// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._rLcsgMUgZh7C_rdNuIm {\n    display: grid;\n    justify-content: center;\n    justify-items: center;\n    align-items: center;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.U5yW7IzfEfDNi6hQmuC3 {\n    grid-template-rows: 4rem min-content;\n}\n\n.hT0xpgy9OIL9XB6jHPxh {}\n\n.wIG8lp464d9jxxW0QYKV {\n    font-size: 1rem;\n    font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./base/components/icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;;IAEnB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,oCAAoC;AACxC;;AAEA,uBAAM;;AAEN;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".container {\n    display: grid;\n    justify-content: center;\n    justify-items: center;\n    align-items: center;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.conteinerWithName {\n    grid-template-rows: 4rem min-content;\n}\n\n.img {}\n\n.iconName {\n    font-size: 1rem;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_rLcsgMUgZh7C_rdNuIm",
	"conteinerWithName": "U5yW7IzfEfDNi6hQmuC3",
	"img": "hT0xpgy9OIL9XB6jHPxh",
	"iconName": "wIG8lp464d9jxxW0QYKV"
};
export default ___CSS_LOADER_EXPORT___;
