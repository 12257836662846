// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z5jfZT0XPCjL4EYvnF5Z {\n    background-color: #fff;\n}\n\n/* --- */\n\n._HhU1_9YNLnk6E_ddqe_ {}\n\n/* --- */\n\n.UMUR_D6ayGTA_OZJB_l5 {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.DBZCtjoPjmmk8gJ6vdkA {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.KaG0IDudTDpr5RJ1tMZF {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: max-content auto;\n    grid-gap: 1rem;\n\n    padding: 0 2rem 2rem 2rem;\n}\n\n.NLJeIxyJIqoag7EaF9FG {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .UMUR_D6ayGTA_OZJB_l5,\n    .DBZCtjoPjmmk8gJ6vdkA,\n    .KaG0IDudTDpr5RJ1tMZF,\n    .NLJeIxyJIqoag7EaF9FG {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-story/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA,QAAQ;;AAER,uBAAyB;;AAEzB,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uCAAuC;IACvC,cAAc;;IAEd,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;;;;QAII,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".juiceStory {\n    background-color: #fff;\n}\n\n/* --- */\n\n.featuredVideoContainer {}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.headline {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.playerAudioToggleButton {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: max-content auto;\n    grid-gap: 1rem;\n\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .headline,\n    .playerAudioToggleButton,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"juiceStory": "Z5jfZT0XPCjL4EYvnF5Z",
	"featuredVideoContainer": "_HhU1_9YNLnk6E_ddqe_",
	"category": "UMUR_D6ayGTA_OZJB_l5",
	"headline": "DBZCtjoPjmmk8gJ6vdkA",
	"playerAudioToggleButton": "KaG0IDudTDpr5RJ1tMZF",
	"content": "NLJeIxyJIqoag7EaF9FG"
};
export default ___CSS_LOADER_EXPORT___;
