import React from "react";

import styles from "./styles.module.css";


const SectionTeacherHeader = (props) => {
    let bottomControls = null;

    if (props.bottomControls.length > 0) {
        bottomControls = (
            <div className={styles.headerBottom}>
                {props.bottomControls}
            </div>
        );
    }

    return (
        <div className={styles.header}>
            <div className={styles.headerTop}>
                <div className={styles.headerLabel}>
                    {props.label}
                </div>
                <div className={styles.headerRightControls}>
                    {props.rightControls}
                </div>
            </div>
            {bottomControls}
        </div>
    );
};

SectionTeacherHeader.defaultProps = {
    label: "",
    rightControls: [],
    bottomControls: [],
};

export default SectionTeacherHeader;
